// React betöltése
var React = require('react');
// -----------------------------------------------------

// Nyelvesítés betöltése
var i18nLoader = require('../commons/I18n.js');
// -----------------------------------------------------

// Brands betöltése
var Brands = require('./Brands.jsx');
// -----------------------------------------------------

module.exports = {

	lang: function () {
		return localStorage.getItem('lang');
	},

	getInitialState: function () {
		return { lang: i18nLoader.getLanguageValues(this.lang()) };
	},

	setLangValue: function (lang) {
		localStorage.setItem('lang',lang);
		if (window.CC) {
			window.CC.updateLanguage(lang, true)
		}
	},

	setLangState: function () {
		this.setState( {
			lang: i18nLoader.getLanguageValues(this.lang())
		});
		this.setResourceState();
	},

	setLangStateToken: function (showMessageAndLoginFunction) {
		this.setState( {
			lang: i18nLoader.getLanguageValues(this.lang())
		}, function(){
			showMessageAndLoginFunction();
		});
	},

	getValue: function (label) {
		var value = this.state.lang[label];

		if(typeof value === 'undefined') {
			return '{{' + label + '}}';
		} else {
			return value;
		}
	}

};
