

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');

import { Utils } from '../model/Utils';

module.exports = React.createClass({

  mixins: [Brands],

  getInitialState: function () {

    return {
      open: false
    };
  },

  getDefaultProps: function () {
    return {
      menuShow: true
    };
  },

  openMobileMenuList: function () {

    $('#mobilMenu').attr('data-open', 'true');
  },

  render: function () {

    if (this.props.menuShow) {
      var menuButton = (<button id="mobileMenuButton" className="icon-hamburgerMenu" onClick={this.openMobileMenuList}></button>);
    } else {
      var menuButton = '';
    }

    let brand = this.getBrand();
    let logoResource = this.getResourceItemValue('logoLying');
    let logoElement = Utils.getClickableLogoForResource(brand, logoResource);
    return (
      <header className="mobileHeader">
        {menuButton}
        <span className="mobilMenuName">{this.props.mobilMenuName}</span>
        {logoElement}
      </header>
    );
  }
});
