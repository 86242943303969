// React betöltése
var React = require('react');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('./Languages.jsx');

module.exports = {

	getInitialState: function() {
	    return { resource: this.getResource() };
	},

	setResourceState: function() {
	    this.setState( {
		resource: this.getResource()
	    });
	},

	getBrand: function() {
	    var brand = "itrack";
	    var hostname = window.location.hostname;
	    if(hostname.indexOf("topcop") !== -1) {
		brand = "topcop";
			} else if (hostname.indexOf("tolltickets") !== -1) {
				brand = "tolltickets";
			}
	    return brand;
	},

	getResource: function () {
	    var brand = this.getBrand();
	    var language = Languages.lang();
	    var brandAndLanguage = brand + '/' + language;
	    switch(brandAndLanguage) {
		case 'itrack/hu':
		    var resource = require('./../../resources/itrack/hu.js');
		    break;
		case 'itrack/en':
		    var resource = require('./../../resources/itrack/en.js');
		    break;
		case 'itrack/ro':
		    var resource = require('./../../resources/itrack/ro.js');
		    break;
		case 'itrack/sr':
		    var resource = require('./../../resources/itrack/sr.js');
			break;
		case 'itrack/hr':
		    var resource = require('./../../resources/itrack/hr.js');
		    break;
		case 'itrack/sk':
			var resource = require('./../../resources/itrack/en.js'); // Use en version for logo
			break;
		case 'itrack/cz':
			var resource = require('./../../resources/itrack/en.js'); // Use en version for logo
			break;
		case 'topcop/hu':
		    var resource = require('./../../resources/topcop/hu.js');
		    break;
		case 'topcop/en':
		    var resource = require('./../../resources/topcop/en.js');
		    break;
		case 'topcop/ro':
		    var resource = require('./../../resources/topcop/ro.js');
		    break;
		case 'topcop/sr':
		    var resource = require('./../../resources/topcop/sr.js');
			break;
		case 'topcop/hr':
		    var resource = require('./../../resources/topcop/hr.js');
		    break;
		case 'topcop/sk':
			var resource = require('./../../resources/topcop/en.js');
			break;
		case 'topcop/cz':
			var resource = require('./../../resources/topcop/en.js');
			break;
		case 'tolltickets/hu':
		    var resource = require('./../../resources/tolltickets/hu.js');
		    break;
		case 'tolltickets/en':
		    var resource = require('./../../resources/tolltickets/en.js');
		    break;
		case 'tolltickets/ro':
		    var resource = require('./../../resources/tolltickets/ro.js');
		    break;
		case 'tolltickets/sr':
		    var resource = require('./../../resources/tolltickets/sr.js');
			break;
		case 'tolltickets/hr':
		    var resource = require('./../../resources/tolltickets/hr.js');
		    break;
		case 'tolltickets/sk':
			var resource = require('./../../resources/tolltickets/en.js');
			break;
		case 'tolltickets/cz':
			var resource = require('./../../resources/tolltickets/en.js');
			break;
		default:
		    var resource = require('./../../resources/itrack/hu.js');
	    }
	    return resource;
	},

	getResourceItemValue: function(imageKey) {
	    var value = this.state.resource[imageKey];
	    if(typeof value === 'undefined') {
		return 'fallback';
	    }
	    return value;
	}
};
