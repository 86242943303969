// React betöltése
var React = require('react');
// -----------------------------------------------------
var $ = require('jquery');
var NotificationSystem = require('react-notification-system');

var style = require('../plugin/NotificationStyle.js');

export default function Notify(Component) {
    class Notified extends React.Component {

        constructor(props) {
            super(props);
            this.notification = null;
            this.notificationSystem = null;
            this.autoDismiss = 10;
            this.addNotification = this.addNotification.bind(this);
            this.addTranslateNotification = this.addTranslateNotification.bind(this);
        }

        addNotification(title, level, action) {
            this.initNotification(title, level, action);
        }

        addTranslateNotification(title, level, action) {
            var translateTitle = this.props.getLangValue(title);
            this.initNotification(translateTitle, level, action);
        }

        initNotification(title, level, action) {
            if (this.notificationSystem != null) {
                var autoDismiss = this.autoDismiss;
                var _this = this;
                setTimeout(function () {
                    var notificationsByLevelAndTitle = $(".notification-" + level + ":contains(" + title + ")");
                    if (notificationsByLevelAndTitle != null && notificationsByLevelAndTitle.length != 0) {
                        return;
                    }

                    if (level == 'error') {
                        autoDismiss = 0;

                        if (_this.notification != null) {
                            _this.notificationSystem.removeNotification(_this.notification);
                            _this.notification = null;
                        }
                    }

                    var notification = _this.notificationSystem.addNotification({
                        title: title,
                        level: level,
                        autoDismiss: autoDismiss,
                        action: action
                    });

                    _this.notification = notification;
                    if (level == 'error') {
                        _this.setState({ state: _this.state });
                    }

                }, 100);
            }
        }

        componentDidMount() {
            this.notificationSystem = this.refs.notificationSystem;
            this.setState({ state: this.state });
        }

        render() {
            var notificationVersions = {
                addNotification: this.addNotification,
                addTranslateNotification: this.addTranslateNotification,
                notification: this.notification,
                notificationSystem: this.notificationSystem
            }
            return (
                <div>
                    <Component
                        notificationVersions={notificationVersions}
                        rednderNotification={this.rednderNotification}
                        {...this.props}
                        {...this.state} />
                    <NotificationSystem ref="notificationSystem" style={style} />
                </div>
            );
        }

    }
    return Notified;
}