import { TrackTargetsExecutor } from './TrackTargetsExecutor';
import { TrackTargets } from './TrackTargets';
import Ajax from '../HOCs/Ajax';
var $ = require('jquery');
var _ = require("lodash");

class PopupTargetRefresh {

    constructor(updateLiveDataFunction, visitor, notificationVersions, getLangValue) {
        this.popupContentSelector = ".popupContent";
        this.visitor = visitor;
        this.notificationVersions = notificationVersions;
        this.getLangValue = getLangValue;
        this.updateLiveDataFunction = updateLiveDataFunction;
        this.actualTargetId = -1;
        this.updateTargetData = this.updateTargetData.bind(this);
        this.processUpdateTargetOnPopupOpen = this.processUpdateTargetOnPopupOpen.bind(this);
        this.unableToUpdateTargetOnPopupOpen = this.unableToUpdateTargetOnPopupOpen.bind(this);
    }

    reAddDataLoaderToPopup() {
        $(this.popupContentSelector).attr('data-loader', true);
    }

    isTargetPopupRefreshInProgress(targetId) {
        if (this.actualTargetId == targetId) {
            return true;
        }
        return false;
    }

    abortAllPopupRefreshCall() {
        for (var x in $.popupRefresh) {
            if ($.popupRefresh[x] != null) {
                $.popupRefresh[x].abort();
            }
        }
        $.popupRefresh = [];
    }

    updateTargetData(targetId, timeMicros) {
        if (this.actualTargetId == targetId) {
            this.reAddDataLoaderToPopup();
            var refreshInProgressMessage = this.getLangValue('refreshInProgress');
            this.notificationVersions.addNotification(refreshInProgressMessage, 'warning');
            return;
        }
        this.abortAllPopupRefreshCall()
        $(this.popupContentSelector).attr('data-loader', true);
        this.actualTargetId = targetId;
        var selectedTarget = {
            targetId: targetId,
            fromTimeMicros: timeMicros,
            asynchronous: false
        }
        var clickedTargetArray = [selectedTarget];
        var xhr = TrackTargets.query(clickedTargetArray, this.processUpdateTargetOnPopupOpen, this.unableToUpdateTargetOnPopupOpen, this.visitor, targetId);
        $.popupRefresh.push(xhr);
    }

    processUpdateTargetOnPopupOpen(refreshResponse) {
        $(this.popupContentSelector).attr('data-loader', false);
        this.actualTargetId = -1;
        var liveDataArray = [];
        refreshResponse.map(function (response) {
            var targetId = response.target.id;
            liveDataArray.push({
                targetId: targetId,
                liveData: response,
                trackingState: TrackTargetsExecutor.processState(response)
            });
        });
        this.updateLiveDataFunction(liveDataArray);
    }

    unableToUpdateTargetOnPopupOpen(response, textStatus, error) {
        $(this.popupContentSelector).attr('data-loader', false);
        this.actualTargetId = -1;
        if (textStatus != "abort") {
            Ajax.showError(response, textStatus, error, this.notificationVersions, this.getLangValue);
        }
    }
}
export { PopupTargetRefresh };