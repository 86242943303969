// React betöltése
var React = require('react');
// -----------------------------------------------------

module.exports = React.createClass({

	render: function() {

		var classNamePage = 'loaderLogin';


		return (
			<div className={classNamePage}><span></span></div>
		);
	}
});