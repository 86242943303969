

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------


module.exports = React.createClass({

  mixins: [Languages],

  getDefaultProps: function() {
    return {
      changeMobilHeaderTitle: function(){}
    };
  },

  handlerBackButton: function() {


    if($('#calendarBox').hasClass('hidden') == true) {
      $('#calendarBox').removeClass('hidden');
      $('.mapButton').removeClass('hidden');
    } else {
			this.props.changeMobilHeaderTitle(this.getValue('cars'));
			this.props.mobilSelectCar();
      $('#trackingTabs .backButton').addClass('hidden');
      $('#trackingTabs .title').addClass('col-xs-offset-4');
      $('#carsList').removeClass('mobilHide');
      $('.mapButton').addClass('hidden');
    }
  },

  handlerMapButton: function() {
    $('#calendarBox').addClass('hidden');
    $('.mapButton').addClass('hidden');
  },

  render: function() {

    return (
      <div id="trackingTabs" className="trackingTabs">
        <ul>
          <li className="col-xs-4 backButton hidden">
            <button onClick={this.handlerBackButton}><i className="icon-arrowLeft"></i>{this.getValue('back')}</button>
          </li>
          <li className="col-xs-4 col-xs-offset-4 title">
            <h2>{this.props.title}</h2>
          </li>
          <li className="col-xs-4 mapButton hidden">
            <button onClick={this.handlerMapButton}>{this.getValue('map')}<i className="icon-arrowLeft"></i></button>
          </li>
        </ul>
      </div>
    )
  }
});
