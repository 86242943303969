// Nyelvek tárolása

module.exports = {
  defaultLanguage: "hu",
  languages: {
    "hu": "hu",
    "en": "en",
    "ro": "ro",
    "sr": "sr",
    "hr": "hr",
    "sk": "sk",
    "cz": "cz"
  }
}
