class DefaultTarget {

	constructor(id, licenseplate, description, enabled) {
		this.id = id;
		this.description = description;
		this.licenseplate = licenseplate;
		this.enabled = enabled;
	}

	getId() {
		return this.id;
	}

	getDescription() {
		if (this.description) {
			return this.description;
		}
		return "";
	}

	getLicenseplate() {
		return this.licenseplate;
	}

	setEnabled(enabled) {
		this.enabled = enabled;
	}

	getEnabled() {
		return this.enabled;
	}
}
export { DefaultTarget };
