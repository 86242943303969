import { TargetList } from '../model/TargetList';
import { TrackingRequest } from '../model/TrackingRequest';
import { TrackTargets } from '../remote/TrackTargets';
import { TrackedTargetState } from '../model/TrackedTargetState';
import Ajax from '../HOCs/Ajax';
import ServerMaintenance from '../model/ServerMaintenance';
var $ = require('jquery');

class TrackTargetsExecutor {

	constructor(updateLiveDataFunction, visitor, notificationVersions, getLangValue, getPopupTargetIdFunction) {
		this.trackedTargets = new TargetList();
		this.updateLiveDataFunction = updateLiveDataFunction;
		this.processResponse = this.processResponse.bind(this);
		this.processError = this.processError.bind(this);
		this.stopLongPolling = this.stopLongPolling.bind(this);
		this.restartLongPolling = this.restartLongPolling.bind(this);
		this.currentRequest = null;
		this.timer = null;
		this.timeoutTime = 0;
		this.longPollingTimeout = null;
		this.visitor = visitor;
		this.notificationVersions = notificationVersions;
		this.getLangValue = getLangValue;
		this.getPopupTargetIdFunction = getPopupTargetIdFunction;
	}

	abortPreviousAjaxCalls() {
		for (var x in $.trackingAjax) {
			if ($.trackingAjax[x] != null) {
				$.trackingAjax[x].abort();
			}
		}
		$.trackingAjax = [];
	}

	stopLongPolling() {
		this.abortPreviousAjaxCalls();
		if (this.currentRequest != null) {
			this.currentRequest.abort();
		}
	}

	restartLongPolling() {
		this.stopLongPolling();

		var trackedTargetArray = this.trackedTargets.getAll();
		var tracingRequestArray = [];
		trackedTargetArray.map(function (trackedTarget) {
			if (trackedTarget.getTrackingState() != TrackedTargetState.NoData) {
				var targetId = trackedTarget.getId();
				var trackRequestTarget = new TrackingRequest(targetId, 0, false);
				tracingRequestArray.push(trackRequestTarget);
			}
		});
		var popupTargetId = this.getPopupTargetIdFunction();
		var xhr = TrackTargets.query(tracingRequestArray,
			this.processResponse,
			this.processError,
			this.visitor,
			popupTargetId);
		$.trackingAjax.push(xhr);
		this.currentRequest = xhr;
	}

	didLogoutHappend() {
		if (this.visitor) {
			return false;
		}
		else if ((localStorage.getItem('user_id') == -1) || (typeof localStorage.getItem('user_hash') == 'undefined')) {
			return true;
		}
		return false;
	}

	shouldSendRequest() {
		if (this.trackedTargets.length() == 0 || this.didLogoutHappend()) {
			return false;
		}
		return true;
	}

	sendRequest() {
		this.stopLongPolling();
		var tracingRequestArray = TrackTargetsExecutor.constructRequest(this.trackedTargets);
		var popupTargetId = this.getPopupTargetIdFunction();
		var xhr = TrackTargets.query(tracingRequestArray,
			this.processResponse,
			this.processError,
			this.visitor,
			popupTargetId);
		$.trackingAjax.push(xhr);
		this.currentRequest = xhr;
	}

	updateTrackedTargets(newTargetList, notificationVersions) {
		var prevTargetList = this.trackedTargets;
		this.trackedTargets = newTargetList;
		this.notificationVersions = notificationVersions;

		if (this.shouldSendRequest() && !prevTargetList.equalsOnIds(newTargetList)) {
			var prevPendingTargets = prevTargetList.getPendingTargetIds();
			var newPendingTargets = newTargetList.getPendingTargetIds();
			var isAnyStillPending = prevPendingTargets.some(element => newPendingTargets.indexOf(element) !== -1);

			if (!isAnyStillPending) {
				if (this.timer !== null) {
					clearTimeout(this.timer);
				}
				this.timer = setTimeout(function () {
					this.sendRequest();
				}.bind(this), 500);
			}
		}
	}

	processResponse(trackingResponse) {
		if (this.notificationVersions.notification != null && this.notificationVersions.notification.level == "error" && this.notificationVersions.notificationSystem != null) {
			if (ServerMaintenance.serverMaintenance == true) {
				this.notificationVersions.notificationSystem.removeNotification(this.notificationVersions.notification);
				ServerMaintenance.serverMaintenance = false;
			}
		}
		if (!this.didLogoutHappend()) {
			var liveDataArray = [];
			trackingResponse.map(function (singleResponse) {
				liveDataArray.push({
					targetId: singleResponse.target.id,
					liveData: singleResponse,
					trackingState: TrackTargetsExecutor.processState(singleResponse)
				});
			});
			this.updateLiveDataFunction(liveDataArray);
			if (this.shouldSendRequest()) {
				this.sendRequest();
			}
		}
	}

	scheduleTimeoutIfNeeded() {
		if (this.timeoutTime == 0) {
			this.timeoutTime = 5000;
		}
		else {
			this.timeoutTime *= 2;
			if (this.timeoutTime > (5 * 60 * 1000)) {
				this.timeoutTime = (5 * 60 * 1000);
			}
		}
		this.longPollingTimeout = setTimeout(function () {
			this.sendRequest();
		}.bind(this), this.timeoutTime);
	}

	processError(response, textStatus, error) {
		if (!this.didLogoutHappend()) {
			if (textStatus != "abort") {
				Ajax.showError(response, textStatus, error, this.notificationVersions, this.getLangValue);
				this.scheduleTimeoutIfNeeded();
			}
		}
	}

	/**
	 * Ajax hívás létrehozása a longpollinghoz
	 */
	static constructRequest(trackedTargetList) {
		var trackedTargetArray = trackedTargetList.getAll();
		var anyPendingTarget = trackedTargetList.getPendingTargetIds().length > 0;
		var tracingRequestArray = [];

		trackedTargetArray.filter(trackedTarget => {
			return !(anyPendingTarget && trackedTarget.getTrackingState() === TrackedTargetState.NoData)
		}).map(trackedTarget => {
			var targetId = trackedTarget.getId();
			var fromTimeMicros = TrackTargetsExecutor.getFromTimeMicros(trackedTarget);
			var asynchronous = TrackTargetsExecutor.getAsynchronous(trackedTarget);
			var trackRequestTarget = new TrackingRequest(targetId, fromTimeMicros, asynchronous);
			tracingRequestArray.push(trackRequestTarget);
		})
		return tracingRequestArray;
	}

	static getFromTimeMicros(trackedTarget) {
		if (trackedTarget.getTrackingState() == TrackedTargetState.Live) {
			return trackedTarget.liveData.currentPosition.timeMicros;
		}
		return -1;
	}

	static getAsynchronous(trackedTarget) {
		return (trackedTarget.getTrackingState() == TrackedTargetState.Pending) ? false : true;
	}

	static processState(singleResponse) {
		var currentPosition = singleResponse.currentPosition;
		if (currentPosition !== undefined && currentPosition !== null && currentPosition.timeMicros !== undefined) {
			return TrackedTargetState.Live;
		}
		return TrackedTargetState.NoData;
	}
}

export { TrackTargetsExecutor };