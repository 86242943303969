var React = require('react');
var classSet = require('react/lib/cx');
var Input = require('react-bootstrap/lib/Input');

module.exports = React.createClass({

  propTypes: {
    value: React.PropTypes.string
  },

  getInitialState: function() {
    return {
      error: false,
      value: this.props.value
    };
  },

  setError: function(errorMessage) {
    this.setState({
      error: true,
      value: errorMessage
    });
  },

  removeError: function() {
    this.setState({
      error: false,
      value: this.props.value
    });
  },

  setValue: function(value) {
    this.setState({ label: value });
  },

  render: function() {
    var classes = {};
    classes['FormElement'] = true;
    classes['FormElementButton'] = true;

    if (this.state.error) {
      classes['hasError'] = true;
    }

    return (
      <Input
        {...this.props}
        type="submit"
        value={this.state.value}
        groupClassName={classSet(classes)}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        ref="button" />
    )
  }
});
