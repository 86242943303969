var $ = require('jquery');
var Base64 = require('js-base64').Base64;

module.exports = {

    passwordResetErrorcallback: function(response,status,xhr,errorAction,addNotification,addTranslateNotification){
		var isSleep = localStorage.getItem('isSleep');
		var httpStatus = response.status;
		var readyState = response.readyState;
		var label = '';
		var text = '';

		// Ajax loader elrejtése, a bejelentkezés gomb megjelenítése
		$('.loaderLogin').hide();
		$('.FormElementButton').parent().show();
		// -----------------------------------------------------

		var action = errorAction;

		if (readyState == 0) {
			if(isSleep == 'false'){
				switch (status) {
					case "error":
						if($('.offline-ui').hasClass('offline-ui-down')){
							addTranslateNotification("networkError", "error", action);
						}
						else{
							addTranslateNotification("systemError", "error", action);
						}
						break;
					case "timeout":
						addTranslateNotification("timeout", "error", action);
						break;
				}
			}
		}
		else {
			if (httpStatus >= 400 && httpStatus < 500) {
				label = 'warning';
				action = null;
			} else if (httpStatus >= 500) {
				label = 'error';
			}
			if(httpStatus != 504){
				if (typeof response.responseJSON != 'undefined') {
					var errorMessage = response.responseJSON.localizedMessage;
					if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
						text = errorMessage;

						if(httpStatus == 401){
							text = 'passwordResetTokenAuthenticationFailed';
							addTranslateNotification(text,label,action);
							$('input[type="password"].form-control').val('');
						}else{
							addNotification(text, label, action);
						}

					} else {
						addTranslateNotification('systemError', label, action);
					}
				}
				else if(typeof response.responseText != 'undefined') {
					try{
						var parsedErrorMessage = JSON.parse(response.responseText);
						var errorMessage = parsedErrorMessage.localizedMessage;
						if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
							text = errorMessage;
							addNotification(text, label, action);
						}
						else{
							addTranslateNotification('systemError', label, action);
						}
					}
					catch(error){
						addTranslateNotification('systemError', label, action);
					}
				}
				else{
					addTranslateNotification('systemError', label, action);
				}
			}
		}
	},
}
