var $ = require('jquery');
var Base64 = require('js-base64').Base64;
import { SentryUtils } from '../model/SentryUtils';

module.exports = {

	authenticate: function (mail, password, errorAction, addNotification, addTranslateNotification, callback) {
		this.loginAjax(
			Base64.encode(mail + ':' + password),
			errorAction,
			addNotification,
			addTranslateNotification,
			function (response) {
				localStorage.setItem('user_id', response.id);
				localStorage.setItem('user_hash', Base64.encode(mail + ':' + password));

				callback();
			},
			false
		);
	},
	checkLogin: function (callback) {
		this.loginAjax(localStorage.getItem('user_hash'), null, null, null, callback, true, null);
	},
	loginAjax: function (hash, errorAction, addNotification, addTranslateNotification, callback, isCheck) {
		$.ajax({
			url: "@@servletUrl" + "users/current",
			type: "GET",
			timeout: 30000,
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Authorization", "Basic " + hash);
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success: function (response, status, xhr) {
				SentryUtils.setUser(response.id);
				if (isCheck) {
					callback(xhr);
				} else {
					callback(response);
				}
			},
			error: function (response, status, xhr) {
				SentryUtils.setUser(null);
				var isSleep = localStorage.getItem('isSleep');
				var httpStatus = response.status;
				var readyState = response.readyState;
				var label = '';
				var text = '';

				// Ajax loader elrejtése, a bejelentkezés gomb megjelenítése
				$('.loaderLogin').hide();
				$('.FormElementButton').parent().show();
				// -----------------------------------------------------

				if (response.status == 401) {
					$('.icon-user').addClass('error animated shake');
					$('.icon-password').addClass('error animated shake');

					$('input[type="password"].form-control').val('');
				}

				if (isCheck) {
					if (typeof callback == 'function') {
						callback(httpStatus);
					}
					return;
				}

				var action = errorAction;

				if (readyState == 0) {
					if (isSleep == 'false') {
						switch (status) {
							case "error":
								if ($('.offline-ui').hasClass('offline-ui-down')) {
									addTranslateNotification("networkError",
										"error",
										action);
								}
								else {
									addTranslateNotification("systemError",
										"error",
										action);
								}
								break;
							case "timeout":
								addTranslateNotification("timeout",
									"error",
									action);
								break;
						}
					}
				}
				else {
					if (httpStatus >= 400 && httpStatus < 500) {
						label = 'warning';
						action = null;
					} else if (httpStatus >= 500) {
						label = 'error';
					}
					if (httpStatus != 504) {
						if (typeof response.responseJSON != 'undefined') {
							var errorMessage = response.responseJSON.localizedMessage;
							if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
								text = errorMessage;
								addNotification(text, label, action);
							} else {
								addTranslateNotification('systemError', label, action);
							}
						}
						else if (typeof response.responseText != 'undefined') {
							try {
								var parsedErrorMessage = JSON.parse(response.responseText);
								var errorMessage = parsedErrorMessage.localizedMessage;
								if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
									text = errorMessage;
									addNotification(text, label, action);
								}
								else {
									addTranslateNotification('systemError', label, action);
								}
							}
							catch (error) {
								addTranslateNotification('systemError', label, action);
							}
						}
						else {
							addTranslateNotification('systemError', label, action);
						}
					}
				}
			}
		});
	},
}
