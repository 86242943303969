var auth = require('../commons/authenticate.js');
var $ = require('jquery');
import LastPath from '../model/LastPath';

module.exports = {
  statics: {
    willTransitionTo: function (transition) {
      var _this = this;
      var path = transition.path;

      if (path == '/tracking' || path == '/router' || path == '/fueling') {
        $('#overlay').addClass('overlay');
        $('#loader').addClass('loader');
        $('#exitButton').addClass('exitButton icon-cancel');
      }

      LastPath.lastPath = transition.path.replace('/', '');

      var redirectToLogin = function () {
        localStorage.removeItem('user_hash');
        localStorage.removeItem('user_id');
        localStorage.attemptedPathBeforeLogin = transition.path;
        //window.location = '/';
        window.location = window.location.href.substr(0, window.location.href.indexOf(window.location.hash));
      }

      if (!localStorage.hasOwnProperty("user_hash")) {
        redirectToLogin();
      }

      auth.checkLogin(function (httpStatus) {
        if (httpStatus == 401) {
          redirectToLogin();
        }
      });

      /*auth.validateToken().done(function(data, status, xhr) {
        if (xhr.status != 200) {
          redirectToLogin();
        } else {
          localStorage.fireflyToken = data.token;
        }
      }).fail(function() {
        redirectToLogin();
      });*/
    }
  }
}
