// React betöltése
var React = require('react');
var $ = require('jquery');

var auth = require('../commons/authenticate.js');
import LastPath from '../model/LastPath';

export default function Authentication(Component) {

    class Authenticated extends React.Component {

        constructor(props) {
            super(props)
        }

        static willTransitionTo(transition) {

            var path = transition.path;

            if (path == '/tracking' || path == '/router' || path == '/fueling') {
                $('#overlay').addClass('overlay');
                $('#loader').addClass('loader');
                $('#exitButton').addClass('exitButton icon-cancel');
            }

            LastPath.lastPath = transition.path.replace('/', '');

            var redirectToLogin = function () {
                localStorage.removeItem('user_hash');
                localStorage.removeItem('user_id');
                localStorage.attemptedPathBeforeLogin = transition.path;
                window.location = window.location.href.substr(0, window.location.href.indexOf(window.location.hash));
            }

            if (!localStorage.hasOwnProperty("user_hash")) {
                redirectToLogin();
            }

            auth.checkLogin(function (httpStatus) {
                if (httpStatus == 401) {
                    redirectToLogin();
                  }
            });
        }

        render() {
            return (
                <Component {...this.props} {...this.state} />
            );
        }

    }

    return Authenticated

}