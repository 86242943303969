module.exports = {
    loginTitle: "Prijava",
    password: "Lozinka",
    tracking: "Praćenje",
    router: "Povijest",
    message: "Poruke",
    fueling: "Lista točenja goriva",
    logout: "Odjava",
    email: "E-mail",
    wrongEmailFormat: "Pogrešan email format!",
    forgotYourPassword: "Zaboravili lozinku?",
    forgotEmailUsernamePair: "Neuspjelo slanje email-a?",
    forgotEmailUsernamePairBody: "Poštovani Klijentu!<br/>Ukoliko niste uspjeli promjeniti zaboravljenu lozinku, molimo Vas da stupite u kontakt s našom korisničkom podrškom.<br/><br/>",
    successPasswordResetEmailSend: "Ukoliko ste unesli ispravnu email adresu i korisničko ime, poslati ćemo Vam na unesenu e-mail adresu link za promjenu iste! ",
    successPasswordChange: "Uspješna promjena lozinke! Od sada se možete prijaviti u naš sustav pomoću nove lozinke!",
    sendPasswordResetButtonText: "Slanje email-a!",
    emptyFieldPasswordReset: "Nije popunjeno polje email adrese ili lozinke!",
    passwordAgain: "Ponovljena lozinka",
    emptyFieldChangePassword: "Ne može ostati prazno niti jedno polje za lozinku!",
    passwordsNotMatch: "Upisane lozinke se razlikuju!",
    passwordsNotLongEnough:'Lozinka mora biti dužine minimalno 8 znakova',
    passwordsUpperLowerCases:'Lozinka mora sadržavati i mala i velika slova',
    passwordResetTokenAuthenticationFailed: "Privremeni link za promjenu lozinke je istekao! Molimo zatražite novi link i promjenite lozinku u roku od 15 minuta nakon zahtjeva! ",
    changePasswordButtonText: "Promjena lozinke",
    passwordChangeServiceNotAvailable: "Stupite u kontakt sa korisničkom službom",
    passwordResetTitle: "Zahtjev za novu lozinku",
    passwordResetDescription: "Molimo Vas unesite email adresu i korisničko ime!",
    changePasswordTitle: "Promjena lozinke",
    changePasswordDescription:'Molimo Vas zadajte novu lozinku za korisničko ime:',
    passwordResetImportantDescription: 'Važno! Isključivo unosom email adrese registrirane u iTrack sistemu možete tražiti novu lozinku!',
    login: "Prijava",
    dontHaveAccount: "Još niste korisnik?",
    signUp: "Registrirajte se",
    username: "Korisničko ime",
    registrationTitle: "Registracija",
    back: "Nazad",
    cars: "Vozila",
    selectableCars: "Izbor vozila",
    trackedCars: "Izabrana vozila",
    allTracking: "Praćenje svih",
    selectDelete: "Brisanje odabranih",
    moreInformation: "Prikaz podataka",
    openGoogleMaps: "Prikaz trenutne pozicije vozila na Google Maps-u",
    nodata: "Nema podataka",
    carShare: "Podjela vidljivosti",
    carShareTitle: "Podjela vidljivosti vozila",
    shareTabTitle: "Podjela",
    page: "Stranica",
    access: "Zadano vrijeme praćenja vozila*",
    startDate: "Početno vrijeme",
    endDate: "Završno vrijeme",
    pleaseWriteEmail: "Molimo ovdje upišite e-mail adresu!",
    embed: "Link za podjelu",
    shareButtonTitle: "Podjela",
    closeButtonTitle: "Zatvori",
    sheSaw: "Vidjeo",
    writeMessage: "Ovdje upišite poruku!",
    seding: "Slanje",
    messages: "Poruke",
    searchButtonTitle: "Pretraga",
    fuelingFormTitle: "Točenje goriva*",
    dateTitle: "Datum",
    country: "Zemlja",
    position: "Pozicija",
    start: "Početak (L)",
    end: "Kraj (L)",
    volume: "Količina (L)",
    logInProgress: "Prijava u toku ...",
    loginFailed: "Neuspješna prijava",
    downtime: "Vrijeme stajanja",
    event: "događaj",
    messageSearchPlace: "Pretraga",
    messageSearchText: "Počnite upis teksta!",
    hu: 'Magyar',
    en: 'English',
    ro: 'Română',
    sr: 'Srpski',
    hr: 'Hrvatski',
    sk: 'Slovenský',
    cz: 'Český',
    maxCarTitle: "Maksimalni broj izabranih vozila je {num}",
    today: 'danas',
    squel: 'Nastavak',
    unknownError: 'Nepoznata greška',
    systemError: 'Usluga trenutno nije dostupna! Molimo kontaktirajte korisničku službu.',
    timeout: 'Veza je zbog prekoračenja vremena prekinuta. Molimo kliknite na sljedeći gumb.',
    networkError: 'Mreža je nedostupna, molimo provjerite Internet konekciju.',
    notPositions: 'Sljedeća vozila još nemaju koordinatu',
    accessExpired: '(ENG) You are currently not authorized to access.<br/><br/>(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(RON) Valabilitatea accesului  a expirat.<br/><br/>(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.<br/><br/>(HRV) Vrijeme dozvoljenog pristupa isteklo.<br/><br/>',
    errorLogin: 'Pogrešno korisničko ime ili lozinka!',
    emptyFieldLogin: 'Polje korisničko ime ili lozinka nije ispunjeno!',
    carshareDialogText: 'Polje početnog ili završnog vremena nije ispunjeno!',
    notSelectCar: 'Nema izabranog vozila!',
    messageSearchTextNotFound: 'Nema pogodka!',
      internetConnectionTerminated: 'Pristup internetu prekinut!',
      emptyFuelList: 'U zadanom periodu nema točenja goriva',
      emptyRoutesList: 'U zadanom periodu nema važeće putanje',
      speed: 'Brzina',
      map: 'Mapa',
      selectDate: 'Izbor datuma',
    pleaseSelectEndDate: 'Molimo zadajte završni datum!',
      oneSelectMode: 'Jedno-putni prikaz',
      multipleSelectMode: 'Više-putni prikaz',
      pageReload: 'Osvježavanje stranice',
      registrationBody: 'Poštovani budući korisniče!<br/>Molimo stupite u kontakt sa našom korisničkom službom.<br/><br/>Radno Vrijeme:<br/>Pon-Čet: 7:30-18:00<br/>Pet: 8:00-15:30',
      lostPasswordBody: 'Poštovani korisniče!<br/>Ukoliko ste zaboravili lozinku molimo stupite u kontakt sa našom korisničkom službom.<br/><br/>Radno Vrijeme:<br/>Pon-Čet: 7:30-18:00<br/>Pet: 8:00-15:30',
      carShareText1: 'Sa ovom funkcijom ste u mogućnosti da za izabrano(a) vozilo(a) sa zadanim vremenskim intervalom generirate važeći link za praćenje. Van zadanog vremenskog intervala pomoću ovoga linka nije moguće pristupit podatcima vozila.',
      carShareText2: 'Pomoću ove funkcije ste u mogućnosti generirati HTML kod pomoću kojeg na vašoj Web stranici možete prikazati izabrana vozila u zadanom vremenskom intervalu.',
      refreshInProgress: 'Za izabrano vozilo osvježavanje podataka je već u toku',
      newPlutoDialogContent: 'Dear Customer,<p><br>The iTrack mobile client application has become available with a renewed appearance and new functionality (e.g. route- and refueling summary).</p><br><p>The old version you are currently using will expire on 31/06/2024</p><br><p>Therefore, please make your selection the new mobile client version is available on the platforms that suit you best (Web, Android, iOS), and switch to using it by the predefined time.</p><br><p>New versions can be available from any of the links below:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Telematics</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>Best regards, <br><br>the iData team',
      newPlutoRecommendContent: 'Upoznajte novu generaciju iTrack Mobile aplikacije!<p><br>Napredna tehnološka rješenja, korisne funkcije, brži, pouzdaniji i stabilniji rad, kao i transparentnija upravljivost, pomažu u poboljšanju učinkovitosti poslovanja vaše tvrtke.</p><br><p>Napravite korak naprijed s novom iTrack Mobile aplikacijom!'
    }