class TrackedTargetState {

	constructor(name) {
		this.name = name;
	}

    toString() {
        return `TrackedTargetState.${this.name}`;
    }
}

TrackedTargetState.Pending = new TrackedTargetState('Pending');
TrackedTargetState.NoData = new TrackedTargetState('NoData');
TrackedTargetState.Live = new TrackedTargetState('Live');

export { TrackedTargetState };