var React = require('react');
var Leaflet = require('leaflet');
var LeafletTextpath = require('leaflet-textpath');
var LeafletMarkerCluster = require('leaflet.markercluster');
var LeafletDecorator = require('leaflet-polylinedecorator');

//moment
var moment = require('moment');

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var Ajax = require('../mixins/Ajax.jsx');

module.exports = React.createClass({

	mixins: [Languages, Ajax],

  decoratorsInMap: [],
	longpollingAjax: null,
	map: null,

  getDefaultProps : function() {
    return {
      stopLongPoll : false,
      timeoutForFirstPoll : null,
      markers : {},
      lines : {},
      lineLObjs : {},
      routeLineObjs : {},
      routeMarkerObjs : {},
      routeEventMarkerObjs : {},
      timeoutTime : 0,
      preventAutoZoom : false,
      markerClickTimeout : undefined,
      defaultZoom : 14,
      actBounds : undefined,
      longpollTimeout : null,

      drawLineDisable: true,

      mapCenter : {
        lat: 47.4980556,
        lng: 19.04
      },
      zoom : 12,
			tokenAuth: false
    }
  },

	getInitialState : function() {
    return {
      routes : {},
			carsActualCoordinate: {},
			markerClusterGroup: undefined,
			carsSelectInList: {},
      markers: {},
      targetRoute: [],
			stopLongPolling: false
    }
  },

	setCarsSelectInList: function(ids) {
		this.setState( {
			carsSelectInList: ids
		});
	},

	getCarsActualCoordinate: function() {
		return this.state.carsActualCoordinate;
	},

	analyticsEvent: function() {
	},

  componentDidMount : function() {
    if (this.map == null) {
      this.leafletInit();
    }

		document.addEventListener("pause", this.stopMapLongPolling, false);
		document.addEventListener("resume", this.restartMapLongPolling, false);
  },

	stopMapLongPolling : function() {
		this.stopLongPolling();
		this.setState( {
			stopLongPolling: true
		});
	},

	restartMapLongPolling : function() {
		var _this = this;

		this.setState( {
			stopLongPolling: false
		}, function() {
			_this.stopLongPolling();

			var actualCarsid = JSON.parse(localStorage.getItem('mapCars'));
			//adott járműhöz a fromTimeMicros idejét visszaállítjuk 0-ra
			for( var j in actualCarsid ) {
				actualCarsid[j]['fromTimeMicros'] = 0;
				actualCarsid[j]['asynchronous'] = false;
			}
			localStorage.setItem('mapCars', JSON.stringify(actualCarsid));

			_this.getActPositions();
		});
	},

  leafletInit: function() {
    var protocol = document.location.protocol;

    if (protocol == 'file:') {
      protocol = 'https:';
    }

    L.Icon.Default.imagePath = '../../images/leaflet';

    //létrehozzuk a mapot.
    this.map = L.map('map', {zoomAnimation: true, zoomControl: false}).setView(this.props.mapCenter, this.props.zoom);
    this.map.eachLayer(function (layer) {
	if (layer instanceof L.mapbox.TileLayer) {
    	    layer.on('tileload', function(e) {
    		if(e != undefined) {
    		    $.ajax({
			url: "https://pluto.itrack.hu/test/testproxy/osm?url=\"" + e.url + "\"",
			type: "GET"
    		    });
    		}
    	    });
	}
    });

    // Min zoom beállítása
    this.map.options.minZoom = 3;
    this.map.fire('zoomend');

    //hozzáadjuk az alap térkép layert
    L.tileLayer(protocol + '//' + '@@osm' + '{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    L.control.zoom({position:'bottomright'}).addTo(this.map);
  },

  // Nyomonkövetés autó markerek
  targetsPositions: function(targetsPositions, carsSelectInList) {
    var _this = this;

    var carIds = [];
    var carsActualCoordinate = _this.state.carsActualCoordinate;

    localStorage.setItem('mapCars', '');

    if(targetsPositions.length > 0) {
      //végigmegyünk a járműveken
      for(var i in targetsPositions) {

        var id = targetsPositions[i]['target']['id'];
        var lat = targetsPositions[i]['currentPosition']['latitude'];
        var long = targetsPositions[i]['currentPosition']['longitude'];

        //beállítjuk a google maps linket
        _this.changeGMapsHREF(lat, long, id);

        carsActualCoordinate[id] = {
          lat: lat,
          long: long
        };

        var found = false;

        //megnézzük, hogy létezik e
        for( var j in _this.props.lines ){
          if( j == targetsPositions[i]['target']['id'] ){ found = true; }
        }

        //ha nincs ilyen az átadott tömbben, kitöröljük lineLObjs tömbből az elemet
        if( !found && Object.keys(_this.props.lines).length > 0 ){
          delete _this.props.lines[targetsPositions[i]['target']['id']];

          if( typeof _this.props.lineLObjs[targetsPositions[i]['target']['id']] !== 'undefined'){
            _this.map.removeLayer(_this.props.lineLObjs[targetsPositions[i]['target']['id']]);
            delete _this.props.lineLObjs[targetsPositions[i]['target']['id']];
          }
        }

        //fromtimeot szedjük össze
        var fromTime = 0;
        if( typeof targetsPositions[i]['lastPositions'] !== 'undefined' ){
          fromTime = _this.parseLastPositions( targetsPositions[i]['lastPositions'], targetsPositions[i]['target']['id'], targetsPositions[i]['serverTimeMicros']);
        }

        //hozzáadjuk a tömbhöz
        carIds.push({
          "targetId" : targetsPositions[i]['target']['id'],
          "fromTimeMicros" : fromTime,
          "asynchronous" : true
        });
      }

      //térkép mozgatásra kikapcsoljuk az autozoom funkciót
      _this.map.on('dragstart', function() {
        if(Object.keys(_this.state.carsSelectInList).length > 0) {
          _this.autozoomDisable();
        }
      });

      //zoomendre megvizsgáljuk, hogy a térkép nézetében vannak e a járművek, ha nem kikapcsoljuk az autozoom funkciót
      _this.map.on('zoomend', function(){
        if( Object.keys(_this.state.carsSelectInList).length <= 1 ){ return false; }

        var allInBounds = _this.markersInBound();

        if( !allInBounds ){
          _this.props.preventAutoZoom = true;
          $('button#autozoom').show();
        }
      });

      //autozoom bekapcsolása és a térkép pozicionálása
      $('button#autozoom').off('click').on('click', function(){
        _this.props.preventAutoZoom = false;
        $('button#autozoom').hide();

        _this.mapsAutoPosition();

        return false;
      });
    }

    localStorage.setItem('mapCars', JSON.stringify(carIds));

    _this.setState( {
      carsActualCoordinate: carsActualCoordinate,
      carsSelectInList: carsSelectInList
    }, function() {
      //törlünk minden polylinet
      _this.removeAllMarkerOnMap();
      _this.deleteLines();

      if(targetsPositions.length > 0) {
        //felrakjuk a markereket a térképre
        _this.addTargetsMarkersToMap(targetsPositions);

        //elindítjuk a longpolling kérést ha még ne mvolt elindítva
        _this.stopLongPolling();
        _this.getActPositions();
      } else {
        _this.stopLongPolling();
      }
    });
  },

  // Útvonalak
  targetRoute: function(targetRoute) {
    var _this = this;
    var actRoutes = this.state.routes;

    //lepucoljuk a térképről a megjelenített elemeket
    this.clearRouteMap();

    //ha jelenleg kijelölt jármű útvonala nem létezik, inicializáljuk
    if( typeof actRoutes[targetRoute['target']['id']] == 'undefined' ) {
      actRoutes[targetRoute['target']['id']] = {};
    }
    if( typeof actRoutes[targetRoute['target']['id']][targetRoute['fromTime']] == 'undefined' ) {
      actRoutes[targetRoute['target']['id']][targetRoute['fromTime']] = {};
    }

    //megadjuk az adott jármű, adott idejéhez az útvonalat
    actRoutes[targetRoute['target']['id']][targetRoute['fromTime']] = targetRoute;

    this.setState( {
      routes: actRoutes,
      targetRoute: targetRoute
    }, function() {
      //kirajzoljuk az útvonalakat
      _this.drawRoutes();
    });
  },

	mapsAutoPosition: function() {
		var _this = this;
		var ids = this.state.carsSelectInList;
    var markers = this.state.markers;
		var latlngs = [];

		//összeszedjük a koordinátákat
		for( var i in markers ){
			if( ids[i] == -1 ){ delete markers[i]; continue; }
			latlngs.push( markers[i].getLatLng() );
		}

    this.setState({
      markers: markers
    }, function() {
      //ha egy elemű a koordináta tömb, akkor magát a térképet pozicionáljuk
      if( latlngs.length == 1 ){
        _this.map.setView( latlngs[0], _this.props.defaultZoom );

        //különben fitbounds-al oldjuk meg a térkép elhelyezkedését
      } else {
        var allInBounds = _this.markersInBound();
        var bounds = new L.LatLngBounds(latlngs);

        if( allInBounds ){
          bounds = _this.map.getPixelBounds();
        }

        _this.map.fitBounds( new L.LatLngBounds(latlngs) ,{
          padding: [10,10]
        });
      }
    });
	},

	autozoomDisable: function() {
		this.props.preventAutoZoom = true;
		$('button#autozoom').show();
	},

	//útvonalak kirajzolása
  drawRoutes : function(){
    var _this = this;
    var routes = this.state.routes;
    var latlngs = [];

	  //végigmegyünk az útvonalakon
    for(var i in routes){
	    //ha target id nem a most kijelölt járműhoz tartozik, akkor ugrunk egy iterációt
      if( i != _this.state.targetRoute['target']['id'] ){ continue; }

      for(var j in routes[i]){
				//kirajzoljuk az adott útvonalat
        _this.drawRoute(routes[i][j]);

	      //összeszedjük a koordinátákat a fitboundshoz
        for(var k in routes[i][j]['positions']){
          latlngs.push([routes[i][j]['positions'][k].latitude, routes[i][j]['positions'][k].longitude]);
        }
      }
    }

    //ha vannak koordináták a térképet pozicionáljuk
    if( latlngs.length ){
      _this.map.fitBounds( new L.LatLngBounds(latlngs),{
        padding: [10,10]
      });
    }
  },

  componentWillUnmount : function() {
		this.stopLongPolling();
    if( this.props.longpollTimeout !== null ){
      clearTimeout(this.props.longpollTimeout);
    }
    clearTimeout(this.props.timeoutForFirstPoll);
  },

	removeMarkerOnMap: function(id) {
		var _this = this;
    var markersObj = this.state.markers;

		if(typeof markersObj[id] != 'undefined') {
			var markers = this.state.markerClusterGroup;
			var marker = markersObj[id];

			//markereket töröljük a téképről
			markers.removeLayer(marker);
			this.map.removeLayer(marker);
			delete markersObj[id];

			this.setState({
        markers: markersObj
      }, function() {
        _this.mapsAutoPosition();
      });
		}
	},

  removeAllMarkerOnMap: function() {
    var markersObj = this.state.markers;
    var markers = this.state.markerClusterGroup;

    Object.keys(markersObj).map(function(index) {
      var marker = markersObj[index];

      //markereket töröljük a téképről
      markers.removeLayer(marker);
      this.map.removeLayer(marker);
      delete markersObj[index];
    }, this);

    this.setState({
      markers: {}
    });
  },

	//Térképre rakja a járművek markereit
  addTargetsMarkersToMap : function(targets){
    var _this = this;
    var latlngs = [];
    var markersObj = this.state.markers;

    //lista info gomb megnyomásakor számít a következő 2 property
    var center = undefined;
    var markerForOpening = undefined;

    //groupoláshoz szükséges cluster object
    var markers = new L.MarkerClusterGroup({
			showCoverageOnHover: false,
			disableClusteringAtZoom: 17
		});

		_this.setState( {
			markerClusterGroup: markers
		});


    markers.on('animationend', function() {
      var ids = [];

      $('.carListUl i.active').each(function(){
        ids.push( parseInt( $(this).parents('li').attr('data-id') ) );
      });

      if(_this.state.drawLineDisable == false) {
        for(var i in ids){
          _this.drawLine( _this.props.lines[ ids[i] ] , ids[i] );
        }
      }
    });

	  //hozzáadjuk a markereket a térképhez
    for(var i in targets){
      var positions = targets[i]['currentPosition'];

			if(positions == null) {
				continue;
			}

      var icon = L.divIcon({
        className: 'custom-marker',
        html : '<div><div style="background-color: '+ positions['color'] +';" ></div></div>',
        iconSize: L.point(20, 20),
        iconAnchor: null
      });

	    //összerakjuk a markert, hozzá tartozó lablet és infowindowt
      var marker = new L.Marker(L.latLng(positions['latitude'],positions['longitude']), { icon: icon })
        .bindPopup( _this.getInfoWindowContent(targets[i]['target'], positions) )
        .bindLabel(targets[i]['target']['name'], { noHide: true , direction: 'auto'});

	    //dupla kattra odazoomolunk és kikapcsoljuk az autozoomot
      marker.off('dblclick').on('dblclick', function(e){
        _this.map.setView( this.getLatLng(), 18 );
        _this.props.preventAutoZoom = true;
        $('button#autozoom').show();
        e.target.closePopup();
        if( typeof _this.props.markerClickTimeout !== 'undefined' ){
          setTimeout(function(){
            clearTimeout(_this.props.markerClickTimeout);
          }, 100);
        }
        return false;
      });

	    //sima klikkre megnyitjuk az infoablakot
      marker.off('click').on('click', function(e){
        e.target.closePopup();

        _this.props.markerClickTimeout = setTimeout(function(){
					_this.analyticsEvent();
          e.target.openPopup();
        }, 50);
        e.originalEvent.preventDefault();

        return false;
      });

      markersObj[targets[i]['target']['id']] = marker;

      //grouphoz adjuk hozzá az adott markert
      markers.addLayer( marker );

      //térkép boundoláshoz szükséges tömbbe pakoljuk a koordinátákat
      latlngs.push([positions['latitude'], positions['longitude']]);
    }

    _this.setState( {
      markers: markersObj
    }, function() {
      //hozzáadjuk a térképhez a groupot
      _this.map.addLayer(markers);

      //ha van centerünk, felülírjuk a fitbounds-hoz használt tömböt, hogy a kijelölt marker középre kerüljön
      if( typeof center !== 'undefined' ){
        latlngs = [center];
      }

      //ha vannak koordináták a térképet pozicionáljuk
      if( latlngs.length ){
        if( latlngs.length == 1 ){
          _this.map.setView( latlngs[0], _this.props.defaultZoom );
        } else {
          _this.map.fitBounds( new L.LatLngBounds(latlngs),{
            padding: [10,10]
          });
        }
      }

      //ha van markerünk, aminek a popupját meg kell nyitni, megnyitjuk
      if( typeof markerForOpening !== 'undefined' ){
        _this.analyticsEvent();
        markerForOpening.openPopup();
      }
    });
  },

	//összeállítjuk az info ablakot
  getInfoWindowContent : function(target, position) {
    var date = moment(position.timeMicros / 1000).format("YYYY. MM. DD. HH:mm:ss");

    var $div = $('<div class="popupContent" ></div>');

    $div
      .append( '<strong>' + target.name +'</strong><br>' )
      .append( '<strong>' + target.description + '</strong><br>' )
      .append( '<strong>' + date  + '</strong><br><br>' )
      .append( this.getValue('speed') + ': ' + position.speed + ' Km/h' +'<br>' );

    if(position.measures.length > 0) {
      position.measures.map(function(measure) {
        $div.append( measure['name'] +': '+ measure['value'] + '<br>' );
      });
    }

		$div.append( '<br>' );

    $div
      .append( position.geocodedAddress +'<br>' )
      .append( position.latitude + ' - ' + position.longitude );

    return $div.prop('outerHTML');
  },

	//longpollingos lekérés
  getActPositions : function() {
    var _this = this;
		var carIds = JSON.parse(localStorage.getItem('mapCars'));

    if(carIds == null) { return; }
		if(carIds.length == 0) { return; }
		if(this.state.stopLongPolling) { return; }

    this.longpollingAjax = $.ajax({
      url: "@@servletUrl"+"targets/positions/tracking",
      type: "POST",
      data : JSON.stringify(carIds),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      timeout : 60000,
      beforeSend: function (xhr) {
        if(_this.props.tokenAuth == true) {
          xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
        } else {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        }
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success : function(response, status, xhr){

        if( xhr.status == 200 && response.length > 0 ) {
          var positions = {};
					var carsActualCoordinate = _this.state.carsActualCoordinate;
          var actualCarsid = JSON.parse(localStorage.getItem('mapCars'));

					//ha van válasz, összeszedjük a változott pozíciókat
          for(var i in response) {
						var lastTimeMicro = null;
						var deleteCarId = false;
						var id = response[i]['target']['id'];

						if(typeof _this.state.carsSelectInList[id] == 'undefined') {
							deleteCarId = true;
						}

            if( response[i]['currentPosition'] != null ) {
							var lat = response[i]['currentPosition']['latitude'];
							var long = response[i]['currentPosition']['longitude'];

							//hozzádjuk a positions objektünkhöz
							positions[ response[i]['target']['id'] ] = { position: response[i]['currentPosition'], target: response[i]['target'] };

							//frissítjük a gmapos linket
							_this.changeGMapsHREF(
								lat,
								long,
								id
							);

							carsActualCoordinate[id] = {
								lat: lat,
								long: long
							};

							//feldolgozzuk a lastPositions tömböt
							lastTimeMicro = _this.parseLastPositions( response[i]['lastPositions'], response[i]['target']['id'], response[i]['serverTimeMicros'] );
						}

	          //frissítjük adott járműhöz a fromTimeMicros idejét
            for( var j in actualCarsid ) {
              if( response[i]['target']['id'] == actualCarsid[j]['targetId'] ) {
                if(deleteCarId) {
                  actualCarsid.splice(j, 1);
                } else if(lastTimeMicro != null) {
                  actualCarsid[j]['fromTimeMicros'] = lastTimeMicro;
		  actualCarsid[j]['asynchronous'] = true;
                }

                break;
              }
            }
          }

          localStorage.setItem('mapCars', JSON.stringify(actualCarsid));

					_this.setState( {
						carsActualCoordinate: carsActualCoordinate
					});

	        //frissítjük a markerek pozícióját
          _this.updateMarkers(positions);
        }

        _this.props.timeoutTime = 0;
        _this.getActPositions();
      },
      error : function(response, status, xhr) {
        if(response.statusText == 'abort') {
          return false;
        }

				if( response.statusText != 'timeout' && response.statusText != 'error' && status != 0 ) {
					_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, null);
				}

        if( _this.props.timeoutTime == 0 ){
          _this.props.timeoutTime = 5000;
        } else {
          _this.props.timeoutTime *= 2;
          if( _this.props.timeoutTime > (5*60*1000) ){
            _this.props.timeoutTime = (5*60*1000);
          }
        }

        if( typeof response !== 'undefined' && status >= 500 ){
          _this.props.longpollTimeout = setTimeout(function() {
            _this.stopLongPolling();
            _this.getActPositions();
          }, _this.props.timeoutTime);
          return false;
        }

        if( response.statusText == 'timeout' ){
          _this.props.longpollTimeout = setTimeout(function(){
            _this.stopLongPolling();
            _this.getActPositions();
          }, _this.props.timeoutTime);
          return false;
        }

        if( typeof _this.props.timeoutTime != 'undefined' ){
          _this.props.longpollTimeout = setTimeout(function(){
            _this.stopLongPolling();
            _this.getActPositions();
          }, _this.props.timeoutTime);
          return false;
        }
      }
    });
  },

	//lastPositions tömb feldolgozása
  parseLastPositions : function( lastPositions, targetId, serverTimeMicros ){
    var _this = this;

	  //ha ezzel a targetid-val még nincs vonalunk, inicializáljuk
    if( typeof _this.props.lines[ targetId ] == 'undefined' ){
      _this.props.lines[ targetId ] = [];
    }

    var lastTimeMicro = 0;

    for( var j in lastPositions ){
      var canAdd = true;
      lastTimeMicro = lastPositions[j]['timeMicros'];

	    //végigmegyünk a meglévő vonalakon és megnézzük, hogy adott időhöz létezik e már
      for( var k in _this.props.lines[ targetId ] ){
        if( _this.props.lines[ targetId ][k]['timeMicros'] == lastPositions[j]['timeMicros'] ){ canAdd = false; break; }
      }

	    //ha még nem, akkor pusholjuk a koordinátákat
      if(canAdd){
        _this.props.lines[ targetId ].push( lastPositions[j] );
      }

	    //itt töröljük a 10 percnél régebbi vonalakat
      if( _this.props.lines[ targetId ].length ){
        for(var k in _this.props.lines[ targetId ]){
          if( parseInt(_this.props.lines[ targetId ][k]['timeMicros']) < ((serverTimeMicros) - (10 * 60 * 1000 * 1000)) ){
            delete _this.props.lines[ targetId ][k];
          }
        }
      }
    }

	  //kirajzoljuk az útvonalat
    if(_this.state.drawLineDisable == false) {
      _this.drawLine( _this.props.lines[ targetId ] , targetId);
    }

    return lastTimeMicro;
  },

	//csiganyál rajzolása
  drawLine : function(lines, carId){
    var _this = this,
        lineCoords = [];

		//ha létezik adott id-vel csiganyál, először is töröljük
    if( typeof _this.props.lineLObjs[carId] !== 'undefined' ){
      delete _this.props.lineLObjs[carId]._text;
      delete _this.props.lineLObjs[carId]._textNode;
      delete _this.props.lineLObjs[carId]._textOptions;
      _this.map.removeLayer(_this.props.lineLObjs[carId]);
      delete _this.props.lineLObjs[carId];
    }

    //összeszedjük az útvonal koordinátákat
    for( var i in lines ){
      lineCoords.push( L.latLng(lines[i].latitude, lines[i].longitude) );
    }

    if( lineCoords.length <= 1 ){ return false; }

		// Csiganyál kirajzolása
		var polyline = this.drawLineToMap(lineCoords);

		//amennyiben nincs groupolva az adott jármű, megjelenítjük a csiganyálat, különben nem kell
    if( _this.state.markerClusterGroup.getVisibleParent( _this.state.markers[carId] ) == null || typeof _this.state.markerClusterGroup.getVisibleParent( _this.state.markers[carId] )._group == 'undefined' ){
      polyline.addTo(_this.map);
    }

    _this.props.lineLObjs[carId] = polyline;
  },

	//frissítjük a marker pozíciót
  updateMarkers : function(positions){
    var _this = this,
        markers = this.state.markers
        latlngs = [];

    for( var i in markers ) {
      if( typeof positions[i] !== 'undefined' && typeof positions[i]['position'] !== 'undefined' ){
        //egyedi marker
        var icon = L.divIcon({
          className: 'custom-marker',
          html : '<div><div style="background-color: '+ positions[i]['position']['color'] +';" ></div></div>',
          iconSize: L.point(20, 20),
          iconAnchor: null
        });

	      //összeszedjük a bounds-hoz a koordinátákat
        latlngs.push(L.latLng( positions[i]['position'].latitude, positions[i]['position'].longitude ));

	      //beállítjuk az új pozíczót
        markers[i].setLatLng(L.latLng( positions[i]['position'].latitude, positions[i]['position'].longitude ));

	      //és ikont
        markers[i].setIcon(icon);

        /* updateljük a popup contentet */
        markers[i]._popup.setContent( _this.getInfoWindowContent( positions[i]['target'], positions[i]['position'] ) );

      } else {
        latlngs.push( markers[i].getLatLng() );
      }
    }

    this.setState( {
      markers: markers
    }, function() {
      //ha be van kapcsolva az autozoom és vannak elemeink is, akkor pozicionáljuk a térképet
      if( !_this.props.preventAutoZoom && latlngs.length ){

        if( latlngs.length == 1 ) {
          var mapZoom = _this.map.getZoom();
          var zoom = _this.props.defaultZoom;

          if( mapZoom !== zoom ){
            zoom = mapZoom;
          }

          _this.map.setView( latlngs[0], zoom );

        } else {
          var allInBounds = _this.markersInBound();
          var bounds = new L.LatLngBounds(latlngs);

          _this.map.fitBounds( bounds,{
            padding: [10,10]
          });
        }
      }
    });
  },

	//útvonal kirajzolása
  drawRoute : function(route){
    var _this = this;
    if( typeof route == 'undefined' ){ return false; }

    var lineCoords = [];

    //összeszedjük az útvonal koordinátákat
    for( var i in route.positions ){
      var pos = route.positions[i];
      lineCoords.push( L.latLng(pos.latitude, pos.longitude) );
    }

		// Csiganyál kirajzolása
		var polyline = this.drawLineToMap(lineCoords);

    if(typeof this.props.routeLineObjs[ route.target.id ] == 'undefined' ){ this.props.routeLineObjs[ route.target.id ] = {}; }
    if(typeof this.props.routeMarkerObjs[ route.target.id ] == 'undefined' ){ this.props.routeMarkerObjs[ route.target.id ] = {}; }
    if(typeof this.props.routeMarkerObjs[ route.target.id ][ route.fromTime ] == 'undefined' ){ this.props.routeMarkerObjs[ route.target.id ][ route.fromTime ] = []; }

	  //letároljuk az adott csiganyálat
    this.props.routeLineObjs[ route.target.id ][ route.fromTime ] = [polyline];

    if( typeof route.markers == 'undefined' ){
      route.markers = {};
    }

	  //végigmegyünk a markereken és megjelenítjük
    for( var i in route.markers){
      var markerCls = '';

      if( route.markers[i].marker == 'start-flag' || route.markers[i].marker == 'stop-flag' ){
        markerCls = route.markers[i].marker;
      }

      var icon = L.divIcon({
        className: 'custom-flag-marker ' + markerCls,
        html : '<div><div style="background-color: red;" ></div></div>',
        iconSize: L.point(30, 40),
        iconAnchor: null
      });

      var marker = L.marker( L.latLng(route.markers[i].position.latitude,route.markers[i].position.longitude) , { icon: icon }).addTo(_this.map);
      this.props.routeMarkerObjs[ route.target.id ][ route.fromTime ].push(marker);
    }

	  //majd az eventeken is végigmegyünk és megjelenítjük
    if( typeof route.events !== 'undefined' ){
      for(var i in route.events){
        _this.addEvent(route.events[i], route.target.id);
      }
    }

  },

	drawLineToMap: function(lineCoords) {
		var _this = this;
		var basedomain = window.location.href.substr(0, window.location.href.indexOf(window.location.hash));

		var arrowIcon = L.icon({
			iconUrl : basedomain + 'images/icon_arrow_white_black_up_small.png',
			iconSize: [ 11, 17 ],
			zIndex: 13
		});

		//hozzáadjuk az útvonalat a térképhez
		// Útvonal kirajzolása

		var routeLineWidth = 5;
		var routeBackgroundLineWidth = routeLineWidth + 3;
		var routeOpacity = 1.0;

		var lineOptions = {
			route : {
				color : '#FF0000',
				weight : routeLineWidth,
				opacity : routeOpacity,
				zIndex: 12,
				linejoin: "bevel"
			},
			routeBackground : {
				color : 'rgb(0,0,0)',
				weight: routeBackgroundLineWidth,
				opacity : routeOpacity,
				zIndex: 11,
				linejoin: "bevel"
			}
		};

		var routesInMap = [];

		// Black background
		var coordsBack = L.polyline(lineCoords, lineOptions.routeBackground).addTo(_this.map);
		routesInMap.push(coordsBack);

		// Red foreground
		var coords = L.polyline(lineCoords, lineOptions.route).addTo(_this.map);
		routesInMap.push(coords);

		var decorator = L.polylineDecorator(lineCoords, {
			patterns : [ {
				offset : '50px',
				repeat : '100px',
				symbol : new L.Symbol.marker({
					rotate : true,
					markerOptions : {
						icon : arrowIcon
					}
				})
			} ]
		}).addTo(_this.map);

		_this.decoratorsInMap.push(decorator);

		return routesInMap;
	},

	//hozzáadjuk az eventeket a térképhez
  addEvent: function(carEvent, carId) {
    var _this = this;

    var icon = L.divIcon({
      className: 'custom-event-marker ' + carEvent.marker,
      html : '<div><div style="background-color: yellow;" ></div></div>',
      iconSize: L.point(32, 40),
      iconAnchor: null
    });

    if( typeof this.props.routeEventMarkerObjs[carId] == 'undefined' ){
      this.props.routeEventMarkerObjs[carId] = [];
    }

    this.props.routeEventMarkerObjs[carId].push( L.marker(L.latLng(carEvent.position.latitude, carEvent.position.longitude), { icon: icon }).addTo(_this.map).bindPopup(_this.getEventPopup(carEvent)).on('click', _this.gaEventClick));
  },

	gaEventClick: function() {
	},

	//event marker infowindow tartalma
  getEventPopup : function(carEvent){
		//var date = moment(carEvent.eventTimeMicros / 1000).format("YYYY. MM. DD. HH:mm:ss");
    var date = moment(carEvent.eventTimeMicros / 1000).format("L LTS");
	
		var $div = $('<div class="popupContent" ></div>');

		$div
			.append( '<strong>' + carEvent.description +'</strong><br>' )
			.append( date  );

		return $div.prop('outerHTML');
  },

	//térképet a kivaálsztott járműhöz képest középre helyezi és felnyitja a popuját
  toCenterWithPopup : function(carId){
    if( typeof this.state.markers[carId] == 'undefined' ){ return false; }

		this.analyticsEvent();
    this.map.setView( this.state.markers[carId].getLatLng(), 20 );
		if(!this.state.markers[carId].getPopup()._isOpen){
	    this.state.markers[carId].openPopup();
			this.autozoomDisable();
		}
		else{
			this.state.markers[carId].closePopup();
		}
  },

	//töröljük az adott útvonalat
  deleteRoute : function(carId, fromTime){
    var _this = this;
    var actRoutes = this.state.routes;

	  //ezzel az adott időhöz tartozó útvonalat töröljük
    if( typeof actRoutes[carId] !== 'undefined' && typeof actRoutes[carId][fromTime] !== 'undefined' ){
      _this.clearRouteMap();
      delete actRoutes[carId][fromTime];

      if( Object.keys(actRoutes[carId]).length == 0 ){
        delete actRoutes[carId];
      }

      this.setState( {
        routes: actRoutes
      }, function() {
        _this.drawRoutes();
      });

		//ezzel az adott autóhoz tartozó összes útvonalat töröljük
    } else if( typeof actRoutes[carId] !== 'undefined' && typeof fromTime == 'undefined' ){
      _this.clearRouteMap();
      delete actRoutes[carId];

      this.setState( {
        routes: {}
      }, function() {
        _this.drawRoutes();
      });
    }
  },

	//letisztítja a térképről az útvonalhoz tartozó elemeket (csiganyál, markerek)
  clearRouteMap : function() {
    var _this = this;

	  //ezzel a csiganyálakat töröljük

    for( var i in _this.props.routeLineObjs ){
      for(var j in _this.props.routeLineObjs[i]){
        for( var k in _this.props.routeLineObjs[i][j] ){
					_this.map.removeLayer( _this.props.routeLineObjs[i][j][k][0]);
          _this.map.removeLayer( _this.props.routeLineObjs[i][j][k][1]);
          delete _this.props.routeLineObjs[i][j][k]._text;
          delete _this.props.routeLineObjs[i][j][k]._textNode;
          delete _this.props.routeLineObjs[i][j][k]._textOptions;
        }
      }
    }

	  //eventeket töröljük a térképről
    for( var i in _this.props.routeEventMarkerObjs ){
      for( var j in  _this.props.routeEventMarkerObjs[i]){
        _this.map.removeLayer( _this.props.routeEventMarkerObjs[i][j] );
      }
    }

    //$('svg.leaflet-zoom-animated').html('');

	  //markereket töröljük a téképről
    for( var i in _this.props.routeMarkerObjs ){
      for(var j in _this.props.routeMarkerObjs[i]){
        for( var k in _this.props.routeMarkerObjs[i][j] ){
          _this.map.removeLayer( _this.props.routeMarkerObjs[i][j][k] );
        }
      }
    }

    //nyilak törlése
    var dl = _this.decoratorsInMap.length;
    if(dl > 0) {
      for(var j = 0; j < dl; j++) {
        _this.map.removeLayer(_this.decoratorsInMap[j]);
      }

      _this.decoratorsInMap = [];
    }
  },

	//törli a csiganyálat a térképről
  deleteLines : function() {
    Object.keys(this.props.lineLObjs).map(function(index) {
      this.map.removeLayer(this.props.lineLObjs[index]);
      delete this.props.lineLObjs[index];
    }, this);

    /*for(var i in _this.props.lineLObjs){
      delete _this.props.lineLObjs[i]._text;
      delete _this.props.lineLObjs[i]._textNode;
      delete _this.props.lineLObjs[i]._textOptions;
      _this.map.removeLayer( _this.props.lineLObjs[i] );
      delete _this.props.lineLObjs[i];
    }*/
  },

  stopLongPolling : function() {
		if(this.longpollingAjax != null) {
			this.longpollingAjax.abort();
		}
  },

	//frissíti a gmapos linken az értékeket
  changeGMapsHREF : function(lat, lng, carId){
    var $a = $('a.car_' + carId);

    if( $a.length == 0 ){ return false; }

    $a.attr({
      'href': 'https://maps.google.com/?q='+ lat +',' + lng + '&t=k',
      'target' : '_blank'
    });
  },

	//megvizsgálja, hogy a kijelölt járművek markere a térkép nézetben vannak e, vagy valamelyik kiesik a látótérből
  markersInBound : function(){
    var _this = this;

    var allInBounds = true;

    if(Object.keys(this.state.carsSelectInList).length > 0) {
      Object.keys(this.state.carsSelectInList).map(function(id) {
        var markers = this.state.markers[id];

        if(typeof markers != 'undefined') {
          var getBounds = this.map.getBounds().contains( markers.getLatLng());

          if(typeof getBounds != 'undefined') {
            if( !getBounds ){
              allInBounds = false;
            }
          }
        }
      }, this);
    }

    return allInBounds;
  },

  render: function() {
    return( <div id="map" ></div> )
  }
});
