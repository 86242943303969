import * as Sentry from '@sentry/browser';

class SentryUtils {

    static setUser(userId) {
        Sentry.configureScope(scope => {
			scope.setUser({
				id: userId,
			})
		});
    }

    static setTag(key, value) {
		Sentry.configureScope(scope => {
			scope.setTag(key, value)
		});
    }

}

export { SentryUtils };