// Jquery betöltése
var $ = require('jquery');

class GetTargetNumbers {

    static getNumberOfAllTargets(successCallback, errorCallback, visitor){
        $.ajax({
			url: "@@servletUrl" + "targets/count",
			type: "GET",
			timeout: 30000,
			async: true,
			cache: false,
			beforeSend: function (xhr) {
				if(visitor == true) {
					xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				} else {
					xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				}
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success: successCallback,
			error: errorCallback
        });
    }

    static getNumberOfSearchedTargets(searchValue, successCallback, errorCallback, visitor){
        $.ajax({
            url: "@@servletUrl" + "targets/count?filter=" + searchValue,
            type: "GET",
            timeout: 30000,
            async: true,
            cache: false,
            beforeSend: function (xhr) {
				if(visitor == true) {
					xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				} else {
					xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				}
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
            },
            success: successCallback,
            error: errorCallback
        });
	}

    static getTrackedTargetLimit(successCallback, errorCallback, visitor){
        $.ajax({
            url: "@@servletUrl" + "targets/trackedTargetLimit",
            type: "GET",
            timeout: 30000,
            async: true,
            cache: false,
            beforeSend: function (xhr) {
				if(visitor == true) {
					xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				} else {
					xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				}
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
            },
            success: successCallback,
            error: errorCallback
        });
    }

}

export { GetTargetNumbers };