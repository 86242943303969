module.exports = {
  loginTitle: "Prijava",
  password: "Lozinka",
  tracking: "Praćenje",
  router: "Rute",
  message: "Poruke",
  fueling: "Lista točenja goriva",
  logout: "Odjava",
  email: "E-mail",
  wrongEmailFormat: "Pogrešan email format!",
  forgotYourPassword: "Zaboravili lozinku?",
  forgotEmailUsernamePair: "Neuspelo slanje email-a?",
  forgotEmailUsernamePairBody: "Poštovani Klijentu!<br/>Ukoliko niste uspeli promeniti zaboravljenu lozinku, molimo Vas da stupite u vezu sa korisničkim servisom.<br/><br/>",
  successPasswordResetEmailSend: "Ukoliko ste zadali ispravnu email adresu i korisničko ime, poslaćemo Vam na zadatu adresu link za promenu iste!",
  successPasswordChange: "Uspešna promena lozinke! Od sada se možete prijaviti u naš sistem pomoću nove lozinke!",
  sendPasswordResetButtonText: "Slanje email-a!",
  emptyFieldPasswordReset: "Nije popunjeno polje email adrese ili lozinke!",
  passwordAgain: "Ponovljena lozinka",
  emptyFieldChangePassword: "Ne može ostati prazna ni jedno polje za lozinku!",
  passwordsNotMatch: "Upisane lozinke se razlikuju!",
  passwordsNotLongEnough:'Lozinka mora biti dužine minimalno 8 karaktera',
  passwordsUpperLowerCases:'Lozinka mora sadržavati i mala i velika slova',
  passwordResetTokenAuthenticationFailed: "Privremeni link za promenu lozinke je istekao! Molimo zatražite novi link i promenite lozinku u roku od 15 minuta nakon zahteva!",
  changePasswordButtonText: "Promena lozinke",
  passwordChangeServiceNotAvailable: "Stupite u kontakt sa korisničkim servisom",
  passwordResetTitle: "Zahtev za novu lozinku",
  passwordResetDescription: "Molimo Vas zadajte email adresu i korisničko ime!",
  changePasswordTitle: "Promena lozinke",
  changePasswordDescription:'Molimo Vas zadajte novu lozinku za korisničko ime:',
  passwordResetImportantDescription: 'Važno! Isključivo zadavanjem email adrese registrovane u iTrack sistemu možete tražiti novu lozinku!',
  login: "Prijava",
  dontHaveAccount: "Još niste korisnik?",
  signUp: "Registrujte se",
  username: "Korisničko ime",
  registrationTitle: 'Registracija',
  back: 'Nazad',
  cars: 'Vozila',
  selectableCars: 'Izbor vozila',
  trackedCars: 'Izabrana vozila',
  allTracking: 'Praćenje svih',
  selectDelete: 'Brisanje odabranih',
  moreInformation: 'Prikaz ostalih podataka',
  openGoogleMaps: 'Prikaz aktuelne pozicije vozila na Google Maps-u',
  nodata: "Nema podataka",
  carShare: 'Podela vidljivosti',
  carShareTitle: 'Podela vidljivosti sa partnerom',
  shareTabTitle: 'Podela',
  page: 'Strana',
  access: 'Pristup*',
  startDate: 'Početno vreme',
  endDate: 'Završno vreme',
  pleaseWriteEmail: 'Molimo ovde upišsite e-mail adresu!',
  embed: 'Ugrađena podela',
  shareButtonTitle: 'Podela',
  closeButtonTitle: 'Zatvori',
  sheSaw: 'Video',
  writeMessage: 'Ovde upišite poruku!',
  seding: 'Slanje',
  messages: 'Poruke',
  searchButtonTitle: 'Pretraga',
  fuelingFormTitle: 'Točenje goriva*',
  dateTitle: 'Datum',
  country: 'Zemlja',
  position: 'Pozicija',
  start: 'Početak (L)',
  end: 'Kraj (L)',
  volume: 'Količina (L)',
  logInProgress: 'Prijava u toku ...',
  loginFailed: 'Neuspešna prijava',
  downtime: 'Vreme stajanja',
  event: 'događaj',
  messageSearchPlace: 'Pretraga',
  messageSearchText: 'Počnite upis teksta!',
  hu: 'Magyar',
  en: 'English',
  ro: 'Română',
  sr: 'Srpski',
  hr: 'Hrvatski',
  sk: 'Slovenský',
  cz: 'Český',
  maxCarTitle: 'Maksimalni broj izabranih vozila je {num}',
  today: 'danas',
  squel: 'Nastavak',
  unknownError: 'Nepoznata greška',
  systemError: 'Usluga trenutno nije dostupna! Molimo stupite u vezu sa korisničkim servisom.',
  timeout: 'Veza je zbog prekoračenja vremena prekinuta. Molimo kliknite na sledeće dugme.',
  networkError: 'Mreža je nedostupna, molimo proverite konekciju.',
  notPositions: 'Sledeća vozila još nemaju koordinatu: ',
  accessExpired: '(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(ENG) You are currently not authorized to access.<br/><br/>(RON) Valabilitatea accesului  a expirat.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.',
  errorLogin: 'Pogrešno korisničko ime ili lozinka!',
  emptyFieldLogin: 'Polje korisničko ime ili lozinka nije ispunjeno!',
  carshareDialogText: 'Polje početnog ili završnog vremena nije ispunjeno!',
  notSelectCar: 'Nema izabranog vozila!',
  messageSearchTextNotFound: 'Nema pogodka!',
  internetConnectionTerminated: 'Pristup internetu prekinut!',
  emptyFuelList: 'Po zadatim uslovima nema točenja goriva',
  emptyRoutesList: 'U željenom periodu nema važeće putanje',
  speed: 'Brzina',
  map: 'Mapa',
  selectDate: 'Izbor datuma',
  pleaseSelectEndDate: 'Molimo zadajte završni datum!',
  oneSelectMode: 'Jedno-putni prikaz',
  multipleSelectMode: 'Više-putni prikaz',
  pageReload: 'Osvežavanje stranice',
  registrationBody: 'Poštovani budući korisniče!<br/>Molimo stupite u kontakt sa našim korisničkim servisom.<br/><br/>Vreme dežurstva:<br/>Pon-Čet: 7:30-18:00<br/>Pet: 8:00-15:30',
  lostPasswordBody: 'Poštovani korisniče!<br/>Ukoliko ste zaboravili lozinku molimo stupite u kontakt sa našim korisničkim servisom.<br/><br/>Vreme dežurstva:<br/>Pon-Čet: 7:30-18:00<br/>Pet: 8:00-15:30',
  carShareText1: 'Sa ovom funkcijom ste u mogućnosti da za izabrano(a) vozilo(a) sa zadatim vremenskim intervalom generišete važeći link. Van zadatog vremenskog intervala pomoću ovoga linka nije moguće pristupit podacima vozila.',
  carShareText2: 'Pomoću ove funkcije ste u mogućnosti da generišete HTML kod pomoću kojeg na vašoj Web stranici možete prikazati izabrana vozila u zadatom vremenskom intervalu.',
  refreshInProgress: 'Za izabrano vozilo osvežavanje podataka je već u toku',
  newPlutoDialogContent: 'Poštovani korisniče,<p><br>Mobilna aplikacija iTrack postala je dostupna sa obnovljenim izgledom i novom funkcionalnošću (npr. rute i zbirni podaci o točenju).</p><br><p>Stara verzija koju trenutno koristite ističe <DDMMGGGG></p><br><p>Stoga, Vas molimo da pre navedenog roka obezbedite sebi novu verziju aplikacije na platformama koje Vam najviše odgovaraju (Veb, Android, iOS) i pređete na njeno korišćenje.</p><br><p>Nove verzije iTracka su dostupne na bilo kojoj dole navedenoj stranici:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Telematics</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>Srdačan pozdrav,<br><br>tim iData',
  newPlutoRecommendContent: 'Upoznajte novu generaciju iTrack Mobilne aplikacije!<p><br>Napredna tehnološka rešenja, korisne funkcije, brži, pouzdaniji i stabilniji rad, kao i transparentnija upravljivost, pomažu u poboljšanju efikasnosti poslovanja vašeg preduzeća.</p><br><p>Napravite korak napred sa novom iTrack Mobile aplikacijom!'
}
