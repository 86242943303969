

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------


module.exports = React.createClass({

  mixins: [Languages],

  getDefaultProps: function() {
    return {
      changeMobilHeaderTitle: function(){}
    };
  },

  handlerBackButton: function() {
    this.props.changeMobilHeaderTitle(this.getValue('message'));

    $('#trackingTabs .backButton').addClass('hidden');
    $('#trackingTabs .title').addClass('col-xs-offset-4');
    $('#messagesList').removeClass('hiddenMobile');
  },

  render: function() {

    return (
      <div id="trackingTabs" className="trackingTabs">
        <ul>
          <li className="col-xs-4 backButton hidden">
            <button onClick={this.handlerBackButton}><i className="icon-arrowLeft"></i>{this.getValue('back')}</button>
          </li>
          <li className="col-xs-4 col-xs-offset-4 title">
            <h2>{this.props.title}</h2>
          </li>
        </ul>
      </div>
    )
  }
});
