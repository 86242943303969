
// React betöltése
var React = require('react');
// -----------------------------------------------------

var Navigation = require('react-router').Navigation;

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var auth = require('../commons/authenticateToken.js');

// Gépjárműlista betöltése
var CarsList = require('./CarsListTracking.jsx');

// Térkép betöltése
var Map = require('./Map.jsx');

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil tab választó betöltése
var TrackingTabs = require('./TrackingTabs.jsx');

var Notification = require('../mixins/Notification.jsx');
var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

	mixins: [Navigation, Languages, Notification, Ajax],

	ajaxLoad : false,

  getInitialState: function() {
    return {
      targetsPositions: [],
      tokenAuth: true,
      visitor: true
    };
  },

	getAjaxLoad: function() {
    return this.state.ajaxLoad;
  },

	handleCarUnselect: function(id, setLoader, showOnMapCarIds) {
		if(id > 0) {
			this.refs.map.removeMarkerOnMap(id);
			this.refs.map.setCarsSelectInList(showOnMapCarIds);

			var index = showOnMapCarIds.indexOf(id);
			showOnMapCarIds.splice(index, 1);

			setLoader(showOnMapCarIds);
		}
	},

  handleCarSelect : function(r, setLoader, unselectCars){
		if(this.ajaxLoad == false) {
			this.ajaxLoad = true;

			this.getPositions(r, setLoader, unselectCars);
		}
  },

	clearMapTargetPositions : function() {
		this.refs.map.stopLongPolling();
		this.refs.map.targetsPositions([], {});
	},

	getPositions : function(carIds, setLoader, unselectCars) {
		var _this = this;
		var _setLoader = setLoader;
		var _carIds = carIds;
		var _unselectCars = unselectCars;

		this.refs.map.stopLongPolling();

		if (carIds.length == 0) {
			this.ajaxLoad = false;
			this.clearMapTargetPositions();
			setLoader([0]);
			return;
		}

		var data = [];

		for(var i in carIds) {
			data.push({
				"targetId" : carIds[i],
				"fromTimeMicros" : 0,
				"asynchronous" : false
			});
		}

		$.ajax({
			url: "@@servletUrl"+"targets/positions/tracking",
			type: "POST",
			data : JSON.stringify(data),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout : 180000,
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				xhr.setRequestHeader("Accept", "application/json");
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success : function(response) {
				if( typeof response !== 'undefined' && response.length ) {
					var items				      = [];
					var noPosition	      = '';
					var itemsId           = {};
					var noPositionCarsId  = [];

					for (var i in response) {
						var car = response[i];
						var id = car.target.id;

						if (car.currentPosition != null) {
							items.push(car);
							itemsId[id] = id;
						} else{
							noPositionCarsId.push(id);
							noPosition = noPosition + car.target.name + ', ';
						}
					}

					setLoader(carIds);

					if (noPosition != '') {
						unselectCars(noPositionCarsId);
						_this.addNotification(_this.getValue('notPositions') + noPosition.substring(0, noPosition.length - 2), 'warning');
					}

					_this.refs.map.targetsPositions(items, itemsId);
				}
			},
			error: function(response, status, xhr) {
				if(setLoader != null) {
					setLoader([], 'error', _carIds);
				}

				_this.ajaxError(response, status, xhr, _this.addNotification, _this.addTranslateNotification, _this.getPositions.bind(_this, _carIds, _setLoader, _unselectCars));
			},
			complete : function() {
				_this.ajaxLoad = false;
			}
		});
	},

  handleInfoIconClick : function(carId){
    this.refs.map.toCenterWithPopup(carId);

		if($(window).innerWidth() <= 992) {
			$('#trackingTabsCars').removeClass('active');
			$('#trackingTabsMaps').addClass('active');
			$('#carsList').css('display', 'none');
		}
  },

  checkLogin: function() {
    var tokenId = this.props.params.tokenId;

    if(typeof localStorage.getItem('tokenId') == 'undefined') {
      this.transitionTo('shareinformation');
    }

    if(tokenId.length > 0) {
      auth.checkLogin(tokenId, this.redirectToInformationPage);
    }
  },

	redirectToInformationPage: function() {
		return this.transitionTo('shareinformation');
	},

  componentWillMount: function() {
    this.checkLogin();
  },

	carsActualCoordinate: function() {
		return this.refs.map.getCarsActualCoordinate();
	},

  render: function() {

    return (
      <main id="trackingPage" className="sharePager">
        <MobileHeader mobilMenu={this.refs.left} menuShow={false} />
        <TrackingTabs lang={this.state.lang} visitor={this.state.visitor} />
        <section id="carsList">
          <header>
            <h1>{this.getValue('cars')}</h1>
          </header>
          <CarsList
            handleCarSelect={this.handleCarSelect}
            handleInfoIconClick={this.handleInfoIconClick}
            tokenAuth={this.state.tokenAuth}
            visitor={this.state.visitor}
						getAjaxLoad={this.getAjaxLoad}
						carsActualCoordinate={this.carsActualCoordinate}
						addNotification={this.addNotification}
						addTranslateNotification={this.addTranslateNotification}
            />
        </section>
        <div className="mapContainer" >
					<Map
						ref="map"
						tokenAuth={this.state.tokenAuth}
						addNotification={this.addNotification}
						addTranslateNotification={this.addTranslateNotification}
						/>
        </div>

				{this.rednderNotification()}
      </main>
    )
  }
});
