

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Login csekkolás
var Authentication = require('../mixins/Authentication.js');
var Router = require('react-router');
var RouteHandler = Router.RouteHandler;
var Navigation = Router.Navigation;
// -----------------------------------------------------

// Oldalsó menüsáv betöltése
var SideMenu = require('./SideMenu.jsx');

// Gépjárműlista betöltése
var CarsList = require('./CarsListRouter.jsx');

// Dátum választó, kilistázott útvonalakkal - betöltés
var Calendar = require('./RouterCalendar.jsx');

// Térkép betöltése
var Map = require('./Map.jsx');

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Mobil hasábválasztó
var MobilTabs = require('./RoutesMobilHeader.jsx');

var moment = require('moment');

var Notification = require('../mixins/Notification.jsx');
var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

  mixins: [Navigation, Authentication, Languages, Notification, Ajax],

  getInitialState: function () {
    return {
      targetRoute: [],
      fromTime: null,
      toTime: null,
      selectedCarId: null,
      tokenAuth: false,
      activeRoutes: 0,
      datepickerRange: null,
      modeRouter: 0,
      isAjaxRoute: false
    };
  },

  getIsAjaxRoute: function () {
    return this.state.isAjaxRoute;
  },

  setIsAjaxRoute: function (value) {
    this.setState({
      isAjaxRoute: value
    });
  },

  getModeRouter: function () {
    return this.state.modeRouter;
  },

  componentWillMount: function () {
    var _this = this;

    var initialStart = moment().startOf('day');
    var initialEnd = moment().startOf('day');

    this.setState({
      mobilHeaderTitle: this.getValue('cars'),
      datepickerRange: moment.range(initialStart, initialEnd)
    });
  },

  componentDidMount: function () {
    setTimeout(function () {
      $('#overlay').removeClass('overlay');
      $('#loader').removeClass('loader');
      $('#exitButton').removeClass('exitButton icon-cancel');
    }, 500);
  },

  getDatepickerRange: function () {
    return this.state.datepickerRange;
  },

  setDatepickerRange: function (value) {
    this.setState({
      datepickerRange: value
    });
  },

  checkActiveRoutes: function (n) {
    if (n == 0) {
      $('.mapButton').addClass('hidden');
    }
  },

  getRoute: function (setLoader) {
    var _this = this;
    var _setLoader = setLoader;

    if (this.state.selectedCarId == null || this.state.fromTime == null || this.state.toTime == null) { return false; }

    $.ajax({
      url: "@@servletUrl" + "targets/" + this.state.selectedCarId + "/positions?fromTime=" + this.state.fromTime + "&toTime=" + this.state.toTime,
      type: "GET",
      timeout: 30000,
      beforeSend: function (xhr) {
        if (_this.state.tokenAuth == true) {
          xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
        } else {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        }
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function (response) {
        if (typeof response !== 'undefined') {
          response['fromTime'] = _this.state.fromTime;
          _this.refs.map.targetRoute(response);
          _this.setIsAjaxRoute(false);
        }

        setLoader(false);
      },
      error: function (response, status, xhr) {
        setLoader(null);

        _this.ajaxError(response, status, xhr, _this.addNotification, _this.addTranslateNotification, _this.getRoute.bind(_this, _setLoader));
      }
    });
  },

  handleItemSelect: function (item, setLoader) {
    var _this = this;

    switch (item.type) {
      case 'car': {
        this.refs.map.deleteRoute(this.state.selectedCarId);
        _this.setState({ selectedCarId: item.carId, fromTime: null, toTime: null, targetRoute: [], timestamp: Date.now(), calendarTimestamp: Date.now() + 1 });

        break;
      }
      case 'calendar': {
        var aRoutes = 1;

        if (!item.active) {
          this.refs.map.deleteRoute(this.state.selectedCarId, item.fromTime);

          aRoutes = --this.state.activeRoutes;

          _this.setState({
            activeRoutes: aRoutes
          }, function () {
            _this.checkActiveRoutes(aRoutes);
          });

          return false;
        }

        if (item.mode == true) {
          aRoutes = ++this.state.activeRoutes;
        } else {
          if (this.state.activeRoutes > 0) {
            this.refs.map.deleteRoute(this.state.selectedCarId);
          }
        }

        _this.setState({
          fromTime: item.fromTime,
          toTime: item.toTime,
          activeRoutes: aRoutes
        }, function () {
          _this.checkActiveRoutes(aRoutes);
          $('.mapButton').removeClass('hidden');
          _this.getRoute(setLoader);
        });

        break;
      }
    }
  },

  deleteAllRoute: function () {
    this.refs.map.deleteRoute(this.state.selectedCarId);
    this.setState({
      targetRoute: [],
      timestamp: Date.now(),
      activeRoutes: 0
    });

    this.checkActiveRoutes(0);
  },

  changeMobilHeaderTitle: function (name) {
    this.setState({
      mobilHeaderTitle: name
    });
  },

  // Mobilon a vissza gomb lenyomására az aktív gépjárműről törli a kijelőlést
  mobilSelectCar: function () {
    this.refs.carlist.setSelectedCarId(null);
  },

  checkModeRouter: function (mode) {
    if (!mode && this.state.activeRoutes > 1) {
      this.deleteAllRoute();
    }
  },

  render: function () {

    return (
      <main id="routerPage">
        <MobileHeader mobilMenu={this.refs.left} mobilMenuName={this.getValue('router')} />
        <MobilTabs lang={this.state.lang} title={this.state.mobilHeaderTitle} changeMobilHeaderTitle={this.changeMobilHeaderTitle} mobilSelectCar={this.mobilSelectCar} />
        <MobilMenu ref="left" alignment="left" lang={this.state.lang} activeMenu="tracking" />
        <SideMenu
          lang={this.state.lang}
          activeMenu="router"
          getValue={this.getValue}
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
          notification={this.notification}
          notificationSystem={this.notificationSystem}
          visitor={false}
        />
        <CarsList
          id="CarsList"
          ref="carlist"
          handleItemSelect={this.handleItemSelect}
          changeMobilHeaderTitle={this.changeMobilHeaderTitle}
          gaSearchLabel="Útvonalak"
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
        />
        <Calendar
          id="Calendar"
          key={this.state.calendarTimestamp}
          handleItemSelect={this.handleItemSelect}
          carId={this.state.selectedCarId}
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
          getDatepickerRange={this.getDatepickerRange}
          setDatepickerRange={this.setDatepickerRange}
          deleteAllRoute={this.deleteAllRoute}
          getIsAjaxRoute={this.getIsAjaxRoute}
          setIsAjaxRoute={this.setIsAjaxRoute}
          getModeRouter={this.getModeRouter}
          checkModeRouter={this.checkModeRouter}
        />
        <div className="mapContainer" >
          <Map
            ref="map"
            addNotification={this.addNotification}
            addTranslateNotification={this.addTranslateNotification}
          />
        </div>

        {this.rednderNotification()}
      </main>
    );
  }
});
