
// React betöltése
var React = require('react');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var Router = require('react-router');
var auth = require('../commons/authenticateToken.js');


module.exports = React.createClass({
  mixins: [Router.Navigation, Languages],
  getInitialState: function() {
    return {
      message: ''
    };
  },

  login: function() {
    var _this = this;

    var tokenId = this.props.params.tokenId;
    var type = this.props.params.type;

    if(tokenId.length > 0 && type.length > 0) {
      auth.authenticate(tokenId, function(xhr){
        if(xhr.status == 200) {
          _this.transitionTo('/share/' + type + '/' + tokenId);
        } else {
					_this.transitionTo('shareinformation');
        }
      });
    } else {
      _this.transitionTo('login');
    }
  },

  showMessageAndLogin: function(){
    this.setState( {
      message: this.getValue('logInProgress')
    });
    this.login();
  },

  componentWillMount: function() {
    this.setLangValue(this.props.params.lang);
  },

  componentDidMount: function(){
    this.setLangStateToken(this.showMessageAndLogin);
  },

  render: function() {

    return (
      <main id="loginToken">
        <h1>
          {this.state.message}
        </h1>
      </main>
    );
  }
});
