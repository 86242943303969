
// React betöltése
var React = require('react');
// -----------------------------------------------------

var Navigation = require('react-router').Navigation;

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

var auth = require('../commons/authenticateToken.js');

// Térkép betöltése
var Map = require('./Map.jsx');

var Notification = require('../mixins/Notification.jsx');
var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

  mixins: [Navigation, Notification, Ajax],

  getInitialState: function() {
    return {
      targetsPositions : [],
      tokenAuth: true,
      visitor: true,
			carIds : {}
    };
  },

  getCarsIds: function() {
    var _this = this;


    $.ajax({
      url: "@@servletUrl"+"targets",
      type: "GET",
      timeout : 30000,
      beforeSend: function (xhr) {
        if(_this.state.tokenAuth == true) {
          xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
        }
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function(response) {
        var carsIds = [];

        response.forEach(function(car) {
          carsIds.push(car.id);
        });

        _this.handleCarSelect(carsIds);
      },
      error: function(response, status, xhr) {
				_this.ajaxError(response, status, xhr, _this.addNotification, _this.addTranslateNotification, _this.getCarsIds());
      }
    });
  },

  handleCarSelect : function(r){
    this.getPositions(r);
  },

  getPositions : function(carIds){
    var _this = this;

    _this.refs.map.stopLongPolling();

    if( Object.keys(carIds).length == 0 ){
      return false;
    }

    var _carIds = carIds;

    var data = [];

    for(var i in carIds){
      data.push({
        "targetId" : carIds[i],
        "fromTimeMicros" : 0,
        "asynchronous" : false
      });
    }

    $.ajax({
      url: "@@servletUrl"+"targets/positions/tracking",
      type: "POST",
      data : JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      timeout : 180000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success : function(response){
        if( typeof response !== 'undefined' && response.length ){
					var items				      = [];
					var noPosition	      = '';
					var itemsId           = {};
					var noPositionCarsId  = [];

					for (var i in response) {
						var car = response[i];
						var id = car.target.id;

						if (car.currentPosition != null) {
							items.push(car);
							itemsId[id] = id;
						} else{
							noPositionCarsId.push(id);
							noPosition = noPosition + car.target.name + ', ';
						}
					}

					if (noPosition != '') {
						_this.addNotification(_this.getValue('notPositions') + noPosition.substring(0, noPosition.length - 2), 'warning');
					}

					_this.refs.map.targetsPositions(items, itemsId);
        }
      },
      error : function (response, status, xhr) {
				_this.ajaxError(response, status, xhr, _this.addNotification, _this.addTranslateNotification, _this.getPositions.bind(_this, _carIds));
      }
    });
  },

  handleInfoIconClick : function(carId){
    //this.getPositions(carId);
    this.refs.map.toCenterWithPopup(carId);
  },

  checkLogin: function() {

    var tokenId = this.props.params.tokenId;

    if(typeof localStorage.getItem('tokenId') == 'undefined') {
      this.transitionTo('shareinformation');
    }

    if(tokenId.length > 0) {
      auth.checkLogin(tokenId, this.redirectToInformationPage);
    }
  },

  redirectToInformationPage: function() {
		return this.transitionTo('shareinformation');
	},

  componentWillMount: function() {
    this.checkLogin();
  },

	componentDidMount: function() {
		this.getCarsIds();
	},

  render: function() {

    return (
      <main id="trackingPage" className="sharePager iframe">
        <div className="mapContainer">
          <Map
            ref="map"
            tokenAuth={this.state.tokenAuth}
						addNotification={this.addNotification}
						addTranslateNotification={this.addTranslateNotification}
          />
        </div>

				{this.rednderNotification()}
      </main>
    )
  }
});
