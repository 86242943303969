
// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

// Router betöltése, inicializálása
var Router = require('react-router');
// -----------------------------------------------------

// Form elemek importálása
var FormElementInput = require('./FormElementInput.jsx');
var ButtonInput = require('react-bootstrap/lib/Input');
// -----------------------------------------------------

// A localstorage-ban lévő nyelvi elem értéket eltárolom
var lang = localStorage.getItem('lang');

// Autentikáció betöltése
var auth = require('../commons/authenticate.js');

// Nyelvválasztó modul
var LanguagesSelector = require('./LanguagesSelector.jsx');

var Loader = require('./Loader.jsx');

var Notification = require('../mixins/Notification.jsx');


module.exports = React.createClass({

	mixins: [Router.Navigation, Languages, Notification, Brands],

	getInitialState: function() {
		return {
			errorMessage: ''
		};
	},

  statics: {
    willTransitionTo: function (transition) {
      if (localStorage.hasOwnProperty("user_hash")) {
        var path = '/dashboard';

        if( typeof localStorage.attemptedPathBeforeLogin !== 'undefined' ){
          path = localStorage.attemptedPathBeforeLogin;
          delete localStorage.attemptedPathBeforeLogin;
        }
        transition.redirect(path);
      }
    }
  },

  handleSubmit: function() {
    var _this = this;

		var username = this.refs.mail.getValue();
		var password = this.refs.password.getValue();

		if(username.length != 0 && password.length != 0) {

			// Ajax loader megjelenítée a bejelentkezés gomb helyén
			$('.FormElementButton').parent().hide();
			$('.loaderLogin').show();
			// -----------------------------------------------------

      var errorAction = {
        label: this.getValue('pageReload'),
        callback: function() {
          location.reload();
        }
      };

			auth.authenticate(
				username,
				password,
        errorAction,
				this.addNotification,
				this.addTranslateNotification,
				function() {
					setTimeout(function() {
            if( typeof localStorage.attemptedPathBeforeLogin !== 'undefined' ){
              var path = localStorage.attemptedPathBeforeLogin;
              delete localStorage.attemptedPathBeforeLogin;
            }
						_this.navigation('dashboard', 'Bejelentkezés');
          }, 500);
				});
		} else {
			this.addTranslateNotification('emptyFieldLogin', 'warning');
			$('.icon-user').addClass('error animated shake');
			$('.icon-password').addClass('error animated shake');
		}

		return false;
},

  handleSubmitBlur: function(e) {
    $('.FormElementMail-input').removeClass('has-error');
    $('.FormElementPassword-input').removeClass('has-error');
    this.refs.submit.removeError();
  },

  // Nyelv módosítás esetén a localStorage-ban lévő lang
  handleLocaleChange: function(lang) {
  	this.setLangValue(lang.id);
    this.setLangState();
		// Nyelvi kulcs frissítése
    $('body').removeAttr('class').addClass(localStorage.getItem('lang'));
  },

	getLanguages: function() {
		var actualLang = localStorage.getItem('lang');
		var actualLangIndex = null;
    var languages = [
        {id: 'hu',title: this.getValue('hu'), name: this.getValue('hu'), flagImg: '',flagTitle: ''},
        {id: 'en',title: this.getValue('en'), name: this.getValue('en'), flagImg: '',flagTitle: ''},
        {id: 'ro',title: this.getValue('ro'), name: this.getValue('ro'), flagImg: '',flagTitle: ''},
        {id: 'sr',title: this.getValue('sr'), name: this.getValue('sr'), flagImg: '',flagTitle: ''},
        {id: 'hr',title: this.getValue('hr'), name: this.getValue('hr'), flagImg: '',flagTitle: ''},
        {id: 'sk',title: this.getValue('sk'), name: this.getValue('sk'), flagImg: '',flagTitle: ''},
        {id: 'cz',title: this.getValue('cz'), name: this.getValue('cz'), flagImg: '',flagTitle: ''}
    ];

		languages.forEach(function(item, index) {
			if(item.id == actualLang) {
				actualLangIndex = index;
			}
		});

		var actualLangItem = languages.splice(actualLangIndex, 1);
		languages.unshift(actualLangItem.shift());

		return languages;
	},

	navigation: function(url, label) {
		this.transitionTo(url);

	},

  render: function() {
		var languages = this.getLanguages();

    return (
      <main id="loginPage">
				<span className="loginBg"></span>
        <div className="container" >

          <form className="RouteLogin col-xs-12 col-sm-6 col-md-4 col-md-offset-4 col-sm-offset-3" onSubmit={this.handleSubmit}>
            <h1 className="login" style={{backgroundImage: 'url(' + this.getResourceItemValue('logo') + ')'}}></h1>

            <FormElementInput
              inputType="mail"
              ref="mail"
              instanceName="mail"
              placeholder={this.getValue('username')}
              label=""
              icon={<i className='icon-user'></i>}
              required={true}
            />

            <FormElementInput
              inputType="password"
              ref="password"
              instanceName="password"
              label=""
              placeholder={this.getValue('password')}
              icon={<i className='icon-password'></i>}
              required={true}
            />

            <div className="forgetPasswordBox" style={{visibility: this.getBrand() == "itrack" ? 'visible' : 'hidden'}}>
              <a className="forgetPassword" onClick={this.navigation.bind(this, 'lostpassword', 'Elfelejtetted a jelszavadat?')}>{this.getValue('forgotYourPassword')}</a>
            </div>

            <ButtonInput
              ref="submit"
              instanceName="submit"
              onSubmit={this.handleSubmit}
              type='submit'
              value={this.getValue('login')}
              className="FormElementButton"
              id="SubmitButton"
            />

						<Loader/>

            <div className="registrationBox" style={{visibility: this.getBrand() == "itrack" ? 'visible' : 'hidden'}}>
              <p>{this.getValue('dontHaveAccount')}<a className="forgetPassword" onClick={this.navigation.bind(this, 'registration', 'Regisztrálj')}>{this.getValue('signUp')}</a></p>
            </div>

          </form>

          <div className="languagesSelector">
            <LanguagesSelector
              items={languages}
              gridColumns="1"
              openMode="click"
              showFlag={false}
              id="languagesSelector"
              onLanguageChanged={this.handleLocaleChange}
            />
          </div>
        </div>

				{this.rednderNotification()}
      </main>
    )
  }
});
