// Jquery betöltése
var $ = require('jquery');

class GetTargetsIframe {

	static doQuery(successCallback, errorCallback, visitor) {
        $.ajax({
            url: "@@servletUrl"+"targets",
            type: "GET",
            timeout : 30000,
            beforeSend: function (xhr) {
                if(visitor == true) {
                    xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
                }
                xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
            },
            success: successCallback,
            error: errorCallback
        });
	}
}

export { GetTargetsIframe };