// React betöltése
var React = require('react');
var TargetTrackingSelectableList = require('./TargetTrackingSelectableList.jsx');
var TargetTrackingSelectedList = require('./TargetTrackingSelectedList.jsx');
var Input = require('react-bootstrap/lib/Input');
var Button = require('react-bootstrap/lib/Button');
var $ = require('jquery');

import { GetTargetNumbers } from '../remote/GetTargetNumbers';
import Ajax from '../HOCs/Ajax';

class TargetTrackingListsWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.setContainerHeightForSmallScreen = this.setContainerHeightForSmallScreen.bind(this);
        this.setContainerHeightForLargeScreen = this.setContainerHeightForLargeScreen.bind(this);

        this.handleSearchInSelectedButton = this.handleSearchInSelectedButton.bind(this);
        this.handleSearchInSelectableButton = this.handleSearchInSelectableButton.bind(this);
        this.handleSearchInBothButton = this.handleSearchInBothButton.bind(this);

        this.emptySearchInputInSelected = this.emptySearchInputInSelected.bind(this);
        this.emptySearchInputInSelectable = this.emptySearchInputInSelectable.bind(this);
        this.emptySearchInputInBoth = this.emptySearchInputInBoth.bind(this);

        this.setAllSelectableTargetNumber = this.setAllSelectableTargetNumber.bind(this);
        this.setAllSelectableTargetNumberError = this.setAllSelectableTargetNumberError.bind(this);
        this.setSelectedTargetNumber = this.setSelectedTargetNumber.bind(this);
        this.setSearchedSelectableTargetNumber = this.setSearchedSelectableTargetNumber.bind(this);
        this.setSearchedSelectableTargetNumberError = this.setSearchedSelectableTargetNumberError.bind(this);

        this.selectAllTarget = this.selectAllTarget.bind(this);
        this.removeAllTrackedTarget = this.removeAllTrackedTarget.bind(this);

        this.resetAllSearch = this.resetAllSearch.bind(this);
        this.radioButtonClickedFunction = this.radioButtonClickedFunction.bind(this);

        this.targetElementHeight = 43;
        this.maxContainerHeight = 1;
        this.didFunctionCalledBefore = false;
        this.isAllTargetSelected = false;

        this.state = {
            searchValueInSelectable: null,
            searchValueInSelected: null,
            isSearchInSelected: false,
            selectableTargetsListHeight: 1,
            trackedTargetsListHeight: 1,
            uniqueSelectableListKey: 1,
            maxSelectableTargetNumber: 0,
            maxSelectableTargetNumberLoaded: false,
            selectedTargetNumber: 0,
            selectableTargetNumber: 0,
            showAllCarsCheckButton: false,
            selectAllRadioButtonClicked: false,
        }
    }

    componentDidMount() {
        if(this.props.onSmallScreen){
                this.setContainerHeightForSmallScreen(this.props.windowHeight);
        }
        else{
            this.setUpLargeScreenDesign();
            this.setContainerHeightForLargeScreen(this.props.trackedTargetList.length(),
                                                    this.props.selectableTargetList.getAllRenderedAndEnabled().length,
                                                    this.props.windowHeight);
        }
    }

    componentWillUpdate(nextProps, nextState){
        //Ha történt keresés úgy, hogy még nem volt behívva a gomb engedélyezése függvény
        //akkor nem is szabad megjeleníteni (vagyis ha keresés esetén kevesebb mint 20 target az eredmény)
        if(!this.didFunctionCalledBefore && this.state.uniqueSelectableListKey != nextState.uniqueSelectableListKey){
            this.didFunctionCalledBefore = true;
        }
        var allTrackedTargetNumber = nextProps.trackedTargetList.length();
        this.toggleRadioButtonFill(nextState.maxSelectableTargetNumberLoaded, nextState.maxSelectableTargetNumber,
            allTrackedTargetNumber);
    }

    componentDidUpdate(prevProps, prevState){
        if(!this.state.maxSelectableTargetNumberLoaded){
            GetTargetNumbers.getNumberOfAllTargets(this.setAllSelectableTargetNumber, this.setAllSelectableTargetNumberError, this.props.visitor);
        }
        if(prevState.selectedTargetNumber != this.state.selectedTargetNumber ||
            prevState.selectableTargetNumber != this.state.selectableTargetNumber ||
            prevProps.onSmallScreen != this.props.onSmallScreen){
                this.setTargetNumbers();
        }
        //Feltételezem, nincs olyan, hogy valakivel megosztanak egy targetet és az a
        //valaki használja a keresést
        if(this.props.onSmallScreen != prevProps.onSmallScreen && !this.props.visitor){
            this.resetAllSearch();
        }
        if(prevState.showAllCarsCheckButton != this.state.showAllCarsCheckButton){
            if(this.props.onSmallScreen){
                this.setContainerHeightForSmallScreen(this.props.windowHeight);
            }
            else{
                this.setContainerHeightForLargeScreen(this.props.trackedTargetList.getAllEnabledTrackedTarget().length,
                                                        this.props.selectableTargetList.getAllRenderedAndEnabled().length,
                                                        this.props.windowHeight);
            }
        }
        if (this.props.maxTrackedTargetLimitUpdated && this.state.maxSelectableTargetNumberLoaded) {
            if (this.state.showAllCarsCheckButton === false && this.props.maxTrackedTargetLimit >= this.state.maxSelectableTargetNumber) {
                this.setState({
                    showAllCarsCheckButton: true
                })
            }
        }
    }

    componentWillReceiveProps(newProps) {
        if((newProps.onSmallScreen != this.props.onSmallScreen) && !newProps.onSmallScreen){
            this.setUpLargeScreenDesign();
        }
        if((newProps.onSmallScreen != this.props.onSmallScreen) ||
            (newProps.trackedTargetList.length() != this.props.trackedTargetList.length()) ||
            (newProps.windowHeight != this.props.windowHeight) ||
            (newProps.selectableTargetList.getAllRenderedAndEnabled().length != this.props.selectableTargetList.getAllRenderedAndEnabled().length) ||
            (newProps.trackedTargetList.getAllEnabledTrackedTarget().length != this.props.trackedTargetList.getAllEnabledTrackedTarget().length)){
                if(newProps.onSmallScreen){
                    this.setContainerHeightForSmallScreen(newProps.windowHeight);
                }
                else{
                    this.setContainerHeightForLargeScreen(newProps.trackedTargetList.getAllEnabledTrackedTarget().length,
                                                            newProps.selectableTargetList.getAllRenderedAndEnabled().length,
                                                            newProps.windowHeight);
                }
        }
        if(this.state.searchValueInSelected != null && (newProps.trackedTargetList.length() != this.props.trackedTargetList.length())){
            TargetTrackingSelectedList.doSearchInTrackedTargetList(newProps.trackedTargetList, this.state.searchValueInSelected, newProps.updateTrackedTargetList);
        }
    }

    render() {
        return (
            <div>
                <header>
                    <h1>{this.props.getLangValue('cars')}</h1>
                    <span className="targetNumbers"></span>
                </header>
                <form onSubmit={this.handleSearchInBothButton} className="searchBoxForBoth">
                    <Input
                        id="searchInputInBoth"
                        type="text"
                        label=""
                        help=""
                        ref="searchInputInBoth"
                        groupClassName=""
                        placeholder={this.props.getLangValue('searchButtonTitle')}
                        onChange={this.emptySearchInputInBoth}
                        buttonAfter={(<Button id="asdbtn" onClick={this.handleSearchInBothButton}><i className='icon-search'></i></Button>)}
                        />
                </form>
                <form onSubmit={this.handleSearchInSelectedButton} className="searchBoxForSelected">
                    <Input
                        id="searchInputInSelected"
                        type="text"
                        label=""
                        help=""
                        ref="searchInputInSelected"
                        groupClassName=""
                        placeholder={this.props.getLangValue('searchButtonTitle')}
                        onChange={this.emptySearchInputInSelected}
                        buttonAfter={(<Button><i className='icon-search' onClick={this.handleSearchInSelectedButton}></i></Button>)}
                        />
                </form>
                <form onSubmit={this.handleSearchInSelectableButton} className="searchBoxForSelectable">
                    <Input
                        id="searchInputInSelectable"
                        type="text"
                        label=""
                        help=""
                        ref="searchInputInSelectable"
                        groupClassName=""
                        placeholder={this.props.getLangValue('searchButtonTitle')}
                        onChange={this.emptySearchInputInSelectable}
                        buttonAfter={(<Button onClick={this.handleSearchInSelectableButton}><i className='icon-search'></i></Button>)}
                        />
                </form>
                <div className="filter">
                    {this.allCheckCarButton()}
                    <div className="allCarsDeleteCheck" onClick={this.removeAllTrackedTarget}>
                        <h2>
                            <i className="icon-cancel"></i>
                            <span>{this.props.getLangValue('selectDelete')}</span>
                        </h2>
                    </div>
                </div>
                <div className="carslistInfinite">
                    <TargetTrackingSelectedList
                        trackedTargetList={this.props.trackedTargetList}
                        onUnselectFunction={this.props.onUnselectFunction}
                        handleInfoIconClick={this.props.handleInfoIconClick}
                        handleCarShareButton={this.props.handleCarShareButton}
                        trackedTargetsListHeight={this.state.trackedTargetsListHeight}
                        targetElementHeight={this.targetElementHeight}
                        setSelectedTargetNumber={this.setSelectedTargetNumber}
                        visitor={this.props.visitor}
                        getLangValue={this.props.getLangValue}
                        />
                    <div className="carslistSeparator"></div>
                    <TargetTrackingSelectableList
                        key={this.state.uniqueSelectableListKey}
                        selectableTargetList={this.props.selectableTargetList}
                        onSelectFunction={this.props.onSelectFunction}
                        onAppendToSelectableFunction={this.props.onAppendToSelectableFunction}
                        handleCarShareButton={this.props.handleCarShareButton}
                        selectableTargetsListHeight={this.state.selectableTargetsListHeight}
                        targetElementHeight={this.targetElementHeight}
                        searchValueInSelectable={this.state.searchValueInSelectable}
                        setSearchedSelectableTargetNumber={this.setSearchedSelectableTargetNumber}
                        setSearchedSelectableTargetNumberError={this.setSearchedSelectableTargetNumberError}
                        maxTrackedTargetLimit={this.props.maxTrackedTargetLimit}
                        maxSelectableTargetNumber={this.state.maxSelectableTargetNumber}
                        visitor={this.props.visitor}
                        selectAllRadioButtonClicked={this.state.selectAllRadioButtonClicked}
                        radioButtonClickedFunction={this.radioButtonClickedFunction}
                        isAllTargetSelected={this.isAllTargetSelected}
                        selectAllTargetFunction={this.selectAllTarget}
                        getLangValue={this.props.getLangValue}
                        notificationVersions={this.props.notificationVersions}
                        />
                </div>
            </div>
        )
    }

    radioButtonClickedFunction() {
        this.setState({
            selectAllRadioButtonClicked: false
        });
    }

    toggleRadioButtonFill(maxSelectableTargetNumberLoaded, maxSelectableTargetNumber, allTrackedTargetNumber) {
        if (maxSelectableTargetNumberLoaded) {
            if (allTrackedTargetNumber === maxSelectableTargetNumber) {
                this.isAllTargetSelected = true;
            } else {
                this.isAllTargetSelected = false;
            }
        } else {
            this.isAllTargetSelected = false;
        }
    }

    allCheckCarButton(){
        //Megjleneljen-e a minden target nyomkövetése gomb
        if(this.state.showAllCarsCheckButton){
            return (
				<div className="allCarsCheckd" data-load={this.state.selectAllRadioButtonClicked} onClick={this.selectAllTarget}>
                    <h2 data-allselect={this.isAllTargetSelected}>
						<i></i>
						<span>{this.props.getLangValue('allTracking')}</span>
						<span className="loaderAllTrackingCar"></span>
					</h2>
				</div>
			);
        }
    }

    /**
     * Minden keresőmező üresre
     * Minden target megjelenik mind a nyomkövetettek, mind a választhatóak között
     */
    resetAllSearch(){
        $('#searchInputInBoth').val('');
        $('#searchInputInSelected').val('');
        $('#searchInputInSelectable').val('');
        $('#overlay').addClass('overlay');
        $('#loader').addClass('loader');
        $('#exitButton').addClass('exitButton icon-cancel');
        this.props.emptySelectableTargets();
        TargetTrackingSelectedList.doSearchInTrackedTargetList(this.props.trackedTargetList, null, this.props.updateTrackedTargetList);
        this.setState({
            uniqueSelectableListKey: this.state.uniqueSelectableListKey * -1,
            searchValueInSelectable: null,
            searchValueInSelected: null
        }, function(){
            this.setTargetNumbers();
        });
    }

    selectAllTarget(){
        if (this.state.maxSelectableTargetNumber <= this.props.selectableTargetList.length()) {
            TargetTrackingSelectableList.selectAllTarget(this.props.selectableTargetList, this.props.onSelectFunction);
        } else {
            this.setState({
                selectAllRadioButtonClicked: true
            });
        }
    }

    removeAllTrackedTarget(){
        TargetTrackingSelectedList.removeAllTrackedTarget(this.props.trackedTargetList, this.props.onUnselectFunction);
    }

    setTargetNumbersForSmallScreen(){
        var selectableTargetNumberToShow;
        if(this.state.searchValueInSelectable != null){
                var numberOfTrackedTargetHasSearchValue = this.props.trackedTargetList.getNumberOfTargetsFromSearchValue(this.state.searchValueInSelectable);
                selectableTargetNumberToShow = this.state.selectableTargetNumber - numberOfTrackedTargetHasSearchValue;
        }
        else{
            selectableTargetNumberToShow = this.state.maxSelectableTargetNumber - this.state.selectedTargetNumber;
        }
        $('#trackingTabsCars').text(this.props.getLangValue('selectableCars') + " (" + selectableTargetNumberToShow + ")");
        $('#trackingTabsCarsSelected').text(this.props.getLangValue('trackedCars') + " (" + this.state.selectedTargetNumber + ")");
    }

    setTargetNumbersForLargeScreen(){
        if(this.state.searchValueInSelectable != null){
            $('.targetNumbers').text(this.state.selectedTargetNumber + " / " + this.state.selectableTargetNumber);
        }
        else{
            $('.targetNumbers').text(this.state.selectedTargetNumber + " / " + this.state.maxSelectableTargetNumber);
        }
    }

    setTargetNumbers(){
        if(this.props.onSmallScreen){
            this.setTargetNumbersForSmallScreen();
        }
        else{
            this.setTargetNumbersForLargeScreen();
        }
    }

    setSelectedTargetNumber(number){
        if(this.state.selectedTargetNumber != number){
            this.setState({
                selectedTargetNumber: number
            }, function(){
                this.setTargetNumbers();
            })
        }
    }

    setSearchedSelectableTargetNumberError(response, textStatus, error){
        Ajax.showError(response, textStatus, error, this.props.notificationVersions, this.props.getLangValue);
        this.setState({
            selectableTargetNumber: 0
        }, function(){
            this.setTargetNumbers();
        })
    }

    setSearchedSelectableTargetNumber(response){
        this.setState({
            selectableTargetNumber: response
        }, function(){
            this.setTargetNumbers();
        })
    }

    setAllSelectableTargetNumberError(response, textStatus, error){
        Ajax.showError(response, textStatus, error, this.props.notificationVersions, this.props.getLangValue);
        this.setState({
            maxSelectableTargetNumber: 0,
            maxSelectableTargetNumberLoaded: true
        }, function(){
            this.setTargetNumbers();
        })
    }

    setAllSelectableTargetNumber(response){
        this.setState({
            maxSelectableTargetNumber: response,
            maxSelectableTargetNumberLoaded: true
        }, function(){
            this.setTargetNumbers();
        })
    }

    setUpLargeScreenDesign() {
		$('#carsList').css('display', 'block');
		$('.searchBoxForBoth').removeClass('visuallyhidden');
		$('.searchBoxForSelectable').addClass('visuallyhidden');
		$('.searchBoxForSelected').addClass('visuallyhidden');
		$('.allCarsCheckd').removeClass('visuallyhidden');
		$('.allCarsDeleteCheck').removeClass('visuallyhidden');
		$('.carslistSeparator').removeClass('visuallyhidden');
		$('.carslistInfiniteSelected').removeClass('visuallyhidden');
		$('.carslistInfiniteSelectable').removeClass('visuallyhidden');
	}

    setContainerHeightForLargeScreen(trackedTargetsLength, selectableTargetsLength, newHeight){
        var selectableListHeight, trackedListHeight;
        this.maxContainerHeight = newHeight;
        //max méret - fejléc("Gépjárművek") - keresőmező - (összes kijelölése és összes törlése) - elválasztó
        if(this.state.showAllCarsCheckButton){
            this.maxContainerHeight = (this.maxContainerHeight - 122 - 50 - 70 - 10);
        }
        else{
            this.maxContainerHeight = (this.maxContainerHeight - 122 - 50 - 35 - 10);
        }
        if(((this.targetElementHeight * selectableTargetsLength) + (this.targetElementHeight * trackedTargetsLength)) >= this.maxContainerHeight){
            this.maxContainerHeight = (this.maxContainerHeight / 2);
            if ((this.targetElementHeight * trackedTargetsLength) > this.maxContainerHeight && (this.targetElementHeight * selectableTargetsLength) > this.maxContainerHeight) {
                trackedListHeight = this.maxContainerHeight;
                selectableListHeight = this.maxContainerHeight;
            }
            else if((this.targetElementHeight * trackedTargetsLength) > this.maxContainerHeight){
                this.maxContainerHeight = this.maxContainerHeight * 2;
                selectableListHeight = selectableTargetsLength * this.targetElementHeight + 1;
                trackedListHeight = this.maxContainerHeight - selectableListHeight;
            }
            else if((this.targetElementHeight * selectableTargetsLength) > this.maxContainerHeight){
                this.maxContainerHeight = this.maxContainerHeight * 2;
                trackedListHeight = trackedTargetsLength * this.targetElementHeight + 1;
                selectableListHeight = this.maxContainerHeight - trackedListHeight;
            }
        }
        else{
            trackedListHeight = this.targetElementHeight * trackedTargetsLength + 1;
            selectableListHeight = this.maxContainerHeight - trackedListHeight;
        }
        this.setState({
            selectableTargetsListHeight: selectableListHeight,
            trackedTargetsListHeight: trackedListHeight
        })
    }

    setContainerHeightForSmallScreen(newHeight){
        var selectableListHeight, trackedListHeight;
        this.maxContainerHeight = newHeight;
        //max méret - menüsáv - tabméret - keresőmező - (összes kijelölése / összes törlése)
        if(this.state.showAllCarsCheckButton){
            selectableListHeight = this.maxContainerHeight - 36 - 50 - 70 - 35;
            trackedListHeight = this.maxContainerHeight - 36 - 50 - 70 - 35;
        }
        else{
            selectableListHeight = this.maxContainerHeight - 36 - 50 - 70;
            trackedListHeight = this.maxContainerHeight - 36 - 50 - 70 - 35;
        }
        this.setState({
            selectableTargetsListHeight: selectableListHeight,
            trackedTargetsListHeight: trackedListHeight
        })
    }

    emptySearchInputInBoth(e) {
        e.preventDefault();
        var bothValue = this.refs.searchInputInBoth.getValue();
        if (bothValue.length == 0) {
            $('#overlay').addClass('overlay');
            $('#loader').addClass('loader');
            $('#exitButton').addClass('exitButton icon-cancel');
            this.props.emptySelectableTargets();
            TargetTrackingSelectedList.doSearchInTrackedTargetList(this.props.trackedTargetList, bothValue, this.props.updateTrackedTargetList);
            this.setState({
                uniqueSelectableListKey: this.state.uniqueSelectableListKey * -1,
                searchValueInSelectable: bothValue,
                searchValueInSelected: bothValue
            });
        }
    }

    emptySearchInputInSelected(e) {
        e.preventDefault();
        var selectedValue = this.refs.searchInputInSelected.getValue();
        if (selectedValue.length == 0) {
            TargetTrackingSelectedList.doSearchInTrackedTargetList(this.props.trackedTargetList, selectedValue, this.props.updateTrackedTargetList);
            this.setState({
                searchValueInSelected: selectedValue
            })
        }
    }

    emptySearchInputInSelectable(e) {
        e.preventDefault();
        var selectableValue = this.refs.searchInputInSelectable.getValue();
        if (selectableValue.length == 0) {
            this.props.emptySelectableTargets();
            this.setState({
                uniqueSelectableListKey: this.state.uniqueSelectableListKey * -1,
                searchValueInSelectable: selectableValue
            });
        }
    }

    handleSearchInBothButton(e) {
        e.preventDefault();
        var bothValue = this.refs.searchInputInBoth.getValue();
        $('#overlay').addClass('overlay');
        $('#loader').addClass('loader');
        $('#exitButton').addClass('exitButton icon-cancel');
        this.props.emptySelectableTargets();
        this.analyticsEvent(bothValue);
        TargetTrackingSelectedList.doSearchInTrackedTargetList(this.props.trackedTargetList, bothValue, this.props.updateTrackedTargetList);
        this.setState({
            uniqueSelectableListKey: this.state.uniqueSelectableListKey * -1,
            searchValueInSelectable: bothValue,
            searchValueInSelected: bothValue
        }, function () {
            $('#searchInputInBoth').blur();
        })
    }

    handleSearchInSelectedButton(e) {
        e.preventDefault();
        var selectedValue = this.refs.searchInputInSelected.getValue();
        this.analyticsEvent(selectedValue);
        TargetTrackingSelectedList.doSearchInTrackedTargetList(this.props.trackedTargetList, selectedValue, this.props.updateTrackedTargetList);
        this.setState({
            searchValueInSelected: selectedValue
        }, function(){
            $('#searchInputInSelected').blur();
        })
    }

    handleSearchInSelectableButton(e) {
        e.preventDefault();
        var selectableValue = this.refs.searchInputInSelectable.getValue();
        this.props.emptySelectableTargets();
        this.analyticsEvent(selectableValue);
        this.setState({
            uniqueSelectableListKey: this.state.uniqueSelectableListKey * -1,
            searchValueInSelectable: selectableValue
        }, function () {
            $('#searchInputInSelectable').blur();
        });
    }

    analyticsEvent(value){
    }

}

module.exports = TargetTrackingListsWrapper;