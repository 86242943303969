var React = require('react');
var classSet = require('react/lib/cx');
var Input = require('react-bootstrap/lib/Input');
var $ = require('jquery');

module.exports = React.createClass({

  getInitialState: function() {
    var value = "";
    if (this.props.value) {
      value = this.props.value;
    }

    return {
      value: value,
      error: "",
      errorMessage: ""
    };
  },

  getDefaultProps: function() {
    return {
      inputType: "text",
      label: "",
      value: "",
      placeholder: "",
      displayError: false,
      required: false
    }
  },

  getValue: function() {
    return this.refs.formGroup.getValue();
  },

  setValue: function(value) {
    this.setState({value: value});
  },

  handleChange: function(event) {
    this.setState({ value: this.getValue() });
  },

  validate: function() {

  },

  componentDidMount: function() {
    $('.RouteLogin').find('input[type="mail"]').attr('autocapitalize','off');
  },

  render: function() {

    var classes = {};
    classes['FormElement'] = true;
    classes['FormElementInput'] = true;
    classes['FormElementInput-Type--' + this.props.inputType] = true;
    classes['Required'] = this.props.required;

    var error = ""
    if (this.state.error) {
      classes['hasError'] = true;
      if (this.props.displayError && this.state.errorMessage != "") {
        error = this.state.errorMessage;
      }
    }

    var type = this.props.inputType;
    if (type == 'mail') {
      type = 'text';
    }

    return (
      <Input
        type={this.props.inputType}
        label={this.props.label}
        help={error}
        ref="formGroup"
        spellcheck="false"
        groupClassName={classSet(classes)}
        placeholder={this.props.placeholder}
        value={this.state.value}
        onBlur={this.props.onBlur}
        onKeyUp={this.props.onKeyUp}
        onChange={this.handleChange}
        addonBefore={this.props.icon} />
    )
  }

});
