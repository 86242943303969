// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

// Oldalsó menüsáv betöltése
var SideMenu = require('./SideMenu.jsx');

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Mobil tab választó betöltése
var TrackingTabs = require('./TrackingTabs.jsx');

var moment = require('moment');

var TargetTrackingMap = require('./TargetTrackingMap.jsx');
var TargetTrackingListsWrapper = require('./TargetTrackingListsWrapper.jsx');
var CarShare = require('./CarShare.jsx');
var WarningDialog = require('./WarningDialog.jsx');
var TargetTrackingSelectableList = require('./TargetTrackingSelectableList.jsx');
var TargetTrackingSelectedList = require('./TargetTrackingSelectedList.jsx');

import { DefaultTarget } from '../model/DefaultTarget';
import { TrackedTarget } from '../model/TrackedTarget';
import { TrackedTargetState } from '../model/TrackedTargetState';
import { TargetList } from '../model/TargetList';
import { GetTargets } from '../remote/GetTargets';
import { TrackTargets } from '../remote/TrackTargets';
import { TrackTargetsExecutor } from '../remote/TrackTargetsExecutor';
import Ajax from '../HOCs/Ajax';
import { GetTargetNumbers } from '../remote/GetTargetNumbers';

class TargetTracking extends React.Component {

	/**
	 * Bind-olások
	 * a választhatók és a nyomkövetettek inicializálása
	 */
	constructor(props) {
		super(props);
		this.addTrackedTarget = this.addTrackedTarget.bind(this);
		this.removeTrackedTarget = this.removeTrackedTarget.bind(this);
		this.appendToSelectableTargets = this.appendToSelectableTargets.bind(this);
		this.populatePrevioslyTrackedTargets = this.populatePrevioslyTrackedTargets.bind(this);
		this.populatePrevioslyError = this.populatePrevioslyError.bind(this);
		this.updateLiveData = this.updateLiveData.bind(this);
		this.handlePopupToggleWithIcon = this.handlePopupToggleWithIcon.bind(this);
		this.handleCarShareButton = this.handleCarShareButton.bind(this);
		this.updateScreenMode = this.updateScreenMode.bind(this);
		this.emptySelectableTargets = this.emptySelectableTargets.bind(this);
		this.updateTrackedTargetList = this.updateTrackedTargetList.bind(this);
		this.trackedTargetLimitError = this.trackedTargetLimitError.bind(this);
		this.getPopupTargetId = this.getPopupTargetId.bind(this);
		this.updatePopupTargetId = this.updatePopupTargetId.bind(this);
		this.setLocalStorageValueAndUpdateStateForTrackedTargetLimit = this.setLocalStorageValueAndUpdateStateForTrackedTargetLimit.bind(this);

		this.visitor = false;
		this.loadPrevioslyTrackedTargetsEnded = false;
		this.trackedTargetLimitKey = "trackedTargetLimit";
		this.defaultTrackedTargetLimit = 20;
		this.popupTargetId = null;
		this.state = {
			onSmallScreen: false,
			windowHeight: 1,
			selectableTargetList: new TargetList(),
			trackedTargetList: new TargetList(),
			maxTrackedTargetLimit: 0,
			maxTrackedTargetLimitUpdated: false
		};
		this.trackTargetsExecutor = new TrackTargetsExecutor(this.updateLiveData,
			this.visitor,
			this.props.notificationVersions,
			this.props.getLangValue, this.getPopupTargetId);
	}

	componentWillMount() {
		this.trackTargetsExecutor.abortPreviousAjaxCalls();
		this.setupLocalStorage();
		this.updateScreenMode();
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateScreenMode);
		document.addEventListener("pause", this.trackTargetsExecutor.stopLongPolling);
		document.addEventListener("resume", this.trackTargetsExecutor.restartLongPolling);
		GetTargetNumbers.getTrackedTargetLimit(this.setLocalStorageValueAndUpdateStateForTrackedTargetLimit, this.trackedTargetLimitError, this.visitor);
		$('button#warningbutton').off('click').on('click', function () {
			this.refs.warningDialog.openModal();
        }.bind(this));
	}

	//Akkor hívódik meg, ha a state frissül
	componentDidUpdate(prevProps, prevState) {
		if (!this.loadPrevioslyTrackedTargetsEnded && this.state.maxTrackedTargetLimitUpdated) {
			this.loadPrevioslyTrackedTargets();
		}
		this.trackTargetsExecutor.updateTrackedTargets(this.state.trackedTargetList, this.props.notificationVersions);
		var warningDialogOpenedTime = localStorage.getItem("warningDialogOpenedTime");
		if (warningDialogOpenedTime == null) {
			var currentTimeMillis = moment.utc().valueOf();
			localStorage.setItem("warningDialogOpenedTime", currentTimeMillis);
			this.refs.warningDialog.openModal();
		} else {
			// var oneDayInMillis = 86400000;
			var tenMinInMillis = 600000;
			var currentTimeMillis = moment.utc().valueOf();
			var maxTimeWithoutWarnDialog = Number(warningDialogOpenedTime) + Number(tenMinInMillis);
			if (maxTimeWithoutWarnDialog < currentTimeMillis) {
				localStorage.setItem("warningDialogOpenedTime", currentTimeMillis);
				this.refs.warningDialog.openModal();
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateScreenMode);
		this.trackTargetsExecutor.abortPreviousAjaxCalls();
	}

	render() {
		return (
			<main id="trackingPage">
				<MobileHeader
					mobilMenuName={this.props.getLangValue('tracking')} />
				<TrackingTabs
					ref="tabs"
					getLangValue={this.props.getLangValue}
					onSmallScreen={this.state.onSmallScreen}
					trackedTargetList={this.state.trackedTargetList}
					visitor={this.visitor} />
				<MobilMenu ref="left" activeMenu="tracking" lang={this.state.lang} />
				<SideMenu
					lang={this.props.lang}
					activeMenu="tracking"
					getValue={this.props.getLangValue}
					addNotification={this.props.notificationVersions.addNotification}
					addTranslateNotification={this.props.notificationVersions.addTranslateNotification}
					notification={this.props.notificationVersions.notification}
					notificationSystem={this.props.notificationVersions.notificationSystem}
					visitor={this.visitor}
				/>
				<section id="carsList">
					<TargetTrackingListsWrapper
						trackedTargetList={this.state.trackedTargetList}
						selectableTargetList={this.state.selectableTargetList}
						onSelectFunction={this.addTrackedTarget}
						onUnselectFunction={this.removeTrackedTarget}
						onAppendToSelectableFunction={this.appendToSelectableTargets}
						handleInfoIconClick={this.handlePopupToggleWithIcon}
						handleCarShareButton={this.handleCarShareButton}
						onSmallScreen={this.state.onSmallScreen}
						windowHeight={this.state.windowHeight}
						emptySelectableTargets={this.emptySelectableTargets}
						updateTrackedTargetList={this.updateTrackedTargetList}
						maxTrackedTargetLimit={this.state.maxTrackedTargetLimit}
						maxTrackedTargetLimitUpdated={this.state.maxTrackedTargetLimitUpdated}
						visitor={this.visitor}
						getLangValue={this.props.getLangValue}
						notificationVersions={this.props.notificationVersions}
					/>
					<CarShare ref="carshare" />
					<WarningDialog ref="warningDialog" />
				</section>
				<div className="mapContainer" >
					<button id="autozoom" ><i className="icon-gps"></i></button>
					<button id="warningbutton" ><i className="icon-warning"></i></button>
					<TargetTrackingMap
						ref="trackingMap"
						trackedTargetList={this.state.trackedTargetList}
						removeTrackedTarget={this.removeTrackedTarget}
						visitor={this.visitor}
						onSmallScreen={this.state.onSmallScreen}
						getLangValue={this.props.getLangValue}
						notificationVersions={this.props.notificationVersions}
						updateLiveDataFunction={this.updateLiveData}
						updatePopupTargetId={this.updatePopupTargetId}
					/>
				</div>
			</main>
		);
	}

	getPopupTargetId() {
		return this.popupTargetId;
	}

	updatePopupTargetId(newPopupTargetId) {
		this.popupTargetId = newPopupTargetId;
	}

	trackedTargetLimitError(response, status, error) {
		Ajax.showError(response, status, error, this.props.notificationVersions, this.props.getLangValue);
		var trackedTargetLimitValue = localStorage.getItem(this.trackedTargetLimitKey);
		if (trackedTargetLimitValue == null) {
			this.setLocalStorageValueAndUpdateStateForTrackedTargetLimit(this.defaultTrackedTargetLimit);
		} else {
			this.setState({
				maxTrackedTargetLimit: trackedTargetLimitValue,
				maxTrackedTargetLimitUpdated: true
			})
		}
	}

	setLocalStorageValueAndUpdateStateForTrackedTargetLimit(value) {
		localStorage.setItem(this.trackedTargetLimitKey, value);
		this.setState({
			maxTrackedTargetLimit: value,
			maxTrackedTargetLimitUpdated: true
		});
	}

	emptySelectableTargets() {
		this.setState({
			selectableTargetList: new TargetList()
		})
	}

	updateScreenMode() {
		if (this.state.onSmallScreen && ($(window).innerWidth() > 992)) {
			this.setState({
				onSmallScreen: false,
			});
		}
		else if (!this.state.onSmallScreen && ($(window).innerWidth() <= 992)) {
			this.setState({
				onSmallScreen: true
			});
		}
		if (this.state.windowHeight != window.innerHeight) {
			this.setState({
				windowHeight: window.innerHeight
			});
		}
	}

	handleCarShareButton(targetId) {
		this.refs.carshare.openModal(true, targetId);
	}

	handlePopupToggleWithIcon(targetId) {
		if (this.state.onSmallScreen && !$('#trackingTabsMaps').hasClass('active') && targetId != null) {
			this.refs.tabs.showMaps();
		}
		this.refs.trackingMap.toggleInfoPopup(targetId);
	}

	// To be used to be called from onEvent()-like methods
	addTrackedTarget(defaultTarget) {
		return function () {
			this.setState((prevState, props) => {
				if (prevState.trackedTargetList.length() >= this.state.maxTrackedTargetLimit) {
					var maxCarTitle = this.props.getLangValue('maxCarTitle');
					var maxCarTitleWithLimit = maxCarTitle.replace("{num}", this.state.maxTrackedTargetLimit);
					this.props.notificationVersions.addNotification(maxCarTitleWithLimit, 'warning');
					return {
						trackedTargetList: prevState.trackedTargetList,
						selectableTargetList: prevState.selectableTargetList
					};
				}
				var newDefaultTarget = new DefaultTarget(defaultTarget.getId(),
					defaultTarget.getLicenseplate(),
					defaultTarget.getDescription(),
					true);
				//var newDefaultTarget = defaultTarget;
				var trackedTarget = new TrackedTarget(newDefaultTarget, TrackedTargetState.Pending, null);
				var newTrackedTargetList = prevState.trackedTargetList.deepCopyTrackedTargetList();
				newTrackedTargetList.add(trackedTarget);

				var targetListToDisableInSelectables = new TargetList();
				targetListToDisableInSelectables.add(newDefaultTarget);
				var newSelectableTargetList = prevState.selectableTargetList.copy();
				newSelectableTargetList.disableTargets(targetListToDisableInSelectables);

				this.saveCurrentlyTrackedTargetsToLocalStorage(newTrackedTargetList);

				return {
					trackedTargetList: newTrackedTargetList,
					selectableTargetList: newSelectableTargetList
				};
			});
		}.bind(this);
	}

	// To be used to be called from onEvent()-like methods
	removeTrackedTarget(defaultTarget) {
		return function () {
			this.setState((prevState, props) => {
				var newDefaultTarget = new DefaultTarget(defaultTarget.getId(),
					defaultTarget.getLicenseplate(),
					defaultTarget.getDescription(),
					false);
				//var newDefaultTarget = defaultTarget;
				var targetId = newDefaultTarget.getId();
				var newTrackedTargetList = prevState.trackedTargetList.deepCopyTrackedTargetList();
				newTrackedTargetList.remove(targetId);

				var targetListToEnableInSelectables = new TargetList();
				targetListToEnableInSelectables.add(newDefaultTarget);
				var newSelectableTargetList = prevState.selectableTargetList.copy();
				newSelectableTargetList.enableTargets(targetListToEnableInSelectables);

				this.saveCurrentlyTrackedTargetsToLocalStorage(newTrackedTargetList);

				return {
					trackedTargetList: newTrackedTargetList,
					selectableTargetList: newSelectableTargetList
				}
			});
		}.bind(this)
	}

	updateTrackedTargetList(newTrackedTargetList) {
		this.setState({
			trackedTargetList: newTrackedTargetList
		})
	}

	appendToSelectableTargets(selectableTargetListToAppend) {
		this.setState((prevState, props) => {
			selectableTargetListToAppend.disableTargets(prevState.trackedTargetList);
			var newSelectableTargetList = prevState.selectableTargetList.copy();
			newSelectableTargetList = newSelectableTargetList.concat(selectableTargetListToAppend);
			return { selectableTargetList: newSelectableTargetList }
		});
	}

	loadPrevioslyTrackedTargets() {
		var itemKey = 'carsTrackingId';
		var previoslyTrackedTargetIds = JSON.parse(localStorage.getItem(itemKey));
		if (previoslyTrackedTargetIds == null || previoslyTrackedTargetIds == 'undefined' || previoslyTrackedTargetIds.length == 0) {
			this.loadPrevioslyTrackedTargetsEnded = true;
			return;
		}
		GetTargets.queryMultiple(previoslyTrackedTargetIds, this.populatePrevioslyTrackedTargets, this.populatePrevioslyError, this.visitor);
		this.loadPrevioslyTrackedTargetsEnded = true;
	}

	/**
	 * Targetek hozzáadása
	 */
	populatePrevioslyTrackedTargets(targetsJson) {
		var newTrackedTargetList = new TargetList();
		targetsJson.map(function (targetJson) {
			var defaultTarget = new DefaultTarget(targetJson.id, targetJson.name, targetJson.description, true);
			var trackedTarget = new TrackedTarget(defaultTarget, TrackedTargetState.Pending, null);
			newTrackedTargetList.add(trackedTarget);
			this.addTrackedTarget(defaultTarget)();
		}, this);
	}

	populatePrevioslyError(response, textStatus, error) {
		Ajax.showError(response, textStatus, error, this.props.notificationVersions, this.props.getLangValue);
	}

	/**
	 * A kapott élő adatok alapján a nyomkövetettek frissítése (állapotfrissítés)
	 */
	updateLiveData(liveDataArray) {
		var deepCopiedTrackedTargetList = this.state.trackedTargetList.deepCopyTrackedTargetList();
		liveDataArray.map(function (livaDataElement) {
			var targetId = livaDataElement.targetId;
			var liveData = livaDataElement.liveData;
			var trackingState = livaDataElement.trackingState;

			deepCopiedTrackedTargetList.updateLiveData(targetId, liveData, trackingState);
		});
		this.setState({
			trackedTargetList: deepCopiedTrackedTargetList
		})
	}

	//A megkapott listát menti el LocalStorage-ba
	saveCurrentlyTrackedTargetsToLocalStorage(targetList) {
		var itemKey = 'carsTrackingId';
		localStorage.setItem(itemKey, JSON.stringify(targetList.getAllIds()));
	}

	//LocalStorage kulcs létrehozása és üresre inicializálása
	setupLocalStorage() {
		var itemKey = 'carsTrackingId';
		var previoslyTrackedTargetIds = JSON.parse(localStorage.getItem(itemKey));
		if (previoslyTrackedTargetIds == null) {
			localStorage.setItem(itemKey, JSON.stringify([]));
		}
	}
}

module.exports = TargetTracking;