// React betĂ¶ltĂ©se
var React = require('react');
// -----------------------------------------------------

// Jquery betĂ¶ltĂ©se
var $ = require('jquery');
// -----------------------------------------------------

var DocumentTitle = require('react-document-title');

// Canadarm javascript logger
var logger = require('canadarm');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');

// Offline js betĂ¶ltĂ©se
require('../commons/offline.js');
// -----------------------------------------------------

// Router betĂ¶ltĂ©se, inicializĂˇlĂˇsa
var Router = require('react-router');
var Route = Router.Route;
var DefaultRoute = Router.DefaultRoute;
var RouteHandler = Router.RouteHandler;
// -----------------------------------------------------

// NyelvesĂ­tĂ©s betĂ¶ltĂ©se
var i18nLoader = require('../commons/I18n.js');
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Nyelvek eltĂˇrolĂˇsa
i18nLoader.setLanguages();

// -----------------------------------------------------

// Localstorage figyelĹ‘
var storageListen = require('../mixins/storageListen.js');
// -----------------------------------------------------

// Router oldalak
var ChangePassword = require('./ChangePassword.jsx');
var PasswordResetEmailSender = require('./PasswordResetEmailSender.jsx');
var ForgotEmailUsernamePair = require('./ForgotEmailUsernamePair.jsx');
var RouteLogin = require('./RouteLogin.jsx');
var RouteLostPassword = require('./RouteLostPassword.jsx');
var RouteRegistration = require('./RouteRegistration.jsx');
var RouteDashboard = require('./RouteDashboard.jsx');
var TargetTracking = require('./TargetTracking.jsx');
var TargetTrackingShare = require('./TargetTrackingShare.jsx');
var TargetTrackingIframe = require('./TargetTrackingIframe.jsx');
var RouteRouter = require('./RouteRouter.jsx');
var RouteMessages = require('./RouteMessages.jsx');
var RouteLogout = require('./RouteLogout.jsx');
var RouteFueling = require('./RouteFueling.jsx');

var RouteLoginToken = require('./RouteLoginToken.jsx');
var RouteToken = require('./RouteToken.jsx');
var RouteRouterToken = require('./RouteRouterToken.jsx');
var RouteTrackingIframe = require('./RouteTrackingIframe.jsx');
var RouteTokenInformation = require('./RouteTokenInformation.jsx');
// -----------------------------------------------------

var moment = require('moment');
// MomentJS nyelvi fĂˇjlok betĂ¶ltĂ©se
var momentRO = require('../../../node_modules/moment/locale/ro.js');
var momentSR = require('../../../node_modules/moment/locale/sr.js');
var momentEN = require('../../../node_modules/moment/locale/en-gb.js');
var momentHU = require('../../../node_modules/moment/locale/hu.js');
var momentHR = require('../../../node_modules/moment/locale/hr.js');
var momentSK = require('../../../node_modules/moment/locale/sk.js');
var momentCS = require('../../../node_modules/moment/locale/cs.js');
// -----------------------------------------------------

import { TrackingMode } from '../model/TrackingMode';
import Authentication from '../HOCs/Authentication';
import Language from '../HOCs/Language';
import Notify from '../HOCs/Notify';
import isIE9 from '../model/IsIE9';

import 'vanilla-cookieconsent';

import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { SentryUtils } from '../model/SentryUtils';
let releaseVersion = "MobileWebapp_1.27";
let environment = "@@sentryEnvironment";

if (environment != "undefined" && environment != "") {
    Sentry.init({
        dsn: 'https://1e99629b6cfc4afd8653977bdd9ca4e4@o30741.ingest.sentry.io/5376830',
        integrations: [new CaptureConsoleIntegration({
            levels: ['error']
        })],
        release: releaseVersion,
        environment: environment
    });
}

SentryUtils.setTag("platform", "web");
let userId = localStorage.getItem('user_id');
if (userId != null && userId != "" && userId != -1) {
    SentryUtils.setUser(userId);
}

let tokenId = localStorage.getItem('tokenId');
if (tokenId != null && tokenId != "") {
    SentryUtils.setTag("token", tokenId);
}
let tokenUserId = localStorage.getItem('token_user_id');
if (tokenUserId != null && tokenUserId != "") {
    SentryUtils.setUser(tokenUserId);
}


// Ha a localStorage-ban nincs eltĂˇrolva nyelv akkor az alapĂ©rtelmezettet beĂˇllĂ­tjuk.
if (localStorage.getItem('lang') == null) {
    localStorage.setItem('lang', i18nLoader.getDefaultLanguage());
}

// Az utolsó time micros érték beállítása -1 értékre
localStorage.setItem('latestTimeMicros', -1);

// Ezzel vizsgáljuk, a hibaüzeneteknél, hogy háttérben van e az alkalmazás vagy nem.
localStorage.setItem('isSleep', 'false');

// Nyelvi kulcs adása a body class-hoz
$('body').addClass(localStorage.getItem('lang'));

// Ajax global beállítások
$.ajaxSetup({
    cache: false
});

// title beállítás brand alapján (a brand hostname alapján kerül meghatározásra a Brands.jsx-ben)
var brand = Brands.getBrand();
var title = "iTrack";
if (brand == "topcop") {
    title = "TopCopGPS webkliens";
}
if (brand == "tolltickets") {
    title = "OMV - Tolltickets";
}

$.trackingAjax = [];
$.popupRefresh = [];
$.messageAjax = [];

var App = React.createClass({

    mixins: [Router.Navigation, storageListen],

    render: function () {
        return (
            <DocumentTitle title={title}>
                <RouteHandler />
            </DocumentTitle>
        );
    }
});
//

var routes = (
    <Route name="app" path="/" handler={App}>
        <Route name="changepassword" path="/changepassword" handler={ChangePassword} />
        <Route name="sendreset" path="/sendreset" handler={PasswordResetEmailSender} />
        <Route name="forgotemailusernamepair" path="/forgotemailusernamepair" handler={ForgotEmailUsernamePair} />
        <Route name="lostpassword" path="/lostpassword" handler={RouteLostPassword} />
        <Route name="login" path="/login" handler={RouteLogin} />
        <Route name="registration" path="/registration" handler={RouteRegistration} />
        <Route name="dashboard" path="/dashboard" handler={RouteDashboard} />
        <Route name="tracking" path="/tracking" handler={Authentication(Language(Notify(TargetTracking)))} />
        <Route name="router" path="/router" handler={RouteRouter} />
        <Route name="messages" path="/messages" handler={RouteMessages}>
            <Route name="messagesDetails" path="/messages/:id" handler={RouteMessages} />
        </Route>
        <Route name="fueling" path="/fueling" handler={RouteFueling} />
        <Route name="logout" path="/logout" handler={RouteLogout} />
        <Route name="loginToken" path="/login/:tokenId/:type/:lang" handler={RouteLoginToken} />
        <Route name="sharertracking" path="/share/tracking/:tokenId" handler={Language(Notify(TargetTrackingShare))} />
        <Route name="sharerouter" path="/share/routes/:tokenId" handler={RouteRouterToken} />
        <Route name="shareiframe" path="/share/iframe/:tokenId" handler={Language(Notify(TargetTrackingIframe))} />
        <Route name="shareinformation" path="/share/information" handler={RouteTokenInformation} />
        <DefaultRoute handler={RouteLogin} />
    </Route>
);

logger.init({
    onError: true,  // Set to false if you do not want window.onerror set.
    wrapEvents: true, // Set to false if you do not want all event handlers to be logged for errors
    logLevel: logger.level.DEBUG, // Will only send logs for level of DEBUG and above.
    appenders: [
        function (level, exception, message) {
            var uId = localStorage.getItem('user_id') || logger.constant.UNKNOWN_LOG;
            if (level == 'DEBUG') {
                return {
                    userId: uId,
                    exception: exception,
                    level: level,
                    message: message,
                    timestamp: moment().utc()
                };
            } else {
                return {
                    userId: uId,
                };
            }
        }
    ],
    handlers: [
        logger.Handler.beaconLogHandler('@@faviconToGet'),
        logger.Handler.consoleLogHandler
    ]
});

// Router futtatása
Router.run(routes, function (Handler, state) {
    // Alkalmazás renderelése
    React.render(<Handler />, document.getElementById('content'));
});

document.addEventListener("pause", function () {
    localStorage.setItem('isSleep', 'true');
}, false);

document.addEventListener("resume", function () {
    setTimeout(function () {
        localStorage.setItem('isSleep', 'false');
    }, 1000);
}, false);

$('#exitButton').click(function () {
    $('#overlay').removeClass('overlay');
    $('#loader').removeClass('loader');
    $('#exitButton').removeClass('exitButton icon-cancel');
});

// Delete google analytics cookies stored in browser
var cookies = document.cookie.split(';')
for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trimStart()
    if (cookie.match("^_ga") || cookie.match("^_gid")) {
        var cookieName = cookie.split('=')[0];
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.itrack.hu; path=/"
    }
}

if (!document.getElementById('cc--main')) {
    window.CC = window.initCookieConsent();
    window.CC.run({
        current_lang: localStorage.getItem('lang'),

        onFirstAction: function (user_preferences, cookie) {
        },

        onAccept: function (cookie) {
        },

        onChange: function (cookie, changed_categories) {
        },

        gui_options: {
            consent_modal: {
                layout: 'box',
                position: 'bottom center',
                transition: 'slide',
                swap_buttons: false
            }
        },

        languages: {
            'en': {
                consent_modal: {
                    title: 'This site uses cookies',
                    description: 'For more information please visit: <a target=_blank href=https://www.idatatelematics.com/privacy-policy/>privacy policy</a>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            },
            'hr': {
                consent_modal: {
                    title: 'Ova stranica koristi kolačiće',
                    description: 'Za više informacija posjetite: <a target=_blank href=https://www.itrack.hr/pravila-privatnosti/>pravila privatnosti</a>',
                    primary_btn: {
                        text: 'Prihvatiti',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Odbijeno',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            },
            'cz': {
                consent_modal: {
                    title: 'This site uses cookies',
                    description: 'For more information please visit: <a target=_blank href=https://www.itrackgps.cz/pruvodce-spravou-dat/>průvodce správou dat</a>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            },
            'sr': {
                consent_modal: {
                    title: 'This site uses cookies',
                    description: 'For more information please visit: <a target=_blank href=https://www.idatatelematics.com/privacy-policy/>privacy policy</a>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            },
            'ro': {
                consent_modal: {
                    title: 'This site uses cookies',
                    description: 'For more information please visit: <a target=_blank href=https://www.idatatelematics.com/privacy-policy/>privacy policy</a>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            },
            'sk': {
                consent_modal: {
                    title: 'This site uses cookies',
                    description: 'For more information please visit: <a target=_blank href=https://www.itrack.sk/prirucka-spravy-dat/>príručka správy dát</a>',
                    primary_btn: {
                        text: 'Accept',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            },
            'hu': {
                consent_modal: {
                    title: 'Az oldal sütiket használ',
                    description: 'További információkat itt talál: <a target=_blank href=https://www.itrack.hu/adatvedelmi-iranyelvek/>adatvédelmi irányelvek</a>',
                    primary_btn: {
                        text: 'Elfogadom',
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Nem fogadom el',
                        role: 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '',
                    save_settings_btn: '',
                    accept_all_btn: '',
                    reject_all_btn: '',
                    close_btn_label: '',
                    blocks: [
                        {
                            title: '',
                            description: '',
                            toggle: {
                                value: 'analytics',
                                enabled: true,
                                readonly: false
                            }
                        }
                    ]
                }
            }
        }
    });
}
