

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var ButtonInput = require('react-bootstrap/lib/Button');

var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

	mixins: [Languages, Ajax],

  getDefaultProps : function(){
    return {
      threadId : 0,
      messageSent : function(){},
      prevKeyCode : 0
    }
  },

  getInitialState: function() {

    return {
      disabledButton : true,
			emptyMessageValue: true,
			isLoad: false
    };
  },

  handleSubmit : function(event){
    event.preventDefault();
    event.stopPropagation();
    var $textarea = $(this.refs.textarea.getDOMNode());
    if( $textarea.val().length === 0 ){ return false; }
    var _this = this;
		var _event = event;

		var mesage = $textarea.val();
		$textarea.val('').trigger('keyup');
		this.setState( {
			emptyMessageValue: true,
			isLoad: true
		});

    $.ajax({
      url: "@@servletUrl"+"messages/send/" + _this.props.threadId,
      type: "POST",
      data : JSON.stringify({
        mimeType : "UTF8_STRING",
        body : mesage
      }),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
			timeout : 30000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success : function(response, xhrStatusText, xhrObj){
        if( xhrObj.status === 201 && typeof response === 'object' ){
          _this.props.messageSent(response);
					_this.setState( {
						isLoad: false
					});
					$('#checkUser').hide();

        }
      },
      error : function(response, status, xhr){

				_this.setState( {
					isLoad: false
				});

        _this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.handleSubmit);
      }
    });

  },

  handleKeyUp : function(e){
		var $textarea = $(this.refs.textarea.getDOMNode());
    var text = $textarea.val();

    var disabled = false;

		if( text.length === 0 ) {
			disabled = true;
		} else if( text.length === 1 ) {
			if(e.keyCode === 13 || e.keyCode === 32) {
				$textarea.val('');
				disabled = true;
			}
		}

    this.setState({disabledButton : disabled});

    if(this.props.prevKeyCode !== 16 && e.keyCode === 13 && !disabled){
      $(this.refs.submitButton.getDOMNode()).trigger('click');
      return false;
    }
  },

  handleKeyDown : function(e){
    this.props.prevKeyCode = e.keyCode;
  },

  render: function() {

		if(this.state.isLoad == false) {
			var load = (<ButtonInput ref="submitButton" disabled={this.state.disabledButton} onClick={this.handleSubmit} type="submit" bsSize="small"><i className="icon-send"></i>{this.getValue('seding')}</ButtonInput>);
		} else {
			var load = (<div className='loaderSendMessage'><span></span></div>);
		}

    return (
      <section id="messagesSend">
        <div>
          <form>
            <textarea onKeyUp={this.handleKeyUp} onChange={this.handleKeyUp} onKeyDown={this.handleKeyDown} ref="textarea" placeholder={this.getValue('writeMessage')}></textarea>
						{load}
          </form>
        </div>
      </section>
    );
  }
});
