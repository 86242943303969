// Jquery betöltése
var $ = require('jquery');

class GetTargets {
	
	static query(limit, page, searchValue, successCallback, errorCallback, visitor) {
		
		// Url összeállítása
		var url = '';

		var params = "offset=" + ((page * limit) - limit) + "&limit=" +  limit;

		if(searchValue == null) {
			url = "targets?" + params;
		} else {
			url = "targets?filter=" + searchValue + '&' + params;
		}

		GetTargets.doQuery(url, successCallback, errorCallback, visitor, true, 30000);
	}

	static queryAllTargetFromOffset(offset, successCallback, errorCallback, visitor) {
		var url = "targets?" + "offset=" + offset;
		GetTargets.doQuery(url, successCallback, errorCallback, visitor, true, 30000);
	}

	static queryMultiple(targetIds, successCallback, errorCallback, visitor) {
		var url = "targets?";

		for(var index = 0; index < targetIds.length; index++) {
			var id = targetIds[index];
			url = url + "id=" + id;
			if(index != targetIds.length - 1) {
					url = url + "&";
			}
		}
		GetTargets.doQuery(url, successCallback, errorCallback, visitor, false, 15000);
	}

	static doQuery(url, successCallback, errorCallback, visitor, async, timeout) {
		$.ajax( {
			url: "@@servletUrl" + url,
			type: "GET",
			timeout : timeout,
			async: async,
			cache: false,
			beforeSend: function (xhr) {
				if(visitor == true) {
					xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				} else {
					xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				}
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success: successCallback,
			error: errorCallback
		});
	}
}

export { GetTargets };