module.exports = {
  loginTitle: "Bejelentkezés",
  password: "Jelszó",
  tracking: "Nyomkövetés",
  router: "Útvonalak",
  message: "Üzenetek",
  fueling: "Tankolási lista",
  logout: "Kijelentkezés",
  email: "E-mail",
  wrongEmailFormat: "Hibás e-mail cím formátum!",
  forgotYourPassword: "Elfelejtette a jelszavát?",
  forgotEmailUsernamePair: "Nem sikerült elküldeni az e-mailt?",
  forgotEmailUsernamePairBody: 'Tisztelt Ügyfelünk!<br/>Amennyiben nem sikerült elfelejtett jelszavát módosítani, kérjük vegye fel a kapcsolatot ügyfélszolgálatunkkal!<br/><br/>Ügyfélszolgálat ügyeleti idő:<br/>H-Cs: 7:30-18:00<br/>P: 8:00-15:30',
  successPasswordResetEmailSend: "Kiküldtük a megadott e-mail címre a jelszó módosításhoz szükséges linket!",
  successPasswordChange: "Sikeresen módosítottunk jelszavát. Most már új jelszava segítségével be tud jelentkezni rendszerünkbe.",
  sendPasswordResetButtonText: 'E-mail küldése!',
  emptyFieldPasswordReset:'Felhasználónév vagy e-mail mező nincs kitöltve!',
  passwordAgain:'Jelszó újra',
  emptyFieldChangePassword:'Kérem töltse ki mind a két mezőt!',
  passwordsNotMatch:'A két beírt jelszó nem egyezik meg!',
  passwordsNotLongEnough:'A jelszavaknak legalább 8 karakter hosszúnak kell lenniük!',
  passwordsUpperLowerCases:'A jelszavaknak tartalmazniuk kell kis- és nagybetűket egyaránt!',
  passwordResetTokenAuthenticationFailed: 'Az Ön által használt ideiglenes jelszómódosító link lejárt! Kérjük igényeljen újat, és változtassa meg jelszavát 15 percen belül azt igénylést követően!',
  changePasswordButtonText:'Jelszó módosítása',
  passwordChangeServiceNotAvailable:'Lépjen kapcsolatba ügyfélszolgálatunkkal',
  passwordResetTitle:'Új jelszó igénylése',
  passwordResetDescription: 'Kérem adja meg felhasználónevét és e-mail címét!',
  changePasswordTitle:'Jelszó módosítás',
  changePasswordDescription:'Kérem adja meg az alábbi felhasználónévhez tartozó új jelszavát:',
  passwordResetImportantDescription: 'Fontos! Kizárólag az iTrack rendszerben felhasználónevéhez rendelt e-mail cím megadásával tud új jelszót igényelni!',
  login: "Bejelentkezés",
  dontHaveAccount: "Még nincs fiókod?",
  signUp: "Regisztrálj",
  username: "Felhasználónév",
  registrationTitle: 'Regisztráció',
  back: 'Vissza',
  cars: 'Gépjárművek',
  selectableCars: 'Választható járművek',
  trackedCars: 'Kiválasztott járművek',
  allTracking: 'Összes követése',
  selectDelete: 'Kijelölések törlése',
  moreInformation: 'További információk megjelenítése',
  openGoogleMaps: 'Gépjármű aktuális helyzete megtekintés a Google Maps térképen',
  nodata: "Nincs adat",
  carShare: 'Gépjármű megosztás',
  carShareTitle: 'Gépjármű megosztás partnerrel',
  shareTabTitle: 'Megosztás',
  page: 'Oldal',
  access: 'Hozzáférés*',
  startDate: 'Kezdő időpont',
  endDate: 'Vég időpont',
  pleaseWriteEmail: 'Kérlek írd be az e-mail címet!',
  embed: 'Beágyazható megosztás',
  shareButtonTitle: 'Megosztás',
  closeButtonTitle: 'Bezár',
  sheSaw: 'Látta',
  writeMessage: 'Írd ide az üzenetet!',
  seding: 'Elküldés',
  messages: 'Üzenetek',
  searchButtonTitle: 'Keresés',
  fuelingFormTitle: 'Tankolás*',
  dateTitle: 'Dátum',
  country: 'Ország',
  position: 'Pozíció',
  start: 'Kezdés (L)',
  end: 'Befejezés (L)',
  volume: 'Mennyiség (L)',
  logInProgress: 'Bejelentkezés folyamatban...',
  loginFailed: 'Sikertelen bejelentkezés',
  downtime: 'Állásidő',
  event: 'esemény',
  messageSearchPlace: 'Keresés',
  messageSearchText: 'Kezdj el gépelni!',
  hu: 'Magyar',
  en: 'English',
  ro: 'Română',
  sr: 'Srpski',
  hr: 'Hrvatski',
  sk: 'Slovenský',
  cz: 'Český',
  maxCarTitle: 'Maximum {num} gépjárművet lehet kijelölni',
  today: 'ma',
  squel: 'Folytatás',
  unknownError: 'Ismeretlen hiba',
  systemError: 'A szolgáltatás jelenleg nem elérhető! Kérjük lépjen kapcsolatba az ügyfélszolgálattal.',
  timeout: 'A kapcsolat időtúllépés miatt megszakadt. Kérjük kattintson az alábbi gombra.',
  networkError: 'A hálózat nem elérhető. Kérjük ellenőrizze a kapcsolatot.',
  notPositions: 'A következő gépjárműveknek még nincsen koordinátája: ',
  accessExpired: '(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(ENG) You are currently not authorized to access.<br/><br/>(RON) Valabilitatea accesului  a expirat.<br/><br/>(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.',
  errorLogin: 'Hibás felhasználónév vagy jelszó!',
  emptyFieldLogin: 'Felhasználónév vagy jelszó mező nincs kitöltve!',
  carshareDialogText: 'Kezdő vagy a vég időpont mező nincs kitöltve!',
  notSelectCar: 'Nincs kiválasztva gépjármű!',
  messageSearchTextNotFound: 'Nincs találat!',
	internetConnectionTerminated: 'Internet kapcsolat megszűnt!',
	emptyFuelList: 'A megadott feltételekkel nincs megjeleníthető tankolási lista!',
	emptyRoutesList: 'A kívánt időszakban nincs érvényes útvonal!',
	speed: 'Sebesség',
	map: 'Térkép',
	selectDate: 'Dátum kiválasztása',
  pleaseSelectEndDate: 'Kérem válasszon végdátumot!',
	oneSelectMode: 'Egy útvonalas üzemmód',
	multipleSelectMode: 'Több útvonalas üzemmód',
	pageReload: 'Oldal frissítése',
	registrationBody: 'Tisztelt Leendő Ügyfelünk!<br/>A regisztrációhoz kérjük, hogy vegye fel a kapcsolatot ügyfélszolgálatunkkal.<br/><br/>Ügyfélszolgálat ügyeleti idő:<br/>H-Cs: 7:30-18:00<br/>P: 8:00-15:30',
	lostPasswordBody: 'Tisztelt Ügyfelünk!<br/>Amennyiben elfelejtette jelszavát, kérjük vegye fel a kapcsolatot ügyfélszolgálatunkkal.<br/><br/>Ügyfélszolgálat ügyeleti idő:<br/>H-Cs: 7:30-18:00<br/>P: 8:00-15:30',
	carShareText1: 'Ezzel a funkcióval a kiválasztott célpont(ok)ra a megadott időintervallumon belül érvényes linket generálhat. Ezt elküldheti a partnereinek akik ezen keresztül nyomon követhetik járműveit. A megadott időintervallumon kívül a generált linken keresztül nincs lehetőség jármű adatokat elérni.',
	carShareText2: 'Ezzel a funkcióval egy Weboldalakba beágyazható HTML részletet generálhat amivel a kiválasztott célpont(ok)at a megadott időintervallumon belül megjelenítheti weboldalán.',
	refreshInProgress: 'A jármű adatainak frissítése már folyamatban van',
  newPlutoDialogContent: 'Kedves Ügyfelünk,<p><br>Elérhetővé vált a megújult külsővel és új funkcionalitással (pl.: útvonal- és tankolási összesítő) is rendelkező iTrack mobil kliens program.</p><br><p>A jelenleg Ön által használt régi verzió rövidesen kivezetésre kerül.</p><br><p>A kivezetés várható időpontja: <b>2024-06-31</b></p><br><p>Ezért kérjük, hogy a megadott időpontig válassza ki az Önnek legmegfelelőbb platformon elérhető (Web-es, Android-os, iOS-es) új mobil kliens verziót és térjen át annak használatára.</p><br><p>Az új verziókat az alábbi linkek bármelyikéről elérheti:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Flottakövetés</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>Köszönettel:<br><br>az iData csapata',
  newPlutoRecommendContent: 'Ismerje meg az új generációs iTrack Mobil alkalmazást!<p><br>Fejlett technológiai megoldások, hasznos funkciók, gyorsabb, megbízhatóbb és stabilabb működés, illetve átláthatóbb kezelhetőség segíti céges működésének hatékonyságát.</p><br><p>Lépjen szintet az új iTrack Mobil alkalmazással!'
}
