

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Bootstrap modulok betöltése
var OverlayTrigger = require('react-bootstrap/lib/OverlayTrigger');
var Tooltip = require('react-bootstrap/lib/Tooltip');
// -----------------------------------------------------


module.exports = React.createClass({

  getInitialState: function() {
    return {
      isSelected: false,
      carId: this.props.carId,
      carName: this.props.carName,
			carDesc: this.props.carDesc
    };
  },

  getDefaultProps : function(){
    return {
      handleChange : function(){},
      changeMobilHeaderTitle : function(){}
    }
  },

	isSelectCar: function() {
		return this.props.isSelectCar(this.props.carId);
	},

  handleClick: function() {
    var selected = !this.isSelectCar();
    this.setState({ isSelected: selected });
    this.props.handleChange({
      selected : selected,
      carId : this.state.carId
    });

    this.props.changeMobilHeaderTitle(this.state.carName);

    $('#carsList').addClass('mobilHide');
    $('#trackingTabs .backButton').removeClass('hidden');
    $('#trackingTabs .title').removeClass('col-xs-offset-4');
  },

  render: function() {
    var active = '';

    if(this.isSelectCar()) {
      active = 'active';
    }

    var id = "carslistitem" + this.props.carId;
		var carName = this.state.carName.toLowerCase();
    return (
       <div className="carElement">
				 <h2 id={id} onClick={this.handleClick} className={active} data-search={carName}>
					 <span className="id">{this.state.carName}</span>
					 <span className="name">{this.state.carDesc}</span>
					 <i className="icon-arrowLeft"></i>
				 </h2>
       </div>
    );
  }
});
