var $ = require('jquery');
var ajax = require('../mixins/Ajax.jsx');
var passwordReset = require('./passwordResetCallback.js');

module.exports = {

    changepass: function (username, password, newPassword, errorAction, addNotification, addTranslateNotification,success){
    var hash = Base64.encode(username + ':' + password);
        
        $.ajax( {
            url: "@@servletUrl" + "passwordreset/changepassword?newPassword=" + newPassword,
            type: "POST",
            timeout : 5000,
            beforeSend: function(xhr){
                xhr.setRequestHeader("Authorization", "Basic " + hash);
                xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
            },
            success : function(response, status, xhr) {
                success()
            },
            error : function(response, status, xhr) {
                passwordReset.passwordResetErrorcallback(response, status, xhr, errorAction, addNotification, addTranslateNotification);
            }
        });
    }

}
