// Jquery betöltése
var $ = require('jquery');

class TrackTargets {

	static query(tracingRequestArray, successCallback, errorCallback, visitor, popupTargetId) {
		var url =  "@@servletUrl" + "targets/positions/tracking";
		if (popupTargetId != undefined || popupTargetId != null) {
			url += "?popupTargetId=" + popupTargetId
		}
		var xhr = $.ajax({
			url: url,
			type: "POST",
			data: JSON.stringify(tracingRequestArray),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			timeout: 60000,
			beforeSend: function (xhr) {
				if(visitor == true) {
					xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				} else {
					xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				}
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success: successCallback,
			error: errorCallback
		});

		return xhr;
	}

}

export { TrackTargets };