var React = require('react');
class Utils {

    static getClickableLogoForResource(brand, logoResource) {
        let logo = ''
        if (brand !== "itrack") {
            logo = (<i className={logoResource}></i>);
        } else {
            let itrackWebsiteUrl = Utils.getItrackWebsiteUrlByLocale();
            logo = (<a target="_blank" href={itrackWebsiteUrl}><i className={logoResource}></i></a>);
        }
        return logo;
    }

    static getItrackWebsiteUrlByLocale() {
        let locale = localStorage.getItem('lang');
        switch (locale) {
            case "hu":
                return "https://www.itrack.hu/";
            case "en":
                return "https://www.idatatelematics.com/";
            case "ro":
                return "https://idataromania.ro/";
            case "hr":
                return "https://www.itrack.hr/";
            default:
                return "https://www.idatatelematics.com/";
        }
    }

}

export { Utils };