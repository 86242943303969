

var React = require('react');
var Row = require('react-bootstrap/lib/Row');
var Col = require('react-bootstrap/lib/Col');
var Button = require('react-bootstrap/lib/Button');
var Modal = require('react-bootstrap/lib/Modal');
var $ = require('jquery');
var Languages = require('../mixins/Languages.jsx');

module.exports = React.createClass({

	mixins: [Languages],

	getInitialState: function () {
		return {
			isModalOpen: false,
			locale: '',
		};
	},

	componentWillMount: function () {
		var lang = localStorage.getItem('lang');

		this.setState({
			locale: lang,
		});

	},

	openModal: function () {
		this.setState({
			isModalOpen: true,
		}, function () {
			$('.rdt input[type="text"]').focus(function () {
				$(this).blur();
			});
		});
	},

	handleToggle: function () {
		var isModelOpen = !this.state.isModalOpen;

		this.setState({
			isModalOpen: isModelOpen
		}, function () {
			$('.rdt input[type="text"]').focus(function () {
				$(this).blur();
			});
		});
	},

	render: function () {
		return (
			<Modal show={this.state.isModalOpen} onHide={this.handleToggle}>
				<Modal.Body>
					<Row>
						<Col xs={12}>
							<p dangerouslySetInnerHTML={{__html: this.getValue('newPlutoRecommendContent')}}></p>
						</Col>
					</Row>
					<Row>
						<Col xs={12} sm={4}>
							<a href="https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile"><img className="img-badge" src="images/googlePlay.svg"></img></a>
						</Col>
						<Col xs={12} sm={4}>
							<a href="https://apps.apple.com/pl/app/itrack-telematics/id6446988003"><img className="img-badge" src="images/appStore.svg"></img></a>
						</Col>
						<Col xs={12} sm={4}>
							<a href="https://beta-m.itrack.hu/"><img className="img-badge" src="images/webApp.svg"></img></a>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button id="CloseButton" onClick={this.handleToggle}>{this.getValue('closeButtonTitle')}</Button>
				</Modal.Footer>
			</Modal>
		);
	}
});