

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Oldalsó menüsáv betöltése
var SideMenu = require('./SideMenu.jsx');

// Gépjárműlista betöltése
var CarsList = require('./CarsListTracking.jsx');

// Térkép betöltése
var Map = require('./Map.jsx');

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Mobil tab választó betöltése
var TrackingTabs = require('./TrackingTabs.jsx');

// Login csekkolás
var Authentication = require('../mixins/Authentication.js');
var Router = require('react-router');
var RouteHandler = Router.RouteHandler;
var Navigation = Router.Navigation;
// -----------------------------------------------------

var Notification = require('../mixins/Notification.jsx');
var Ajax = require('../mixins/Ajax.jsx');

module.exports = React.createClass({

  mixins: [Navigation, Authentication, Languages, Notification, Ajax],

  ajaxLoad: false,

  getInitialState: function () {
    return {
      targetsPositions: []
    };
  },

  getAjaxLoad: function () {
    return this.ajaxLoad;
  },

  handleCarUnselect: function (id, setLoader, showOnMapCarIds) {
    if (id > 0) {
      var itemsIds = {};
      var index = showOnMapCarIds.indexOf(id);

      showOnMapCarIds.splice(index, 1);

      showOnMapCarIds.map(function (id) {
        itemsIds[id] = id;
      });

      this.refs.map.removeMarkerOnMap(id);
      this.refs.map.setCarsSelectInList(itemsIds);

      setLoader(showOnMapCarIds);
    }
  },

  handleCarSelect: function (r, setLoader, unselectCars) {
    if (this.ajaxLoad == false) {
      this.ajaxLoad = true;

      this.getPositions(r, setLoader, unselectCars);
    }
  },

  clearMapTargetPositions: function () {
    this.refs.map.targetsPositions([], {});

    // Nyomkövetés gomb elrejtése
    $('#autozoom').css('display', 'none');
  },

  getPositions: function (carIds, setLoader, unselectCars) {
    var _this = this;
    var _setLoader = setLoader;
    var _carIds = carIds;
    var _unselectCars = unselectCars;

    this.refs.map.stopLongPolling();

    if (carIds.length == 0) {
      this.ajaxLoad = false;
      this.clearMapTargetPositions();
      setLoader([]);
      return;
    }

    var data = [];

    for (var i in carIds) {
      data.push({
        "targetId": carIds[i],
        "fromTimeMicros": 0,
        "asynchronous": false
      });
    }

    $.ajax({
      url: "@@servletUrl" + "targets/positions/tracking",
      type: "POST",
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      timeout: 180000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function (response) {
        if (typeof response !== 'undefined' && response.length) {
          var items = [];
          var noPosition = '';
          var itemsId = {};
          var noPositionCarsId = [];

          for (var i in response) {
            var car = response[i];
            var id = car.target.id;

            if (car.currentPosition != null) {
              items.push(car);
              itemsId[id] = id;
            } else {
              noPositionCarsId.push(id);
              noPosition = noPosition + car.target.name + ', ';
            }
          }

          setLoader(carIds);

          if (noPosition != '') {
            unselectCars(noPositionCarsId);
            _this.addNotification(_this.getValue('notPositions') + noPosition.substring(0, noPosition.length - 2), 'warning');
          }

          _this.refs.map.targetsPositions(items, itemsId);
        }
      },
      error: function (response, status, xhr) {
        if (setLoader != null) {
          setLoader([], 'error', _carIds);
        }

        _this.ajaxError(response, status, xhr, _this.addNotification, _this.addTranslateNotification, _this.getPositions.bind(_this, _carIds, _setLoader, _unselectCars));
      },
      complete: function () {
        _this.ajaxLoad = false;
      }
    });
  },

  handleInfoIconClick: function (carId) {
    this.refs.map.toCenterWithPopup(carId);

    if ($(window).innerWidth() <= 992) {
      this.refs.tabs.showMaps();
    }
  },

  carsActualCoordinate: function () {
    return this.refs.map.getCarsActualCoordinate();
  },

  render: function () {

    return (
      <main id="trackingPage">
        <MobileHeader mobilMenu={this.refs.left} mobilMenuName={this.getValue('tracking')} />
        <TrackingTabs ref="tabs" lang={this.state.lang} />
        <MobilMenu ref="left" alignment="left" lang={this.state.lang} activeMenu="tracking" />
        <SideMenu
          lang={this.state.lang}
          activeMenu="tracking"
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
          visitor={false}
        />
        <section id="carsList">
          <header>
            <h1>{this.getValue('cars')}</h1>
          </header>
          <CarsList
            handleCarSelect={this.handleCarSelect}
            handleCarUnselect={this.handleCarUnselect}
            handleInfoIconClick={this.handleInfoIconClick}
            getAjaxLoad={this.getAjaxLoad}
            carsActualCoordinate={this.carsActualCoordinate}
            addNotification={this.addNotification}
            addTranslateNotification={this.addTranslateNotification}
          />
        </section>
        <div className="mapContainer" >
          <button id="autozoom" ><i className="icon-gps"></i></button>
          <Map
            ref="map"
            addNotification={this.addNotification}
            addTranslateNotification={this.addTranslateNotification}
          />
        </div>

        {this.rednderNotification()}
      </main>
    );
  }
});
