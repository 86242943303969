var React = require('react');
var $ = require('jquery');
// Canadarm javascript logger
var logger = require('canadarm');
import ServerMaintenance from '../model/ServerMaintenance';

class Ajax {

    static showError(response, textStatus, error, notificationVersions, getLangvalue) {
        var isSleep = localStorage.getItem('isSleep');
        var exception = new Error('Error during remote call');
        var httpStatus = response.status;
        var readyState = response.readyState;
        var label = '';
        var text = '';
        var action = {
            label: getLangvalue('pageReload'),
            callback: function () {
                location.reload();
            }
        };

        $('#overlay').removeClass('overlay');
        $('#loader').removeClass('loader');
        $('#exitButton').removeClass('exitButton icon-cancel');

        //Nem sikerült a kapcsolatot megnyitni (hálózati hiba vagy timeout)
        if (readyState == 0) {
            if(isSleep == 'false'){
                switch (textStatus) {
                    case "error":
                        if($('.offline-ui').hasClass('offline-ui-down')){
                            logger.debug('Cannot connect to server. Probably network error', exception)
                            notificationVersions.addTranslateNotification("networkError",
                                                                    "error",
                                                                    action);
                        }
                        //Ha mégsem hálózati hiba miatt nem tudunk csatlakozni a szerverhez (pl a böngésző kilőtte a kérést)
                        else{
                            logger.debug('Cannot connect to server. Unknown error', exception)
                            notificationVersions.addTranslateNotification("systemError",
                                                            "error",
                                                            action);
                        }
                        break;
                    case "timeout":
                        logger.debug('Cannot connect to server. Timeout error', exception)
                        notificationVersions.addTranslateNotification("timeout",
                                                                "error",
                                                                action);
                        break;
                }
            }
        }
        //Létrejött a kapcsolat, csak vagy a kliensoldalon vagy a szerveroldalon történt hiba
        else {
            if (httpStatus >= 400 && httpStatus < 500) {
                label = 'warning';
                action = null;
            } else if (httpStatus >= 500) {
                label = 'error';
            }
            if(httpStatus != 504){
                if (typeof response.responseJSON != 'undefined') {
                    var errorMessage = response.responseJSON.localizedMessage;
                    if(typeof response.responseJSON.serverMaintenance != 'undefined'){
                        ServerMaintenance.serverMaintenance = response.responseJSON.serverMaintenance;
                    }
                    logger.debug(response.responseJSON.message, exception)
                    if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
                        text = errorMessage;
                        notificationVersions.addNotification(text, label, action);
                    } else {
                        notificationVersions.addTranslateNotification('systemError', label, action);
                    }
                } else if(typeof response.responseText != 'undefined') {
                    try{
                        var parsedErrorMessage = JSON.parse(response.responseText);
                        var errorMessage = parsedErrorMessage.localizedMessage;
                        if(typeof parsedErrorMessage.serverMaintenance != 'undefined'){
                            ServerMaintenance.serverMaintenance = parsedErrorMessage.serverMaintenance;
                        }
                        logger.debug(parsedErrorMessage.message, exception)
                        if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
                            text = errorMessage;
                            notificationVersions.addNotification(text, label, action);
                        }
                        else{
                            notificationVersions.addTranslateNotification('systemError', label, action);
                        }
                    }
                    catch(error){
                        logger.debug('Exception during JSON parse. ' + response.responseText, error)
                        notificationVersions.addTranslateNotification('systemError', label, action);
                    }
                }
                else{
                    logger.debug('Unable to determine error cause', exception)
                    notificationVersions.addTranslateNotification('systemError', label, action);
                }
            }
        }
    }
}

export default Ajax;