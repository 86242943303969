// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

import { DefaultTarget } from '../model/DefaultTarget';
import { TrackedTarget } from '../model/TrackedTarget';
import { TargetList } from '../model/TargetList';

var TargetTrackingSelectedListElement = require('./TargetTrackingSelectedListElement.jsx');

class TargetTrackingSelectedList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			trackedTargetsToShow: new TargetList()
		}
	}

	static removeAllTrackedTarget(targetList, onUnselectFunction){
		var allTarget = targetList.getAll();
		for(var i = 0; i < allTarget.length; i++){
			var defaultTarget = allTarget[i].getDefaultTarget();
			if(defaultTarget.getEnabled()){
				onUnselectFunction(defaultTarget)();
			}
		}
		return [];
	}

	static doSearchInTrackedTargetList(targetList, searchValue, updateTrackedTargetList){
		var newList = targetList.deepCopyTrackedTargetList();
		newList = newList.enableTrackedTargetsIfHasSearchValue(searchValue);
		updateTrackedTargetList(newList);
	}

	componentWillReceiveProps(newProps){
		newProps.setSelectedTargetNumber(newProps.trackedTargetList.getAllEnabledTrackedTarget().length);
	}

	render() {
		return (
 			<Infinite className="carslistInfiniteSelected"
 				elementHeight={this.props.targetElementHeight}
 				containerHeight={this.props.trackedTargetsListHeight}
			>
			{this.getAllTrackedAndEnabled(this.props.trackedTargetList)}
 			</Infinite>
		);
	}

	getAllTrackedAndEnabled(trackedTargetArray){
		trackedTargetArray = trackedTargetArray.sort();
		var renderedTargetsList = [];
		trackedTargetArray.map(function (trackedTarget, id) {
			if(trackedTarget.getEnabled()){
				var renderedListElement = this.renderListElement(trackedTarget);
				renderedTargetsList.push(renderedListElement);
			}
		}, this);
		return renderedTargetsList;
	}

	renderListElement(trackedTarget) {
		return (
			<TargetTrackingSelectedListElement 
				key={trackedTarget.getId()}
				trackedTarget={trackedTarget} 
				onUnselectFunction={this.props.onUnselectFunction}
				handleInfoIconClick={this.props.handleInfoIconClick}
				handleCarShareButton={this.props.handleCarShareButton}
				visitor={this.props.visitor}
				getLangValue={this.props.getLangValue}
			/>
		);
	}
}

TargetTrackingSelectedList.defaultProps = {
	trackedTargetList: new TargetList()
};

module.exports = TargetTrackingSelectedList;