

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var moment = require('moment');

module.exports = React.createClass({

	mixins: [Languages],

  getDefaultProps : function(){
    return {
      messageAttributes:{},
      threadUserId : null
    }
  },

  render: function() {
    var time = this.props.messageAttributes.sentStatus.timeMicros / 1000;

    var date = new Date(time);
    var timeStr = '';

    if(new Date().getDate() === date.getDate() ){
      timeStr += this.getValue('today') + ', ';
    } else {
      timeStr += moment(time).format('L (dddd)') + ', ';
    }

    timeStr += moment(time).format('HH:mm');

    var liClass = "messageBorder";

    if( this.props.messageAttributes.type === 'sent' ){
      liClass = "messageBg";
    }

    if( typeof this.props.messageAttributes.new !== 'undefined' && this.props.messageAttributes.new ){
      liClass += ' new';
    }

    return (
      <li
        className={liClass}
        data-timestamp={this.props.messageAttributes.sentStatus.timeMicros}
        data-id={this.props.messageAttributes.id} >
        <div>
          <span>{timeStr}</span>
          <div>
            <p>
              {this.props.messageAttributes.content.body}
            </p>
          </div>
        </div>
      </li>
    );
  }
});
