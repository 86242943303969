

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
var jQuery = $;
window.jQuery = jQuery;
// -----------------------------------------------------
var jQueryUI = require('jquery-ui');

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------
import { TargetList } from '../model/TargetList';
import { TrackedTargetState } from '../model/TrackedTargetState';

module.exports = React.createClass({

	mixins: [Languages],

	pulseTabColorWhenIE9: true,

	getInitialState: function () {
		return {
			trackingTabsMaps: '',
			trackingTabsSelectableTargets: '',
			trackingTabsSelectedTargets: ''
		};
	},

	getDefaultProps: function () {
		return {
			visitor: false,
			onSmallScreen: false,
			trackedTargetList: new TargetList()
		};
	},

	handleSelectableTabClick: function () {
		$('#carsList').css('display', 'block');
		$('.searchBoxForBoth').addClass('visuallyhidden');
		$('.searchBoxForSelectable').removeClass('visuallyhidden');
		$('.searchBoxForSelected').addClass('visuallyhidden');
		$('.allCarsCheckd').removeClass('visuallyhidden');
		$('.allCarsDeleteCheck').addClass('visuallyhidden');
		$('.carslistSeparator').addClass('visuallyhidden');
		$('.carslistInfiniteSelected').addClass('visuallyhidden');
		$('.carslistInfiniteSelectable').removeClass('visuallyhidden');
	},

	showSelectableTargetsList: function () {
		$('#trackingTabsCars').addClass('active');
		$('#trackingTabsCarsSelected').removeClass('active');
		$('#trackingTabsMaps').removeClass('active');
		this.handleSelectableTabClick();
	},

	handleSelectedTabClick: function () {
		$('#carsList').css('display', 'block');
		$('.searchBoxForBoth').addClass('visuallyhidden');
		$('.searchBoxForSelectable').addClass('visuallyhidden');
		$('.searchBoxForSelected').removeClass('visuallyhidden');
		$('.allCarsCheckd').addClass('visuallyhidden');
		$('.allCarsDeleteCheck').removeClass('visuallyhidden');
		$('.carslistSeparator').addClass('visuallyhidden');
		$('.carslistInfiniteSelectable').addClass('visuallyhidden');
		$('.carslistInfiniteSelected').removeClass('visuallyhidden');
	},

	showSelectedTargetsList: function () {
		$('#trackingTabsCars').removeClass('active');
		$('#trackingTabsCarsSelected').addClass('active');
		$('#trackingTabsMaps').removeClass('active');
		this.handleSelectedTabClick();
	},

	showMaps: function () {
		$('#trackingTabsCars').removeClass('active');
		$('#trackingTabsCarsSelected').removeClass('active');
		$('#trackingTabsMaps').addClass('active');
		$('#carsList').css('display', 'none');
	},

	animateTabColor: function(){
		if(this.pulseTabColorWhenIE9){
			$( '#trackingTabsCarsSelected' ).stop();
			$( "#trackingTabsCarsSelected" ).animate({
				backgroundColor: "#0071bc",
				color: "#fff"
			}, 1000, function(){
				$( "#trackingTabsCarsSelected" ).animate({
					backgroundColor: "#fff",
					color: "#888"
				}, 1000, function() {
					this.animateTabColor();
				}.bind(this));
			}.bind(this) );
		}
		else{
			$( "#trackingTabsCarsSelected" ).css('background-color', '');
			$( "#trackingTabsCarsSelected" ).css('color', '');
		}
	},

	componentWillReceiveProps: function(newProps){
		if(newProps.onSmallScreen && !this.props.onSmallScreen){
			this.onChangeFromLargeToSmallScreen();
		}
		if(newProps.onSmallScreen){
			if(newProps.trackedTargetList.hasAnyTrackedTargetState(TrackedTargetState.Pending)){
				if(window.isIE9){
					this.pulseTabColorWhenIE9 = true;
					this.animateTabColor();
				}
				else{
					$('#trackingTabsCarsSelected').addClass('animateTabColor');
				}
			}
			else{
				if(window.isIE9){
					this.pulseTabColorWhenIE9 = false;
					$( "#trackingTabsCarsSelected" ).css('background-color', '');
					$( "#trackingTabsCarsSelected" ).css('color', '');
				}
				else{
					$('#trackingTabsCarsSelected').removeClass('animateTabColor');
				}
			}
		}
	},

	onChangeFromLargeToSmallScreen: function(){
		/**
		 * Kis kijelzős nézetre visszatérve az a tab legyen aktív, amelyen utoljára voltunk
		 * Ha egyik sem volt még aktív, akkor a Választható járművek tab lesz az aktív
		 */
		if ($('#trackingTabsCars').hasClass('active')) {
			this.showSelectableTargetsList();
		}
		else if ($('#trackingTabsCarsSelected').hasClass('active')) {
			this.showSelectedTargetsList();
		}
		else if ($('#trackingTabsMaps').hasClass('active')) {
			this.showMaps();
		}
		else{
			this.showSelectableTargetsList();
		}
	},

	componentDidMount: function () {
		if(window.isIE9){
			$('#trackingTabs button').css('display', 'table-cell');
		}
		var name = this.props.visitor ? 'carsTrackingIdVisitor' : 'carsTrackingId';
		var carlist = JSON.parse(localStorage.getItem(name));
		if (this.props.onSmallScreen) {
			if (carlist != null && carlist.length > 0) {
				this.showMaps();
			} else {
				this.showSelectableTargetsList();
			}
		}
	},

	render: function () {

		return (
			<nav id="trackingTabs" className="trackingTabs">
				<button id="trackingTabsCars" className={this.state.trackingTabsSelectableTargets} onClick={this.showSelectableTargetsList}>{this.props.getLangValue('selectableCars')}</button>
				<button id="trackingTabsCarsSelected" className={this.state.trackingTabsSelectedTargets} onClick={this.showSelectedTargetsList}>{this.props.getLangValue('trackedCars')}</button>
				<button id="trackingTabsMaps" className={this.state.trackingTabsMaps} onClick={this.showMaps}>{this.props.getLangValue('map')}</button>
			</nav>
		)
	}
});
