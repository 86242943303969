

// React betöltése
var React = require('react');
// -----------------------------------------------------

var Router = require('react-router');
var Navigation = Router.Navigation;

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Bootstrap modulok betöltése
var OverlayTrigger = require('react-bootstrap/lib/OverlayTrigger');
var Tooltip = require('react-bootstrap/lib/Tooltip');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

// Menülista betöltése
var menus = require('../i18n/menus.js');

import LastPath from '../model/LastPath';

module.exports = React.createClass({

  mixins: [Languages, Navigation, Brands],

  getInitialState: function() {
    return {
      activeMenu: this.props.activeMenu
    };
  },

	componentDidMount: function() {
		var _this = this;

		$('#mobilMenu li a.active').click(function() {
			_this.closeMobileMenuList();
		});
	},

  closeMobileMenuList: function() {
    $('#mobilMenu').attr('data-open','false');
  },

	navigation: function(url, label) {
    if(LastPath.lastPath != url && (url == 'tracking' || url == 'router' || url == 'fueling')){
      $('#overlay').addClass('overlay');
      $('#loader').addClass('loader');
      $('#exitButton').addClass('exitButton icon-cancel');

      setTimeout(function() {
        this.transitionTo(url);

      }.bind(this), 500);
    }
    else{
      this.transitionTo(url);

    }
	},

  render: function() {
		var _this = this;


    // A menü listát tárolom le benne
    var menusElement = [];

    // Nyelvi elemek eltárolása
    var langItems = this.state.lang;

    // Active menüpont nevének az eltárolása
    var activeMenu = this.state.activeMenu;

    // Végigmegyek az összes private menüponton
    menus.private.forEach(function(menu) {

      if(menu.name == activeMenu) {
        var active = 'active';
      } else {
        var active = '';
      }

      // Push-olom a menüpontokat a menusElement tömbbe
      menusElement.push(
        <li id={menu.name}>
          <a className={active} onClick={_this.navigation.bind(_this, menu.url, menu.ga)}>
            <span className={menu.icon}></span>
            {langItems[menu.name]}
            <i className="icon-arrowUp"></i>
          </a>
        </li>
      );
    });

    return (
      <nav id="mobilMenu" className="mobilMenu" data-open="false">
        <ul>
          <li>
            <i className={ this.getResourceItemValue('logoMenu') }></i>
            <button className="icon-cancel" id="CancelButton" onClick={this.closeMobileMenuList}></button>
          </li>
          {menusElement}
        </ul>
      </nav>
    )
  }
});
