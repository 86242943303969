
// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Bootstrap elemek
var Input = require('react-bootstrap/lib/Input');
var Button = require('react-bootstrap/lib/Button');
// -----------------------------------------------------

var CarsListItem = require('./CarsListItemTracking.jsx');

var Ajax = require('../mixins/Ajax.jsx');

var Infinite = require('react-infinite');

var CarShare = require('./CarShare.jsx');


module.exports = React.createClass( {

	mixins: [Languages, Ajax],

	infiniteScrollPage: 1,
	infiniteScrollStop: false,
	isSelectAllCars: false,
	searchValue: null,
	isSearch: false,
	isSelectAllCar: false,
	loadCarInMap: false,

  getInitialState: function() {
    return {
			firstLoadCars: false,
			showAllCarButton: false,
			allSelectCars: false,

			carsList: [],
			carIds: [],

			isInfiniteLoading: false,
			infiniteScrollContainerHeight: 0,
			carsItemKey: 0,

			shareModal: false,
			shareId: 0
    };
  },

  getDefaultProps : function() {
    return {
			maxTrackingCars: 20,
			getCarsLimit: 50,
			tokenAuth: false,
			visitor: false
    };
  },

	getSelectCar: function() {
		var name = this.props.visitor ? 'carsSelectIdVisitor' : 'carsSelectId';
		return JSON.parse(localStorage.getItem(name));
	},

	getShowCarMaps: function() {
		var name = this.props.visitor ? 'carsSowMapsVisitor' : 'carsSowMaps';
		return JSON.parse(localStorage.getItem(name));
	},

	setSelectCar: function(ids) {
		var name = this.props.visitor ? 'carsSelectIdVisitor' : 'carsSelectId';
		localStorage.setItem(name, JSON.stringify(ids));
	},

	setShowCarMaps: function(ids) {
		var name = this.props.visitor ? 'carsSowMapsVisitor' : 'carsSowMaps';
		localStorage.setItem(name, JSON.stringify(ids));
	},

	getLocalStorageTrackingCarsId: function() {
		var name = this.props.visitor ? 'carsTrackingIdVisitor' : 'carsTrackingId';
		return JSON.parse(localStorage.getItem(name));
	},

	setLocalStorageTrackingCarsId: function(ids) {
		var name = this.props.visitor ? 'carsTrackingIdVisitor' : 'carsTrackingId';
		localStorage.setItem(name, JSON.stringify(ids));
	},

	setLoadCarInMap: function(value) {
		this.loadCarInMap = value;
	},

	componentWillMount: function() {
		var localStorageTrackingCarsId = this.getLocalStorageTrackingCarsId();

		this.setSelectCar([]);
		this.setShowCarMaps([]);

		if(localStorageTrackingCarsId == null) {
			this.setLocalStorageTrackingCarsId([]);
		}
	},

	componentDidMount: function() {
		window.addEventListener("resize", this.resizeWindow);
	},

	getCars: function() {
		var self = this;

		if(self.infiniteScrollStop) {
			this.setState( {
				isInfiniteLoading: false
			});
			return;
		}

		this.setState( {
			isInfiniteLoading: true
		}, function() {
			self.getCarsAjax();
		});
	},

	getCarsAjax: function() {
		var self = this;

		// Url összeállítása
		var url = '';

		var limit = this.props.getCarsLimit;
		var page = this.infiniteScrollPage;
		var params = "offset=" + ((page * limit) - limit) + "&limit=" +  limit;

		if(this.searchValue == null) {
			url = "targets?" + params;
		} else {
			url = "targets?filter=" + this.searchValue + '&' + params;
		}

		// Lekérés
		$.ajax( {
			url: "@@servletUrl" + url,
			type: "GET",
			timeout : 30000,
			async: false,
			cache: false,
			beforeSend: function (xhr) {
				if(self.props.tokenAuth == true) {
					xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
				} else {
					xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				}
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success: function(response) {
				self.isSearch = false;
				var responseLength = response.length;

				if(self.props.getCarsLimit > responseLength) {
					self.infiniteScrollStop = true;
				}

				if(responseLength == 0) {
					self.setState( {
						isInfiniteLoading: false
					});

					return;
				}

				var actcualCarIds = self.state.carIds;
				var actualCarList = self.state.carsList;
				var newCarsList = self.drawCars(response);

				self.setState( {
					isInfiniteLoading: false,
					carsList: actualCarList.concat(newCarsList.list),
					carIds: actcualCarIds.concat(newCarsList.ids)
				}, function() {
					self.listenCheckAllCars();
					$('.carslistInfinite').css('display','inline-block');
				});

				self.infiniteScrollPage++;

				if(self.state.firstLoadCars == false) {

					if(self.props.visitor) {
						self.setLocalStorageTrackingCarsId([]);
						setTimeout(function() {
							self.selectAllCar();
						}, 1000);
					} else {
						var localStorageTrackingCarsId = self.getLocalStorageTrackingCarsId();

						if(localStorageTrackingCarsId.length > 0) {
							self.setSelectCar(localStorageTrackingCarsId);
							self.drawCarsMap(self.getSelectCar());
						}
					}

					self.setState( {
						firstLoadCars: true,
						showAllCarButton: self.props.maxTrackingCars > responseLength ? true : false
					}, function() {
						$('.carslistInfinite').css('display','inline-block');
					});
				}
			},
			error: function(response, status, xhr) {
				self.ajaxError(response, status, xhr, self.props.addNotification, self.props.addTranslateNotification, self.getCars);
			}
		});
	},

	drawCars: function(cars) {
		var carsActualCoordinate = this.props.carsActualCoordinate();
		var ids = [];
		var newCarsElements = [];

		if(cars.length > 0) {
			var i = this.state.carsItemKey;

			cars.map(function(car) {
				var id = car.id;
				var actualCoordinate = null;

				ids[id] = id;

				if(typeof carsActualCoordinate[id] != 'undefined') {
					actualCoordinate = carsActualCoordinate[id];
				}

				newCarsElements.push(this.drawCar(car, actualCoordinate, i));

				i++;
			}, this);
		}

		return {
			list: newCarsElements,
			ids: ids
		};
	},

	drawCar: function(car, actualCoordinate, i) {
		var infoButton = this.carInfoButtonShow.bind(this, car.id);

		return (
			<CarsListItem
				key={i}
				carId={car.id}
				carName={car.name}
				carDesc={car.description}
				onSelect={this.carSelect}
				isSelectCar={this.isSelectCar}
				infoButton={infoButton}
				isShowMap={this.isShowMap}
				actualCoordinate={actualCoordinate}
				visitor={this.props.visitor}
				openShareModal={this.openShareModal}
				addNotification={this.props.addNotification}
				addTranslateNotification={this.props.addTranslateNotification}
			/>
		);
	},

	isShowMap: function(id, isSelect) {
		var status = false;

		if(isSelect) {
			var showCarsinMap = this.getShowCarMaps();

			if(showCarsinMap.indexOf(id) > -1) {
				status = true;
			} else {
				status = 'loading';
			}
 		}

		return status;
	},

	carInfoButtonShow: function(carId) {
		this.props.handleInfoIconClick(carId);
	},

	carSelect: function(id, selected) {
		if(this.loadCarInMap) {return;}
		if(this.isSelectAllCar) {return;}

		var carsSelect = this.getSelectCar();
		var eventFuntion = null;

		if(selected) {
			var index = carsSelect.indexOf(id);
			carsSelect.splice(index, 1);

			this.removeTrackingCarIdLocalStorage(id);

			if(carsSelect.length == 0) {
				// Térképen megjelenítés
				eventFuntion = function(){this.drawCarsMap([])};
			} else {
				// Térkép frissitése
				eventFuntion = function(){this.props.handleCarUnselect(id, this.loadingCarInMap, this.getShowCarMaps())};
			}
		} else {
			if(!this.maxTrackingCars()) {
				this.loadCarInMap = true;
				this.saveTrackingCarIdLocalStorage(id);
				carsSelect.push(id);
				eventFuntion = function(){this.drawCarsMap(carsSelect)};
			}
		}

		this.setSelectCar(carsSelect);

		if (eventFuntion) {
			eventFuntion.apply(this);
		}

		this.listenCheckAllCars();
	},

	saveTrackingCarIdLocalStorage: function(id) {
		var localStoageTrackingCarId = this.getLocalStorageTrackingCarsId();

		localStoageTrackingCarId.push(id);
		this.setLocalStorageTrackingCarsId(localStoageTrackingCarId);
	},

	removeTrackingCarIdLocalStorage: function(id) {
		var localStoageTrackingCarId = this.getLocalStorageTrackingCarsId();
		var index = localStoageTrackingCarId.indexOf(id);

		if(index >= 0) {
			localStoageTrackingCarId.splice(index, 1);
			this.setLocalStorageTrackingCarsId(localStoageTrackingCarId);
		}
	},

	maxTrackingCars: function() {
		if(this.props.maxTrackingCars <= this.getSelectCar().length) {
			this.props.addTranslateNotification('maxCarTitle', 'warning');
			return true;
		} else {
			return false;
		}
	},

	listenCheckAllCars: function() {
		if(this.state.showAllCarButton) {
			var selectCars = this.getSelectCar();
			var allselect = true;

			this.state.carIds.map(function (id) {
				var index = selectCars.indexOf(id);

				if (index < 0) {
					allselect = false;
				}
			}, this);

			this.isSelectAllCars = allselect;

			this.setState({
				allSelectCars: allselect
			});
		}
	},

	isSelectCar: function(id) {
		var selectCar = this.getSelectCar();
		var select = false;

		if(selectCar.indexOf(id) > -1) {
			select = true;
		}

		return select;
	},

	selectAllCar: function() {
		if(this.isSelectAllCar) {return;}
		if(this.isSelectAllCars) {return;}
		if(this.loadCarInMap) {return;}

		this.isSelectAllCar = true;

		var self = this;
		var localStorageTrackingCarsId = this.getLocalStorageTrackingCarsId();
		var carsSelect = this.getSelectCar();

		this.state.carIds.map(function(id) {
			var carsSelectIndex = carsSelect.indexOf(id);
			var localStorageTrackingCarsIdIndex = localStorageTrackingCarsId.indexOf(id);

			if(carsSelectIndex == -1) {
				carsSelect.push(id);
			}

			if(localStorageTrackingCarsIdIndex == -1) {
				localStorageTrackingCarsId.push(id);
			}
		}, this);

		self.setLocalStorageTrackingCarsId(localStorageTrackingCarsId);
		self.setSelectCar(carsSelect);
		self.drawCarsMap(carsSelect);

		this.setState( {
			allSelectCars: true
		});
	},

	unCheckAllCar: function() {
		if(this.getSelectCar().length == 0) {return;}
		if(this.loadCarInMap) {return;}
		if(this.isSelectAllCar) {return;}

		// LocalStorage-ból is töröljük az összes gépjármű id-t
		this.setLocalStorageTrackingCarsId([]);

		this.setSelectCar([]);

		this.setShowCarMaps([]);

		// Térképen frissítése
		this.drawCarsMap([]);

		this.isSelectAllCars = false;

		// Összes gépjármű követésének a törlése
		this.setState( {
			allSelectCars: false
		});
	},

	unselectCars: function(carsId) {
		if (carsId.length > 0) {
			var carsSelect = this.getSelectCar();

			carsId.map(function(carId) {
				this.removeTrackingCarIdLocalStorage(carId);
				var index = carsSelect.indexOf(carId);
				carsSelect.splice(index, 1);
			}, this);

			this.setSelectCar(carsSelect);
			this.setShowCarMaps(this.removeIdShowOnMap(carsId));

			this.setState( {
				allSelectCars: false
			});
		}
	},

	removeIdShowOnMap: function(ids) {
		var showOnMapCarIds = this.getShowCarMaps();

		ids.map(function(id) {
			var index = showOnMapCarIds.indexOf(id);
			showOnMapCarIds.splice(index, 1);
		}, this);

		return showOnMapCarIds;
	},

	drawCarsMap: function(ids) {
		this.props.handleCarSelect(ids, this.loadingCarInMap, this.unselectCars);
	},

	handleSearchButton: function() {
		var value = this.refs.searchInput.getValue();

		if(value.length == 0) {return;}
		if(this.isSearch) {return;}

		this.doSearch(value);

		$('#searchInput').blur();

		return false;
	},

	emptySearchInput: function() {
		var value = this.refs.searchInput.getValue();

		if(value.length == 0) {
			this.doSearch(null);
		}

		return false;
	},

	doSearch: function(value) {
		var self = this;

		this.isSearch = true;
		this.searchValue = value;
		this.infiniteScrollStop = false;
		this.infiniteScrollPage = 1;

		this.setState( {
			carsList: [],
			carIds: [],
			carsItemKey: 0
		}, function() {
			self.getCars();
		});
	},

	loadingCarInMap: function(ids, type, unSelectCarIds) {

		if(ids.length > 0) {
			this.setShowCarMaps(ids);
			this.forceUpdate();
		} else if(type != 'error') {
			this.setShowCarMaps([]);
			this.forceUpdate();
		}

		this.loadCarInMap = false;
		this.isSelectAllCar = false;

		if(type == 'error') {
			var selectCarIds = this.getSelectCar();
			var showOnMap = this.getShowCarMaps();
			var localStorageTrackingCarsId = this.getLocalStorageTrackingCarsId();


			unSelectCarIds.map(function(id) {
				var selectIndex = selectCarIds.indexOf(id);
				var mapIndex = showOnMap.indexOf(id);

				if(selectIndex > -1 && mapIndex == -1) {
					selectCarIds.splice(selectIndex, 1);
					localStorageTrackingCarsId.splice(selectIndex, 1);
				}
			}, this);

			this.setSelectCar(selectCarIds);
			this.setLocalStorageTrackingCarsId(localStorageTrackingCarsId);
			this.forceUpdate();
		}
	},

	resizeWindow: function() {
		this.setState( {
			infiniteScrollContainerHeight: this.settingHeightInfiniteContainer()
		});
	},

	settingHeightInfiniteContainer: function() {
		var containerHeight = window.innerHeight;

		if(this.state.showAllCarButton) {
			containerHeight -= 214;
		} else {
			containerHeight -= 180;
		}

		return containerHeight
	},

	elementInfiniteLoad: function() {
		return (
			<div className='loaderCarList'><span></span></div>
		);
	},

	openShareModal: function(id) {
		this.refs.carshare.openModal(true, id);
	},

  render: function() {
		var allCheckCarButton = '';
		var containerHeight = 0;

		if(this.state.infiniteScrollContainerHeight == 0) {
			containerHeight = this.settingHeightInfiniteContainer();
		} else {
			containerHeight = this.state.infiniteScrollContainerHeight;
		}

		if(this.state.showAllCarButton) {
			allCheckCarButton = (
				<div className="allCarsCheckd" data-load="false" onClick={this.selectAllCar}>
					<h2 data-allselect={this.state.allSelectCars}>
						<i></i>
						<span>{this.getValue('allTracking')}</span>
						<span className="loaderAllTrackingCar"></span>
					</h2>
				</div>
			);
		}

    return (
      <div data-showallcarbutton={this.state.showAllCarButton}>
				<form onSubmit={this.handleSearchButton} className="searchBox">
					<Input
							id="searchInput"
							type="text"
							label=""
							help=""
							ref="searchInput"
							groupClassName=""
							placeholder={this.getValue('searchButtonTitle')}
							onChange={this.emptySearchInput}
							buttonAfter={(<Button onClick={this.handleSearchButton}><i className='icon-search'></i></Button>)}
						/>
				</form>

				<div className="filter">
					{allCheckCarButton}
					<div className="allCarsDeleteCheck" onClick={this.unCheckAllCar}>
						<h2>
							<i className="icon-cancel"></i>
							<span>{this.getValue('selectDelete')}</span>
						</h2>
					</div>
				</div>

				<div className="carslistInfinite">
					<Infinite
						elementHeight={43}
						containerHeight={containerHeight}
						infiniteLoadBeginEdgeOffset={containerHeight - 100}
						onInfiniteLoad={this.getCars}
						loadingSpinnerDelegate={this.elementInfiniteLoad()}
						isInfiniteLoading={this.state.isInfiniteLoading}
						>
						{this.state.carsList}
					</Infinite>
				</div>

				<CarShare ref="carshare" addNotification={this.props.addNotification} addTranslateNotification={this.props.addTranslateNotification}/>
      </div>
    )
  }
});