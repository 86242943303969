// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

import { TrackedTarget } from '../model/TrackedTarget';
import { DefaultTarget } from '../model/DefaultTarget';
import { TrackedTargetState } from '../model/TrackedTargetState';

class TargetTrackingSelectedListElement extends React.Component {

    constructor(props) {
        super(props);
        this.handleInfoIconClickElement = this.handleInfoIconClickElement.bind(this);
        this.handleCarsShareButtonElement = this.handleCarsShareButtonElement.bind(this);
    }

    render() {
        var trackedTarget = this.props.trackedTarget;
        var defaultTarget = this.props.trackedTarget.getDefaultTarget();
        return (
            <div className="carsElement"
                data-id={defaultTarget.getId()}
                data-trackingstate={trackedTarget.getTrackingState().name}>
                <h2 data-select="true" onClick={this.props.onUnselectFunction(defaultTarget)}>
                    <i></i>
                    <span className="licenseplate">{defaultTarget.getLicenseplate()}</span>
                    <span className="description">{defaultTarget.getDescription()}</span>
                </h2>
                {this.addCarShareButton()}
                {this.addGmapsButton()}
                {this.addInfoButton()}
                {this.trackingCarNoData()}
                {this.renderAjaxLoader()}
            </div>
        );
    }

    renderAjaxLoader() {
        return (
            <span className="loaderTrackingCar"></span>
        );
    }

    trackingCarNoData() {
        return (
            <button className="icon-blocked"
                title={this.props.getLangValue('nodata')}
            ></button>
        );
    }

    handleCarsShareButtonElement() {
        this.props.handleCarShareButton(this.props.trackedTarget.getId());
    }

    addCarShareButton() {
        if(!this.props.visitor){
            return (
                <button className="icon-share btn btn-primary"
                    title={this.props.getLangValue('carShare')}
                    onClick={this.handleCarsShareButtonElement}></button>
            );
        }
    }

    gmapsButtonGaEvent() {
	}

    addGmapsButton() {
        var trackedTarget = this.props.trackedTarget;
        var defaultTarget = this.props.trackedTarget.getDefaultTarget();
        if (trackedTarget.getTrackingState() == TrackedTargetState.Live) {
            var gmapsButtonCls = 'icon-map car_' + defaultTarget.getId();
            var actualCoordinate = trackedTarget.getLiveData().currentPosition;
            var href = 'https://maps.google.com/?t=k';

            if (actualCoordinate != null) {
                var lat = actualCoordinate.latitude;
                var long = actualCoordinate.longitude;

                href = 'https://maps.google.com/?q=' + lat + ',' + long + '&t=k';
            }
            return (
                <a href={href} title={this.props.getLangValue('openGoogleMaps')} className={gmapsButtonCls} target="_blank" onClick={this.gmapsButtonGaEvent}></a>
            );
        }
    }

    handleInfoIconClickElement() {
        this.props.handleInfoIconClick(this.props.trackedTarget.getId());
    }

    addInfoButton() {
        return (
            <button className="icon-information"
                title={this.props.getLangValue("moreInformation")}
                onClick={this.handleInfoIconClickElement}></button>
        );
    }

};

TargetTrackingSelectedListElement.defaultProps = {
    trackedTarget: new TrackedTarget(),
    onUnselectFunction: function(){},
    handleInfoIconClick: function(){},
    handleCarShareButton: function(){}
};

module.exports = TargetTrackingSelectedListElement;
