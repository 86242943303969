

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var format = require('../plugin/DateFormat.js');

//var DateRangePicker = require('react-daterange-picker');
var DateRangePicker = require('../plugin/react-daterange-picker/dest/index.js');

var moment = require('moment');

var GeminiScrollbar = require('react-gemini-scrollbar');

var RouterCalendarItem = require('./RouterCalendarItem.jsx');

var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

	mixins: [Languages, Ajax],

  isDateRangeSelected: false,

  getInitialState: function() {
    return {
        routes: [],
        fromTime: null,
        toTime: null,
				isLoadList: null,
        modeRouter: 0,
        selectEndDate: false,
        ids: {},
			  emptyRoutesList: false,
        width: window.innerWidth,
        timeout: null
    };
  },


  setModeRouter: function(mode) {
    localStorage.setItem('modeRouter', mode);
    this.setState( {
      modeRouter: mode
    });
  },

  getDefaultProps : function(){
    return {
      carId : null,
      handleItemSelect : function(){},
      tokenAuth: false
    }
  },

  componentWillMount: function() {
    var modeRouter = localStorage.getItem('modeRouter');
    var lang = localStorage.getItem('lang');

    moment.locale(lang);

    if(modeRouter != null) {
      this.setModeRouter(modeRouter);
    }
  },

  componentWillUnmount: function(){
    window.removeEventListener("resize", this.setWidth);
  },

  setWidth: function(){
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function(){
      if(window.innerWidth != this.state.width){
        this.setState({
          width: window.innerWidth
        })
      }
    }.bind(this), 100);
  },

	componentDidMount: function() {
		var range = this.props.getDatepickerRange();

		if(range != null && this.props.carId != null) {
			this.datepickerRange(range);
		}
    window.addEventListener("resize", this.setWidth);
	},

  handleSelect: function(range, states) {

    this.setState( {
      selectEndDate: false,
      ids: {}
    });

    // range is a moment-range object
    if( typeof range == 'undefined' ){ return false; }
    this.isDateRangeSelected = true;

		this.props.deleteAllRoute();

    this.datepickerRange(range);

		this.props.setDatepickerRange(range);

		this.hiddenCalendarMobile();
  },

	datepickerRange: function(range) {
		var fromTime = range.start.unix() * 1000 * 1000;
		var toTime = range.end.unix() * 1000 * 1000;

		// Ha megegyezik a két ídőpont akkor a vég ídőponthoz még hozzáadunk 24 órát
		//if(fromTime == toTime) {
		fromTime = range.start.hour(0).minute(0).seconds(0).unix() * 1000 * 1000;
		toTime = range.end.hour(23).minute(59).seconds(59).unix() * 1000 * 1000;
		//}

		this.setState({
			fromTime : fromTime,
			toTime : toTime
		});

		this.getRoutes(fromTime, toTime);
	},

  getRoutes: function(fromTime, toTime) {
    var _this = this;
    var _fromTime = fromTime;
    var _toTime = toTime;

    if( this.props.carId == null || fromTime == null || toTime == null ){
			this.props.addTranslateNotification('notSelectCar', 'warning');
      _this.drawRoutes([]);
      return false;
    }

		this.setState( {
			isLoadList: false,
			emptyRoutesList: false
		});

    $.ajax({
      url: "@@servletUrl"+"targets/"+ this.props.carId +"/routes?fromTime="+ fromTime +"&toTime="+ toTime,
      type: "GET",
      timeout : 60000,
      beforeSend: function (xhr) {
        if(_this.props.tokenAuth == true) {
          xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
        } else {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        }
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success : function(response) {

				if(response.length > 0) {
					_this.drawRoutes(response);
				} else {
					_this.setState( {
						isLoadList: null,
						emptyRoutesList: true
					});
				}

      },
      error : function(response, status, xhr) {
				_this.setState({
					isLoadList: null
				});

				_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.getRoutes.bind(_this, _fromTime, _toTime));
      }
    });
  },

  handleClick : function(id, fromTime, toTime, active, setLoader) {
    var _this = this;
    var activeRoutesId = this.state.ids;

    this.props.handleItemSelect({
      type : 'calendar',
      fromTime : fromTime,
      toTime : toTime,
      active : active,
      mode : this.state.modeRouter
    }, setLoader);

    if(this.state.modeRouter == false && Object.keys(activeRoutesId).length > 0) {
      Object.keys(activeRoutesId).map(function(i) {
        this.refs[i].setActive(false);
        this.refs[i].setIsLoad(null);
      }, this);

      activeRoutesId = {};
    }

    if(active) {
      activeRoutesId[id] = id;
    } else {
			if(typeof activeRoutesId[id] != 'undefined') {
        delete activeRoutesId[id];
      }
		}

    this.setState( {
      ids: activeRoutesId
    });
  },

  drawRoutes: function(routes) {
    var _this = this;
    var sortedObj = {};

    for(var i in routes){
      var startTime = routes[i]['startPosition']['timeMicros'] / 1000,
          dateObj = new Date(startTime);

      dateObj.setSeconds(59);
      dateObj.setMinutes(59);
      dateObj.setHours(23);
      dateObj.setMilliseconds(0);

      var tmstmp = dateObj.getTime();

      if(typeof sortedObj[tmstmp] == 'undefined'){
        sortedObj[tmstmp] = [];
      }

      sortedObj[tmstmp].push( routes[i] );
    }

    this.setState({
      routes: sortedObj,
			isLoadList: true
    });
  },

	showCalendarMobile: function() {
		$('#calendarBox').addClass('calendarActive');
		$('.calendar').addClass('show');
		return false;
	},

	hiddenCalendarMobile: function() {
		$('#calendarBox').removeClass('calendarActive');
		$('.calendar').removeClass('show');
	},

	onSelectStart: function() {
    this.setState( {
      selectEndDate: true
    });
	},

  modeRouter: function(modeRouter, mode) {
    if(modeRouter == mode) {
      return 'true';
    } else {
      return 'false';
    }
  },

  changeModeRouter: function(mode) {
    var activeRoutesId = this.state.ids;

    this.setModeRouter(mode);

    if(!mode && Object.keys(activeRoutesId).length > 1) {
      this.props.checkModeRouter(mode);

      Object.keys(activeRoutesId).map(function(i) {
        this.refs[i].setActive(false);
        this.refs[i].setIsLoad(null);
      }, this);

      this.setState( {
        ids: {}
      });
    }
  },

  drawRoutesElement: function (elements) {
    var n = 0;
    var r = [];

    for(var i in elements){
      var date = new Date(+i);
      var formateDate = moment(date).format("L");

      r.push(
      <header>
        <h3>{formateDate}</h3>
      </header>
      );

      for(var j in elements[i]){
        var id = 'RouterCalendarItem_' + n;
        r.push(<RouterCalendarItem id={id} ref={id} handleClick={this.handleClick} data={elements[i][j]} getIsAjaxRoute={this.props.getIsAjaxRoute} setIsAjaxRoute={this.props.setIsAjaxRoute}/>);
        n++;
      }
    }

    return r;
  },

  render: function() {

		var stateDefinitions = {
			available: {
				color: null,
				label: 'Available',
			},
			enquire: {
				color: '#CCCCCC',
				label: 'Enquire',
			}
		};

		var dateRanges = [
			{
				state: 'enquire',
				range: moment.range(
					moment(),
					moment()
				),
			}
		];

		var datepickerValue = this.props.getDatepickerRange();
    var buttonText = '';
    if(this.isDateRangeSelected){
      var start = datepickerValue.start.format('L');
      var end = datepickerValue.end.format('L');
      var dateRange = start + " - " + end;
      buttonText = "[" + dateRange + "]";
    }
    else{
      buttonText = this.getValue('selectDate')
    }

		if(this.state.isLoadList == true) {
			var loadElement = (
        <GeminiScrollbar>
					<div className="routerBox">
						<div className="routerDate">
              {this.drawRoutesElement(this.state.routes)}
						</div>
					</div>

				</GeminiScrollbar>
			);
		} else if(this.state.isLoadList == false) {
			var loadElement = (
				<div className='loaderRoutesList'><span></span></div>
			);
		} else {
			var loadElement = '';
		}

		var emptyRoutesListClass = 'emptyRoutesList';

		if(this.state.emptyRoutesList) {
			emptyRoutesListClass += ' show'
		}

    return(
      <section id="calendarBox">
        <div className="calendar">
          <a className="closeMobilCalendarSelect" onClick={this.hiddenCalendarMobile}><i className="icon-cancel"></i></a>
          <DateRangePicker
            id="RangePicker"
            firstOfWeek={1}
            numberOfCalendars={1}
            selectionType='range'
            earliestDate={new Date()}
            stateDefinitions={stateDefinitions}
            defaultState="available"
						dateStates={dateRanges}
            showLegend={true}
            singleDateRange={true}
            value={datepickerValue}
            onSelect={this.handleSelect}
            locale={localStorage.getItem('lang')}
						onSelectStart={this.onSelectStart}
          />

          <div className="pleaseSelectEndDate" data-show={this.state.selectEndDate}>
            <p>{this.getValue('pleaseSelectEndDate')}</p>
          </div>
        </div>

        <div className="modeModifyRouter">
          <a onClick={this.changeModeRouter.bind(this, 0)} data-select={this.modeRouter(this.state.modeRouter, 0)}>
            <i></i> {this.getValue('oneSelectMode')}
          </a>
          <a onClick={this.changeModeRouter.bind(this, 1)} data-select={this.modeRouter(this.state.modeRouter, 1)}>
            <i></i> {this.getValue('multipleSelectMode')}
          </a>
        </div>

				<div className="mobilCalendarSelect">
					<button onClick={this.showCalendarMobile}><i className="icon-calendar"></i>{buttonText}</button>
				</div>


				{loadElement}

				<div className={emptyRoutesListClass}>
					<p>{this.getValue('emptyRoutesList')}</p>
				</div>

      </section>
    );
  }
});
