var _ = require("lodash");

class TrackedTarget {

	constructor(defaultTarget, trackingState, liveData) {
		this.defaultTarget = defaultTarget;
		this.trackingState = trackingState;
		this.liveData = liveData;
	}

	getId() {
		return this.defaultTarget.getId();
	}

	getDefaultTarget() {
		return this.defaultTarget;
	}

	getTrackingState() {
		return this.trackingState;
	}

	setTrackingState(trackingState) {
		this.trackingState = trackingState;
	}

	getLiveData() {
		return this.liveData;
	}

	setLiveData(liveData) {
		this.liveData = this.mergeLiveData(liveData);
	}

	mergeLiveData(newLiveData) {
		if (this.liveData == null) {
			return newLiveData
		}
		if (newLiveData == null) {
			return null;
		}

		var oldLiveData = this.liveData;
		var oldServerTimeMicros = oldLiveData.serverTimeMicros;
		var newServerTimeMicros = newLiveData.serverTimeMicros;
		if (newServerTimeMicros >= oldServerTimeMicros) {
			return this.createMergedLiveData(newServerTimeMicros, newLiveData.target, newLiveData.lastPositions, oldLiveData.currentPosition, newLiveData.currentPosition, newLiveData.uvxEnabled);
		} else {
			return this.createMergedLiveData(oldServerTimeMicros, oldLiveData.target, oldLiveData.lastPositions, newLiveData.currentPosition, oldLiveData.currentPosition, oldLiveData.uvxEnabled);
		}
	}

	createMergedLiveData(serverTimeMicros, target, lastPositions, oldCurrentPosition, newCurrentPosition, uvxEnabled) {
		var mergedLiveData = new Object();
		mergedLiveData.serverTimeMicros = serverTimeMicros;
		mergedLiveData.target = target;
		mergedLiveData.lastPositions = lastPositions;
		mergedLiveData.uvxEnabled = uvxEnabled;
		if (newCurrentPosition == undefined || newCurrentPosition == null) {
			mergedLiveData.currentPosition = null;
			return mergedLiveData;
		}
		mergedLiveData.currentPosition = this.createCurrentPosition(newCurrentPosition);
		if (oldCurrentPosition == undefined || oldCurrentPosition == null) {
			mergedLiveData.currentPosition.measures = newCurrentPosition.measures;
		} else {
			mergedLiveData.currentPosition.measures = this.createMeasures(oldCurrentPosition.measures, newCurrentPosition.measures);
		}
		return mergedLiveData;
	}

	createCurrentPosition(newCurrentPosition) {
		var currentPosition = new Object();
		currentPosition.longitude = newCurrentPosition.longitude;
		currentPosition.latitude = newCurrentPosition.latitude;
		currentPosition.timeMicros = newCurrentPosition.timeMicros;
		currentPosition.geocodedAddress = newCurrentPosition.geocodedAddress;
		currentPosition.poi = newCurrentPosition.poi;
		currentPosition.speed = newCurrentPosition.speed;
		currentPosition.direction = newCurrentPosition.direction;
		currentPosition.color = newCurrentPosition.color;
		return currentPosition;
	}

	createMeasures(oldMeasures, newMeasures) {
		var measures = []
		newMeasures.map(function(newMeasure) {
			var matchingOldMeasure = _.find(oldMeasures, function(oldMeasure) {
				return newMeasure.name == oldMeasure.name;
			})
			if (matchingOldMeasure == undefined) {
				measures.push(newMeasure);
				return;
			}
			if(newMeasure.timestampMicros >= matchingOldMeasure.timestampMicros) {
				measures.push(newMeasure);
			} else {
				measures.push(matchingOldMeasure);
			}
		});
		return measures;
	}

	setEnabled(enabled) {
		this.defaultTarget.setEnabled(enabled);
	}

	getEnabled() {
		return this.defaultTarget.getEnabled();
	}

	getFromTimeMicros() {
		if (this.liveData == null) {
			return 0;
		}
		if (this.liveData.currentPosition == undefined) {
			return 0;
		}
		if (this.liveData.currentPosition == null) {
			return 0;
		}
		return this.liveData.currentPosition.timeMicros;
	}

}
export { TrackedTarget };
