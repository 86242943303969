class isIE9{
    /**
     * A useragent-ben benne van-e az IE
     * Ha benne van lekérjük a verziószámot és ellenőrizzük, hogy 9-es IE-e
     */
    constructor(){
        var ua = window.navigator.userAgent;
		var msie = ua.indexOf('MSIE ');
		if(msie > 0){
			var ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
			if(ieVersion == 9){
				window.isIE9 = true;
			}
            else{
                window.isIE9 = false;
            }
		}
        else{
            window.isIE9 = false;
        }
    }
}

export default (new isIE9);