class RenderedTarget {

	constructor(defaultTarget, renderedElement) {
		this.defaultTarget = defaultTarget;
		this.renderedElement = renderedElement;
	}

	getId() {
		return this.defaultTarget.getId();
	}

	getDefaultTarget() {
		return this.defaultTarget;
	}

	getRenderedElement() {
		return this.renderedElement;
	}

	setEnabled(enabled) {
		this.defaultTarget.setEnabled(enabled);
	}

	getEnabled() {
		return this.defaultTarget.getEnabled();
	}

}
export { RenderedTarget };
