

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var Select = require('react-select');

// Lista elem betöltése
var Item = require('./MessagesListItem.jsx');

var Ajax = require('../mixins/Ajax.jsx');

module.exports = React.createClass({

	mixins: [Languages, Ajax],

  getDefaultProps : function(){
    return {
      listItemClick : function(){},
      threadLimit : 4,
      messagesByThreadLimit : 10,
			searchAjaxCall: null,
			selectThreadId: 0
    }
  },
  getInitialState: function() {

    return {
      items: [],
			stopLongPolling: false
    };
  },

  //MessagesListItem en való kattintást kezeli le
  handleClick : function(id){
    var items = this.state.items;

		if(this.longPollingMobileFunc != null) {
			this.longPollingMobileFunc.abort();
		}

    var selectedItem = {};

	  //beállítjuk az active propertyket
    for(var i in items){
      if( items[i]['id'] === id ){
        items[i]['active'] = true;
        selectedItem = items[i];
        continue;
      }

      items[i]['active'] = false;
    }

	  //átadjuk a szülőnek a kiválasztott elemet
    this.props.listItemClick(selectedItem, this.props.messagesByThreadLimit);

    this.setState({
      items: items
    });

  },

	//lekérdezzük az üzenetszálakat
  componentWillMount : function() {
    var _this = this;

    this.props.setIsLoadList(true);

    var limit  = 1;
    var height = window.innerHeight;

    var limit  = Math.ceil(height / 800);

    _this.props.messagesByThreadLimit = _this.props.messagesByThreadLimit * limit;

    $.ajax({
      url: "@@servletUrl"+"messageThreads/recent?threadLimit="+ _this.props.threadLimit + "&messagesByThreadLimit="+ _this.props.messagesByThreadLimit,
      type: "GET",
			timeout : 30000,
			cache: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success : function(response){
        var items = [];

        if( typeof response !== 'undefined' && response.length > 0){
          for(var i in response){
            var tmpItem = response[i];
            tmpItem['active'] = false;

            response[i]['messages'] = response[i]['messages'].reverse();

            items.push(
              tmpItem
            );
          }
        }

				// Elemek sorbarendezése
				var sortItems = _this.sortThread(items);

				_this.setState({
					items: sortItems
				});

				if(_this.props.selectThreadId == 0) {
					if($(window).innerWidth() > 992) {
						$('#messagesList').find('ul.items li').first().trigger('click');
					} else {
						_this.longPollingMobile(sortItems);
					}
				} else {
					$('#messagesList').find('ul.items li[data-id="' + _this.props.selectThreadId + '"]').trigger('click');
				}

        _this.props.setIsLoadList(false);
      },
      error : function(response, status, xhr){
				_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.componentWillMount);
      }
    });
  },

	componentDidMount : function() {
    document.addEventListener("pause", this.stopMessageMobileLongPolling, false);
		document.addEventListener("resume", this.restartMessageMobileLongPolling, false);
  },

	stopMessageMobileLongPolling : function() {
		if(this.longPollingMobileFunc != null) {
			this.longPollingMobileFunc.abort();
		}

		this.setState( {
			stopLongPolling: true
		});
	},

	restartMessageMobileLongPolling : function() {
		var _this = this;

		this.setState( {
			stopLongPolling: false
		}, function() {
			if(_this.longPollingMobileFunc != null) {
				_this.longPollingMobileFunc.abort();
			}
			_this.longPollingMobile();
		});
	},

	//idő alapján sortoljuk a listát
	sortThread: function(items) {

		items.sort(function(a,b){
			var aTime = 0,
				bTime = 0;

			//az első elem idejét szedjük össze
			if( a.messages.length ){
				if( a.messages[ a.messages.length - 1 ]['readStatus']['timeMicros'] !== 0){
					aTime = a.messages[ a.messages.length - 1 ]['readStatus']['timeMicros'];
				} else {
					aTime = a.messages[ a.messages.length - 1 ]['sentStatus']['timeMicros'];
				}
			}

			//a második elem idejét szedjük össze
			if( b.messages.length ){
				if( b.messages[ b.messages.length - 1 ]['readStatus']['timeMicros'] !== 0){
					bTime = b.messages[ b.messages.length - 1 ]['readStatus']['timeMicros'];
				} else {
					bTime = b.messages[ b.messages.length - 1 ]['sentStatus']['timeMicros'];
				}
			}

			if( aTime > bTime ){
				return -1;
			}

			if( aTime < bTime ){
				return 1;
			}

			return 0;

		});


		return items;
	},

	longPollingMobileFunc: null,

  longPollingMobile : function(items) {
		var _this = this;

		if(this.state.stopLongPolling) { return; }

		if(typeof items != 'undefined') {
			var firstItem = items[0];
			if (firstItem == undefined || firstItem == null) {
				latestTimeMicros = localStorage.getItem('latestTimeMicros');
			} else {
				var latestTimeMicros = firstItem['lastMessageSentTime'];
				var localStorageLatestTimeMicros = localStorage.getItem('latestTimeMicros');
				if(localStorageLatestTimeMicros != -1) {
					latestTimeMicros = localStorageLatestTimeMicros;
				}
			}
		} else {
			latestTimeMicros = localStorage.getItem('latestTimeMicros');
		}

		this.longPollingMobileFunc = $.ajax({
			url: "@@servletUrl"+"messages/tracking?fromTime="+ latestTimeMicros +"&asynchronous=true",
			type: "GET",
			timeout : 60000,
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success : function(response, status, xhr) {

				if(response.messages.length > 0) {

					var differentThreadItems = [];

					//végigmegyünk a response elemein
					for( var i in response.messages ) {
						var message = response.messages[i];

						if(typeof differentThreadItems[message.threadId] == 'undefined') {
							differentThreadItems[message.threadId] = [];
							differentThreadItems[message.threadId].push(message);
						}
					}

					_this.updateMessages(differentThreadItems);
				}

				localStorage.setItem('latestTimeMicros', response.serverTimeMicros);
				_this.longPollingMobile();
			},
			error : function(response, status, xhr){

				if(response.statusText == 'abort') {
					return false;
				}

				if( response.statusText != 'timeout' && response.statusText != 'error' && status != 0 ) {
					_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, null);
				}
			}
		});
  },

  updateMessages : function(messages){
    var items = this.state.items,
        changed = false;

    //végigmegyünk a threadeken
    for(var i in items){

      //ha nincs módosult elem az adott threadhez, továbblépünk
      if( typeof messages[ items[i]['id'] ] === 'undefined' ){ continue; }

      //végigmegyünk az updatelt üzeneteken
      for(var j in messages[ items[i]['id'] ] ){

        var found = false;

        //végigmegyünk az adott thread üzenetein
        for(var k in items[i]['messages'] ){

          //ha létezik benne elem, az annyit jelent, hogy egy elem updatelődött
          if( items[i]['messages'][k]['id'] === messages[ items[i]['id'] ][j]['id'] ){
            found = true;
            changed = true;
            items[i]['messages'][k] = messages[ items[i]['id'] ][j];
          }

        }

        //ha nem volt még ilyen elem, akkor egy új, hozzáadjuk
        if( !found ){
          changed = true;
          items[i]['messages'].push( messages[ items[i]['id'] ][j] );
        }

      }
    }

    //ha volt változás, akkor updateljük a stateben az elemeket és ezzel újra rendereli
    if( changed ){
			// Elemek sorbarendezése
			var sortItems = this.sortThread(items);

			this.setState({
				items: sortItems
			});
    }

  },

  searchRequested : function(input, callback){
    var _this = this;
		var _input = input;
		var _callback = callback;

    var options = [];

    if( input.length < 3 ){
      callback(null, {options: options});
      return false;
    }

		if(this.props.searchAjaxCall != null) {
			this.props.searchAjaxCall.abort();
		}

		var ajaxCall = $.ajax({
			url: "@@servletUrl"+"messageThreads/threadUser?name="+ input +"&messagesByThreadLimit=" + _this.props.messagesByThreadLimit,
			type: "GET",
			timeout : 30000,
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success : function(response, status, xhr){
				if( xhr.status == 200 && typeof response !== 'undefined' && response.length > 0 ){
					for(var i in response){
						options.push({
							label : response[i]['threadUser']['name'],
							value : response[i]['id'],
							item : response[i]
						});
					}
				}

				callback(null, {options: options});
			},
			error : function(response, status, xhr){
				if(response.statusText == 'abort') {
					return false;
				}

				_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.searchRequested.bind(_this, _input, _callback));
			}
		})

		this.props.searchAjaxCall = ajaxCall;
  },

  itemSelected : function(item, selectedOptions){
    var _this = this;

    if( typeof selectedOptions[0] === 'undefined' ){ return false; }

    var itms = _this.state.items;

    var selected = selectedOptions[0]['item'];

    var found = false;

    for( var i in itms ){
      if( itms[i]['id'] === selected['id'] ){
        found = true;
        break;
      }
    }

    if( !found ){
      itms.push(selected);
      _this.setState({items: itms});
    }

    setTimeout(function(){
			$('#messagesList').find('ul.items li[data-id="'+ selected['id'] +'"]').trigger('click');
    }, 1);

  },

	removeUnread: function(threadId) {
		this.refs['thread' + threadId].removeUnread();
	},

  render: function() {
    var itemsJSX = [],
        _this = this;

    for(var i in this.state.items){
			var item = this.state.items[i];
			var threadId = 'thread' + item.id;

      itemsJSX.push(
        <Item
					ref={threadId}
          active={item['active']}
          listClick={_this.handleClick}
          threadUser={item['threadUser']}
          lastMessage={item['messages'][ item['messages'].length - 1 ]}
          threadId={item['id']}
          />
      )
    }

    //select https://github.com/JedWatson/react-select

    return (
      <section id="messagesList">
        <header>
          <h1>
            {this.getValue('messages')}
          </h1>
        </header>
        <Select
          name="autocomplete"
          asyncOptions={this.searchRequested}
          onChange={this.itemSelected}
					value=""
					placeholder={this.getValue('messageSearchPlace')}
					searchPromptText={this.getValue('messageSearchText')}
					noResultsText={this.getValue('messageSearchTextNotFound')}
          autoload={false}
					backspaceRemoves={false}
          />
        <ul className="items">
          {itemsJSX}
        </ul>
      </section>
    );
  }
});
