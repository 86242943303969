
// React betöltése
var React = require('react');
// -----------------------------------------------------
var $ = require('jquery');

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

// Menüpontok betöltése
var Menus = require('../i18n/menus.js');
// -----------------------------------------------------

// Login csekkolás
var Authentication = require('../mixins/Authentication.js');
var Router = require('react-router');
var RouteHandler = Router.RouteHandler;
var Navigation = Router.Navigation;
// -----------------------------------------------------
import LastPath from '../model/LastPath';

module.exports = React.createClass({

	mixins: [Navigation, Authentication, Languages, Brands],

	navigation: function(url, label) {

    if(LastPath.lastPath != url && (url == 'tracking' || url == 'router' || url == 'fueling')){
      $('#overlay').addClass('overlay');
      $('#loader').addClass('loader');
      $('#exitButton').addClass('exitButton icon-cancel');

      setTimeout(function() {
        this.transitionTo(url);

      }.bind(this), 500);
    }
    else{
      this.transitionTo(url);

    }
	},

  render: function() {
    return (
      <main id="dashboardPage">
        <div className="container">
          <i className="logo" style={{backgroundImage: 'url(' + this.getResourceItemValue('logo') + ')'}}></i>
          <ul>
            <li>
              <a onClick={this.navigation.bind(this, 'tracking', 'Nyomkövetés')}>
                <i className="icon-track"></i>
                <span>
                  {this.getValue('tracking')}
                </span>
              </a>
            </li>
            <li>
              <a onClick={this.navigation.bind(this, 'router', 'Útvonalak')}>
                <i className="icon-router"></i>
                <span>
									{this.getValue('router')}
                </span>
              </a>
            </li>
            <li>
              <a onClick={this.navigation.bind(this, 'messages', 'Üzenetek')}>
                <i className="icon-message"></i>
                <span>
									{this.getValue('message')}
                </span>
              </a>
            </li>
						<li>
							<a onClick={this.navigation.bind(this, 'fueling', 'Tankolási lista')}>
								<i className="icon-fuel"></i>
								<span>
									{this.getValue('fueling')}
								</span>
							</a>
						</li>
            <li>
              <a onClick={this.navigation.bind(this, 'logout', 'Kijelentkezés')}>
                <i className="icon-logout"></i>
                <span>
									{this.getValue('logout')}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </main>
    )
  }
});
