

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

module.exports = React.createClass({

	mixins: [Languages],

  setClassName: function(){
    var lang = this.lang();
    var className = '';
    switch(lang){
      case 'hu':
        className = 'icon-logo'
        break;
      case 'en':
        className = 'icon-logo_en'
        break;
      case 'ro':
        className = 'icon-logo_ro'
        break;
      case 'sr':
        className = 'icon-logo_sr'
        break;
      case 'hr':
        className = 'icon-logo_hr'
        break;
      case 'sk':
        className = 'icon-logo_en'
        break;
      case 'cz':
        className = 'icon-logo_en'
        break;
    }
    return className;
  },

  render: function() {

    return (
      <main id="registrationPage">
        <header>
          <div className="container">
            <i className={this.setClassName()}></i>
            <a href="#/"><i className="icon-arrowLeft"></i>{ this.getValue('back') }</a>
          </div>
        </header>
        <div className="container">
          <h1>
            { this.getValue('registrationTitle') }
          </h1>
					<p dangerouslySetInnerHTML={{__html: this.getValue('registrationBody')}}></p>
          <div className="phoneNumber"><i className="icon-telephone"></i><span>+36 (1) 7 76 76 76</span></div>
        </div>
      </main>
    )
  }
});
