

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Datepicker betöltése
var DateTime = require('react-datetime');
var moment = require('moment');
// -----------------------------------------------------

// Bootstrap elemek importĂˇlĂˇsa
var Input = require('react-bootstrap/lib/Input');
var Row = require('react-bootstrap/lib/Row');
var Col = require('react-bootstrap/lib/Col');
var Button = require('react-bootstrap/lib/Button');
var Modal = require('react-bootstrap/lib/Modal');
var ModalHeader = require('react-bootstrap/lib/ModalHeader');
var ModalFooter = require('react-bootstrap/lib/ModalFooter');
var ModalBody = require('react-bootstrap/lib/ModalBody');

var Alert = require('react-bootstrap/lib/Alert');
var OverlayTrigger = require('react-bootstrap/lib/OverlayTrigger');
var Tooltip = require('react-bootstrap/lib/Tooltip');
var Tabs = require('react-bootstrap/lib/Tabs');
var Tab = require('react-bootstrap/lib/Tab');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var Ajax = require('../mixins/Ajax.jsx');

module.exports = React.createClass({

	mixins: [Languages, Ajax],

	getInitialState: function () {
		return {
			carId: 0,
			isModalOpen: false,
			share_start: null,
			share_end: null,
			iframe_start: null,
			iframe_end: null,
			weekdays: undefined,
			locale: '',
			dateFormatCallendar: "YYYY MMMM",
			shareUrl: '',
			iframeUrl: '',
			iframeCode: '',
			iframeWidth: 800,
			iframeHeight: 600,
			key: 1,
			selectedTab: 'share',
			isLoad: false,
			datePickerOptions: { readOnly: "readonly" }
		};
	},

	componentWillMount: function () {
		var lang = localStorage.getItem('lang');
		var date = new Date();
		var actuaclDate = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

		this.setState({
			locale: lang,
			share_start: actuaclDate,
			share_end: actuaclDate,
			iframe_start: actuaclDate,
			iframe_end: actuaclDate,
		});

		moment.locale(lang);
	},

	openModal: function (open, id) {
		this.setState({
			isModalOpen: open,
			carId: id
		}, function () {
			$('.rdt input[type="text"]').focus(function () {
				$(this).blur();
			});
		});
	},

	handleToggle: function () {
		var isModelOpen = !this.state.isModalOpen;

		this.setState({
			isModalOpen: isModelOpen
		}, function () {
			$('.rdt input[type="text"]').focus(function () {
				$(this).blur();
			});
		});

		if (isModelOpen) {
			this.gaEvent('Click', 'Gépjármű megosztás ikon');
		} else {
			this.gaEvent('Click', 'Megosztás bezárása');
		}
	},

	handleShareStartDateChange: function (date) {
		var time = date.hour(0).minute(0).seconds(0).unix() * 1000 * 1000;

		if (typeof this.refs.startShareDate != 'undefined') {
			this.refs.startShareDate.handleClickOutside();
		}

		this.setState({
			share_start: date
		});
	},

	handleShareEndDateChange: function (date) {
		var time = date.hour(23).minute(59).seconds(59).unix() * 1000 * 1000;

		if (typeof this.refs.endShareDate != 'undefined') {
			this.refs.endShareDate.handleClickOutside();
		}

		this.setState({
			share_end: date
		});
	},

	handleIframeStartDateChange: function (date) {
		var time = date.hour(0).minute(0).seconds(0).unix() * 1000 * 1000;

		if (typeof this.refs.startIframeDate != 'undefined') {
			this.refs.startIframeDate.handleClickOutside();
		}

		this.setState({
			iframe_start: date
		});
	},

	handleIframeEndDateChange: function (date) {
		var time = date.hour(23).minute(59).seconds(59).unix() * 1000 * 1000;

		if (typeof this.refs.endIframeDate != 'undefined') {
			this.refs.endIframeDate.handleClickOutside();
		}

		this.setState({
			iframe_end: date
		});
	},

	handleSubmit: function () {
		var _this = this;

		if (this.state.selectedTab == 'share') {
			if (this.state.share_start == null || this.state.share_end == null) {
				this.props.addTranslateNotification('carshareDialogText', 'warning');
				return false;
			}
		}

		if (this.state.selectedTab == 'embed') {
			if (this.state.iframe_start == null || this.state.iframe_end == null) {
				this.props.addTranslateNotification('carshareDialogText', 'warning');
				return false;
			}
		}

		var start = '';
		var end = '';
		var email = '';
		var type = '';

		switch (this.state.selectedTab) {
			case 'share':
				start = moment(this.state.share_start).hour(0).minute(0).seconds(0).unix() * 1000 * 1000;
				end = moment(this.state.share_end).hour(23).minute(59).seconds(59).unix() * 1000 * 1000;
				type = $('#shareType').val();
				break;
			case 'embed':
				start = moment(this.state.iframe_start).hour(0).minute(0).seconds(0).unix() * 1000 * 1000;
				end = moment(this.state.iframe_end).hour(23).minute(59).seconds(59).unix() * 1000 * 1000;
				type = 'iframe';
				break;
			default: type = 'tracking';
		}

		this.setState({
			isLoad: true
		});

		$.ajax({
			url: "@@servletUrl" + "urls/generate",
			type: "POST",
			data: JSON.stringify({
				targetId: _this.state.carId,
				fromTimeMicros: start,
				toTimeMicros: end,
				emailAddress: email,
				type: type
			}),
			timeout: 30000,
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
				xhr.setRequestHeader("Accept", "application/json");
				xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
			},
			success: function (response) {
				var basedomain = window.location.href.substr(0, window.location.href.indexOf(window.location.hash));

				switch (type) {
					case 'tracking':
					case 'routes':
						_this.setState({
							shareUrl: basedomain + '#/login/' + response.loginToken + '/' + type + '/' + _this.lang()
						});
						$('#shareUrl').css('display', 'block');

						_this.gaEvent('Share', 'Gépjármű');
						break;
					case 'iframe':

						var url = basedomain + '#/login/' + response.loginToken + '/' + type + '/' + _this.lang();

						_this.setState({
							iframeUrl: url,
							iframeCode: '<iframe src="' + url + '" width="' + _this.state.iframeWidth + '" height="' + _this.state.iframeHeight + '"></iframe>'
						});

						$('#shareIframe').css('display', 'block');

						_this.gaEvent('Share', 'Gépjármű iframe');
						break;
					default: type = 'tracking';
				}


				_this.setState({
					isLoad: false
				});
			},
			error: function (response, status, xhr) {
				_this.setState({
					isLoad: false
				});

				_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.handleSubmit)
			}
		});
	},

	urlSelected: function () {
		$("#shareUrlInput").select();
	},

	iframeSelected: function () {
		$("#iframeUrlInput").select();
	},

	changeSelectedTab: function (key) {
		if (key == 1) {
			this.setState({
				selectedTab: 'share'
			});
		} else if (key == 2) {
			this.setState({
				selectedTab: 'embed'
			});
		}

		this.setState({
			key: key
		});
	},

	gaEvent: function (action, label) {
	},

	isValidShareStartDate: function (currentDate, selectedDate) {

		if (currentDate.isAfter(this.state.share_end)) {
			return false;
		} else {
			return true;
		}
	},

	isValidShareEndDate: function (currentDate, selectedDate) {
		if (this.state.share_start != null) {
			if (currentDate.isBefore(this.state.share_start)) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	},

	isValidIframeStartDate: function (currentDate, selectedDate) {

		if (currentDate.isAfter(this.state.iframe_end)) {
			return false;
		} else {
			return true;
		}
	},

	isValidIframeEndDate: function (currentDate, selectedDate) {
		if (this.state.iframe_start != null) {
			if (currentDate.isBefore(this.state.iframe_start)) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	},

	render: function () {
		if (this.state.isLoad == true) {
			var loader = (<div className='loaderShareButton'><span></span></div>);
		} else {
			var loader = (<Button bsStyle='primary' id="ShareButton" onClick={this.handleSubmit}>{this.getValue('shareButtonTitle')}</Button>);
		}


		return (
			<Modal show={this.state.isModalOpen} onHide={this.handleToggle}>
				<Modal.Header closeButton>
					<Modal.Title>{this.getValue('carShareTitle')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Tabs activeKey={this.state.key} onSelect={this.changeSelectedTab}>
						<Tab eventKey={1} title={this.getValue('shareTabTitle')}>
							<Row>
								<Col xs={12}>
									<p className="text">
										{this.getValue('carShareText1')}
									</p>
								</Col>
							</Row>
							<Row>
								<Col xs={12} id="shareUrl">
									<Alert bsStyle='info'>
										<Input
											type='text'
											value={this.state.shareUrl}
											onClick={this.urlSelected}
											id="shareUrlInput"
										/>
									</Alert>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<Input type='select' label={this.getValue('page')} placeholder='select' id="shareType">
										<option value='tracking'>{this.getValue('tracking')}</option>

									</Input>
								</Col>
							</Row>
							<Input label={this.getValue('access')} help='' wrapperClassName='wrapper'>
								<Row>
									<Col xs={12} sm={6}>
										<Input label={this.getValue('startDate')} help='' wrapperClassName='wrapper'>
											<DateTime
												ref="startShareDate"
												timeFormat={false}
												onChange={this.handleShareStartDateChange}
												isValidDate={this.isValidShareStartDate}
												defaultValue={this.state.share_start}
												inputProps={this.state.datePickerOptions}
												locale={this.state.locale}
											/>
										</Input>
									</Col>
									<Col xs={12} sm={6}>
										<Input label={this.getValue('endDate')} help='' wrapperClassName='wrapper'>
											<DateTime
												ref="endShareDate"
												timeFormat={false}
												onChange={this.handleShareEndDateChange}
												isValidDate={this.isValidShareEndDate}
												defaultValue={this.state.share_end}
												inputProps={this.state.datePickerOptions}
												locale={this.state.locale}
											/>
										</Input>
									</Col>
								</Row>
							</Input>
						</Tab>

						<Tab eventKey={2} title={this.getValue('embed')}>
							<Row>
								<Col xs={12}>
									<p className="text">
										{this.getValue('carShareText2')}
									</p>
								</Col>
							</Row>
							<Row>
								<Col xs={12} id="shareIframe">
									<Alert bsStyle='info'>
										<Input
											type='textarea'
											value={this.state.iframeCode}
											onClick={this.iframeSelected}
											id="iframeUrlInput"
										/>
									</Alert>
								</Col>
							</Row>
							<Input label={this.getValue('access')} help='' wrapperClassName='wrapper'>
								<Row>
									<Col xs={12} sm={6}>
										<Input label={this.getValue('startDate')} help='' wrapperClassName='wrapper'>
											<DateTime
												ref="startIframeDate"
												timeFormat={false}
												onChange={this.handleIframeStartDateChange}
												isValidDate={this.isValidIframeStartDate}
												defaultValue={this.state.iframe_start}
												inputProps={this.state.datePickerOptions}
												locale={this.state.locale}
											/>
										</Input>
									</Col>
									<Col xs={12} sm={6}>
										<Input label={this.getValue('endDate')} help='' wrapperClassName='wrapper'>
											<DateTime
												ref="endIframeDate"
												timeFormat={false}
												onChange={this.handleIframeEndDateChange}
												isValidDate={this.isValidIframeEndDate}
												defaultValue={this.state.iframe_end}
												inputProps={this.state.datePickerOptions}
												locale={this.state.locale}
											/>
										</Input>
									</Col>
								</Row>
							</Input>
						</Tab>
					</Tabs>
				</Modal.Body>
				<Modal.Footer>
					{loader}
					<Button id="CloseButton" onClick={this.handleToggle}>{this.getValue('closeButtonTitle')}</Button>
				</Modal.Footer>
			</Modal>
		);
	}
});