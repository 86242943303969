import { RenderedTarget } from './RenderedTarget';
import { DefaultTarget } from './DefaultTarget';
import { TrackedTarget } from './TrackedTarget';
import { TrackedTargetState } from './TrackedTargetState';

class TargetList {

	constructor() {
		this.targetArray = [];
	}

	add(target) {
		var targetId = target.getId();
		var index = this.indexOf(targetId);
		if (index == -1) {
			this.targetArray.push(target);
		}
	}

	getTarget(targetId) {
		var index = this.indexOf(targetId)
		if (index != -1) {
			return this.targetArray[index];
		}
		return null;
	}

	copy(){
		var targetList = new TargetList();
		return targetList.concat(this);
	}

	deepCopyTrackedTargetList() {
		var newTargetList = new TargetList();
		//return newTargetList.concat(this);
		for(var i = 0; i < this.targetArray.length; i++){
			var element = this.targetArray[i];
			var defaultTarget = element.getDefaultTarget();
			var deepDefaultTarget = new DefaultTarget(defaultTarget.getId(),
														defaultTarget.getLicenseplate(),
														defaultTarget.getDescription(),
														defaultTarget.getEnabled());
			newTargetList.add(new TrackedTarget(deepDefaultTarget, element.getTrackingState(), element.getLiveData()));
		}
		return newTargetList;
	}

	deepCopySelectableTargetList() {
		var newTargetList = new TargetList();
		//return newTargetList.concat(this);
		for(var i = 0; i < this.targetArray.length; i++){
			var element = this.targetArray[i];
			var defaultTarget = element.getDefaultTarget();
			var deepDefaultTarget = new DefaultTarget(defaultTarget.getId(),
														defaultTarget.getLicenseplate(),
														defaultTarget.getDescription(),
														defaultTarget.getEnabled());
			newTargetList.add(new RenderedTarget(deepDefaultTarget, element.getRenderedElement()));
		}
		return newTargetList;
	}

	remove(targetId) {
		var index = this.indexOf(targetId);
		if (index != -1) {
			this.targetArray.splice(index, 1);
		}
	}

	length() {
		return this.targetArray.length;
	}

	indexOf(targetId) {
		for (var i = 0; i < this.targetArray.length; i++) {
			var target = this.targetArray[i]
			if (target.getId() == targetId) {
				return i;
			}
		}
		return -1;
	}

	enableTargets(otherTargetList) {
		this.enableTargetsImpl(otherTargetList, true);
	}

	disableTargets(otherTargetList) {
		this.enableTargetsImpl(otherTargetList, false);
	}

	enableTargetsImpl(otherTargetList, enabled) {
		this.targetArray.map(function (target) {
			var index = otherTargetList.indexOf(target.getId());
			if (index != -1) {
				target.setEnabled(enabled);
			}
		});
	}

	updateLiveData(targetId, liveData, trackingState) {
		var index = this.indexOf(targetId)
		if (index != -1) {
			var target = this.targetArray[index];
			target.setLiveData(liveData);
			target.setTrackingState(trackingState);
		}
	}

	equalsOnIds(otherTargetList) {
		var ids = this.getAllIds().sort();
		var otherIds = otherTargetList.getAllIds().sort();

		return ids.length == otherIds.length
			&& ids.every(
				(value, i) => value === otherIds[i]
			);
	}

	//A \ B
	difference(otherTargetList) {
		var differenceList = [];
		for (var i = 0; i < this.targetArray.length; i++) {
			var target = this.targetArray[i];
			var id = target.getId();
			if (otherTargetList.indexOf(id) == -1) {
				differenceList.push(target);
			}
		}
		return differenceList;
	}

	commonTargets(otherTargetList) {
		var commonList = [];
		for(var i = 0; i < this.targetArray.length; i++){
			var target = this.targetArray[i];
			var id = target.getId();
			if (otherTargetList.indexOf(id) != -1) {
				commonList.push(target);
			}
		}
		return commonList;
	}

	concat(listToAppend) {
		var targetArrayToAppend = listToAppend.getAll();
		this.targetArray = this.targetArray.concat(targetArrayToAppend);
		return this;
	}

	getAll() {
		return this.targetArray;
	}

	hasAnyTrackedTargetState(state){
		var hasState = false;
		for(var i = 0; i < this.targetArray.length; i++){
			if(this.targetArray[i].getTrackingState() == state){
				hasState = true;
				break;
			}
		}
		return hasState;
	}

	sort(){
		this.targetArray.sort(function(a, b){
			if(a.defaultTarget.licenseplate.toLowerCase() < b.defaultTarget.licenseplate.toLowerCase())
				return -1;
			if(a.defaultTarget.licenseplate.toLowerCase() > b.defaultTarget.licenseplate.toLowerCase())
				return 1;
			return 0;
		});
		return this.targetArray;
	}

	getNumberOfTargetsFromSearchValue(searchValue){
		var targetsHasSearchValue = 0;
		var lowerCaseSearchValue = searchValue.toLowerCase();
		for (var i = 0; i < this.targetArray.length; i++) {
			var lowerCaseLicenseplate = this.targetArray[i].getDefaultTarget().getLicenseplate().toLowerCase();
			var lowerCaseDescription = this.targetArray[i].getDefaultTarget().getDescription().toLowerCase();
			if ((lowerCaseLicenseplate.search(lowerCaseSearchValue) != -1) ||
				lowerCaseDescription.search(lowerCaseSearchValue) != -1){
					targetsHasSearchValue++;
			}
		}
		return targetsHasSearchValue;
	}

	enableTrackedTargetsIfHasSearchValue(searchValue){
		var targetsToDisable = new TargetList();
		var targetsToEnable = new TargetList();
		if(searchValue != null){
			var lowerCaseSearchValue = searchValue.toLowerCase();
			for (var i = 0; i < this.targetArray.length; i++) {
				var lowerCaseLicenseplate = this.targetArray[i].getDefaultTarget().getLicenseplate().toLowerCase();
				var lowerCaseDescription = this.targetArray[i].getDefaultTarget().getDescription().toLowerCase();
				if ((lowerCaseLicenseplate.search(lowerCaseSearchValue) != -1) ||
					lowerCaseDescription.search(lowerCaseSearchValue) != -1){
						targetsToEnable.add(this.targetArray[i]);
				}
				else{
					targetsToDisable.add(this.targetArray[i]);
				}
			}
		}
		else{
			for (var i = 0; i < this.targetArray.length; i++) {
				if(!this.targetArray[i].getDefaultTarget().getEnabled()){
					targetsToEnable.add(this.targetArray[i]);
				}
			}
		}

		this.disableTargets(targetsToDisable);
		this.enableTargets(targetsToEnable);
		return this;
	}

	getAllIds() {
		var ids = [];
		for (var i = 0; i < this.targetArray.length; i++) {
			ids.push(this.targetArray[i].getId());
		}
		return ids;
	}

	getAllEnabledTrackedTarget(){
		var enabledTrackedTargetArray = [];
		this.targetArray.map(function (enabledTrackedTarget) {
			if (enabledTrackedTarget.getEnabled()) {
				enabledTrackedTargetArray.push(enabledTrackedTarget);
			}
		});
		return enabledTrackedTargetArray;
	}

	getAllRenderedAndEnabled() {
		var renderedTargetsList = [];
		this.targetArray.map(function (renderedTarget) {
			if (renderedTarget.getEnabled()) {
				renderedTargetsList.push(renderedTarget.getRenderedElement());
			}
		});
		return renderedTargetsList;
	}

	getPendingTargetIds() {
		var pendingTargetIds = [];
		this.targetArray.map(function (renderedTarget) {
			if (renderedTarget.getTrackingState() === TrackedTargetState.Pending) {
				pendingTargetIds.push(renderedTarget.getId());
			}
		});
		return pendingTargetIds;
	}
}

export { TargetList };
