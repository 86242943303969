module.exports = {
  loginTitle: "Přihlášení",
  password: "Heslo",
  tracking: "Sledování",
  router: "Trasa",
  message: "Zprávy",
  fueling: "Tankovací seznam",
  logout: "Odhlášení",
  email: "E-mail",
  wrongEmailFormat: "Nesprávný formát e-mailovej adresy!",
  forgotYourPassword: "Zapomněli jste heslo?",
  forgotEmailUsernamePair: "Nepovedlo se odeslat e-mail?",
  forgotEmailUsernamePairBody: 'Vážený zákazníku!<br/>Pokud se vám nepodařilo změnit zapomenuté heslo, kontaktujte náš zákaznický servis!<br/><br/>Zákaznický servis:<br/>Po-Št: 7:30-18 :00<br />Po: 8:00-15:30',
  successPasswordResetEmailSend: "Na zadanou e-mailovou adresu jsme vám zaslali odkaz pro změnu hesla!",
  successPasswordChange: "Úspěšně jsme změnili vaše heslo. Nyní se můžete do našeho systému přihlásit pomocí nového hesla.",
  sendPasswordResetButtonText: 'Poslat e-mail!',
  emptyFieldPasswordReset:'Není vyplněno pole uživatelského jména nebo e-mailu!',
  passwordAgain:'Znovu zadat heslo',
  emptyFieldChangePassword:'Vyplňte prosím obě pole!',
  passwordsNotMatch:'Obě zadaná hesla se neshodují!',
  passwordsNotLongEnough:'Hesla musí mít alespoň 8 znaků!',
  passwordsUpperLowerCases:'Hesla musí obsahovat malá i velká písmena!',
  passwordResetTokenAuthenticationFailed: 'Platnost dočasného odkazu pro změnu hesla, který používáte, vypršela! Požádejte o nové heslo a změňte si ho do 15 minut od podání žádosti!',
  changePasswordButtonText:'Změňte heslo',
  passwordChangeServiceNotAvailable:'Obraťte se na náš zákaznický servis',
  passwordResetTitle:'Vyžádat si nové heslo',
  passwordResetDescription: 'Zadejte své uživatelské jméno a e-mailovou adresu!',
  changePasswordTitle:'Změňte heslo',
  changePasswordDescription:'Níže zadejte nové uživatelské jméno a heslo:',
  passwordResetImportantDescription: 'Důležité! O nové heslo můžete požádat pouze zadáním e-mailové adresy přidružené k vašemu uživatelskému účtu iTrack!',
  login: "Přihlášení",
  dontHaveAccount: "Ještě nemáte účet?",
  signUp: "Zaregistrujte se",
  username: "Jméno",
  registrationTitle: 'Registrace',
  back: 'Zpět',
  cars: 'Vozidla',
  selectableCars: 'Voliteľné vozidlá',
  trackedCars: 'Vybraná vozidla',
  allTracking: 'Sledovat vše',
  selectDelete: 'Odebrat vybrané',
  moreInformation: 'Zobrazit více informací',
  openGoogleMaps: 'Zobrazení aktuální polohy vozidla na Google Maps',
  nodata: "Žádné údaje nejsou k dispozici",
  carShare: 'Sdílení vozidel',
  carShareTitle: 'Sdílení vozu s partnerem',
  shareTabTitle: 'Sdílet',
  page: 'Strana',
  access: 'Přístup*',
  startDate: 'Datum od',
  endDate: 'Datum do',
  pleaseWriteEmail: 'Zadejte prosím svou e-mailovou adresu!',
  embed: 'Vložitelné sdílení',
  shareButtonTitle: 'Sdílet',
  closeButtonTitle: 'Zavřít',
  sheSaw: 'Přečteno',
  writeMessage: 'Napište sem svou zprávu!',
  seding: 'Odeslat',
  messages: 'Zprávy',
  searchButtonTitle: 'Hledat',
  fuelingFormTitle: 'Tankování*',
  dateTitle: 'Datum',
  country: 'Země',
  position: 'Pozice',
  start: 'Začátek (L)',
  end: 'Konec (L)',
  volume: 'Množství (L)',
  logInProgress: 'Prebíhá přihlašování...',
  loginFailed: 'Přihlášení selhalo',
  downtime: 'Doba stání',
  event: 'Událost',
  messageSearchPlace: 'Hledat',
  messageSearchText: 'Začněte psát!',
  hu: 'Magyar',
  en: 'English',
  ro: 'Română',
  sr: 'Srpski',
  hr: 'Hrvatski',
  sk: 'Slovenský',
  cz: 'Český',
  maxCarTitle: 'Je možné přiřadit maximální {num} vozidel ',
  today: 'Dnes',
  squel: 'Pokračovat',
  unknownError: 'Neznámá závada',
  systemError: 'Služba je v současné době nedostupná! Kontaktujte prosím zákaznický servis.',
  timeout: 'Spojení bylo ukončeno z časových důvodů. Klikněte na tlačítko níže.',
  networkError: 'Síť není k dispozici. Zkontrolujte připojení.',
  notPositions: 'Následující vozidla zatím nemají souřadnice: ',
  accessExpired: '(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(ENG) You are currently not authorized to access.<br/><br/>(RON) Valabilitatea accesului  a expirat.<br/><br/>(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.<br/><br/>(SVK) Momentálne nemáte oprávnenie na prístup.<br/><br/>(CZE) Momentálně nemáte oprávnění k přístupu.',
  errorLogin: 'Nesprávné uživatelské jméno nebo heslo!',
  emptyFieldLogin: 'Pole pro jméno nebo heslo nejsou vyplněna!',
  carshareDialogText: 'Pole pro začátek nebo konec nejsou vyplněna!',
  notSelectCar: 'Není vybráno žádné vozidlo!',
  messageSearchTextNotFound: 'Žádné výsledky!',
	internetConnectionTerminated: 'Ztráta internetového připojení!',
	emptyFuelList: 'Za těchto podmínek nelze zobrazit seznam tankování.',
	emptyRoutesList: 'V požadovaném období neexistuje žádná platná trasa!',
	speed: 'Rychlost',
	map: 'Mapa',
	selectDate: 'Vybrať datum',
  pleaseSelectEndDate: 'Zvolte datum ukončení!',
	oneSelectMode: 'Jedna trasa',
	multipleSelectMode: 'Více tras',
	pageReload: 'Obnovit stránku',
	registrationBody: 'Vážený potenciální zákazníku!<br/>Pro registraci kontaktujte náš zákaznický servis.<br/><br/>Zákaznicky servis:<br/>Po-Št: 7:30-18:00<br/> Pia: 8:00 – 15:30',
	lostPasswordBody: 'Vážený zákazník!<br/>Pokud jste zapomněli své heslo, kontaktujte náš zákaznický servis.<br/><br/>Zákaznícky servis:<br/>Po-Št: 7:30-18:00<br/ >F : 8:00 - 15:30',
	carShareText1: 'Pomocí této funkce můžete vygenerovat platný odkaz pro vybraná vozidla v zadaném časovém intervalu. Můžete je poslat svým partnerům, kteří je mohou použít ke sledování vozidel. Mimo zadaný časový interval není možné přistupovat k údajům o vozidle prostřednictvím vygenerovaného odkazu.',
	carShareText2: 'Pomocí této funkce můžete vygenerovat detail HTML, který lze vložit do webové stránky a který můžete použít k zobrazení vybraných vozidel na svých webových stránkách v zadaném časovém intervalu.',
	refreshInProgress: 'Údaje o vozidle se již aktualizují',
  newPlutoDialogContent: 'Vážený zákazníku,<p><br>Mobilní klientská aplikace iTrack je k dispozici s obnoveným vzhledem a novými funkcemi (např. přehled trasy a tankování).</p><br><p>Platnost staré verze, kterou aktuálně používáte, vyprší dne 31/06/2024</p><br><p>Proto si prosím vyberte, zda je nová verze mobilního klienta dostupná na platformách, které vám nejvíce vyhovují (Web, Android, iOS), a přejděte na její používání v předdefinovaném čase.</p><br><p>Nové verze mohou být k dispozici na kterémkoli z níže uvedených odkazů:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Telematics</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>S pozdravem,<br><br>tým iData',
  newPlutoRecommendContent: 'Objevte novou generaci iTrack Mobil!<p><br>Pokročilá technologická řešení, užitečné funkce, rychlejší, spolehlivější a stabilnější výkon i přehlednější správa pomáhají zvýšit efektivitu vaší společnosti.</p><br><p>Posuňte ji na vyšší úroveň s novým iTrack Mobil!'
}
