
// React betöltése
var React = require('react');
// -----------------------------------------------------

var Navigation = require('react-router').Navigation;

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

var auth = require('../commons/authenticateToken.js');

// Gépjárműlista betöltése
var CarsList = require('./CarsListRouter.jsx');

// Dátum választó, kilistázott útvonalakkal - betöltés
var Calendar = require('./RouterCalendar.jsx');

// Térkép betöltése
var Map = require('./Map.jsx');

var Notification = require('../mixins/Notification.jsx');
var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

  mixins: [Navigation, Notification, Ajax],

  getInitialState: function() {
    return {
      targetRoute : [],
      fromTime: null,
      toTime: null,
      selectedCarId : null,
      tokenAuth: true,
      visitor: true,
			datepickerRange: null
    };
  },

	getDatepickerRange: function() {
		return this.state.datepickerRange;
	},

	setDatepickerRange: function(value) {
		this.setState( {
			datepickerRange: value
		});
	},

  getRoute : function(){
    var _this = this;

    if( this.state.selectedCarId == null || this.state.fromTime == null || this.state.toTime == null ){
      _this.setState({targetRoute : [], timestamp: new Date().getTime()});
      return false;
    }

    $.ajax({
      url: "@@servletUrl"+"targets/"+ this.state.selectedCarId +"/positions?fromTime="+ this.state.fromTime +"&toTime="+ this.state.toTime,
      type: "GET",
      timeout : 30000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success : function(response){
        if( typeof response !== 'undefined' ){
          response['fromTime'] = _this.state.fromTime;
          _this.setState({targetRoute : response, timestamp: new Date().getTime()});
        }
      },
      error : function(response, status, xhr) {
				_this.ajaxError(response, status, xhr, _this.addNotification, _this.addTranslateNotification, _this.getRoute);
      }
    });
  },

  handleItemSelect : function(item){
    var _this = this;

    switch( item.type ){
      case 'car' : {
        _this.refs.map.deleteRoute(this.state.selectedCarId);
        _this.setState({selectedCarId : item.carId, fromTime : null, toTime : null, targetRoute : [], timestamp: new Date().getTime(), calendarTimestamp : new Date().getTime()+1});
        break;
      }
      case 'calendar' : {
        if( !item.active ){
          this.refs.map.deleteRoute(this.state.selectedCarId, item.fromTime);
          return false;
        }
        _this.setState({fromTime : item.fromTime, toTime : item.toTime});
        break;
      }
    }

    setTimeout(function(){
      _this.getRoute();
    }, 1);
  },

  checkLogin: function() {

    var tokenId = this.props.params.tokenId;

    if(typeof localStorage.getItem('tokenId') == 'undefined') {
      this.transitionTo('shareinformation');
    }

    if(tokenId.length > 0) {
      auth.checkLogin(tokenId, this.redirectToInformationPage);
    }
  },

	redirectToInformationPage: function() {
		return this.transitionTo('shareinformation');
	},

  componentWillMount: function() {
    this.checkLogin();
  },

  render: function() {

    return (
      <main id="routerPage" className="sharePager">
        <CarsList
          handleItemSelect={this.handleItemSelect}
          tokenAuth={this.state.tokenAuth}
					gaSearchLabel="Útvonalak"
					addNotification={this.addNotification}
					addTranslateNotification={this.addTranslateNotification}
        />
        <Calendar
          id="Calendar"
          key={this.state.calendarTimestamp}
          handleItemSelect={this.handleItemSelect}
          carId={this.state.selectedCarId}
          tokenAuth={this.state.tokenAuth}
					addNotification={this.addNotification}
					addTranslateNotification={this.addTranslateNotification}
					getDatepickerRange={this.getDatepickerRange}
					setDatepickerRange={this.setDatepickerRange}
        />
        <div className="mapContainer" >
          <Map
            key={this.state.timestamp}
            targetRoute={this.state.targetRoute}
            ref="map"
            tokenAuth={this.state.tokenAuth}
						addNotification={this.addNotification}
						addTranslateNotification={this.addTranslateNotification}
          />
        </div>

				{this.rednderNotification()}
      </main>
    )
  }
});
