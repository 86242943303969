// React betöltése
var React = require('react');
// -----------------------------------------------------
var $ = require('jquery');
var NotificationSystem = require('react-notification-system');

var style = require('../plugin/NotificationStyle.js');


module.exports = {

	notification: null,
	notificationSystem: null,
	autoDismiss: 10,

	addNotification: function (title, level, action) {
		this.initNotification(title, level, action);
	},

	addTranslateNotification: function (title, level, action) {
		var translateTitle = this.getValue(title);
		this.initNotification(translateTitle, level, action);
	},

	initNotification: function (title, level, action) {
		var autoDismiss = this.autoDismiss;
		var _this = this;
		setTimeout(function () {
			var notificationsByLevelAndTitle = $(".notification-" + level + ":contains(" + title + ")");
			if (notificationsByLevelAndTitle != null && notificationsByLevelAndTitle.length != 0) {
				return;
			}

			if (level == 'error') {
				autoDismiss = 0;

				if (_this.notification != null) {
					_this.notificationSystem.removeNotification(_this.notification);
					_this.notification = null;
				}
			}

			var notification = _this.notificationSystem.addNotification({
				title: title,
				level: level,
				autoDismiss: autoDismiss,
				action: action
			});

			_this.notification = notification;
			if (level == 'error') {
				_this.setState({ state: _this.state });
			}

		}, 100);
	},

	componentDidMount: function () {
		this.notificationSystem = this.refs.notificationSystem;
	},

	rednderNotification: function () {

		return (
			<NotificationSystem ref="notificationSystem" style={style} />
		);
	}
};