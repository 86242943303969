

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Oldalsó menüsáv betöltése
var SideMenu = require('./SideMenu.jsx');

// Üzenet lista betöltése
var MessageList = require('./MessagesList.jsx');

// Üzenet részletes nézet betöltése
var MessageDetails = require('./MessagesDetails.jsx');

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Mobil hasábválasztó
var MobilTabs = require('./MessagesMobilHeader.jsx');

// Login csekkolás
var Authentication = require('../mixins/Authentication.js');
var Router = require('react-router');
var RouteHandler = Router.RouteHandler;
var Navigation = Router.Navigation;

var Notification = require('../mixins/Notification.jsx');

module.exports = React.createClass({
  mixins: [Navigation, Authentication, Languages, Notification],

  getInitialState: function () {
    return {
      threadId: null,
      detailsKey: Date.now(),
      selectedThread: null,
      limit: null,
      isLoadList: false
    };
  },

  setIsLoadList: function (load) {
    this.setState({
      isLoadList: load
    });
  },

  listItemClick: function (selectedThread, limit) {
    //összeszedjük a kezdeti üzeneteket
    this.setState({
      selectedThread: selectedThread,
      limit: limit,
      detailsKey: Date.now(),
      mobilHeaderTitle: selectedThread.threadUser.name
    });

    $('#trackingTabs .backButton').removeClass('hidden');
    $('#trackingTabs .title').removeClass('col-xs-offset-4');
  },

  componentWillMount: function () {
    this.setState({
      mobilHeaderTitle: this.getValue('message')
    });
  },

  newMessageAdded: function (items) {
    this.refs.messageList.updateMessages(items);
  },

  removeMessageUnread: function (threadId) {
    this.refs.messageList.removeUnread(threadId);
  },

  setUnreadCount: function (count) {
    this.refs.sidemenu.setUnreadCount(count);
  },

  changeMobilHeaderTitle: function (name) {
    this.setState({
      mobilHeaderTitle: name
    });
  },

  render: function () {

    if (this.state.isLoadList == true) {
      var loadElement = (
        <div className='loaderMessage'><span></span></div>
      );
    } else if (this.state.isLoadList == false) {
      var loadElement = '';
    }

    return (
      <main id="MessagesPage">
        <MobileHeader mobilMenu={this.refs.left} mobilMenuName={this.getValue('message')} />
        <MobilTabs lang={this.state.lang} title={this.state.mobilHeaderTitle} changeMobilHeaderTitle={this.changeMobilHeaderTitle} />
        <MobilMenu ref="left" alignment="left" lang={this.state.lang} activeMenu="tracking" />
        <SideMenu
          ref="sidemenu"
          lang={this.state.lang}
          activeMenu="message"
          getValue={this.getValue}
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
          notification={this.notification}
          notificationSystem={this.notificationSystem}
          visitor={false}
        />

        <MessageList
          ref="messageList"
          listItemClick={this.listItemClick}
          lang={this.state.lang}
          selectThreadId={this.props.params.id}
          setIsLoadList={this.setIsLoadList}
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
        />
        <MessageDetails
          setUnreadCount={this.setUnreadCount}
          newMessageAdded={this.newMessageAdded}
          limit={this.state.limit}
          key={this.state.detailsKey}
          thread={this.state.selectedThread}
          removeMessageUnread={this.removeMessageUnread}
          lang={this.state.lang}
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
        />
        {loadElement}

        {this.rednderNotification()}
      </main>
    );
  }
});
