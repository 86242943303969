class TrackingMode {

	constructor(name) {
		this.name = name;
	}

    toString() {
        return `TrackingMode.${this.name}`;
    }
}

TrackingMode.Normal = new TrackingMode('Normal');
TrackingMode.Share = new TrackingMode('Share');
TrackingMode.Iframe = new TrackingMode('Iframe');

export { TrackingMode };