// Menüpontok listája

module.exports = {

  // Bejelentkezés útáni menüpontokat tartalmazza
  private: [
    {
      name: 'tracking',
      icon: 'icon-track',
      url:  'tracking',
			ga: 	'Nyomkövetés'
    },
    {
      name: 'router',
      icon: 'icon-router',
      url:  'router',
			ga: 	'Útvonalak'
    },
    {
      name: 'message',
      icon: 'icon-message',
      url:  'messages',
			ga: 	'Üzenetek'
    },
    {
      name: 'fueling',
      icon: 'icon-fuel',
      url:  'fueling',
			ga: 	'Tankolási lista'
    },
    {
      name: 'logout',
      icon: 'icon-logout',
      url:  'logout',
			ga: 	'Kijelentkezés'
    }
  ]
}
