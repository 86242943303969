

// React betöltése
var React = require('react');
// -----------------------------------------------------

var Router = require('react-router');
var Navigation = Router.Navigation;

// Bootstrap modulok betöltése
var OverlayTrigger = require('react-bootstrap/lib/OverlayTrigger');
var Tooltip = require('react-bootstrap/lib/Tooltip');
// -----------------------------------------------------

// Menülista betöltése
var menus = require('../i18n/menus.js');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

var AjaxForSideMenu = require('../mixins/AjaxForSideMenu.jsx');

import LastPath from '../model/LastPath';
import ServerMaintenance from '../model/ServerMaintenance';
import { Utils } from '../model/Utils';

module.exports = React.createClass({

  mixins: [Navigation, AjaxForSideMenu, Brands],

  getDefaultProps: function () {
    return {
      timeoutTime: 0,
      stopLongPoll: false,
      longpollTimeout: null,
      messageLongpolling: null
    }
  },

  getInitialState: function () {
    return {
      lang: this.props.lang,
      activeMenu: this.props.activeMenu,
      unreadCount: 0,
      stopLongPolling: false
    };
  },

  componentWillMount: function () {
    if (window.location.href.indexOf('messages') === -1) {
      this.getActItems();
    }
  },

  componentDidMount: function () {
    document.addEventListener("pause", this.stopMessageLongPolling, false);
    document.addEventListener("resume", this.restartMessageLongPolling, false);
  },

  abortPreviousAjaxCalls: function () {
    for (var x in $.messageAjax) {
      if ($.messageAjax[x] != null) {
        $.messageAjax[x].abort();
      }
    }
    $.messageAjax = [];
  },

  stopMessageLongPolling: function () {
    this.abortPreviousAjaxCalls();
    if (this.props.messageLongpolling != null) {
      this.props.messageLongpolling.abort();
    }

    this.setState({
      stopLongPolling: true
    });
  },

  restartMessageLongPolling: function () {
    var _this = this;

    this.setState({
      stopLongPolling: false
    }, function () {
      _this.getActItems();
    });
  },

  componentWillUnmount: function () {
    this.abortPreviousAjaxCalls();
    this.props.stopLongPoll = true;
    if (this.props.longpollTimeout === null) { return false; }
    clearTimeout(this.props.longpollTimeout);
  },

  setUnreadCount: function (count) {
    if (typeof this.refs.sidemenu === 'undefined') { return false; }
    this.setState({ unreadCount: count || 0 });
  },

  //longpollingos lekérés
  getActItems: function () {
    var _this = this;

    if (this.props.visitor) {
      return;
    }

    if (this.state.stopLongPolling) {
      return;
    }

    if (window.location.href.indexOf('messages') > -1) {
      _this.props.longpollTimeout = setTimeout(function () {
        _this.getActItems();
      }, 10000);
      return false;
    }

    var latestTimeMicrosLocalStorage = localStorage.getItem('latestTimeMicros');
    var latestTimeMicrosLocalStorageInvalid = latestTimeMicrosLocalStorage == undefined || latestTimeMicrosLocalStorage == null || latestTimeMicrosLocalStorage.length == 0;
    var latestTimeMicros;
    if (latestTimeMicrosLocalStorageInvalid) {
      latestTimeMicros = -1;
    } else {
      latestTimeMicros = latestTimeMicrosLocalStorage;
    }

    var messageLongpolling = $.ajax({
      url: "@@servletUrl" + "messages/tracking?fromTime=" + latestTimeMicros + "&asynchronous=true",
      type: "GET",
      timeout: 60000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function (response, status, xhr) {

        if (_this.props.notification != null && _this.props.notification.level == "error" && _this.props.notificationSystem != null) {
          if (ServerMaintenance.serverMaintenance == true) {
            _this.props.notificationSystem.removeNotification(_this.props.notification);
            ServerMaintenance.serverMaintenance = false;
          }
        }

        var items = _this.state.items;
        var count = 0;

        if (xhr.status == 200 && typeof response.messages !== 'undefined' && response.messages.length > 0) {
          //latestTimeMicros = response.messages[ response.messages.length - 1 ]['sentStatus']['timeMicros'];

          for (var i in response.messages) {
            if (response.messages[i]['type'] !== 'received') { continue; }
            if (response.messages[i]['readStatus']['name'] === 'unread') { count++; }

          }
        }

        _this.setState({ unreadCount: count });

        if (_this.props.stopLongPoll) { return false; }

        if (typeof response.serverTimeMicros != 'undefined') {
          localStorage.setItem('latestTimeMicros', response.serverTimeMicros);
        }

        _this.getActItems();
      },
      error: function (response, status, xhr) {
        if (_this.props.stopLongPoll) { return false; }

        if (response.statusText != 'timeout' && response.statusText != 'error' && status != 0) {
          _this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, null, _this.props.getValue);
        }

        if (_this.props.timeoutTime == 0) {
          _this.props.timeoutTime = 5000;
        } else {
          _this.props.timeoutTime *= 2;
          if (_this.props.timeoutTime > (5 * 60 * 1000)) {
            _this.props.timeoutTime = (5 * 60 * 1000);
          }
        }

        if (typeof response !== 'undefined' && status >= 500) {
          _this.props.longpollTimeout = setTimeout(function () {
            _this.getActItems();
          }, _this.props.timeoutTime);
          return false;
        }

        if (response.statusText == 'timeout') {
          _this.props.longpollTimeout = setTimeout(function () {
            _this.getActItems();
          }, _this.props.timeoutTime);
          return false;
        }

        if (typeof _this.props.timeoutTime != 'undefined') {
          _this.props.longpollTimeout = setTimeout(function () {
            _this.getActItems();
          }, _this.props.timeoutTime);
          return false;
        }
      }
    });

    this.props.messageLongpolling = messageLongpolling;
    $.messageAjax.push(messageLongpolling);

  },

  navigation: function (url, label) {

    if (LastPath.lastPath != url && (url == 'tracking' || url == 'router' || url == 'fueling')) {
      $('#overlay').addClass('overlay');
      $('#loader').addClass('loader');
      $('#exitButton').addClass('exitButton icon-cancel');

      setTimeout(function () {
        this.transitionTo(url);
      }.bind(this), 500);
    }
    else {
      this.transitionTo(url);
    }
  },

  render: function () {
    var _this = this;
    // A menü listát tárolom le benne
    var menusElement = [];

    if (!this.props.visitor) {
      // Nyelvi elemek eltárolása
      var langItems = this.state.lang;

      // Active menüpont nevének az eltárolása
      var activeMenu = this.state.activeMenu;

      // Végigmegyek az összes private menüponton
      menus.private.forEach(function (menu) {
        var active = '';

        if (menu.name == activeMenu) {
          active = 'active'
        }

        var count = '';

        if (_this.state.unreadCount > 0 && menu.name === 'message') {
          count = (<span className="unreadCount" >{_this.state.unreadCount}</span>);
        }


        // Push-olom a menüpontokat a menusElement tömbbe
        menusElement.push(
          <li id={menu.name}>
            <OverlayTrigger placement="right" overlay={<Tooltip>{langItems[menu.name]}</Tooltip>} delayShow={300} delayHide={150}>
              <a className={active} onClick={_this.navigation.bind(_this, menu.url, menu.ga)}>
                <i className={menu.icon}></i>
                {count}
              </a>
            </OverlayTrigger>
          </li>
        );
      });
    }

    let brand = this.getBrand();
    let logoResource = this.getResourceItemValue('smallLogo');
    let logoElement = Utils.getClickableLogoForResource(brand, logoResource);
    return (
      <nav className="smallMenu">
        <ul>
          <li>
            {logoElement}
          </li>
          {menusElement}
        </ul>
      </nav>
    )
  }
});
