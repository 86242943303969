// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// import { TrackedTarget } from '../mixins/TrackedTarget';
import { DefaultTarget } from '../model/DefaultTarget';

class TargetTrackingSelectableListElement extends React.Component {

    constructor(props) {
        super(props);
        this.handleCarsShareButtonElement = this.handleCarsShareButtonElement.bind(this);
    }

    render() {
        var target = this.props.target;
        return (
            <div className="carsElement" data-id={target.getId()}>
                <h2 data-select="false" onClick={this.props.onSelectFunction(target)}>
                    <i></i>
                    <span className="licenseplate">{target.getLicenseplate()}</span>
                    <span className="description">{target.getDescription()}</span>
                </h2>
                {this.addCarShareButton()}
            </div>
        );
    }

    handleCarsShareButtonElement() {
        this.props.handleCarShareButton(this.props.target.getId());
    }

    addCarShareButton() {
        if(!this.props.visitor){
            return (
                <button className="icon-share btn btn-primary"
                    title={this.props.getLangValue('carShare')}
                    onClick={this.handleCarsShareButtonElement}></button>
            );
        }
    }

};

TargetTrackingSelectableListElement.defaultProps = {
    target: new DefaultTarget(),
    onSelectFunction: function(){}

};

module.exports = TargetTrackingSelectableListElement;
