var React = require('react');
var Leaflet = require('leaflet');
var LeafletTextpath = require('leaflet-textpath');
var LeafletMarkerCluster = require('leaflet.markercluster');
var LeafletDecorator = require('leaflet-polylinedecorator');

var moment = require('moment');
var $ = require('jquery');
var _ = require("lodash");

import { TargetList } from '../model/TargetList';
import { TrackedTargetState } from '../model/TrackedTargetState';
import { PopupTargetRefresh } from '../remote/PopupTargetRefresh';

class TargetTrackingMap extends React.Component {

    constructor(props) {
        super(props);
        this.map = null;
        this.autoZoomEnabled = true;
        this.markerCluster = null;
        this.hackTargetIds = [
            //4791 - Era Transport - RudiPhonex Szerbia
            40006,
            40027,
            40424,
            41498,
            44087,
            46745,
            46750,
            48085,
            48936,
            51409,
            54188,
            56534,
            60332,
            60374,
            63517,
            65735,
            68026,
            68027,
            71182,
            75053,
            //3942 - Schábel Kft. - iData
            37778,
            46725,
            48808,
            50279,
            65756,
            //1882 - DHP Trans Kft. - iData
            19659,
            24477,
            28095,
            32674,
            34878,
            35490,
            38428,
            43823,
            64058,
            70628,
            72051,
            //6970 - EHL transport - RudiPhonex Szerbia
            16063,
            60681,
            60710,
            61396,
            61683,
            61736,
            61737,
            63889,
            64025,
            64691,
            65008,
            65061,
            65274,
            65456,
            65460,
            65541,
            66220,
            66689,
            66695,
            67167,
            67351,
            67747,
            68319,
            68432,
            68436,
            68466,
            68518,
            69101,
            69103,
            69108,
            69109,
            71530,
            72105,
            72108,
            72109,
            73124,
            73133,
            // Dunis1, Duniskonteneri
            29920,
            31753,
            31754,
            31755,
            31756,
            31758,
            31759,
            31760,
            31763,
            31766,
            31767,
            31768,
            31769,
            31770,
            31771,
            31772,
            31776,
            31781,
            31782,
            31784,
            31789,
            31795,
            31797,
            31798,
            31799,
            31801,
            32304,
            32309,
            32312,
            32313,
            32314,
            32316,
            32317,
            32348,
            32350,
            32403,
            32427,
            32428,
            32430,
            32431,
            32433,
            32435,
            32438,
            32453,
            32455,
            32456,
            32476,
            32477,
            32478,
            32479,
            32480,
            32511,
            32516,
            32518,
            32521,
            32522,
            32529,
            32620,
            32685,
            32686,
            32687,
            32840,
            32841,
            32842,
            32847,
            32879,
            32880,
            32886,
            32899,
            33287,
            33311,
            33312,
            33314,
            33634,
            33638,
            33640,
            34452,
            34562,
            34843,
            34844,
            34845,
            34955,
            35310,
            35340,
            35410,
            35494,
            35630,
            35643,
            35671,
            35690,
            35883,
            36016,
            36025,
            36271,
            36272,
            36273,
            36431,
            36916,
            37133,
            38049,
            38313,
            38315,
            38742,
            38758,
            38760,
            38762,
            38763,
            38766,
            38768,
            38780,
            39000,
            39001,
            39045,
            39536,
            39997,
            39998,
            39999,
            40005,
            40194,
            40328,
            40329,
            41747,
            42269,
            42270,
            42271,
            42272,
            42339,
            42574,
            42576,
            42577,
            42579,
            42607,
            42609,
            42610,
            42613,
            42615,
            42732,
            42735,
            42737,
            42739,
            42741,
            42786,
            44068,
            44154,
            46099,
            46322,
            46327,
            47591,
            47612,
            47613,
            47614,
            47616,
            47621,
            49358,
            49372,
            50879,
            50990,
            50991,
            54609,
            55553,
            55837,
            55861,
            55864,
            55943,
            56196,
            56249,
            57568,
            60370,
            60371,
            63892,
            64028,
            64032,
            70593,
            70603,
            70611,
            70693,
            70719,
            70723,
            71467,
            71667,
            71668,
            71671,
            71674,
            72442,
            72454,
            72468,
            72470,
            72471,
            72587,
            72588,
            72589,
            72590,
            72591,
            72592,
            72593,
            72622,
            73298,
            77821,
            77911,
            // Euro merkur
            77447,
            60454,
            49805,
            47615,
            47609,
            46913,
            46909,
            46618,
            46615,
            40440,
            40438,
            40434,
            40416,
            40415,
            36896,
            36893,
            36891,
            36880,
            36436,
            36435,
            36432,
            36426,
            36024,
            34451,
            33208,
            32904,
            32903,
            32902,
            32901,
            32900,
            32898,
            32897,
            32895,
            32894,
            32893,
            32892,
            32891,
            32890,
            32888,
            32887,
            32885,
            32884,
            32883,
            32530,
            32519,
            32514,
            32505,
            32402,
            31794,
            31786,
            31779,
            31778,
            // Vibor
            77678,
            73465,
            73322,
            66768,
            66767,
            64141,
            58932,
            58900,
            57135,
            54105,
            50666,
            45610,
            45074,
            43460,
            43458,
            43454,
            43453,
            43154
        ];
        this.totalFuelMeasureChannelNames = [
            "Total Nivo Goriva [litar]",
            "Total Nivo Goriva [liter]",
            "Összesített üzemanyagszint [liter]"
        ];
        this.rightOrLeftFuelMeasureChannelNames = [
            "Nivo Goriva 1 [liter]",
            "Nivo Goriva 1 [l]",
            "Nivo Goriva 2 [liter]",
            "Nivo Goriva 2 [l]",
            "Nivo Goriva 2 [litar]",
            "Jobb tank üzemanyagszint [liter]",
            "Jobbtank üzemanyagszint [liter]",
            "Bal tank üzemanyagszint [liter]",
            "Baltank üzemanyagszint [liter]",
            "Baltank üzemanyagszint  [liter]"
        ];
        this.popupTargetRefresh = new PopupTargetRefresh(this.props.updateLiveDataFunction,
            this.props.visitor,
            this.props.notificationVersions,
            this.props.getLangValue);
        this.updateTargetOnPopupOpen = this.updateTargetOnPopupOpen.bind(this);
        this.popupRefreshClicked = this.popupRefreshClicked.bind(this);
        this.toggleDate = this.toggleDate.bind(this);
        this.appendMeasureData = this.appendMeasureData.bind(this);
        this.showDateByTarget = new Object();
        this.state = {
            markersByTarget: new Object()
        }
    }

    componentDidMount() {
        this.leafletInit();
        $('#map').on('click', '.icon-spinner', this.popupRefreshClicked);
        $('#map').on('click', '.icon-clock', this.toggleDate);
    }

    isTargetUpdated(oldTrackedTargetList, newTrackedTargetList) {
        for (var i = 0; i < newTrackedTargetList.length; i++) {
            if (!_.isEqual(oldTrackedTargetList[i], newTrackedTargetList[i])) {
                return false;
            }
        }
        return true;
    }

    /**
     * A komponenst csak akkor frissítjük, ha
     * - változott a nyomkövetettek listájának hossza
     * - van olyan target amelynek frissültek az adatai
     * - nézetváltás történt
     * egyébként nem frissítünk
     */
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.trackedTargetList.length() != nextProps.trackedTargetList.length()) {
            return true;
        }
        else if (!this.isTargetUpdated(this.props.trackedTargetList.getAll(), nextProps.trackedTargetList.getAll())) {
            return true;
        }
        else if (this.props.onSmallScreen != nextProps.onSmallScreen) {
            return true;
        }
        else {
            return false;
        }
    }

    componentWillReceiveProps(newProps) {
        var prevTrackedTargetList = this.props.trackedTargetList;
        var newTrackedTargetList = newProps.trackedTargetList;

        var targetArrayToRemove = prevTrackedTargetList.difference(newTrackedTargetList);
        var targetArrayToUpdate = newTrackedTargetList.getAll();

        var _this = this;
        targetArrayToUpdate.map(function(targetToUpdate) {
            var isNoDataTarget = targetToUpdate.getTrackingState() == TrackedTargetState.NoData;
            var targetId = targetToUpdate.getId();
            var marker = _this.state.markersByTarget[targetId];
            var markerExists = _this.isMarkerExists(marker);
            if (isNoDataTarget && markerExists) {
                targetArrayToRemove.push(targetToUpdate);
            }
        });

        if (targetArrayToRemove.length > 0) {
            this.setState({
                markersByTarget: this.removeMarkerFromMap(targetArrayToRemove)
            }, function () {
                var markersLatlng = this.getMarkersLatLng();
                if (markersLatlng.length > 1) {
                    this.setMapBounds(markersLatlng);
                }
                else if (markersLatlng.length == 1) {
                    this.setMapView(markersLatlng);
                }
            })
        }

        if (targetArrayToUpdate.length > 0) {
            var arrayOfNewTargets = [];
            var arrayOfUpdateableTargets = [];
            var markers = this.state.markersByTarget;
            targetArrayToUpdate.map(function (targetToUpdate) {
                var liveData = targetToUpdate.liveData;
                if (liveData != null && liveData.currentPosition != null) {
                    var targetId = targetToUpdate.getId();
                    var markerToUpdate = markers[targetId];
                    if (this.isMarkerExists(markerToUpdate)) {
                        arrayOfUpdateableTargets.push(targetToUpdate);
                    }
                    else {
                        arrayOfNewTargets.push(targetToUpdate)
                    }
                }
            }.bind(this))

            if (arrayOfNewTargets.length > 0) {
                this.setState({
                    markersByTarget: this.addMarkersToMap(arrayOfNewTargets)
                }, function () {
                    var markersLatlng = this.getMarkersLatLng();
                    if (markersLatlng.length > 1) {
                        this.setMapBounds(markersLatlng);
                    }
                    else if (markersLatlng.length == 1) {
                        this.setMapView(markersLatlng);
                    }
                }.bind(this))
            }

            if (arrayOfUpdateableTargets.length > 0) {
                this.setState({
                    markersByTarget: this.updateMarkersOnMap(arrayOfUpdateableTargets)
                }, function () {
                    if (this.autoZoomEnabled) {
                        var markersLatlng = this.getMarkersLatLng();
                        if (markersLatlng.length > 1) {
                            this.setMapBounds(markersLatlng);
                        }
                        else if (markersLatlng.length == 1) {
                            this.setMapViewWhenUpdate(markersLatlng);
                        }
                    }
                }.bind(this))
            }
        }

        if (this.props.onSmallScreen != newProps.onSmallScreen) {
            this.setState({ state: this.state }, function () {
                var markersLatlng = this.getMarkersLatLng();
                if (markersLatlng.length > 1) {
                    this.setMapBounds(markersLatlng);
                }
                else if (markersLatlng.length == 1) {
                    this.setMapViewWhenUpdate(markersLatlng);
                }
            }.bind(this))
        }
    }

    leafletInit() {
        var protocol = document.location.protocol;

        if (protocol == 'file:') {
            protocol = 'https:';
        }

        var mapCenter = L.latLng(47.4980556, 19.04);
        var zoom = 12;

        L.Icon.Default.imagePath = '../../images/leaflet';

        this.map = L.map('map', { zoomAnimation: true, zoomControl: false, minZoom: 3 })
            .setView(mapCenter, zoom)
            .fire('zoomend');

        //hozzáadjuk az alap térkép layert
        L.tileLayer(protocol + '//' + '@@osm' + '{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        L.control.zoom({ position: 'bottomright' }).addTo(this.map);

        //Ha a felhasználó mozgatja a térképet, és van legalább 1 nyomkövetett target
        //akkor kikapcsoljuk az autozoom funkciót
        this.map.on('dragstart', function () {
            var markersByTargetLength = Object.keys(this.state.markersByTarget).length;
            if (markersByTargetLength >= 1) {
                this.disableAutoZoom();
            }
        }.bind(this));

        //Zoomolás végeztével, ha több mint 1 targetet követünk nyomon és nincs mind a nézetben
        //akkor kikapcsoljuk az autozoom funkciót
        this.map.on('zoomend', function () {
            var markersByTargetLength = Object.keys(this.state.markersByTarget).length;
            if (markersByTargetLength > 1) {
                var markersInBound = this.isAllMarkerInBound();
                if (!markersInBound) {
                    this.disableAutoZoom();
                }
            }
        }.bind(this));

        //Gombnyomásra bekapcsoljuk az autozoom funkciót és pozicionáljuk a térképet
        //hogy minden target a nézetben legyen
        $('button#autozoom').off('click').on('click', function () {
            this.enableAutoZoom();
            var markersLatlng = this.getMarkersLatLng();
            if (markersLatlng.length > 1) {
                this.setMapBounds(markersLatlng);
            }
            else if (markersLatlng.length == 1) {
                this.setMapView(markersLatlng);
            }
        }.bind(this));

        var markerClusterGroup = L.markerClusterGroup({
            showCoverageOnHover: false,
            disableClusteringAtZoom: 17,
            spiderfyOnMaxZoom: false,
            zoomToBoundsOnClick: true
        });

        this.markerCluster = markerClusterGroup;

        this.map.on('popupopen', function (e) {
            var _this = this;
            Object.keys(this.state.markersByTarget).map(function (id) {
                var marker = _this.state.markersByTarget[id];
                if (marker != undefined && marker.isPopupOpen()) {
                    _this.props.updatePopupTargetId(Number(id));
                }
            });
        }.bind(this));

        this.map.on('popupclose', function (e) {
            this.props.updatePopupTargetId(null);
        }.bind(this));
    }

    addMarkersToMap(arrayOfNewTargets) {
        var markers = this.state.markersByTarget;
        arrayOfNewTargets.map(function (targetToAdd) {
            var liveData = targetToAdd.liveData;
            if (liveData != null && liveData.currentPosition != null) {
                var uvxEnabled = liveData.uvxEnabled;
                var currentPosition = liveData.currentPosition;
                var targetId = targetToAdd.getId();
                var markerToAdd = markers[targetId];
                markers[targetId] = this.addMarkerToMap(targetToAdd, currentPosition, uvxEnabled);
                this.markerCluster.addLayer(markers[targetId])
                this.map.addLayer(this.markerCluster);
            }
        }.bind(this))
        return markers;
    }

    addMarkerToMap(target, currentPosition, uvxEnabled) {
        var color = currentPosition.color;
        var latitude = currentPosition.latitude;
        var longitude = currentPosition.longitude;
        var icon = this.createMarkerIcon(color);
        var showMeasureDates = false;
        var popupContent = this.getInfoWindowContent(target, currentPosition, uvxEnabled, showMeasureDates);
        var targetId = target.getId();
        var point = new L.Point(latitude, longitude + 10)

        var marker = new L.Marker(L.latLng(latitude, longitude), { icon: icon })
            .bindPopup(popupContent);
        var licenseplate = target.defaultTarget.getLicenseplate();
        var description = target.defaultTarget.getDescription();
        var tooltip = description === "" ? licenseplate : licenseplate + " (" + description + ")";
        marker.bindTooltip(tooltip, { permanent: true, direction: 'auto' });

        //Itt sajnos le kellett váltanom a model frissítés és az alapján a buborék megnyitása megoldást
        //Így rábízom a leaflet-re a buborékok megnyitását és zárását marker klikk esetén (valószínúleg leaflet-bug miatt)
        marker.off('click').on('click', function (e) {
            e.target.closePopup();
            setTimeout(function () {
                this.analyticsEvent();
                e.target.openPopup();
                this.updateTargetOnPopupOpen(e.target);
            }.bind(this), 50);
        }.bind(this));

        marker.off('dblclick').on('dblclick', function (e) {
            this.map.setView(e.target.getLatLng(), 18);
            e.target.closePopup();
            this.disableAutoZoom();
        }.bind(this));

        return marker;
    }

    updateMarkersOnMap(arrayOfUpdateableTargets) {
        var markers = this.state.markersByTarget;
        arrayOfUpdateableTargets.map(function (targetToUpdate) {
            var liveData = targetToUpdate.liveData;
            if (liveData != null && liveData.currentPosition != null) {
                var uvxEnabled = liveData.uvxEnabled;
                var currentPosition = liveData.currentPosition;
                var targetId = targetToUpdate.getId();
                var markerToUpdate = markers[targetId];
                markers[targetId] = this.updateMarkerOnMap(targetToUpdate, markerToUpdate, currentPosition, uvxEnabled);
            }
        }.bind(this))
        return markers;
    }

    updateMarkerOnMap(target, marker, currentPosition, uvxEnabled) {
        var newIcon = this.createMarkerIcon(currentPosition.color);
        var targetId = target.defaultTarget.id;
        var showDatesForTarget = this.showDateByTarget[targetId];
        var showMeasureDates;
        if (showDatesForTarget == true) {
            showMeasureDates = true;
        } else {
            showMeasureDates = false;
        }
        var popupContent = this.getInfoWindowContent(target, currentPosition, uvxEnabled, showMeasureDates);
        marker.setLatLng(L.latLng(currentPosition.latitude, currentPosition.longitude));
        marker.setIcon(newIcon);
        marker.bindPopup(popupContent);
        var popupRefreshInProgress = this.popupTargetRefresh.isTargetPopupRefreshInProgress(target.getId())
        if (popupRefreshInProgress) {
            this.popupTargetRefresh.reAddDataLoaderToPopup();
        }
        return marker;
    }

    removeMarkerFromMap(targetArrayToRemove) {
        var markers = this.state.markersByTarget;
        targetArrayToRemove.map(function (targetToRemove) {
            var targetId = targetToRemove.getId();
            var markerToRemove = markers[targetId];
            if (this.isMarkerExists(markerToRemove)) {
                this.markerCluster.removeLayer(markerToRemove)
                this.map.removeLayer(markerToRemove);
                delete markers[targetId];
            }
        }.bind(this), this)
        return markers;
    }

    toggleInfoPopup(targetId) {
        this.toCenterWithMarker(targetId);
    }

    toCenterWithMarker(targetId) {
        var marker = this.state.markersByTarget[targetId];
        if (this.isMarkerExists(marker)) {
            this.analyticsEvent();
            this.map.setView(marker.getLatLng(), 20);
            if (marker.isPopupOpen()) {
                marker.closePopup();
            }
            else {
                this.disableAutoZoom();
                marker.openPopup();
                this.updateTargetOnPopupOpen(marker);
            }
            this.setState({
                markersByTarget: this.state.markersByTarget
            })
        }
    }

    isAllMarkerInBound() {
        var isAllMarkerInBound = true;
        Object.keys(this.state.markersByTarget).map(function (id) {
            var marker = this.state.markersByTarget[id];
            var markerInBound = this.map.getBounds().contains(marker.getLatLng());
            if (!markerInBound) {
                isAllMarkerInBound = false;
                return isAllMarkerInBound;
            }
        }.bind(this), this)
        return isAllMarkerInBound;
    }

    getMarkersLatLng() {
        var markersLatlng = [];
        Object.keys(this.state.markersByTarget).map(function (id) {
            var marker = this.state.markersByTarget[id];
            markersLatlng.push(marker.getLatLng());
        }.bind(this), this)
        return markersLatlng;
    }

    setMapBounds(markersLatlng) {
        this.map.fitBounds(new L.LatLngBounds(markersLatlng), {
            padding: [10, 10]
        });
    }

    setMapViewWhenUpdate(markersLatlng) {
        var mapZoomLevel = this.map.getZoom();
        this.map.setView(markersLatlng[0], mapZoomLevel);
    }

    setMapView(markersLatlng) {
        this.map.setView(markersLatlng[0], 14);
    }

    isMarkerExists(marker) {
        if (typeof marker === 'undefined') {
            return false;
        }
        return true;
    }

    enableAutoZoom() {
        this.autoZoomEnabled = true;
        $('button#autozoom').hide();
    }
    disableAutoZoom() {
        this.autoZoomEnabled = false;
        $('button#autozoom').show();
    }

    createMarkerIcon(color) {
        var icon = new L.divIcon({
            className: 'custom-marker',
            html: '<div><div style="background-color: ' + color + ';" ></div></div>',
            iconSize: L.point(20, 20),
            iconAnchor: null
        });
        return icon;
    }

    updateTargetOnPopupOpen(selectedMarker) {
        var _this = this;
        Object.keys(this.state.markersByTarget).map(function (id) {
            var marker = _this.state.markersByTarget[id];
            if (selectedMarker === marker) {
                var targetId = Number(id);
                var trackedTarget = _this.props.trackedTargetList.getTarget(targetId);
                _this.popupTargetRefresh.updateTargetData(targetId, trackedTarget.getFromTimeMicros());
            }
        });
    }

    popupRefreshClicked() {
        var _this = this;
        Object.keys(this.state.markersByTarget).map(function (id) {
            var marker = _this.state.markersByTarget[id];
            if (marker.isPopupOpen()) {
                _this.updateTargetOnPopupOpen(marker);
            }
        });
    }

    toggleDate() {
        var _this = this;
        Object.keys(this.state.markersByTarget).map(function (id) {
            var marker = _this.state.markersByTarget[id];
            if (marker.isPopupOpen()) {
                var targetId = Number(id);
                var showDateForTarget = _this.showDateByTarget[targetId];
                var trackedTarget = _this.props.trackedTargetList.getTarget(targetId);
                var liveData = trackedTarget.getLiveData();
                var currentPosition = liveData.currentPosition;
                var uvxEnabled = liveData.uvxEnabled;
                var showMeasureDates;
                if (showDateForTarget == true) {
                    showMeasureDates = false;
                } else {
                    showMeasureDates = true;
                }
                var popupContent = _this.getInfoWindowContent(trackedTarget, currentPosition, uvxEnabled, showMeasureDates);
                marker.bindPopup(popupContent);
                _this.showDateByTarget[targetId] = showMeasureDates;
            }
        });
    }

    getInfoWindowContent(target, position, uvxEnabled, showMeasureDates) {
        var _this = this;
        var date = moment(position.timeMicros / 1000).format("L HH:mm:ss");

        var $div = $('<div class="popupContent"></div>');

        $div
            .append('<button class="icon-spinner"></button>')
            .append('<button class="icon-clock"></button>')
            .append('<span class="loaderTrackingCar"></span>')
            .append('<strong>' + target.defaultTarget.licenseplate + '</strong><br>')
            .append('<strong>' + target.defaultTarget.description + '</strong><br>')
            .append('<strong>' + date + '</strong><br><br>')
            .append(this.props.getLangValue('speed') + ': ' + position.speed + ' Km/h' + '<br>');

        var selectedTarget = target.defaultTarget;
        var measures = position.measures;
        if (measures.length > 0) {
            if (uvxEnabled) {
                measures.map(function (measure) {
                    _this.appendMeasureData(showMeasureDates, $div, measure['name'], measure['value'], measure['timestampMicros'])
                });
            } else {
                var containsTargetId = this.hackTargetIds.indexOf(selectedTarget.id);
                if (containsTargetId !== -1) {
                    // Do a little hack
                    var _this = this;
                    var language = localStorage.getItem('lang');
                    var totalFuel = 0.0;
                    var hasMetric = false;
                    measures.map(function (measure) {
                        var measureName = measure['name'];
                        _this.rightOrLeftFuelMeasureChannelNames.forEach(element => {
                            if (element === measureName) {
                                var measureValueInString = measure['value'];
                                if (language === "en") {
                                    var measureValueWithOutSpaces = measureValueInString.split(",").join("");
                                    var lastCharOfMeasureValue = measureValueWithOutSpaces.charAt(measureValueWithOutSpaces.length - 1)
                                    if (lastCharOfMeasureValue === "l") {
                                        hasMetric = true;
                                        var measureValueNumberInString = measureValueWithOutSpaces.substr(0, measureValueWithOutSpaces.length - 1)
                                        var measureValueNumber = parseFloat(measureValueNumberInString);
                                        totalFuel += measureValueNumber;
                                    } else {
                                        var measureValueNumber = parseFloat(measureValueWithOutSpaces);
                                        totalFuel += measureValueNumber;
                                    }
                                } else if (language === "hu") {
                                    var measureValueWithOutSpaces = measureValueInString.split(" ").join("")
                                    var lastCharOfMeasureValue = measureValueWithOutSpaces.charAt(measureValueWithOutSpaces.length - 1)
                                    if (lastCharOfMeasureValue === "l") {
                                        hasMetric = true;
                                        var measureValueNumberInString = measureValueWithOutSpaces.substr(0, measureValueWithOutSpaces.length - 1)
                                        var parseableMeasureValueNumberInString = measureValueNumberInString.replace(/\,/g, '.');
                                        var measureValueNumber = parseFloat(parseableMeasureValueNumberInString);
                                        totalFuel += measureValueNumber;
                                    } else {
                                        var parseableMeasureValueNumberInString = measureValueWithOutSpaces.replace(/\,/g, '.');
                                        var measureValueNumber = parseFloat(parseableMeasureValueNumberInString);
                                        totalFuel += measureValueNumber;
                                    }
                                }
                                else { // ro, hr, sr
                                    var measureValueWithOutSpaces = measureValueInString.split(".").join("")
                                    var lastCharOfMeasureValue = measureValueWithOutSpaces.charAt(measureValueWithOutSpaces.length - 1)
                                    if (lastCharOfMeasureValue === "l") {
                                        hasMetric = true;
                                        var measureValueNumberInString = measureValueWithOutSpaces.substr(0, measureValueWithOutSpaces.length - 1)
                                        var parseableMeasureValueNumberInString = measureValueNumberInString.replace(/\,/g, '.');
                                        var measureValueNumber = parseFloat(parseableMeasureValueNumberInString);
                                        totalFuel += measureValueNumber;
                                    } else {
                                        var parseableMeasureValueNumberInString = measureValueWithOutSpaces.replace(/\,/g, '.');
                                        var measureValueNumber = parseFloat(parseableMeasureValueNumberInString);
                                        totalFuel += measureValueNumber;
                                    }
                                }
                            }
                        });
                    });
                    totalFuel = totalFuel.toLocaleString(language);
                    if (hasMetric) {
                        totalFuel += " l";
                    }
                    var hasTotalFuel = false;
                    measures.map(function (measure) {
                        var measureName = measure['name'];
                        _this.totalFuelMeasureChannelNames.forEach(element => {
                            if (element === measureName) {
                                hasTotalFuel = true;
                                _this.appendMeasureData(showMeasureDates, $div, measureName, totalFuel, measure['timestampMicros'])
                            }
                        });
                        if (!hasTotalFuel) {
                            _this.appendMeasureData(showMeasureDates, $div, measure['name'], measure['value'], measure['timestampMicros'])
                        }
                    });
                } else {
                    measures.map(function (measure) {
                        _this.appendMeasureData(showMeasureDates, $div, measure['name'], measure['value'], measure['timestampMicros'])
                    });
                }
            }
        }
        $div.append('<br>');

        var latitude = Math.floor(position.latitude * 10000) / 10000;
        var longitude = Math.floor(position.longitude * 10000) / 10000;

        $div
            .append(position.geocodedAddress + '<br>')
            .append(latitude + ' - ' + longitude);

        return $div.prop('outerHTML');
    }

    appendMeasureData(showMeasureDates, div, measureName, measureValue, measureTimeMicros) {
        if (showMeasureDates) {
            var measureTimeMillis = measureTimeMicros / 1000;
            if (measureTimeMillis < 0) {
                // Invalid date
                div.append(measureName + ': ' + measureValue + '<br>');
            } else {
                var localizedDateTime = moment(measureTimeMillis).format("L HH:mm:ss");
                div.append(measureName + ': ' + measureValue + ' (' + localizedDateTime + ')' + '<br>');
            }
        } else {
            div.append(measureName + ': ' + measureValue + '<br>');
        }
    }

    analyticsEvent() {
    }

    render() {
        return (<div id="map" ></div>)
    }


}

TargetTrackingMap.defaultProps = {
    trackedTargetList: new TargetList(),
    handlePopupToggle: function () { },
    removeTrackedTarget: function () { },
}

module.exports = TargetTrackingMap;
