// React betĂ¶ltĂ©se
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

var Languages = require('../mixins/Languages.jsx');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

// Router betöltése, inicializálása
var Router = require('react-router');
// -----------------------------------------------------


// Nyelvválasztó modul
var LanguagesSelector = require('./LanguagesSelector.jsx');

// Form elemek importálása
var FormElementInput = require('./FormElementInput.jsx');
var ButtonInput = require('react-bootstrap/lib/Input');
// -----------------------------------------------------

var Loader = require('./Loader.jsx');

var Notification = require('../mixins/Notification.jsx');

var changepassword = require('../commons/changePassword.js');

module.exports = React.createClass({

  mixins: [Languages,Notification,Brands,Router.Navigation],
  componentWillMount: function() {
      var lang = this.props.query.lang;
      localStorage.setItem('lang', lang);
      this.setLangState();
  },
  //handle button click
  handleSubmit: function(){

    var _this = this;
    
    var newPassword = this.refs.password.getValue();
    var repeatedPassword = this.refs.repeatedPassword.getValue();  
    var username = _this.props.query.user;
    var password = _this.props.query.hash;
      
    if(_this.validatePasswords(newPassword, repeatedPassword)){

        // Ajax loader megjelenítée a bejelentkezés gomb helyén
        $('.FormElementButton').parent().hide();
        $('.loaderLogin').show();
        // -----------------------------------------------------

        var errorAction = {
          label: this.getValue('passwordChangeServiceNotAvailable'),
          callback: function() {
            _this.navigation('forgotemailusernamepair','forgotemailusernamepair');
          }
        };
        
        //jelszócsere hívás
        changepassword.changepass(username, password, newPassword, errorAction, this.addNotification, this.addTranslateNotification,this.success);

    }
    else
    {
          $('.icon-password').addClass('error animated shake');
          $('.icon-password').addClass('error animated shake');
    }

      return false;
  },
  validatePasswords: function(newPassword, repeatedPassword){
    var isValid = false

    if(newPassword.length == 0 || repeatedPassword.length == 0){
      this.addTranslateNotification('emptyFieldChangePassword', 'warning');
    }else if(newPassword != repeatedPassword){
      this.addTranslateNotification('passwordsNotMatch', 'warning');
    }else if(newPassword.length < 8){
      this.addTranslateNotification('passwordsNotLongEnough', 'warning');
    }else if(this.notContainsUpperOrLoverCases(newPassword)){
      this.addTranslateNotification('passwordsUpperLowerCases', 'warning');
    }else{
      isValid = true
    }
    
    return isValid;
          
  },
  notContainsUpperOrLoverCases: function(pass){
    var notContainsLowerCase = true;
    var notContainsUpperCase = true;
    
    for(var i = 0; i < pass.length; i++){
      if(pass[i] == pass[i].toLowerCase()){
        notContainsLowerCase = false
      }else if(pass[i] == pass[i].toUpperCase()){
        notContainsUpperCase  = false
      }
    }
    return (notContainsLowerCase || notContainsUpperCase)
  },
  success : function(response, status, xhr) {

    this.addTranslateNotification('successPasswordChange','info')
    var actualLang = localStorage.getItem('lang');
    var latestTimeMicros = localStorage.getItem('latestTimeMicros');

    //Töröljük a mentett bejelentkezési adatokat
    localStorage.clear();
    localStorage.setItem('lang',actualLang);
    localStorage.setItem('latestTimeMicros',latestTimeMicros);
    localStorage.setItem('user_id',-1);
    // -----------------------------------------------------

    $('.FormElementButton').parent().show();
    $('.loaderLogin').hide();
  },
  navigation: function(url, label) {
    this.transitionTo(url);

  },
  // Nyelv módosítás esetén a localStorage-ban lévő lang
  handleLocaleChange: function(lang) {
  	this.setLangValue(lang.id);
    this.setLangState();
		// Nyelvi kulcs frissítése
    $('body').removeAttr('class').addClass(localStorage.getItem('lang'));
  },

	getLanguages: function() {
		var actualLang = localStorage.getItem('lang');
		var actualLangIndex = null;
    var languages = [
        {id: 'hu',title: this.getValue('hu'), name: this.getValue('hu'), flagImg: '',flagTitle: ''},
        {id: 'en',title: this.getValue('en'), name: this.getValue('en'), flagImg: '',flagTitle: ''},
        {id: 'ro',title: this.getValue('ro'), name: this.getValue('ro'), flagImg: '',flagTitle: ''},
        {id: 'sr',title: this.getValue('sr'), name: this.getValue('sr'), flagImg: '',flagTitle: ''},
        {id: 'hr',title: this.getValue('hr'), name: this.getValue('hr'), flagImg: '',flagTitle: ''},
        {id: 'sk',title: this.getValue('sk'), name: this.getValue('sk'), flagImg: '',flagTitle: ''},
        {id: 'cz',title: this.getValue('cz'), name: this.getValue('cz'), flagImg: '',flagTitle: ''}
    ];

		languages.forEach(function(item, index) {
			if(item.id == actualLang) {
				actualLangIndex = index;
			}
		});

		var actualLangItem = languages.splice(actualLangIndex, 1);
		languages.unshift(actualLangItem.shift());

		return languages;
	},
  render: function(){
    var languages = this.getLanguages();

      return (
      
        <main id="changePasswordPage">
        <span className="changePasswordBg"></span>
        <div className="container" >
          <form className="ChangePassword col-xs-12 col-sm-6 col-md-4 col-md-offset-4 col-sm-offset-3" onSubmit={this.handleSubmit}>
          <h1 className="logo" style={{backgroundImage: 'url(' + this.getResourceItemValue('logo') + ')'}}></h1>

          <h1 className="changePasswordTitle"> {this.getValue('changePasswordTitle')}</h1>

          <h1 className="changePasswordDescription">{this.getValue('changePasswordDescription')}</h1>

          <h1 className="changePasswordUserName">{this.props.query.user}</h1>

              <FormElementInput
                inputType="password"
                ref="password"
                instanceName="password"
                placeholder={this.getValue('password')}
                label=""
                icon={<i className='icon-password'></i>}
                required={true}
              />

              <FormElementInput
                inputType="password"
                ref="repeatedPassword"
                instanceName="password"
                label=""
                placeholder={this.getValue('passwordAgain')}
                icon={<i className='icon-password'></i>}
                required={true}
              />

              <ButtonInput
                ref="submit"
                instanceName="submit"
                onSubmit={this.handleSubmit}
                type='submit'
                value={this.getValue('changePasswordButtonText')}
                className="FormElementButton"
                id="SubmitButton"
              />

              <Loader/>

            </form>

            <div className="languagesSelector">
            <LanguagesSelector
              items={languages}
              gridColumns="1"
              openMode="click"
              showFlag={false}
              id="languagesSelector"
              onLanguageChanged={this.handleLocaleChange}
            />
            </div>

            </div>
            {this.rednderNotification()}
      </main>
      )
  }


});
