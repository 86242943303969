class TrackingRequest {

	constructor(targetId, fromTimeMicros, asynchronous) {
		this.targetId = targetId;
		this.fromTimeMicros = fromTimeMicros;
		this.asynchronous = asynchronous;
	}

}

export { TrackingRequest };