// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Oldalsó menüsáv betöltése
var SideMenu = require('./SideMenu.jsx');

// Mobil tab választó betöltése
var TrackingTabs = require('./TrackingTabs.jsx');
var TargetTrackingSelectableList = require('./TargetTrackingSelectableList.jsx');
var TargetTrackingSelectedList = require('./TargetTrackingSelectedList.jsx');
var TargetTrackingMap = require('./TargetTrackingMap.jsx');
var TargetTrackingListsWrapper = require('./TargetTrackingListsWrapper.jsx');
var Notification = require('../mixins/Notification.jsx');
var Navigation = require('react-router').Navigation;
var auth = require('../commons/authenticateToken.js');

import { DefaultTarget } from '../model/DefaultTarget';
import { TrackedTarget } from '../model/TrackedTarget';
import { TrackedTargetState } from '../model/TrackedTargetState';
import { TargetList } from '../model/TargetList';
import { TrackingMode } from '../model/TrackingMode';
import { GetTargets } from '../remote/GetTargets';
import { TrackTargets } from '../remote/TrackTargets';
import { TrackTargetsExecutor } from '../remote/TrackTargetsExecutor';

class TargetTrackingShare extends React.Component {

	/**
	 * Bind-olások
	 * a választhatók és a nyomkövetettek inicializálása
	 */
	constructor(props) {
		super(props);
		this.addTrackedTarget = this.addTrackedTarget.bind(this);
		this.removeTrackedTarget = this.removeTrackedTarget.bind(this);
		this.appendToSelectableTargets = this.appendToSelectableTargets.bind(this);
		this.updateLiveData = this.updateLiveData.bind(this);
		this.handlePopupToggleWithIcon = this.handlePopupToggleWithIcon.bind(this);
		this.updateScreenMode = this.updateScreenMode.bind(this);
		this.emptySelectableTargets = this.emptySelectableTargets.bind(this);
		this.updateTrackedTargetList = this.updateTrackedTargetList.bind(this);
		this.getPopupTargetId = this.getPopupTargetId.bind(this);
		this.updatePopupTargetId = this.updatePopupTargetId.bind(this);
		this.visitor = true;
		this.popupTargetId = null;
		this.state = {
			onSmallScreen: false,
			windowHeight: 1,
			selectableTargetList: new TargetList(),
			trackedTargetList: new TargetList(),
			maxTrackedTargetLimit: 20,
		};
		this.trackTargetsExecutor = new TrackTargetsExecutor(this.updateLiveData,
			this.visitor,
			this.props.notificationVersions,
			this.props.getLangValue, this.getPopupTargetId);
	}

	componentWillMount() {
		this.checkLogin();
		this.setupLocalStorage();
		this.updateScreenMode();
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateScreenMode);
		document.addEventListener("pause", this.trackTargetsExecutor.stopLongPolling);
		document.addEventListener("resume", this.trackTargetsExecutor.restartLongPolling);
	}

	//Akkor hívódik meg, ha a state frissül
	componentDidUpdate(prevProps, prevState) {
		this.trackTargetsExecutor.updateTrackedTargets(this.state.trackedTargetList, this.props.notificationVersions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateScreenMode);
	}

	checkLogin() {
		var tokenId = this.props.params.tokenId;

		if (typeof localStorage.getItem('tokenId') == 'undefined') {
			this.context.router.transitionTo('shareinformation');
		}

		if (tokenId.length > 0) {
			auth.checkLogin(tokenId, this.redirectToInformationPage);
		}
	}

	redirectToInformationPage() {
		return this.context.router.transitionTo('shareinformation');
	}

	render() {
		return (
			<main id="trackingPage" className="sharePager">
				<MobileHeader
					mobilMenuName={this.props.getLangValue('tracking')} />
				<TrackingTabs
					ref="tabs"
					getLangValue={this.props.getLangValue}
					onSmallScreen={this.state.onSmallScreen}
					trackedTargetList={this.state.trackedTargetList}
					visitor={this.visitor}
				/>
				<SideMenu
					lang={this.props.lang}
					activeMenu="tracking"
					getValue={this.props.getLangValue}
					addNotification={this.props.notificationVersions.addNotification}
					addTranslateNotification={this.props.notificationVersions.addTranslateNotification}
					notification={this.props.notificationVersions.notification}
					notificationSystem={this.props.notificationVersions.notificationSystem}
					visitor={this.visitor}
				/>
				<section id="carsList">
					<TargetTrackingListsWrapper
						trackedTargetList={this.state.trackedTargetList}
						selectableTargetList={this.state.selectableTargetList}
						onSelectFunction={this.addTrackedTarget}
						onUnselectFunction={this.removeTrackedTarget}
						onAppendToSelectableFunction={this.appendToSelectableTargets}
						handleInfoIconClick={this.handlePopupToggleWithIcon}
						onSmallScreen={this.state.onSmallScreen}
						windowHeight={this.state.windowHeight}
						emptySelectableTargets={this.emptySelectableTargets}
						updateTrackedTargetList={this.updateTrackedTargetList}
						maxTrackedTargetLimit={this.state.maxTrackedTargetLimit}
						visitor={this.visitor}
						getLangValue={this.props.getLangValue}
						notificationVersions={this.props.notificationVersions}
					/>
				</section>
				<div className="mapContainer" >
					<TargetTrackingMap
						ref="trackingMap"
						trackedTargetList={this.state.trackedTargetList}
						removeTrackedTarget={this.removeTrackedTarget}
						visitor={this.visitor}
						onSmallScreen={this.state.onSmallScreen}
						getLangValue={this.props.getLangValue}
						notificationVersions={this.props.notificationVersions}
						updateLiveDataFunction={this.updateLiveData}
						updatePopupTargetId={this.updatePopupTargetId}
					/>
				</div>
			</main>
		);
	}

	getPopupTargetId() {
		return this.popupTargetId;
	}

	updatePopupTargetId(newPopupTargetId) {
		this.popupTargetId = newPopupTargetId;
	}

	emptySelectableTargets() {
		this.setState({
			selectableTargetList: new TargetList()
		})
	}

	updateScreenMode() {
		if (this.state.onSmallScreen && ($(window).innerWidth() > 992)) {
			this.setState({
				onSmallScreen: false,
			});
		}
		else if (!this.state.onSmallScreen && ($(window).innerWidth() <= 992)) {
			this.setState({
				onSmallScreen: true
			});
		}
		if (this.state.windowHeight != window.innerHeight) {
			this.setState({
				windowHeight: window.innerHeight
			});
		}
	}

	handlePopupToggleWithIcon(targetId) {
		if (this.state.onSmallScreen && !$('#trackingTabsMaps').hasClass('active') && targetId != null) {
			this.refs.tabs.showMaps();
		}
		this.refs.trackingMap.toggleInfoPopup(targetId);
	}

	// To be used to be called from onEvent()-like methods
	addTrackedTarget(defaultTarget) {
		return function () {
			this.setState((prevState, props) => {
				if (prevState.trackedTargetList.length() >= this.state.maxTrackedTargetLimit) {
					return {
						trackedTargetList: prevState.trackedTargetList,
						selectableTargetList: prevState.selectableTargetList
					};
				}
				var newDefaultTarget = new DefaultTarget(defaultTarget.getId(),
					defaultTarget.getLicenseplate(),
					defaultTarget.getDescription(),
					true);
				//var newDefaultTarget = defaultTarget;
				var trackedTarget = new TrackedTarget(newDefaultTarget, TrackedTargetState.Pending, null);
				var newTrackedTargetList = prevState.trackedTargetList.deepCopyTrackedTargetList();
				newTrackedTargetList.add(trackedTarget);

				var targetListToDisableInSelectables = new TargetList();
				targetListToDisableInSelectables.add(newDefaultTarget);
				var newSelectableTargetList = prevState.selectableTargetList.copy();
				newSelectableTargetList.disableTargets(targetListToDisableInSelectables);

				this.saveCurrentlyTrackedTargetsToLocalStorage(newTrackedTargetList);

				return {
					trackedTargetList: newTrackedTargetList,
					selectableTargetList: newSelectableTargetList
				};
			});
		}.bind(this);
	}

	// To be used to be called from onEvent()-like methods
	removeTrackedTarget(defaultTarget) {
		return function () {
			this.setState((prevState, props) => {
				var newDefaultTarget = new DefaultTarget(defaultTarget.getId(),
					defaultTarget.getLicenseplate(),
					defaultTarget.getDescription(),
					false);
				//var newDefaultTarget = defaultTarget;
				var targetId = newDefaultTarget.getId();
				var newTrackedTargetList = prevState.trackedTargetList.deepCopyTrackedTargetList();
				newTrackedTargetList.remove(targetId);

				var targetListToEnableInSelectables = new TargetList();
				targetListToEnableInSelectables.add(newDefaultTarget);
				var newSelectableTargetList = prevState.selectableTargetList.copy();
				newSelectableTargetList.enableTargets(targetListToEnableInSelectables);

				this.saveCurrentlyTrackedTargetsToLocalStorage(newTrackedTargetList);

				return {
					trackedTargetList: newTrackedTargetList,
					selectableTargetList: newSelectableTargetList
				}
			});
		}.bind(this)
	}

	updateTrackedTargetList(newTrackedTargetList) {
		this.setState({
			trackedTargetList: newTrackedTargetList
		})
	}

	appendToSelectableTargets(selectableTargetListToAppend) {
		this.setState((prevState, props) => {
			selectableTargetListToAppend.disableTargets(prevState.trackedTargetList);
			var newSelectableTargetList = prevState.selectableTargetList.copy();
			newSelectableTargetList = newSelectableTargetList.concat(selectableTargetListToAppend);
			return { selectableTargetList: newSelectableTargetList }
		});
	}

	/**
	 * A kapott élő adatok alapján a nyomkövetettek frissítése (állapotfrissítés)
	 */
	updateLiveData(liveDataArray) {
		var deepCopiedTrackedTargetList = this.state.trackedTargetList.deepCopyTrackedTargetList();
		liveDataArray.map(function (livaDataElement) {
			var targetId = livaDataElement.targetId;
			var liveData = livaDataElement.liveData;
			var trackingState = livaDataElement.trackingState;

			deepCopiedTrackedTargetList.updateLiveData(targetId, liveData, trackingState);
		});
		this.setState({
			trackedTargetList: deepCopiedTrackedTargetList
		})
	}

	//A megkapott listát menti el LocalStorage-ba
	saveCurrentlyTrackedTargetsToLocalStorage(targetList) {
		var itemKey = 'carsTrackingIdVisitor';
		localStorage.setItem(itemKey, JSON.stringify(targetList.getAllIds()));
	}

	//LocalStorage kulcs létrehozása és üresre inicializálása
	setupLocalStorage() {
		var mode = this.props.mode;
		var itemKey = 'carsTrackingIdVisitor';
		var previoslyTrackedTargetIds = JSON.parse(localStorage.getItem(itemKey));
		if (previoslyTrackedTargetIds == null) {
			localStorage.setItem(itemKey, JSON.stringify([]));
		}
	}
}

TargetTrackingShare.contextTypes = {
	router: React.PropTypes.object.isRequired
};

module.exports = TargetTrackingShare;
