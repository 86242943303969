

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Üzenet elem betöltése
var Item = require('./MessagesDetailsItem.jsx');

// Üzenetküldő nézet betöltése
var MessageSend = require('./MessagesSend.jsx');

var moment = require('moment');

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

  mixins: [Languages, Ajax],

  getDefaultProps: function () {
    return {
      thread: null,
      limit: 10,
      page: 1,
      loading: false,
      stopLoadItems: false,
      timeoutTime: 0,
      stopLongPoll: false,
      longpollTimeout: null,
      newMessageAdded: function () { },
      setUnreadCount: function () { },
      messageTrackingAjax: null
    }
  },

  getInitialState: function () {

    return {
      items: [],
      lastTimeMicros: 0,
      threadId: 0,
      isInfiniteScrollLoad: false,
      newMessageNumber: 0,
      stopLongPolling: false
    };
  },

  componentWillMount: function () {
    if (this.props.thread === null) {
      return false;
    }

    var id = this.props.thread.id;
    var items = this.props.thread.messages;

    this.setState({
      threadId: id,
      items: items,
      lastTimeMicros: (typeof this.props.thread.lastMessageSentTime === 'undefined') ? (-1) : (this.props.thread.lastMessageSentTime)
    });
  },

  componentDidMount: function () {
    if (this.props.thread === null) {
      return false;
    }

    this.setLastSeenTime(this.state.items[this.props.thread.messages.length - 1]);

    //lista végére görgetünk
    this.scrollToListBottom();

    //inicializáljuk az infinite scrollt
    this.initInfiniteScroll();

    //lecsekkoljuk a kapott üzenetek olvasottságát
    this.checkForUnseenMessage('click');

    //elindítjuk a longpolling kéréseket
    this.getActItems();

    document.addEventListener("pause", this.stopDetailsLongPolling, false);
    document.addEventListener("resume", this.restartDetailsLongPolling, false);
  },

  stopDetailsLongPolling: function () {
    if (this.props.messageTrackingAjax != null) {
      this.props.messageTrackingAjax.abort();
    }

    this.setState({
      stopLongPolling: true
    });
  },

  restartDetailsLongPolling: function () {
    var _this = this;

    this.setState({
      stopLongPolling: false
    }, function () {
      _this.getActItems();
    });
  },

  //amikor elnavigálunk az oldalról megállítjuk a longpollingot
  componentWillUnmount: function () {
    this.props.stopLongPoll = true;
    if (this.props.longpollTimeout !== null) {
      clearTimeout(this.props.longpollTimeout);
    }
    clearTimeout(this.props.timeoutForFirstPoll);

    if (this.props.messageTrackingAjax != null) {
      this.props.messageTrackingAjax.abort();
    }
  },

  //ezzel a metódussal megadott id-jú üzenethez scrollozunk
  scrollListToElem: function (id) {
    var $listHolder = $('#messagesDetails').find('div.listHolder');
    if ($listHolder.length === 0) { return false; }

    var $elem = $listHolder.find('li[data-id="' + id + '"]');
    $listHolder.scrollTop($elem.offset().top - $listHolder.offset().top + $listHolder.scrollTop());

  },

  //ezzel a függvénnyel az üzenetek aljára görgetünk
  scrollToListBottom: function () {
    var $listHolder = $('#messagesDetails').find('div.listHolder');
    if ($listHolder.length === 0) { return false; }

    $listHolder.scrollTop($listHolder[0].scrollHeight);
  },

  //inicializáljuk az infinite scrollt
  initInfiniteScroll: function () {
    var _this = this;
    var $listHolder = $('#messagesDetails').find('div.listHolder');

    $listHolder.off('scroll').on('scroll', function () {
      var scrollTop = $(this).scrollTop();

      if (_this.props.stopLoadItems) {
        return false;
      }

      if (scrollTop <= 0 && !_this.props.loading) {
        _this.props.loading = true;

        _this.setState({
          isInfiniteScrollLoad: true
        });

        //lekérjük az elemeket és bepakoljuk őket a statebe
        _this.getItems(function (elems, lastTimeMicros, lastId) {
          _this.setState({
            items: elems,
            lastTimeMicros: lastTimeMicros,
            isInfiniteScrollLoad: false
          });
          _this.props.loading = false;
          _this.scrollListToElem(lastId);
        });
      }
    });
  },

  //infinite scrollos lekérdezés
  getItems: function (callback) {
    var _this = this;
    var _callback = callback;

    $.ajax({
      url: "@@servletUrl" + "messages?threadId=" + _this.props.thread.id + "&lastTimeMicros=" + _this.state.lastTimeMicros + "&limit=" + _this.props.limit,
      type: "GET",
      timeout: 30000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function (response) {
        var items = _this.state.items;

        if (typeof response !== 'undefined' && response.length) {
          var tmpArr = [];
          response = response.reverse();
          //végigmegyünk a response elemeken
          for (var i in response) {
            var found = false;

            //végigmegyünk a már meglévő elemeken, hogy megnézzük létezik e már
            for (var j in items) {
              if (items[j]['id'] === response[i]['id']) {
                found = true;
              }
            }

            //ha nem létezik akkor hozzáadjuk szépen a tmp tömbünkhöz, így biztosítva, hogy a régebbi elemek az előzők elé kerüljenek
            if (!found) {
              response[i]['new'] = true;
              tmpArr.push(response[i]);
            }
          }

          //majd ha megnéztünk mindent, tmp-be bakoljuk a meglévő elemeket és így lesz teljes az összes üzenet
          for (var i in items) {
            tmpArr.push(items[i]);
          }

          if (typeof callback === 'function') {
            callback(tmpArr, tmpArr[0]['sentStatus']['timeMicros'], items[0]['id']);
          }

          //ha undefined vagy 0 hosszúságú, akkor nincs több elem, nem kérünk le többet
        } else if (typeof response !== 'undefined' && response.length === 0) {
          _this.props.stopLoadItems = true;
          _this.setState({
            isInfiniteScrollLoad: false
          });
        }
      },
      error: function (response, status, xhr) {
        _this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.getItems.bind(_this, _callback));
      }
    });
  },

  //longpollingos lekérés
  getActItems: function () {
    var _this = this;

    if (this.state.stopLongPolling) {
      return;
    }

    var latestTimeMicros = this.state.lastTimeMicros;
    var localStorageLatestTimeMicros = localStorage.getItem('latestTimeMicros');

    if (localStorageLatestTimeMicros.length != 0) {
      if (localStorageLatestTimeMicros != -1) {
        latestTimeMicros = localStorageLatestTimeMicros;
      }
      if (latestTimeMicros === null) {
        latestTimeMicros = localStorageLatestTimeMicros;
      }
    }

    var messageTrackingAjax = $.ajax({
      url: "@@servletUrl" + "messages/tracking?fromTime=" + latestTimeMicros + "&asynchronous=true",
      type: "GET",
      timeout: 60000,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function (response, status, xhr) {

        if (_this.props.stopLongPoll) { return false; }
        var items = _this.state.items;
        var count = 0;
        var scrollToBottom = false;

        if (xhr.status == 200 && typeof response.messages !== 'undefined' && response.messages.length > 0 && localStorage.getItem('latestTimeMicros') != -1) {

          //tmp tömb elemei az összes meglévő elem lesz
          var tmpArr = items;

          //változott elemek tömbje
          var changedItems = [];

          //másik thread elemeinek tömbje
          var differentThreadItems = {};

          //végigmegyünk a response elemein
          for (var i in response.messages) {
            var found = false;

            //végigmegyünk a meglévő elemeken
            for (var j in items) {

              //ha megegyezik a threadId, levizsgáljuk, hogy már létezik e
              if (response.messages[i]['threadId'] === _this.props.thread.id) {
                if (items[j]['id'] === response.messages[i]['id']) {
                  found = true;
                }
                //ha nem egyezik meg, az egy másik szál üzenete lesz, szóval összeszedjük ezeket is, figyelve a duplikációra
              } else {
                if (typeof differentThreadItems[response.messages[i]['threadId']] === 'undefined') {
                  differentThreadItems[response.messages[i]['threadId']] = [];
                }

                var msgFound = false;

                for (var k in differentThreadItems[response.messages[i]['threadId']]) {
                  if (differentThreadItems[response.messages[i]['threadId']][k]['id'] === response.messages[i]['id']) {
                    msgFound = true;
                  }
                }

                if (!msgFound) {
                  differentThreadItems[response.messages[i]['threadId']].push(response.messages[i]);
                }
              }
            }

            //ha nem találtunk és egyezik a thread id, hozzáadjuk a tmp tömbhöz, mivel ez egy új üzenet
            if (!found && response.messages[i]['threadId'] === _this.props.thread.id) {
              tmpArr.push(response.messages[i]);
              scrollToBottom = true;
              //differentThreadItems objecthez is hozzá kell adni, hogy frissüljön a szál utolsó üzenete a bal oldali lisában
              if (typeof differentThreadItems[response.messages[i]['threadId']] === 'undefined') {
                differentThreadItems[response.messages[i]['threadId']] = [];
              }

              var msgFound = false;

              for (var k in differentThreadItems[response.messages[i]['threadId']]) {
                if (differentThreadItems[response.messages[i]['threadId']][k]['id'] === response.messages[i]['id']) {
                  msgFound = true;
                }
              }

              if (!msgFound) {
                differentThreadItems[response.messages[i]['threadId']].push(response.messages[i]);
              }

              //ha már van ilyen elem és a thread id megegyezik, akkor üzenet updateről van szó
            } else if (found && response.messages[i]['threadId'] === _this.props.thread.id) {
              changedItems.push(response.messages[i]);
            }
          }

          //ha van változott elem, akkor végigmegyünk a tmp tömbön, ami mostanra az összes releváns üzenetet tartalmazza és felülírjuk a megfelelőt
          if (changedItems.length) {
            for (var i in changedItems) {
              for (var j in tmpArr) {
                if (changedItems[i]['id'] === tmpArr[j]['id']) {
                  tmpArr[j] = changedItems[i];
                }
              }
            }
          }

          //ha vannak az objectben elemek, meghívjuk a változásokat lekezelő függvényt
          if (Object.keys(differentThreadItems).length) {
            _this.props.newMessageAdded(differentThreadItems);
          }

          //az utolsó üzenet idejét
          if (tmpArr.length) {
            //latestTimeMicros = tmpArr[tmpArr.length-1]['sentStatus']['timeMicros'];
            localStorage.setItem('latestTimeMicros', response.serverTimeMicros);
          }

          for (var i in tmpArr) {
            if (tmpArr[i]['readStatus']['name'] === 'unread' && tmpArr[i]['type'] === 'received') { count++; }
          }

          //beállítjuk a statet
          _this.setState({ items: tmpArr, lastTimeMicros: latestTimeMicros });

          if (scrollToBottom) {
            //az üzenetek végére scrollozunk
            _this.scrollToListBottom();
          }
          _this.setLastSeenTime(tmpArr[tmpArr.length - 1]);
        } else {
          localStorage.setItem('latestTimeMicros', response.serverTimeMicros);
        }


        //lecsekkoljuk a kapott üzenetek olvasottságát
        _this.checkForUnseenMessage('longpolling');

        if (_this.props.stopLongPoll) { return false; }
        _this.getActItems();
      },
      error: function (response, status, xhr) {
        if (response.statusText == 'abort') {
          return false;
        }

        if (response.statusText != 'timeout' && response.statusText != 'error' && status != 0) {
          _this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, null);
        }

        if (_this.props.timeoutTime == 0) {
          _this.props.timeoutTime = 5000;
        } else {
          _this.props.timeoutTime *= 2;
          if (_this.props.timeoutTime > (5 * 60 * 1000)) {
            _this.props.timeoutTime = (5 * 60 * 1000);
          }
        }

        if (typeof response !== 'undefined' && status >= 500) {
          _this.props.longpollTimeout = setTimeout(function () {
            _this.getActItems();
          }, _this.props.timeoutTime);
          return false;
        }

        if (response.statusText == 'timeout') {
          _this.props.longpollTimeout = setTimeout(function () {
            _this.getActItems();
          }, _this.props.timeoutTime);
          return false;
        }

        if (typeof _this.props.timeoutTime != 'undefined') {
          _this.props.longpollTimeout = setTimeout(function () {
            _this.getActItems();
          }, _this.props.timeoutTime);
          return false;
        }
      }
    });

    this.props.messageTrackingAjax = messageTrackingAjax;

  },

  //ez leellenőrzi a kapott üzenetek olvasottságát
  checkForUnseenMessage: function (mode) {
    var _this = this;

    var lastNotRead = [];

    var items = _this.state.items;

    //összeszedjük azokat az elemeket, amiket a bejelentkezett felhasználó kapott és még nem lett elolvasva
    for (var i = items.length - 1; i >= 0; i--) {
      if (_this.state.items[i]['type'] == 'received' && _this.state.items[i]['readStatus']['name'] === 'unread') {
        lastNotRead.push(items[i]);
      }
    }

    if (lastNotRead.length) {
      var ids = [];

      //amennyiben volt ilyen, végigmegyünk és amelyek láthatóak a képernyőn, az üzenet azonosítóját hozzáadjuk a tömbünkhöz
      for (var i in lastNotRead) {
        /*if( $('li[data-timestamp="'+ lastNotRead[i]['sentStatus']['timeMicros'] +'"]').is(':visible') ){
          ids.push(lastNotRead[i]['id']);
        }*/

        ids.push(lastNotRead[i]['id']);
      }

      //Ha van ilyen elem, felküldjük a szervernek az azonosító tömböt
      if (ids.length) {
        $.ajax({
          url: "@@servletUrl" + "messages/read",
          type: "PUT",
          data: JSON.stringify(ids),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          timeout: 30000,
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
          },
          success: function (response, status, xhr) {
            if (xhr.status == 200 && typeof response !== 'undefined' && response.length > 0) {

              //stateben tárolt elemeket frissítjük a visszaküldött elemekkel
              for (var i in items) {
                for (var j in response) {
                  if (items[i]['id'] === response[j]['id']) {
                    items[i] = response[j];
                  }
                }
              }

              //beállítjuk a "látta" feliratot
              _this.setLastSeenTime(items[items.length - 1]);

              //lista végére görgetünk
              _this.scrollToListBottom();

              _this.setState({ items: items });

              switch (mode) {
                case 'click': _this.hiddenNewMessageMarker(); break;
                case 'longpolling': _this.waitNewMessageMarker(); break;
              };
            }
          },
          error: function (response, status, xhr) {
            _this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, _this.checkForUnseenMessage);
          }
        });
      }
    }
  },

  waitNewMessageMarker: function () {
    var _this = this;
    var newMessageNumber = ++_this.state.newMessageNumber;

    this.setState({
      newMessageNumber: newMessageNumber
    }, function () {
      setTimeout(function () {
        _this.checkNewMessageNumber();
      }, 30000);

    });
  },

  checkNewMessageNumber: function () {
    var actualNewMessageNumber = this.state.newMessageNumber;

    if (actualNewMessageNumber == 1) {
      this.hiddenNewMessageMarker()
    }

    actualNewMessageNumber--;

    this.setState({
      newMessageNumber: actualNewMessageNumber
    });
  },

  hiddenNewMessageMarker: function () {
    $('.items').find('li[data-id="' + this.state.threadId + '"]').removeClass('unread');
    //this.props.removeMessageUnread(this.state.threadId);
  },

  //beállítja a "látta" feliratot
  setLastSeenTime: function (lastUserMsg) {
    var _this = this;

    if (typeof lastUserMsg === 'undefined' || lastUserMsg === null || lastUserMsg['type'] == 'received') { $('#checkUser').hide(); return false; }

    if (lastUserMsg['type'] == 'sent' && lastUserMsg['readStatus']['name'] === 'read' && $('#messagesDetails').find('div.listHolder li.messageBg').last().attr('data-id') == lastUserMsg['id']) {
      $('#checkUser').find('span.time').html(_this.getTimeStr(lastUserMsg['readStatus']['timeMicros'])).end().show();

      //lista végére görgetünk
      this.scrollToListBottom();
    } else {
      $('#checkUser').hide();
    }

  },

  //átalakítja formázottá a beadott timemicrot
  getTimeStr: function (timeMicros) {
    var time = timeMicros / 1000;
    var date = new Date(time);
    var timeStr = '';

    if (new Date().getDate() === date.getDate()) {
      timeStr += this.getValue('today') + ', ';
    } else {
      timeStr += moment(time).format('L (dddd)') + ', ';
    }

    timeStr += moment(time).format('HH:mm');

    return timeStr;
  },

  //kezeli az elküldött üzenetet és továbbadja a szülőnek, hogy az oldalso boxba frissítse az utolsó üzenetet
  messageSentHandler: function (sentMessage) {
    var itms = this.state.items;

    itms.push(sentMessage);

    this.setState({ items: itms });

    var obj = {};

    obj[sentMessage['threadId']] = [sentMessage];

    this.props.newMessageAdded(obj);

    this.scrollToListBottom();
  },

  render: function () {
    if (this.props.thread === null) {
      return (<section id="messagesDetails"></section>)
    }

    var items = [],
      sortedItems = this.state.items;

    //id szerint sorbarendezzük az elemeket, mert előfordulhat, hogy tracking hívásnál a végére kerül egy régebbi üzenet
    sortedItems.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }

      if (a.id > b.id) {
        return 1;
      }

      return 0;
    });

    for (var i in sortedItems) {
      items.push(
        <Item
          messageAttributes={sortedItems[i]}
          threadUserId={this.props.thread.threadUser.id}
        />
      )
    }

    if (this.state.isInfiniteScrollLoad == true) {
      var infiniteScrollLoader = (
        <div className='loaderMessageMoreList'><span></span></div>
      );
    } else {
      var infiniteScrollLoader = '';
    }

    return (
      <section id="messagesDetails">
        <div>
          <header>
            <h1>
              {this.props.thread.threadUser.name}
            </h1>
          </header>
          <div className="listHolder" >
            {infiniteScrollLoader}
            <ul className="items">
              {items}
            </ul>
            <span id="checkUser"><i className="icon-check"></i>{this.getValue('sheSaw')}: <span className="time" ></span></span>
          </div>
        </div>
        <MessageSend
          messageSent={this.messageSentHandler}
          threadId={this.props.thread.threadUser.id}
          addNotification={this.state.addNotification}
          addTranslateNotification={this.state.addTranslateNotification}
        />
      </section>
    );
  }
});
