module.exports = {
  loginTitle: "Logare",
  password: "Parolă",
  tracking: "Urmărire",
  router: "Trasee",
  message: "Mesaje",
  fueling: "Listă alimentare",
  logout: "Deconectare",
  email: "E-mail",
  wrongEmailFormat: "Format de e-mail greșit!",
  forgotYourPassword: "Ai uitat parola?",
  forgotEmailUsernamePair: "Nu ați reușit să trimiteți e-mail?",
  forgotEmailUsernamePairBody: "Stimate Client!<br/>În cazul în care nu ați reușit să schimbați parola uitată, vă rog să sunați la serviciul clienți.<br/><br/>",
  successPasswordResetEmailSend: "În cazul în care adresa de e-mail și nume utilizator introduse sunt corecte, vă trimitem un link pentru modificarea parolei.",
  successPasswordChange: "Parola a fost modificată! Puteți să logați folosind parola nouă!",
  sendPasswordResetButtonText: "Trimite e-mail!",
  emptyFieldPasswordReset: "Câmpul numele utilizator sau parola nu este completată!",
  passwordAgain: "Parola din nou",
  emptyFieldChangePassword: "Completarea câmpurile cu parola sunt obligatoare.",
  passwordsNotMatch: "Parole intoduse nu sunt identice!",
  passwordsNotLongEnough:'Parola trebuie să aibă minim 8 careactere.',
  passwordsUpperLowerCases:'Parola trebuie să conțină litere majuscule și minuscule.',
  passwordResetTokenAuthenticationFailed: "Link-ul folosit pentru chimbarea parolei s-a expirat. Solicitați încă o dată și schimbați parola în 15 minute după deschiderea link-ului.",
  changePasswordButtonText: "Chimbarea parolei",
  passwordChangeServiceNotAvailable: "Contactați serviciul de relații cu clienți",
  passwordResetTitle: "Solicitare parola nouă",
  passwordResetDescription: "Introduceți numele utilizator și adresa de e-mail!",
  changePasswordTitle: "Chimbarea parolei",
  changePasswordDescription:'Vă rog introduceți parola utilizatorului de mai jos:',
  passwordResetImportantDescription: 'Important! Modificarea parolei este posibilă numai cu adresa de mail înregistrată în sistemul iTrack.',
  login: "Logare",
  dontHaveAccount: "Nu ai cont?",
  signUp: "Creare cont",
  username: "Nume de utilizator",
  registrationTitle: 'Înregistrare',
  back: 'Înapoi',
  cars: 'Vehicule',
  selectableCars: 'Selectați vehiculul',
  trackedCars: 'Vehicule selectate',
  allTracking: 'Selectează tot',
  selectDelete: 'Șterge selecția',
  moreInformation: 'Afișează mai multe informații',
  openGoogleMaps: 'Afișarea poziției actuale pe hartă Google Maps',
  nodata: "Nu există date",
  carShare: 'Distribuirea mașinii',
  carShareTitle: 'Distribuirea mașinii cu parteneri',
  shareTabTitle: 'Distribuie',
  page: 'Pagină',
  access: 'Acces*',
  startDate: 'Începând de la',
  endDate: 'Până la',
  pleaseWriteEmail: 'Te rugăm să introduci adresa de e-mail!',
  embed: 'Distribuire link site',
  shareButtonTitle: 'Distribuire',
  closeButtonTitle: 'Închidere',
  sheSaw: 'Citit',
  writeMessage: 'Scrie mesajul aici!',
  seding: 'Trimitere',
  messages: 'Mesaje',
  searchButtonTitle: 'Căutare',
  fuelingFormTitle: 'Alimentare*',
  dateTitle: 'Dată',
  country: 'Țară',
  position: 'Poziție',
  start: 'Început (L)',
  end: 'Sfârșit (L)',
  volume: 'Cantitate alimentată (L)',
  logInProgress: 'Logare în curs...',
  loginFailed: 'Logare nereușită',
  downtime: 'Timp de staționare',
  event: 'eveniment',
  messageSearchPlace: 'Căutare',
  messageSearchText: 'Începe să tastezi!',
  hu: 'Magyar',
  en: 'English',
  ro: 'Română',
  sr: 'Srpski',
  hr: 'Hrvatski',
  sk: 'Slovenský',
  cz: 'Český',
  maxCarTitle: 'Pot fi alese maxim {num} de mașini',
  today: 'astăzi',
  squel: 'Continuare',
  unknownError: 'Eroare necunoscută',
  systemError: 'Serviciul momentan nu este disponibil! Vă rugăm să contactați serviciul de relații cu clienți.',
  timeout: 'Conexiunea s-a întrerupt din cauza inactivității. Vă rugăm să dați clic pe următorul buton.',
  networkError: 'Rețeaua este indisponibilă. Vă rugăm să verificați conexiunea dumneavoastră.',
  notPositions: 'Următoarele mașini încă nu au coordinate:',
  accessExpired: '(RON) Valabilitatea accesului  a expirat.<br/><br/>(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(ENG) You are currently not authorized to access.<br/><br/>(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.',
  errorLogin: 'Nume de utilizator sau parolă incorectă',
  emptyFieldLogin: 'Câmpul pentru numele de utilizator sau parolă nu este completat!',
  carshareDialogText: 'Câmpul pentru începutul sau sfârșitul distribuirii nu este completat!',
  notSelectCar: 'Nici o mașină nu a fost selectată!',
  messageSearchTextNotFound: 'Căutarea nu a întors nici un ezultat!',
  internetConnectionTerminated: 'Conexiunea la internet s-a întrerupt!',
  emptyFuelList: 'Conform criteriilor selectate nu există nicio listă de alimentare',
  emptyRoutesList: 'În perioada selectată nu există niciun traseu valid!',
  speed: 'Viteză',
  map: 'Hartă',
  selectDate: 'Selectarea perioadei',
  pleaseSelectEndDate: 'Vă rugăm să selectați sfârșitul perioadei!',
  oneSelectMode: 'Mod cu o singură rută',
  multipleSelectMode: 'Mod cu mai multe rute',
  pageReload: 'Actualizare pagină',
  registrationBody: 'Stimate viitor client!<br/>Pentru înregistrare vă rugăm să luați legătura cu serviciul de relații clienți.<br/><br/>Program relații cu clienții:<br/>L-V: 08.00-17.00.',
  lostPasswordBody: 'Stimate client!<br/>Dacă ați uitat parola vă rugăm să contactați serviciul de relații clienți!<br/><br/>Program relații cu clienții:<br/>L-V: 08.00-17.00.',
  carShareText1: 'Cu această funcție puteți genera un link valid pentru ținta (țintele) aleasă în intervalul selectat. Acest link poate fi trimis către partenerii dumneavoastră, care pot urmări mașinile selectate. În afara intervalului selectat nu există posibilitatea de accesare a datelor mașinii folosind linkul.',
  carShareText2: 'Cu această funcție puteți genera un link HTML, care poate fi incorporat în site-ul dumneavoastră, arătând astfel poziția țintei (țintelor) selectate în intervalul de timp menționat.',
  refreshInProgress: 'Datele despre vehicul sunt deja în curs de actualizare',
  newPlutoDialogContent: 'Stimate client,<p><br>Vă informăm că noua aplicaţie mobilă iTrack cu un aspect reînnoit și cu funcționalităţi noi (de ex: sumar traseu și alimentări) a devenit disponibilă.</p><br><p>Vechea versiune pe care o utilizați în prezent va fi retrasă în curând. Data estimată a retragerii este <YYYYMMDD>.</p><br><p>Prin urmare, vă rugăm să selectați noua versiune de client mobil disponibilă pe platforma cea mai potrivită pentru dvs. (Web, Android, iOS) până la data specificată și să treceți la utilizarea acesteia.</p><br><p>Noile versiuni pot fi accesate de la oricare dintre linkurile de mai jos:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Telematics</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>Cu stimă,<br><br>Echipa iData',
  newPlutoRecommendContent: 'Descoperiți noua generație a aplicației iTrack Mobil!<p><br>Tehnologie avansată, funcții utile, funcționare mai rapidă, mai fiabilă și mai stabilă și management mai transparent pentru a vă ajuta afacerea să funcționeze mai eficient.</p><br><p>Treceți la nivelul următor cu noua aplicație iTrack Mobile!'
}
