

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var Input = require('react-bootstrap/lib/Input');
var Button = require('react-bootstrap/lib/Button');

var GeminiScrollbar = require('react-gemini-scrollbar');

var CarsListItem = require('./CarsListItemRouter.jsx');

var Ajax = require('../mixins/Ajax.jsx');

var Infinite = require('react-infinite');


module.exports = React.createClass({

	mixins: [Languages, Ajax],

	infiniteScrollStop: false,
	infiniteScrollPage: 1,
	searchValue: null,
	isSearch: false,

  getInitialState: function() {
    return {
      cars: [],
      isSelected: false,
      selectedCarId : null,
			isLoadList: false,
			carsElement: [],
			isInfiniteLoading: false,
			carsItemKey: 0,
			infiniteScrollContainerHeight: 0
    };
  },

  getDefaultProps : function(){
    return {
      handleItemSelect : function(){},
      tokenAuth: false,
			changeMobilHeaderTitle: function(){},
			getCarsLimit: 50
    }
  },

	setSelectedCarId: function(value) {
		this.setState( {
			selectedCarId: value
		});
	},

	isSelectCar: function(carId) {
		var select = false;

		if(this.state.selectedCarId == carId) {
			select = true;
		}

		return select;
	},

	componentDidMount: function() {
		window.addEventListener("resize", this.resizeWindow);
	},

	resizeWindow: function() {
		this.setState( {
			infiniteScrollContainerHeight: this.settingHeightInfiniteContainer()
		});
	},

	settingHeightInfiniteContainer: function() {
		var containerHeight = window.innerHeight;

		if(window.innerWidth > 992) {
			containerHeight -= 144;
		} else {
			containerHeight -= 142;
		}

		return containerHeight
	},

  //lekérjük az autók listáját
  getCarsList: function() {
    var _this = this;

		if(_this.infiniteScrollStop) {
			this.setState( {
				isInfiniteLoading: false
			});
			return;
		}

		this.setState( {
			isInfiniteLoading: true
		}, function() {
			var url = null;
			var limit = _this.props.getCarsLimit;
			var page = _this.infiniteScrollPage;
			var params = "offset=" + ((page * limit) - limit) + "&limit=" +  limit;

			if(_this.searchValue == null) {
				url = "targets?" + params;
			} else {
				url = "targets?filter=" + _this.searchValue + '&' + params;
			}

			$.ajax({
				url: "@@servletUrl" + url,
				type: "GET",
				timeout : 30000,
				beforeSend: function (xhr) {
					if(_this.props.tokenAuth == true) {
						xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
					} else {
						xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
					}
					xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
				},
				success: function(response) {
					if(_this.props.getCarsLimit > response.length) {
						_this.infiniteScrollStop = true;
					}

					if(response.length == 0) {
						_this.isSearch = false;
						_this.setState( {
							isInfiniteLoading: false
						});

						return;
					}

					var actualCarList = _this.state.carsElement;
					var newCarList = _this.getCarsListItems(response)

					// Gépjárművek kiíratása
					_this.setState( {
						isInfiniteLoading: false,
						carsElement : actualCarList.concat(newCarList)
					}, function() {
						$('.carslistInfinite').css('display','inline-block');
					});

					_this.infiniteScrollPage++;
					_this.isSearch = false;
				},
				error: function(response, status, xhr) {
					_this.ajaxError(response, status, xhr, _this.props.addNotification, _this.props.addTranslateNotification, null);
				}
			});
		});
  },

  getCarsListItems: function(carArr) {
    var _this = this;

    var carsArray = [];

    // Ha kapunk vissza gépjárműveket akkor végigmegyünk rajta majd megjelenítjük a felhasználónak
    if(carArr.length > 0) {
			var i = this.state.carsItemKey;

      carArr.forEach(function(car) {
        var isSelected = false;

        if( _this.state.selectedCarId == car.id ){
          isSelected = true;
        }

        var id = 'carslistitem' + car.id;

        carsArray.push(<CarsListItem id={id} key={i} handleChange={_this.handleItemChange} isSelectCar={_this.isSelectCar} carId={car.id} carName={car.name} carDesc={car.description} changeMobilHeaderTitle={_this.props.changeMobilHeaderTitle} />);

				i++;
      });

			this.setState( {
				carsItemKey: i
			});
    }

		return carsArray;
  },

	//listaelemen való kattintás lekezelése
  handleItemChange : function(item){
    var _this = this;
    var selectedCarId = (item.selected)?(item.carId):(null);

    this.setState({ selectedCarId : selectedCarId });

    this.props.handleItemSelect({
      type : 'car',
      carId : selectedCarId
    });

  },

	emptySearchInput: function() {
		var value = this.refs.searchInput.getValue();

		if(value.length == 0) {
			this.setState( {
				isLoadList: false
			});
			this.doSearch(null);
		}
	},

	handleSearchButton: function() {
		var value = this.refs.searchInput.getValue();

		if(value.length > 0 && !this.isSearch) {
			this.doSearch(value);

			$('#searchInput').blur();
		}

		return false;
	},

	doSearch: function(value) {
		var _this = this;

		this.isSearch = true;
		this.searchValue = value;
		this.infiniteScrollStop = false;
		this.infiniteScrollPage = 1;

		this.setState( {
			carsElement: [],
			carsItemKey: 0
		}, function() {
			_this.getCarsList();
		});
	},

	elementInfiniteLoad: function() {
		return (
			<div className='loaderCarList'><span></span></div>
		);
	},

  render: function() {
		var containerHeight = 0;
		if(this.state.infiniteScrollContainerHeight == 0) {
			containerHeight = this.settingHeightInfiniteContainer();
		} else {
			containerHeight = this.state.infiniteScrollContainerHeight;
		}

		var innerButton = (<Button onClick={this.handleSearchButton}><i className='icon-search'></i></Button>);

    return (
      <section id="carsList" className="oneSelectCarsList">
        <header>
          <h1>{this.getValue('cars')}</h1>
        </header>

				<form onSubmit={this.handleSearchButton} className="searchBox">
					<Input
							id="searchInput"
							type="text"
							label=""
							help=""
							ref="searchInput"
							spellcheck="false"
							groupClassName=""
							placeholder={this.getValue('searchButtonTitle')}
							onChange={this.emptySearchInput}
							buttonAfter={innerButton}
						/>
				</form>

				<div className="carslistInfinite">
					<Infinite
							elementHeight={58}
							containerHeight={containerHeight}
							infiniteLoadBeginEdgeOffset={containerHeight - 100}
							onInfiniteLoad={this.getCarsList}
							loadingSpinnerDelegate={this.elementInfiniteLoad()}
							isInfiniteLoading={this.state.isInfiniteLoading}
						>
						{this.state.carsElement}
					</Infinite>
				</div>
      </section>
    )
  }
});
