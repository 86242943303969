var Router = require('react-router');
var Navigation = Router.Navigation;
var $ = require('jquery');
import { SentryUtils } from '../model/SentryUtils';

module.exports = {
  mixins: [Navigation],

  authenticate: function (token, callback) {
    this.loginAjax(
      token,
      function (response, xhr) {
        if (response != null) {
          if (typeof response !== 'undefined' && typeof response.id !== 'undefined') {
            localStorage.setItem('tokenId', token);
            localStorage.setItem('token_user_id', response.id);
            if (typeof callback == 'function') {
              callback(xhr);
            }
          }
        } else {
          if (typeof callback == 'function') {
            callback(xhr);
          }
        }
      });
  },

  checkLogin: function (token, redirect) {
    return this.loginAjax(token, redirect, true);
  },

  loginAjax: function (token, callback, isCheck) {
    $.ajax({
      url: "@@servletUrl" + "users/current",
      type: "GET",
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Token " + token);
      },
      success: function (response, status, xhr) {
        SentryUtils.setUser(response.id);
        SentryUtils.setTag("token", token);
        if (typeof callback == 'function') {
          if (isCheck) {
            if (xhr.status != '200') {
              callback();
            }

            return false;
          }
          callback(response, xhr);
        }
      },
      error: function (xhr, textStatus, errorThrown) {
        SentryUtils.setUser(null);
        SentryUtils.setTag("token", token);
        if (isCheck) {
          if (typeof callback == 'function') {
            callback(xhr);
          }
        } else {
          if (typeof callback == 'function') {
            callback(null, xhr);
          }
        }
      }
    });
  }
}
