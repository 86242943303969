// A nyelvi elemek betöltéséért felelős modul.

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------


module.exports = {

  languages: null,

  // Betölti a nyelveket
  setLanguages: function() {
    var self = this;

    self.languages = require('../I18n/lang.js');
  },

  // Visszaadja az alapértelmezett nyelvet.
  getDefaultLanguage: function() {
    var self = this;

    return self.languages.defaultLanguage;
  },

  // Visszaadja az adott nyelvhez tartozó nyelvi értékeket.
  getLanguageValues: function(locale) {
    var self = this;

    var lang = self.languages.languages[locale];

    switch (lang) {
      case 'hu':
        var data = require('../I18n/hu.js');
        break;
      case 'en':
        var data = require('../I18n/en.js');
        break;
      case 'ro':
        var data = require('../I18n/ro.js');
        break;
      case 'sr':
        var data = require('../I18n/sr.js');
        break;
      case 'hr':
        var data = require('../I18n/hr.js');
        break;
      case 'sk':
        var data = require('../I18n/sk.js');
        break;
      case 'cz':
        var data = require('../I18n/cz.js');
        break;
      default: var data = require('../I18n/hu.js');
    }

    return data;
  }

};
