

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var moment = require('moment');


module.exports = React.createClass({

  mixins: [Languages],

  getDefaultProps: function(){
    return {
      threadUser : {},
      lastMessage : undefined,
      threadId : null,
      listClick : function(){},
      active : false
    }
  },

  componentWillMount: function() {
    var lang = localStorage.getItem('lang');
    moment.locale(lang);
  },

  handleClick : function(){
    this.props.listClick(this.props.threadId);

    $('#messagesList').addClass('hiddenMobile');
  },

	removeUnread: function() {

	},

  render: function() {
    var lastMsgBody = '',
        liClass = 'empty-thread',
        timeStr = '',
        lastMsgUser = '';

    if( typeof this.props.lastMessage !== 'undefined' ){
      liClass = this.props.lastMessage.sentStatus.timeMicros;
      var time = this.props.lastMessage.sentStatus.timeMicros / 1000;

      if( this.props.lastMessage.readStatus.timeMicros !== 0 ){
        time = this.props.lastMessage.readStatus.timeMicros / 1000;
      }

      var date = new Date(time);

      if(new Date().getDate() === date.getDate() ){
        timeStr +=  this.getValue('today') + ', ';
      } else {
        timeStr += moment(time).format('L (dddd)') + ', ';
      }

      timeStr += moment(time).format('HH:mm');

      if( this.props.active ){
        liClass += ' active';
      }

      var user_id = localStorage.getItem('user_id');
			if(this.props.lastMessage.readStatus.name === 'unread' && this.props.lastMessage.deliveryStatus.name === 'delivered' && this.props.lastMessage.type != 'sent'){
        liClass += ' unread';
      }

      lastMsgBody = this.props.lastMessage.content.body;
      lastMsgUser = this.props.lastMessage.fromUser['name'];
    }

    return (
      <li onClick={this.handleClick} className={liClass} data-id={this.props.threadId} >
        <div>
          <header>
            <h2>
              {this.props.threadUser.name}
            </h2>
            <time>{timeStr}</time>
            <h3>
              {lastMsgUser}
            </h3>
          </header>
          <p>
            {lastMsgBody}
          </p>
        </div>
      </li>
    );
  }
});
