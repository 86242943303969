// React betĂ¶ltĂ©se
var React = require('react');
// -----------------------------------------------------

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

var Ajax = require('../mixins/Ajax.jsx')

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Router betöltése, inicializálása
var Router = require('react-router');
// -----------------------------------------------------

// Nyelvválasztó modul
var LanguagesSelector = require('./LanguagesSelector.jsx');

var Languages = require('../mixins/Languages.jsx');

// Form elemek importálása
var FormElementInput = require('./FormElementInput.jsx');
var ButtonInput = require('react-bootstrap/lib/Input');
// -----------------------------------------------------

var Loader = require('./Loader.jsx');

var Notification = require('../mixins/Notification.jsx');

// email küldés betöltése
var reset = require('../commons/sendPasswordReset.js');

// Autentikáció betöltése
var auth = require('../commons/authenticate.js');

module.exports = React.createClass({

  mixins: [Router.Navigation,Languages,Notification,Ajax,Brands],
  componentWillMount: function() {
    var lang = this.props.query.lang;
    localStorage.setItem('lang', lang);
    this.setLangState();
  },
  //handle button click
  handleSubmit: function(){
      
    var _this = this;

    var username = this.refs.username.getValue();
    var email = this.refs.email.getValue();
    var lang = localStorage.getItem('lang');
      
    if(username.length != 0 && email.length != 0){

      //email formátum ellenőrzés
      if(this.validateEmail(email) == true){

        // Ajax loader megjelenítée a bejelentkezés gomb helyén
        $('.FormElementButton').parent().hide();
        $('.loaderLogin').show();
        // -----------------------------------------------------

        var errorAction = {
          label: this.getValue('passwordChangeServiceNotAvailable'),
          callback: function() {
            _this.navigation('forgotemailusernamepair','forgotemailusernamepair');
          }
        };
        //email küldése a megadott email címre authentikációt követően
        reset.sendreset(username,email,lang,errorAction,this.addNotification,this.addTranslateNotification,this.success);

        }else{
          this.addTranslateNotification('wrongEmailFormat','warning');
          $('.icon-send').addClass('error animated shake');
        }

      }else {
        this.addTranslateNotification('emptyFieldPasswordReset', 'warning'); 
        $('.icon-user').addClass('error animated shake');
        $('.icon-send').addClass('error animated shake');
      }

      return false;
  },
  validateEmail: function(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  navigation: function(url, label) {
    this.transitionTo(url);

  },
  success : function(response, status, xhr) {
    this.addTranslateNotification('successPasswordResetEmailSend','info')
    $('.FormElementButton').parent().show();
    $('.loaderLogin').hide();
  },
  // Nyelv módosítás esetén a localStorage-ban lévő lang
  handleLocaleChange: function(lang) {
  	this.setLangValue(lang.id);
    this.setLangState();
		// Nyelvi kulcs frissítése
    $('body').removeAttr('class').addClass(localStorage.getItem('lang'));
  },

	getLanguages: function() {
		var actualLang = localStorage.getItem('lang');
		var actualLangIndex = null;
    var languages = [
        {id: 'hu',title: this.getValue('hu'), name: this.getValue('hu'), flagImg: '',flagTitle: ''},
        {id: 'en',title: this.getValue('en'), name: this.getValue('en'), flagImg: '',flagTitle: ''},
        {id: 'ro',title: this.getValue('ro'), name: this.getValue('ro'), flagImg: '',flagTitle: ''},
        {id: 'sr',title: this.getValue('sr'), name: this.getValue('sr'), flagImg: '',flagTitle: ''},
        {id: 'hr',title: this.getValue('hr'), name: this.getValue('hr'), flagImg: '',flagTitle: ''},
        {id: 'sk',title: this.getValue('sk'), name: this.getValue('sk'), flagImg: '',flagTitle: ''},
        {id: 'cz',title: this.getValue('cz'), name: this.getValue('cz'), flagImg: '',flagTitle: ''}
    ];

		languages.forEach(function(item, index) {
			if(item.id == actualLang) {
				actualLangIndex = index;
			}
		});

		var actualLangItem = languages.splice(actualLangIndex, 1);
		languages.unshift(actualLangItem.shift());

		return languages;
	},
  render: function(){

    var languages = this.getLanguages();

      return (
        <main id="passwordResetPage">
        <span className="passwordResetBg"></span>
        <div className="container" >
          <form className="PasswordReset col-xs-12 col-sm-6 col-md-4 col-md-offset-4 col-sm-offset-3" onSubmit={this.handleSubmit}>
          <h1 className="logo" style={{backgroundImage: 'url(' + this.getResourceItemValue('logo') + ')'}}></h1>
          
          <h1 className="passwordResetTitle"> {this.getValue('passwordResetTitle')}</h1>

          <h1 className="passwordResetDescription">{this.getValue('passwordResetDescription')}</h1>

          <h1 className="passwordResetImportantDescription">{this.getValue('passwordResetImportantDescription')}</h1>

              <FormElementInput
                inputType="mail"
                ref="username"
                instanceName="mail"
                placeholder={this.getValue('username')}
                label=""
                icon={<i className='icon-user'></i>}
                required={true}
              />

              <FormElementInput
                inputType="mail"
                ref="email"
                instanceName="mail"
                label=""
                placeholder={this.getValue('email')}
                icon={<i className='icon-send'></i>}
                required={true}
              />

              <div className="forgotEmailUsernamePairBox">
                <a className="forgotEmailUsernamePair" onClick={this.navigation.bind(this, 'forgotemailusernamepair', 'Nem sikerült elküldeni az emailt?')}>{this.getValue('forgotEmailUsernamePair')}</a>
              </div>

              <ButtonInput
                ref="submit"
                instanceName="submit"
                onSubmit={this.handleSubmit}
                type='submit'
                value={this.getValue('sendPasswordResetButtonText')}
                className="FormElementButton"
                id="SubmitButton"
              />

              <Loader/>

            </form>

            <div className="languagesSelector">
            <LanguagesSelector
              items={languages}
              gridColumns="1"
              openMode="click"
              showFlag={false}
              id="languagesSelector"
              onLanguageChanged={this.handleLocaleChange}
            />
            </div>

        </div>
        {this.rednderNotification()}
      </main>

      )
  }


});