
// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Bootstrap modulok betöltése
var OverlayTrigger = require('react-bootstrap/lib/OverlayTrigger');
var Tooltip = require('react-bootstrap/lib/Tooltip');
// -----------------------------------------------------


module.exports = React.createClass({

	mixins: [Languages],

	getInitialState: function() {
		return {
			isSelected: false,
			mapStatus: false
		};
	},

	infoButton: function() {
		return (
				<button className="icon-information" onClick={this.props.infoButton} title={this.getValue('moreInformation')}></button>
		);
	},

	openShareModal: function() {
		this.props.openShareModal(this.props.carId);
	},

	carShareButton: function() {
		return (
				<button className="icon-share btn btn-primary" title={this.getValue('carShare')} onClick={this.openShareModal}></button>
		);
	},

	ajaxLoader: function() {
		return (
			<span className="loaderTrackingCar"></span>
		);
	},

	gmapsButton: function() {
		var gmapsButtonCls = 'icon-map car_' + this.props.carId;
		var actualCoordinate = this.props.actualCoordinate;
		var href = 'https://maps.google.com/?t=k';

		if(actualCoordinate != null) {
			var lat = actualCoordinate.lat;
			var long = actualCoordinate.long;

			href = 'https://maps.google.com/?q='+ lat +',' + long + '&t=k';
		}

		return (
			<a href={href} title={this.getValue('openGoogleMaps')} className={gmapsButtonCls} target="_blank" onClick={this.gmapsButtonGaEvent}></a>
		);
	},

	gmapsButtonGaEvent: function() {
	},

	isSelectCar: function() {
		return this.props.isSelectCar(this.props.carId);
	},

	handleSelect: function() {
		this.props.onSelect(this.props.carId, this.isSelectCar());
		this.forceUpdate();
	},

	showMap: function(status) {
		return this.props.isShowMap(this.props.carId, status);
	},

  render: function() {
		var ajaxLoader = undefined;
		var infoButton = undefined;
		var gmapsButton = undefined;
		var careShare = undefined;

		var active = this.props.isSelectCar(this.props.carId);
		var mapStatus = this.showMap(active);

		if(mapStatus == 'loading') {
			ajaxLoader = this.ajaxLoader();
		} else if(mapStatus == true) {
			infoButton = this.infoButton();
			gmapsButton = this.gmapsButton();
		}

		if(!this.props.visitor) {
			careShare = this.carShareButton();
		}

    return(
			<div className="carsElement" data-id={this.props.carId} data-maps={mapStatus}>
				<h2 data-select={active} onClick={this.handleSelect}>
					<i></i>
					<span className="id">{this.props.carName}</span>
					<span className="name">{this.props.carDesc}</span>
				</h2>

				{careShare}
				{gmapsButton}
				{infoButton}
				{ajaxLoader}
			</div>
    );
  }
});
