var $ = require('jquery');
var Base64 = require('js-base64').Base64;
var ajax = require('../mixins/Ajax.jsx');
var passwordreset = require('./passwordResetCallback.js');

module.exports = {

    sendreset: function (username, email,lang,errorAction,addNotification,addTranslateNotification, successCallback){
        $.ajax( {
            url: "@@servletUrl" + "passwordreset/sendreset?desiredUser="+username+"&desiredEmail="+email+"&lang="+lang ,
            type: "POST",
            timeout : 5000,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Accept", "application/json");
                xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
              },
            success : function(response, status, xhr) {
               successCallback(response,status,xhr)
            },
            error : function(response, status, xhr) {
                passwordreset.passwordResetErrorcallback(response, status, xhr, errorAction, addNotification, addTranslateNotification);
            }
        });
    }

}