// React betöltése
var React = require('react');

// Nyelvesítés betöltése
var i18nLoader = require('../commons/I18n.js');
// -----------------------------------------------------
var Brands = require('../mixins/Brands.jsx');

export default function Language(Component) {

    class Lang extends React.Component {

        constructor(props) {
            super(props)
            this.getLangValue = this.getLangValue.bind(this);
            this.state = {
                lang: i18nLoader.getLanguageValues(this.lang())
            }
        }

        lang() {
            return localStorage.getItem('lang');
        }

        setLangValue(lang) {
            localStorage.setItem('lang', lang);
            if (window.CC) {
                window.CC.updateLanguage(lang, true)
            }
        }

        setLangState() {
            this.setState({
                lang: i18nLoader.getLanguageValues(this.lang())
            });
            Brands.setResourceState();
        }

        getLangValue(label) {
            var value = this.state.lang[label];

            if (typeof value === 'undefined') {
                return '{{' + label + '}}';
            } else {
                return value;
            }
        }

        render() {
            return (
                <Component
                    getLangValue={this.getLangValue}
                    lang={this.state.lang}
                    {...this.props}
                    />
            );
        }

    }

    return Lang;
}