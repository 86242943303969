
// React betöltése
var React = require('react');
// -----------------------------------------------------
var Router = require('react-router');
// Autentikáció betöltése
var auth = require('../commons/authenticate.js');
import * as Sentry from '@sentry/browser';

module.exports = React.createClass({
  mixins: [Router.Navigation],
  componentWillMount: function () {
    var actualLang = localStorage.getItem('lang');
    var latestTimeMicros = localStorage.getItem('latestTimeMicros');
    //auth.logout();
    localStorage.clear();
    localStorage.setItem('lang', actualLang);
    localStorage.setItem('latestTimeMicros', latestTimeMicros);
    localStorage.setItem('user_id', -1);
    Sentry.configureScope(scope => scope.setUser(null));
    this.transitionTo('/');
  },

  render: function () {
    return (<div></div>);
  }
});
