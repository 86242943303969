// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// ------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');

// Brands betöltése
var Brands = require('../mixins/Brands.jsx');
// -----------------------------------------------------

import { DefaultTarget } from '../model/DefaultTarget';
import { RenderedTarget } from '../model/RenderedTarget';
import { TargetList } from '../model/TargetList';
import { GetTargets } from '../remote/GetTargets';

var TargetTrackingSelectableListElement = require('./TargetTrackingSelectableListElement.jsx');

import { GetTargetNumbers } from '../remote/GetTargetNumbers';
import Ajax from '../HOCs/Ajax';

class TargetTrackingSelectableList extends React.Component {

	constructor(props) {
		super(props);
		this.queryTargets = this.queryTargets.bind(this);
		this.renderTargetJson = this.renderTargetJson.bind(this);
		this.updateSelectableList = this.updateSelectableList.bind(this);
		this.resetInfiniteScrollParams = this.resetInfiniteScrollParams.bind(this);
		this.infiniteScrollStop = false;
		this.page = 0;
		this.state = {
			isInfiniteLoading: false,
			firstLoadIninite: true
		}
	}

	static selectAllTarget(selectableList, onSelectFunction){
		var allSelectable = selectableList.getAll();
        for(var i = 0; i < allSelectable.length; i++){
           onSelectFunction(allSelectable[i].getDefaultTarget())();
        }
	}

	componentDidMount(){
		if(this.props.searchValueInSelectable != null){
			GetTargetNumbers.getNumberOfSearchedTargets(this.props.searchValueInSelectable,
														this.props.setSearchedSelectableTargetNumber,
														this.props.setSearchedSelectableTargetNumberError,
														this.props.visitor);
		}
	}

	render() {
		return (
				<Infinite className="carslistInfiniteSelectable"
					elementHeight={this.props.targetElementHeight}
					containerHeight={this.props.selectableTargetsListHeight}
					infiniteLoadBeginEdgeOffset={this.props.selectableTargetsListHeight - 100}
					onInfiniteLoad={this.queryTargets}
					isInfiniteLoading={this.state.isInfiniteLoading}
					loadingSpinnerDelegate={this.elementInfiniteLoad()}
					>
					{this.props.selectableTargetList.getAllRenderedAndEnabled()}

				</Infinite>
		);
	}

	elementInfiniteLoad() {
		return (
			<div className='loaderCarList'><span></span></div>
		);
	}

	queryTargets() {
		if (this.infiniteScrollStop) {
			this.setState({
				isInfiniteLoading: false
			});
			return;
		}
		var limit = this.props.queryTargetsLimit;
		this.page = this.page + 1;
		this.setState({ isInfiniteLoading: true }, function () {
			GetTargets.query(limit, this.page, this.props.searchValueInSelectable, this.updateSelectableList, this.resetInfiniteScrollParams, this.props.visitor);
		})
	}

	componentWillReceiveProps(newProps) {
		if (newProps.selectAllRadioButtonClicked == true && newProps.selectAllRadioButtonClicked != this.props.selectAllRadioButtonClicked) {
			var length = newProps.selectableTargetList.length();
			GetTargets.queryAllTargetFromOffset(length, this.updateSelectableList, this.resetInfiniteScrollParams, newProps.visitor)
		}
		if(newProps.selectableTargetList.length() != this.props.selectableTargetList.length() && newProps.selectAllRadioButtonClicked == true) {
			TargetTrackingSelectableList.selectAllTarget(newProps.selectableTargetList, newProps.onSelectFunction);
			newProps.radioButtonClickedFunction();
		}
	}

	resetInfiniteScrollParams(response, textStatus, error){
		Ajax.showError(response, textStatus, error, this.props.notificationVersions, this.props.getLangValue);
		//TODO: Hibajelzés
		this.setState({
			isInfiniteLoading: false
		});
	}

	updateSelectableList(targetsJson) {
		var targetsJsonLength = targetsJson.length;
		if (this.props.queryTargetsLimit > targetsJsonLength) {
			this.infiniteScrollStop = true;
		}
		var selectableListToAppend = new TargetList();
		targetsJson.map(function (targetJson) {
			selectableListToAppend.add(this.renderTargetJson(targetJson))
		}, this);
		this.props.onAppendToSelectableFunction(selectableListToAppend);
		this.setState({ isInfiniteLoading: false },
			function(){
				setTimeout(function() {
					$('#overlay').removeClass('overlay');
					$('#loader').removeClass('loader');
					$('#exitButton').removeClass('exitButton icon-cancel');
				}, 100);
				if(this.state.firstLoadIninite){
					this.setState({
						firstLoadIninite: false
					})
					if(this.props.visitor){
						this.props.selectAllTargetFunction();
					}
				}
			}.bind(this));
	}

	renderTargetJson(targetJson) {
		var target = new DefaultTarget(targetJson.id, targetJson.name, targetJson.description, true);
		return new RenderedTarget(target, this.getTargetTrackingSelectedListElement(target));
	}

	getTargetTrackingSelectedListElement(target) {
		return (
			<TargetTrackingSelectableListElement
				target={target}
				key={target.getId()}
				onSelectFunction={this.props.onSelectFunction} 
				handleCarShareButton={this.props.handleCarShareButton}
				visitor={this.props.visitor}
				getLangValue={this.props.getLangValue}/>
		);
	}
}

TargetTrackingSelectableList.defaultProps = {
	selectableTargetList: new TargetList(),
	queryTargetsLimit: 200,
	maxTrackedTargetLimit: 20
};


module.exports = TargetTrackingSelectableList;