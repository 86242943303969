module.exports = {
  loginTitle: "Login",
  password: "Password",
  tracking: "Tracking",
  router: "Routes",
  message: "Messages",
  fueling: "Refueling list",
  logout: "Logout",
  email: "Email",
  wrongEmailFormat: "Wrong email format!",
  forgotYourPassword: "Forgot your password?",
  forgotEmailUsernamePair: "Unable to send the email?",
  forgotEmailUsernamePairBody: "Dear Customer!<br/>If changing your password wasn't successful please contact our customer service center!<br/><br/>",
  successPasswordResetEmailSend: "You will receive an email with a password reset link within a few minutes. ",
  successPasswordChange: "Your password has been changed successfully! Now you can log in using your new password.",
  sendPasswordResetButtonText: "Sending email!",
  emptyFieldPasswordReset: "Username or email field is empty!",
  passwordAgain: "Password again",
  emptyFieldChangePassword: "Please fill out both password fields!",
  passwordsNotMatch: "Password does not match the confirm password.",
  passwordsNotLongEnough:'Passwords must be at least 8 characters in length!',
  passwordsUpperLowerCases:'Passwords must contain: a minimum of 1 lower case letter [a-z] and; a minimum of 1 upper case letter [A-Z]',
  passwordResetTokenAuthenticationFailed: "Your temporary password reset link expired! Please request a new one and change your password within 15 minutes! ",
  changePasswordButtonText: "Change password",
  passwordChangeServiceNotAvailable: "Contact our customer service center",
  passwordResetTitle: "Password reset request",
  passwordResetDescription: "Please enter your username and email address below!",
  changePasswordTitle: "Change password",
  changePasswordDescription:'Please give your new password belonging to the following user:',
  passwordResetImportantDescription: 'Important: You can only request a new password by using your email address which is already registered in iTrack system!',
  login: "Login",
  dontHaveAccount: "Don't have an account?",
  signUp: "Sign up",
  username: "Username",
  registrationTitle: "Registration",
  back: "Back",
  cars: "Targets",
  selectableCars: "Selectable targets",
  trackedCars: "Tracked targets",
  allTracking: "Track all",
  selectDelete: "Remove selection",
  moreInformation: "Show more information",
  openGoogleMaps: "View target's actual position on Google Maps",
  nodata: "No data",
  carShare: "Share target",
  carShareTitle: "Share target with a partner",
  shareTabTitle: "Share",
  page: "Page",
  access: "Can access*",
  startDate: "From",
  endDate: "To",
  pleaseWriteEmail: "Please type in your e-mail address!",
  embed: "Embeddable sharing",
  shareButtonTitle: "Share",
  closeButtonTitle: "Close",
  sheSaw: "Seen",
  writeMessage: "Type here the message!",
  seding: "Send",
  messages: "Messages",
  searchButtonTitle: "Search",
  fuelingFormTitle: "Refueling*",
  dateTitle: "Date",
  country: "Country",
  position: "Position",
  start: "Before (L)",
  end: "After (L)",
  volume: "Quantity (L)",
  logInProgress: "Logging in is in progress...",
  loginFailed: "Unsuccessful login",
  downtime: "Idle time",
  event: "Event",
  messageSearchPlace: "Search",
  messageSearchText: "Start typing!",
  hu: 'Magyar',
  en: 'English',
  ro: 'Română',
  sr: 'Srpski',
  hr: 'Hrvatski',
  sk: 'Slovenský',
  cz: 'Český',
  maxCarTitle: "You can track only {num} targets at the same time!",
  today: 'today',
  squel: 'Sequel',
  unknownError: 'Unknown error',
  systemError: 'The service is currently not available! Please contact our customer service.',
  timeout: 'Connection timed out. Please click on the button below.',
  networkError: 'Network error occured. Please check your connection.',
  notPositions: 'The following cars don\'t have coordinate yet:',
  accessExpired: '(ENG) You are currently not authorized to access.<br/><br/>(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(RON) Valabilitatea accesului  a expirat.<br/><br/>(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.',
  errorLogin: 'Incorrect username or password!',
  emptyFieldLogin: 'Username or password field is not filled!',
  carshareDialogText: 'Starting or ending date field is required!',
  notSelectCar: 'No selected car!',
  messageSearchTextNotFound: 'No results found!',
	internetConnectionTerminated: 'Internet connection is terminated!',
	emptyFuelList: 'The indicated terms do not have to display refueling!',
	emptyRoutesList: 'No valid route to the desired period!',
	speed: 'Speed',
	map: 'Map',
	selectDate: 'Select a date',
  pleaseSelectEndDate: 'Please select an end date',
	oneSelectMode: 'One select mode',
	multipleSelectMode: 'Multiple select mode',
	pageReload: 'Reload this Page',
	registrationBody: 'Dear Prospective Customer,<br/>To register, please contact our customer service.<br/><br/>Customer service availability hours:<br/>Mon-Thu: 7:30am - 6:00pm<br/>Fri: 8:00am - 3:30pm',
	lostPasswordBody: 'Dear Customer,<br/>If you forgot your password, please contact our customer service.<br/><br/>Customer service availability hours:<br/>Mon-Thu: 7:30am - 6:00pm<br/>Fri: 8:00am - 3:30pm',
	carShareText1: 'With this function you can generate a link for the selected vehicle(s) that is available only within the given time interval. You may want to share this link with your partner so they can track your vehicles as you do. Beyond the given time interval there is no possibility to access vehicle data.',
	carShareText2: 'With this function you can generate a part of HTML code that can be embedded to websites. You can embed this code into your website to visualize the selected vehicle(s) in the given time interval.',
	refreshInProgress: 'Data refresh is already in progress for the selected vehicle',
  newPlutoDialogContent: 'Dear Customer,<p><br>The iTrack mobile client application has become available with a renewed appearance and new functionality (e.g. route- and refueling summary).</p><br><p>The old version you are currently using will expire on 31/06/2024</p><br><p>Therefore, please make your selection the new mobile client version is available on the platforms that suit you best (Web, Android, iOS), and switch to using it by the predefined time.</p><br><p>New versions can be available from any of the links below:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Telematics</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>Best regards, <br><br>the iData team',
  newPlutoRecommendContent: "Discover the new generation iTrack Mobile application!<p><br>Advanced technological solutions, useful features, faster, more reliable, and more stable operation, as well as more transparent manageability, help improve the efficiency of your company's operations.</p><br><p>Level up with the new iTrack Mobile application!"
}
