module.exports = {
  loginTitle: "Prihlásenie",
  password: "Heslo",
  tracking: "Monitorovanie",
  router: "Trasa",
  message: "Správy",
  fueling: "Zoznam tankovaní",
  logout: "Odhlásenie",
  email: "E-mail",
  wrongEmailFormat: "Nesprávny formát e-mailovej adresy!",
  forgotYourPassword: "Zabudli ste heslo?",
  forgotEmailUsernamePair: "Nepodarilo sa odoslať e-mail?",
  forgotEmailUsernamePairBody: 'Vážený zákazník!<br/>Ak sa vám nepodarilo zmeniť svoje zabudnuté heslo, kontaktujte náš zákaznícky servis!<br/><br/>Zákaznícky servis:<br/>Po-Št: 7:30-18 :00<br />Po: 8:00-15:30',
  successPasswordResetEmailSend: "Odkaz na zmenu hesla sme odoslali na uvedenú e-mailovú adresu!",
  successPasswordChange: "Úspešne sme zmenili vaše heslo. Teraz sa môžete prihlásiť do nášho systému pomocou nového hesla.",
  sendPasswordResetButtonText: 'Poslať e-mail!',
  emptyFieldPasswordReset:'Používateľské meno alebo e-mailové pole nie je vyplnené!',
  passwordAgain:'Heslo znova',
  emptyFieldChangePassword:'Vyplňte prosím obe polia!',
  passwordsNotMatch:'Dve zadané heslá sa nezhodujú!',
  passwordsNotLongEnough:'Heslá musia mať aspoň 8 znakov!',
  passwordsUpperLowerCases:'Heslá musia obsahovať veľké aj malé písmená!',
  passwordResetTokenAuthenticationFailed: 'Platnosť dočasného odkazu na zmenu hesla, ktorý používate, vypršala! Požiadajte o nové a zmeňte svoje heslo do 15 minút od vyžiadania!',
  changePasswordButtonText:'Zmeniť heslo',
  passwordChangeServiceNotAvailable:'Kontaktujte náš zákaznícky servis',
  passwordResetTitle:'Vyžiadať nové heslo',
  passwordResetDescription: 'Zadajte svoje používateľské meno a e-mailovú adresu!',
  changePasswordTitle:'Zmeniť heslo',
  changePasswordDescription:'Zadajte svoje nové heslo pre používateľské meno nižšie:',
  passwordResetImportantDescription: 'Dôležité! O nové heslo môžete požiadať iba zadaním e-mailovej adresy priradenej k vášmu používateľskému účtu v systéme iTrack!',
  login: "Prihlásenie",
  dontHaveAccount: "Ešte nemáte účet?",
  signUp: "Registrujte sa",
  username: "Meno",
  registrationTitle: 'Registrácia',
  back: 'Späť',
  cars: 'Vozidlá',
  selectableCars: 'Voliteľné vozidlá',
  trackedCars: 'Vybrané vozidlá',
  allTracking: 'Sledovať všetky',
  selectDelete: 'Odstrániť vybrané',
  moreInformation: 'Zobraziť viac informácií',
  openGoogleMaps: 'Pozrite si aktuálnu polohu vozidla na mape Google Maps',
  nodata: "Nie sú k dispozícii žiadne údaje",
  carShare: 'Zdieľať vozidlá',
  carShareTitle: 'Zdieľanie vozidla s partnerom',
  shareTabTitle: 'Zdieľať',
  page: 'Strana',
  access: 'Prístup*',
  startDate: 'Dátum od',
  endDate: 'Dátum do',
  pleaseWriteEmail: 'Prosím, zadajte svoju e-mailovú adresu!',
  embed: 'Vložiteľné zdieľanie',
  shareButtonTitle: 'Zdieľať',
  closeButtonTitle: 'Zatvoriť',
  sheSaw: 'Prečítal',
  writeMessage: 'Správu napíšte sem!',
  seding: 'Odoslať',
  messages: 'Správy',
  searchButtonTitle: 'Hľadať',
  fuelingFormTitle: 'Tankovanie*',
  dateTitle: 'Dátum',
  country: 'Krajina',
  position: 'Pozícia',
  start: 'Začiatok (L)',
  end: 'Koniec (L)',
  volume: 'Množstvo (L)',
  logInProgress: 'Prebieha prihlasovanie...',
  loginFailed: 'Prihlásenie zlyhalo',
  downtime: 'Doba státia',
  event: 'udalosť',
  messageSearchPlace: 'Hľadať',
  messageSearchText: 'Začnite písať!',
  hu: 'Magyar',
  en: 'English',
  ro: 'Română',
  sr: 'Srpski',
  hr: 'Hrvatski',
  sk: 'Slovenský',
  cz: 'Český',
  maxCarTitle: 'Je možné priradiť maximálne {num} vozidiel',
  today: 'dnes',
  squel: 'Pokračovať',
  unknownError: 'Neznáma chyba',
  systemError: 'Služba je momentálne nedostupná! Kontaktujte prosím zákaznícky servis.',
  timeout: 'Pripojenie bolo ukončené z dôvodu časového limitu. Kliknite na tlačidlo nižšie.',
  networkError: 'Sieť nie je dostupná. Skontrolujte pripojenie.',
  notPositions: 'Nasledujúce vozidlá ešte nemajú súradnice: ',
  accessExpired: '(HUN) A hozzáférésre pillanatnyilag nem jogosult.<br/><br/>(ENG) You are currently not authorized to access.<br/><br/>(RON) Valabilitatea accesului  a expirat.<br/><br/>(SRP) Vreme dozvoljenog pristupa isteklo.<br/><br/>(GER) Sie sind derzeit nicht zugriffsberechtigt.<br/><br/>(SWE) Du är för tillfället ej behörig för åtkomst.<br/><br/>(ESP) De momento, su acceso no está autorizado.<br/><br/>(NED) U bent momenteel niet gemachtigd tot toegang.<br/><br/>(FRA) Votre accès n\'est pas disponible pour le moment.<br/><br/>(SVK) Momentálne nemáte oprávnenie na prístup.<br/><br/>(CZE) Momentálně nemáte oprávnění k přístupu.',
  errorLogin: 'Nesprávne užívateľské meno alebo heslo!',
  emptyFieldLogin: 'Polia pre meno alebo heslo nie sú vyplnené!',
  carshareDialogText: 'Polia začiatku alebo konca nie sú vyplnené!',
  notSelectCar: 'Nie je vybrané žiadne vozidlo!',
  messageSearchTextNotFound: 'Žiadne výsledky!',
	internetConnectionTerminated: 'Internetové pripojenie sa stratilo!',
	emptyFuelList: 'Za uvedených podmienok nie je možné zobraziť žiadny zoznam tankovania!',
	emptyRoutesList: 'V požadovanom období neexistuje žiadna platná trasa!',
	speed: 'Rýchlosť',
	map: 'Mapa',
	selectDate: 'Vybrať dátum',
  pleaseSelectEndDate: 'Vyberte dátum ukončenia!',
	oneSelectMode: 'Jedna trasa',
	multipleSelectMode: 'Viac trás',
	pageReload: 'Obnoviť stránku',
	registrationBody: 'Vážený potenciálny zákazník!<br/>Pre registráciu kontaktujte náš zákaznícky servis.<br/><br/>Zákaznícky servis:<br/>Po-Št: 7:30-18:00<br/> Pia: 8:00 – 15:30',
	lostPasswordBody: 'Vážený zákazník!<br/>Ak ste zabudli svoje heslo, kontaktujte náš zákaznícky servis.<br/><br/>Zákaznícky servis:<br/>Po-Št: 7:30-18:00<br/ >F : 8:00 - 15:30',
	carShareText1: 'Pomocou tejto funkcie môžete vygenerovať platný odkaz na vybrané vozidlá v zadanom časovom intervale. Môžete to poslať svojim partnerom, ktorí to môžu použiť na sledovanie vašich vozidiel. Mimo stanoveného časového intervalu nie je možné cez vygenerovaný odkaz pristupovať k údajom o vozidle.',
	carShareText2: 'Pomocou tejto funkcie môžete vygenerovať HTML detail, ktorý je možné vložiť do webových stránok, pomocou ktorého môžete v zadanom časovom intervale zobraziť vybrané vozidlá na vašej webovej lokalite.',
	refreshInProgress: 'Údaje o vozidle sa už aktualizujú',
  newPlutoDialogContent: 'Vážený zákazník,<p><br>Aplikácia mobilného klienta iTrack je dostupná s vynoveným vzhľadom a novou funkcionalitou (napr. súhrn trasy a tankovania).</p><br><p>Platnosť starej verzie, ktorú momentálne používate, vyprší <DDMMRRRR></p><br><p>Preto si prosím vyberte, či je nová verzia mobilného klienta dostupná na platformách, ktoré vám najviac vyhovujú (Web, Android, iOS) a prejdite na jej používanie v preddefinovanom čase.</p><br><p>Nové verzie môžu byť dostupné na ktoromkoľvek z nižšie uvedených odkazov:</p><br><p>Web: <a href=https://beta-m.itrack.hu>https://beta-m.itrack.hu</a></p><br><p>Android: <a href=https://play.google.com/store/apps/details?id=hu.itrack.itrackmobile>iTrack Telematics</a></p><br><p>iOS: <a href=https://apps.apple.com/pl/app/itrack-telematics/id6446988003>iTrack Telematics</a></p><br>S Pozdravom,<br><br>tím iData',
  newPlutoRecommendContent: 'Objavte novú generáciu aplikácie iTrack Mobil!<p><br>Pokročilé technologické riešenia, užitočné funkcie, rýchlejší, spoľahlivejší a stabilnejší výkon, ako aj prehľadnejšia spravovateľnosť pomáhajú zlepšiť efektívnosť fungovania Vašej spoločnosti.</p><br><p>Postúpte na novú úroveň s novou aplikáciou iTrack Mobil!'
}
