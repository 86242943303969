module.exports = {

	componentDidMount: function() {
		window.addEventListener('storage', this.onStorageEvent, false);
	},

	onStorageEvent: function(storageEvent) {
		if(storageEvent.key == 'user_id') {
			this.transitionTo('/');
		}
	}

}
