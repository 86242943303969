// React betöltése
var React = require('react');
// -----------------------------------------------------
var $ = require('jquery');
import ServerMaintenance from '../model/ServerMaintenance';

module.exports = {

	ajaxError: function(response, status, xhr, addNotification, addTranslateNotification, reFunction) {
		var isSleep = localStorage.getItem('isSleep');
		var httpStatus = response.status;
        var readyState = response.readyState;
		var label = '';
		var text = '';
		var action = {
			label: this.getValue('pageReload'),
			callback: function() {
				location.reload();
			}
		};

        $('#overlay').removeClass('overlay');
        $('#loader').removeClass('loader');
        $('#exitButton').removeClass('exitButton icon-cancel');

		 //Nem sikerült a kapcsolatot megnyitni (hálózati hiba vagy timeout)
        if (readyState == 0) {
            if(isSleep == 'false'){
                switch (status) {
                    case "error":
                        if($('.offline-ui').hasClass('offline-ui-down')){
                            addTranslateNotification("networkError",
                                                                    "error",
                                                                    action);
                        }
                        //Ha mégsem hálózati hiba miatt nem tudunk csatlakozni a szerverhez (pl a böngésző kilőtte a kérést)
                        else{
                            addTranslateNotification("systemError",
                                                            "error",
                                                            action);
                        }
                        break;
                    case "timeout":
                        addTranslateNotification("timeout",
                                                                "error",
                                                                action);
                        break;
                }
            }
        }
        //Létrejött a kapcsolat, csak vagy a kliensoldalon vagy a szerveroldalon történt hiba
        else {
            if (httpStatus >= 400 && httpStatus < 500) {
                label = 'warning';
                action = null;
            } else if (httpStatus >= 500) {
                label = 'error';
            }
            if(httpStatus != 504){
                if (typeof response.responseJSON != 'undefined') {
                    var errorMessage = response.responseJSON.localizedMessage;
                    if(typeof response.responseJSON.serverMaintenance != 'undefined'){
                        ServerMaintenance.serverMaintenance = response.responseJSON.serverMaintenance;
                    }
                    if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
                        text = errorMessage;
                        addNotification(text, label, action);
                    } else {
                        addTranslateNotification('systemError', label, action);
                    }
                } else if(typeof response.responseText != 'undefined') {
                    try{
                        var parsedErrorMessage = JSON.parse(response.responseText);
                        var errorMessage = parsedErrorMessage.localizedMessage;
                        if(typeof parsedErrorMessage.serverMaintenance != 'undefined'){
                            ServerMaintenance.serverMaintenance = parsedErrorMessage.serverMaintenance;
                        }
                        if (errorMessage != undefined && errorMessage != null && errorMessage.length > 0) {
                            text = errorMessage;
                            addNotification(text, label, action);
                        }
                        else{
                            addTranslateNotification('systemError', label, action);
                        }
                    }
                    catch(error){
                        addTranslateNotification('systemError', label, action);
                    }
                }
                else{
                    addTranslateNotification('systemError', label, action);
                }
            }
        }
	}
};
