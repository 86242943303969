

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

var moment = require('moment');


module.exports = React.createClass({

	mixins: [Languages],

	hideEventsList: false,

  getInitialState: function() {
    return {
      open: false,
      data: this.props.data,
      active: false,
			isLoad: null,
			addressCut: true
    };
  },

  getDefaultProps : function(){
    return {
      handleClick : function(){}
    }
  },

	setIsLoad: function(value) {
		this.setState( {
			isLoad: value
		});
	},

  setActive: function(value) {
    this.setState( {
      active: value
    });
  },

  eventList: function() {
		if(!this.hideEventsList) {
			var open = !this.state.open;

			this.setState( {
				open: open
			});

		}
  },

  handleSelect : function(){
    var _this = this;
    var active = !this.state.active;

		if(this.props.getIsAjaxRoute()) {return;}

		this.props.setIsAjaxRoute(true);

		if(active == true) {
			var isLoad = true;

		} else {
			var isLoad = null;
			this.props.setIsAjaxRoute(false);
		}

    _this.setState({
			active: active,
			isLoad: isLoad
    });

    _this.props.handleClick(this.props.id, _this.state.data['startPosition']['timeMicros'], _this.state.data['stopPosition']['timeMicros'], active, _this.setIsLoad );
  },

	showAddress: function() {
		var addressCut = this.state.addressCut;
		this.setState( {
			addressCut: !addressCut
		});
	},

  render: function() {
    var route = this.state.data;

    var eventActive = this.state.open == false ? 'hidden eventList' : 'eventList';
    var eventButton = this.state.open == false ? 'down icon-arrow' : 'up icon-arrow';

    var startTime = moment(route.startPosition.timeMicros / 1000).format("HH:mm:ss");
    var endTime = moment(route.stopPosition.timeMicros / 1000).format("HH:mm:ss");

    var durationTime = moment.utc(moment(route.durationTimeMicros / 1000)).utcOffset("00:00").format("H:mm:ss");
    var standTime = moment.utc(moment(route.standTimeMicros / 1000).utcOffset("00:00")).format("H:mm:ss");

		var addressCut = '';

		if(this.state.addressCut) {
			addressCut = 'cut';
		}


    //Események listázása
    var eventsLength = route.events.length;
    var emptyEvents = eventsLength == 0 ? 'empty' : '';
    var eventsButtonTitle = eventsLength + ' ' + this.getValue('event');

    var poi = undefined;
    var locationCls = 'location displayTable';
    if( route.stopPosition.poi != null ){
      poi = <span className={addressCut} title={route.stopPosition.poi}>{route.stopPosition.poi}</span>;
      locationCls = 'location';
    }

    var eventsList = [];

		if(route.events.length > 0) {
			route.events.forEach(function(event) {
				var eventDate = moment(event.eventTimeMicros / 1000).format("HH:mm");

				if(event.marker == 'running') {
					var iconsMarker = <i className="icon-moveCar"></i>;
				} else if(event.marker == 'standing') {
					var iconsMarker = <i className="icon-waitCar"></i>;
				}


				eventsList.push(
					<li>
						{iconsMarker}
						<span>{eventDate}</span>
						<p>
							{event.description}
						</p>
					</li>
				);
			});
		} else {
			this.hideEventsList = true;
		}

		if(this.state.isLoad == true) {
			var loadElement = (<span className="loaderRoutesItem"></span>);
		} else if(this.state.isLoad == false) {
			var loadElement = (<i className="icon-cancel"></i>);
			var activeButton = 'active';
		} else {
			var loadElement = (<i className="icon-arrowLeft"></i>);
			var activeButton = '';
		}

    return(
      <div className="routerItem">
        <div>
          <div>
            <div>
              <span className="begin">
                {startTime}
                <i className="icon-arrowRight"></i>
              </span>
              <span className="time">{durationTime}  <i className="line">|</i> {route.distanceKm} km</span>
              <span className="end">
                {endTime}
                <i className="icon-arrowRight"></i>
              </span>
            </div>
            <p className={locationCls} onClick={this.showAddress}>
              <i className="icon-map"></i>
              { poi }
              <span className={addressCut} title={route.stopPosition.geocodedAddress}>{route.stopPosition.geocodedAddress}</span>
            </p>
          </div>
          <div>
            <button id="ShowOnMap" onClick={this.handleSelect} className={activeButton}>
							{loadElement}
            </button>
          </div>
        </div>
        <p>{this.getValue('downtime')}: {standTime}</p>
        <button id="GetEventList" onClick={this.eventList} className={emptyEvents}>
          {eventsButtonTitle}
          <i className={eventButton}></i>
        </button>
        <ul className={eventActive}>
          {eventsList}
        </ul>
        <div id="hideButton" className={eventActive}>
          <button id="HideEventList" onClick={this.eventList}>
            <i className="icon-arrowUp"></i>
          </button>
        </div>
      </div>
    );
  }
});
