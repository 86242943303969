

// React betöltése
var React = require('react');
// -----------------------------------------------------

// Jquery betöltése
var $ = require('jquery');
// -----------------------------------------------------

// Nyelvesítés betöltése
var Languages = require('../mixins/Languages.jsx');
// -----------------------------------------------------

// Login csekkolás
var Authentication = require('../mixins/Authentication.js');
var Router = require('react-router');
var RouteHandler = Router.RouteHandler;
var Navigation = Router.Navigation;
// -----------------------------------------------------

// Oldalsó menüsáv betöltése
var SideMenu = require('./SideMenu.jsx');

// Gépjárműlista betöltése
var CarsList = require('./CarsListRouter.jsx');

// Datepicker betöltése
var DatePicker = require('react-datepicker');
var moment = require('moment');
// -----------------------------------------------------

// Mobil fejléc betöltése
var MobileHeader = require('./MobileHeader.jsx');

// Mobil menü betöltése
var MobilMenu = require('./MobileMenu.jsx');

// Mobil hasábválasztó
var MobilTabs = require('./FuelingMobilHeader.jsx');

// Bootstrap elemek importálása
var Input = require('react-bootstrap/lib/Input');
var Row = require('react-bootstrap/lib/Row');
var Col = require('react-bootstrap/lib/Col');
var Button = require('react-bootstrap/lib/Button');
// -----------------------------------------------------

var DateTime = require('react-datetime');

var Notification = require('../mixins/Notification.jsx');
var Ajax = require('../mixins/Ajax.jsx');


module.exports = React.createClass({

  mixins: [Navigation, Authentication, Languages, Notification, Ajax],

  getInitialState: function () {
    return {
      share_start: null,
      share_end: null,
      weekdays: undefined,
      locale: '',
      dateFormatCallendar: "YYYY MMMM",
      shareUrl: '',
      selectedCarId: null,
      startMicros: null,
      endMicros: null,
      listElems: [],
      stopLoadItems: false,
      mobilHeaderTitle: '',
      isLoadList: null,
      isInfiniteScrollLoad: false,
      datePickerOptions: { readOnly: "readonly" }
    };
  },

  getDefaultProps: function () {
    return {
      loading: false,
      limit: 20,
      page: 0
    }
  },

  handleShareStartDateChange: function (date) {
    var time = date.hour(0).minute(0).seconds(0).unix() * 1000 * 1000;

    if (typeof this.refs.startDate != 'undefined') {
      this.refs.startDate.handleClickOutside();
    }

    this.setState({
      share_start: date,
      startMicros: time
    });
  },

  handleShareEndDateChange: function (date) {
    var time = date.hour(23).minute(59).seconds(59).unix() * 1000 * 1000;

    if (typeof this.refs.endDate != 'undefined') {
      this.refs.endDate.handleClickOutside();
    }

    this.setState({
      share_end: date,
      endMicros: time
    });
  },

  handleItemSelect: function (car) {
    var carId = null;
    if (typeof car != 'undefined' && typeof car.carId != 'undefined') {
      carId = car.carId;
    }

    this.setState({
      selectedCarId: carId,
      listElems: []
    });
  },

  handleClick: function () {
    var _this = this;
    if (_this.state.share_start == null || _this.state.share_end == null || _this.state.selectedCarId == null) { return false; }

    _this.props.page = 0;
    _this.props.stopLoadItems = false;

    _this.calcLimit();

    this.setState({
      isLoadList: false
    });

    _this.getItems([], function (listElems) {
      _this.setState({
        listElems: listElems,
        isLoadList: true
      });
      _this.props.page++;
    });

    var docYHeight = $('#fuelingList').height();
    var posYBottom = $(window).scrollTop() + $(window).height() + 1;

    $('.btn-primary').blur();

    $(window).off('scroll').on('scroll', function () {
      if (_this.props.stopLoadItems) {
        return false;
      }

      docYHeight = $('#fuelingList').height();
      posYBottom = $(window).scrollTop() + $(window).height() + 1;

      if (docYHeight <= posYBottom) {
        if (_this.props.loading) { return false; }
        _this.props.loading = true;
        _this.setState({
          isInfiniteScrollLoad: true
        });

        _this.getItems(_this.state.listElems || [], function (listElems) {
          _this.props.loading = false;
          _this.setState({
            listElems: listElems,
            isInfiniteScrollLoad: false
          });
          _this.props.page++;
        });
      }
    });
  },

  calcLimit: function () {
    var _this = this;

    var height = window.innerHeight;

    height -= parseFloat($('div.fuelingListTableBox').css('padding-top'));
    height -= parseFloat($('div.fuelingListTableBox').find('header').height());
    height -= parseFloat($('div.fuelingListTableBox').find('header').css('margin-bottom'));
    height -= parseFloat($('#fuelingList').find('ol').height());

    _this.props.limit = Math.ceil(height / 45) + 2;
  },

  getItems: function (listElems, callback) {
    var _this = this;
    var _listElems = listElems;
    var _callback = callback;

    listElems = listElems || [];

    $.ajax({
      url: "@@servletUrl" + "targets/" + _this.state.selectedCarId + "/fuelfillings?fromTime=" + _this.state.startMicros + "&toTime=" + _this.state.endMicros + "&offset=" + (_this.props.page * _this.props.limit) + "&limit=" + _this.props.limit,
      type: "GET",
      timeout: 30000,
      beforeSend: function (xhr) {
        if (_this.props.tokenAuth == true) {
          xhr.setRequestHeader("Authorization", "Token " + localStorage.getItem('tokenId'));
        } else {
          xhr.setRequestHeader("Authorization", "Basic " + localStorage.getItem('user_hash'));
        }
        xhr.setRequestHeader("Accept-Language", localStorage.getItem('lang'));
      },
      success: function (response) {
        //var listElems = _this.state.listElems || [];
        if (typeof response == 'undefined' || response.length == 0) {
          _this.props.stopLoadItems = true;

          if (_this.props.page == 0) {
            _this.addTranslateNotification('emptyFuelList', 'warning');
          }
        }
        if (typeof response !== 'undefined') {
          for (var i in response) {
            listElems.push((
              <li>
                <ul>
                  <li><span className="tableName">{this.getValue('dateTitle')}</span>{moment(response[i].fuelFillingTimeMicrose / 1000).format("L  HH:mm")}</li>
                  <li className="min"><span className="tableName">{this.getValue('country')}</span>{response[i].fuelFillingCountry}</li>
                  <li className="med"><span className="tableName">{this.getValue('position')}</span>{response[i].fuelFillingPosition}</li>
                  <li className="min right"><span className="tableName">{this.getValue('start')}</span>{response[i].fillStartLevel}</li>
                  <li className="min right"><span className="tableName">{this.getValue('end')}</span>{response[i].fillEndLevel}</li>
                  <li className="min right"><span className="tableName">{this.getValue('volume')}</span>{response[i].fillAmount}</li>
                </ul>
              </li>
            ));
          }
        }

        //_this.setState({ listElems: listElems });

        if (typeof callback == 'function') {
          callback(listElems);
        }
      }.bind(this),
      error: function (response, status, xhr) {
        _this.setState({
          isLoadList: null,
          isInfiniteScrollLoad: false
        });

        this.ajaxError(response, status, xhr, this.addNotification, this.addTranslateNotification, this.getItems.bind(this, _listElems, _callback));
      }.bind(this)
    });
  },

  componentWillMount: function () {
    var lang = localStorage.getItem('lang');
    var date = new Date();
    var share_start = moment(new Date(date.getFullYear(), date.getMonth(), 1));
    var share_end = moment(new Date());


    this.setState({
      mobilHeaderTitle: this.getValue('cars'),
      locale: lang,
    });

    moment.locale(lang);

    this.handleShareStartDateChange(share_start);
    this.handleShareEndDateChange(share_end);
  },

  componentDidMount: function () {
    $('.rdt input[type="text"]').focus(function () {
      $(this).blur();
    });
    setTimeout(function () {
      $('#overlay').removeClass('overlay');
      $('#loader').removeClass('loader');
      $('#exitButton').removeClass('exitButton icon-cancel');
    }, 500);
  },

  changeMobilHeaderTitle: function (carPlateNumber) {
    this.setState({
      mobilHeaderTitle: carPlateNumber
    });
  },

  isValidStartDate: function (currentDate, selectedDate) {
    if (currentDate.isAfter(this.state.share_end)) {
      return false;
    } else {
      return true;
    }
  },

  isValidEndDate: function (currentDate, selectedDate) {
    if (this.state.share_start != null) {
      if (currentDate.isBefore(this.state.share_start)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  },

  // Mobilon a vissza gomb lenyomására az aktív gépjárműről törli a kijelőlést
  mobilSelectCar: function () {
    this.refs.carlist.setSelectedCarId(null);
  },

  render: function () {
    var button = (<Button bsStyle='primary' onClick={this.handleClick} >{this.getValue('searchButtonTitle')}</Button>);

    if (this.state.share_start == null || this.state.share_end == null || this.state.selectedCarId == null) {
      button = (<Button bsStyle='primary' id="SearchFuelList" onClick={this.handleClick} disabled >{this.getValue('searchButtonTitle')}</Button>);
    }

    if (this.state.isLoadList == true) {
      var loadElement = (
        <ul>
          {this.state.listElems}
        </ul>
      );
    } else if (this.state.isLoadList == false) {
      var loadElement = (
        <div className='loaderFuelList'><span></span></div>
      );
    } else {
      var loadElement = '';
    }

    if (this.state.isInfiniteScrollLoad == true) {
      var infiniteScrollLoader = (
        <div className='loaderFuelList'><span></span></div>
      );
    } else {
      var infiniteScrollLoader = '';
    }

    return (
      <main id="fuelingPage">
        <MobileHeader mobilMenu={this.refs.left} mobilMenuName={this.getValue('fueling')} />
        <MobilTabs title={this.state.mobilHeaderTitle} changeMobilHeaderTitle={this.changeMobilHeaderTitle} mobilSelectCar={this.mobilSelectCar} />
        <MobilMenu ref="left" alignment="left" activeMenu="tracking" />
        <SideMenu
          lang={this.state.lang}
          activeMenu="fueling"
          getValue={this.getValue}
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
          notification={this.notification}
          notificationSystem={this.notificationSystem}
          visitor={false}
        />
        <CarsList
          id="CarsList"
          ref="carlist"
          handleItemSelect={this.handleItemSelect}
          changeMobilHeaderTitle={this.changeMobilHeaderTitle}
          gaSearchLabel="Tankolási lista"
          addNotification={this.addNotification}
          addTranslateNotification={this.addTranslateNotification}
        />
        <div className="fuelingListTableBox">
          <div className="container-fluid">
            <header>
              <Col xs={12} md={4}>
                <Input label={this.getValue('startDate')} help='' wrapperClassName='wrapper'>
                  <DateTime
                    ref="startDate"
                    timeFormat={false}
                    onChange={this.handleShareStartDateChange}
                    isValidDate={this.isValidStartDate}
                    defaultValue={this.state.share_start}
                    inputProps={this.state.datePickerOptions}
                    locale={this.state.locale}
                  />
                </Input>
              </Col>
              <Col xs={12} md={4}>
                <Input label={this.getValue('endDate')} help='' wrapperClassName='wrapper'>
                  <DateTime
                    ref="endDate"
                    timeFormat={false}
                    onChange={this.handleShareEndDateChange}
                    isValidDate={this.isValidEndDate}
                    defaultValue={this.state.share_end}
                    inputProps={this.state.datePickerOptions}
                    locale={this.state.locale}
                  />
                </Input>
              </Col>
              <Col xs={12} md={4}>
                <Input label=" " help='' wrapperClassName='wrapper'>
                  {button}
                </Input>
              </Col>
            </header>
            <Col xs={12} id="fuelingList">
              <ol>
                <li>{this.getValue('dateTitle')}</li>
                <li className="min">{this.getValue('country')}</li>
                <li className="med">{this.getValue('position')}</li>
                <li className="min right">{this.getValue('start')}</li>
                <li className="min right">{this.getValue('end')}</li>
                <li className="min right">{this.getValue('volume')}</li>
              </ol>

              {loadElement}

              {infiniteScrollLoader}

            </Col>
          </div>
        </div>

        {this.rednderNotification()}
      </main>
    );
  }
});